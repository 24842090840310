import React from 'react';
import SidebarMenu from 'components/SidebarMenu';
import FooterDashboard from 'components/FooterLink/Dashboard';
import { connect } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import IconArrow from 'assets/images/arrow-left.svg';
import { Box, Grid } from '@mui/material';
import Images from 'assets/images/plumbing-with-different-tools.png';
import IconPdf from 'assets/images/pdf.svg';
import IconFile from 'assets/file.svg';
import IconDollar from 'assets/dollar.svg';
import IconTime from 'assets/time.svg';
import IconStar from 'assets/images/star.svg';
import ImageProfile from 'assets/images/profile-img-2.jpg';
import IconLocation from 'assets/location.svg';
import IconCalendar from 'assets/calendar.svg';
const JobDetail = () => {
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner-wrapper project-detail">
              <div className="nav-heading">
                <h1 className="title-2 d-flex mt-0">
                  <a href="#">
                    <img src={IconArrow} alt="" />
                  </a>
                  <span className="pl-1"> Project Details</span>
                </h1>
              </div>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <h3 className="subtitle">HVAC contractor for Atrium Business Center</h3>
                  <p>
                    Material, human and time resources, with various technical and organizational
                    difficulties, are used to carry out design and construction and installation
                    work (CMP). At the same time, situations often arise when a contractor for a
                    project may act in bad faith, take another more profitable order and not start
                    work on a previously concluded contract
                  </p>
                  <p>
                    At the same time, situations often arise when a contractor for a project may act
                    in bad faith, take another more profitable order and not start work on a
                    previously concluded contract
                  </p>

                  <hr />

                  <h4 className="subtitle mt-4">Company Information</h4>
                  <hr className="m-0" />
                  <div className="preference-list">
                    <div className="mui-row mui-row d-flex items-center">
                      <div className="mui-col-xs-4">Company name:</div>
                      <div className="mui-col-xs-8 text-dark">Corporation Inc</div>
                    </div>
                  </div>
                  <div className="preference-list">
                    <div className="mui-row mui-row d-flex items-center">
                      <div className="mui-col-xs-4">City:</div>
                      <div className="mui-col-xs-8 text-dark">Los Angeles</div>
                    </div>
                  </div>
                  <div className="preference-list">
                    <div className="mui-row mui-row d-flex items-center">
                      <div className="mui-col-xs-4">State:</div>
                      <div className="mui-col-xs-8 text-dark">California</div>
                    </div>
                  </div>
                  <div className="preference-list">
                    <div className="mui-row mui-row d-flex items-center">
                      <div className="mui-col-xs-4">Website:</div>
                      <div className="mui-col-xs-8 text-dark">www.corporationinc.com</div>
                    </div>
                  </div>
                  <div className="preference-list">
                    <div className="mui-row mui-row d-flex items-center">
                      <div className="mui-col-xs-4">ZIP Code:</div>
                      <div className="mui-col-xs-8 text-dark">333124</div>
                    </div>
                  </div>

                  <h4 className="subtitle-2 mt-4">Photos</h4>
                  <div className="project-photo">
                    <div className="photo-item">
                      <div className="images-box">
                        <img src={Images} alt="" />
                      </div>
                    </div>
                    <div className="photo-item">
                      <div className="images-box">
                        <img src={Images} alt="" />
                      </div>
                    </div>
                    <div className="photo-item">
                      <div className="images-box">
                        <img src={Images} alt="" />
                      </div>
                    </div>
                    <div className="photo-item">
                      <div className="images-box">
                        <img src={Images} alt="" />
                      </div>
                    </div>
                  </div>

                  <hr />

                  <h4 className="subtitle-2">Files</h4>

                  <div className="files mb-4">
                    <div className="file-container">
                      <img src={IconPdf} alt="" />
                    </div>
                    <div className="file-container">
                      <img src={IconPdf} alt="" />
                    </div>
                    <div className="file-container">
                      <img src={IconPdf} alt="" />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="list-icon">
                      <div className="ico-pps">
                        <img src={IconFile} alt="" />
                      </div>
                      <div>Terms: Hourly</div>
                    </div>
                    <div className="list-icon">
                      <div className="ico-pps">
                        <img src={IconDollar} alt="" />
                      </div>
                      <div>Price: Negotiated</div>
                    </div>
                    <div className="list-icon">
                      <div className="ico-pps">
                        <img src={IconTime} alt="" />
                      </div>
                      <div>Estimated Start Date: 2 weeks</div>
                    </div>
                  </div>

                  <h4 className="subtitle-2">Trade</h4>
                  <div className="mb-4">
                    <span className="tag-label grey">Large Building Construction</span>
                    <span className="tag-label grey">HVAC</span>
                  </div>

                  <h3 className="subtitle">Other open Available Project Ads by the Contractor</h3>
                  <div className="d-flex">
                    <div className="underline">Plumbing team needed</div>
                    <div className="pl-2">
                      <p className="text-grey">
                        <strong>$3.450</strong>
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="underline">Roof repair on a large square</div>
                    <div className="pl-2">
                      <p className="text-grey">
                        <strong>Negotiated</strong>
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="side-detail">
                    <Grid container justify="flex-end" className="mb-2">
                      <button className="btn fav-btn ml-auto">
                        <img src={IconStar} alt="" />
                      </button>
                    </Grid>
                    <button className="btn btn-primary w-100">Quick Apply</button>

                    <h3 className="mt-3">About the Contractor</h3>

                    <div className="profile-project-detail">
                      <div className="profile-img">
                        <div className="images-box">
                          <img src={ImageProfile} alt="" />
                        </div>
                      </div>
                      <div className="w-100 pl-1">
                        <p>General Contruction Inc.</p>
                        <div className="d-flex">
                          <div>
                            <img src={IconLocation} alt="" />
                          </div>
                          <div className="pl-1">10005 New York, US</div>
                        </div>
                        <p className="text-grey profile-role">General Contractor</p>
                      </div>
                    </div>
                    <hr />

                    <div className="d-flex mb-1">
                      <div style={{ width: '30px' }}>
                        <img src={IconCalendar} alt="" />
                      </div>
                      <div>Posted 8 hours ago</div>
                    </div>
                    <div className="d-flex mb-1">
                      <div style={{ width: '30px' }}>
                        <img src={IconLocation} alt="" />
                      </div>
                      <div>10005, New York, NY</div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(JobDetail);

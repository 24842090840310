import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authState } from 'store/slices/appSlice';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  DEFAULT_IMAGE,
  JOB_SEEKER,
  RECRUITER,
  GENERAL_CONTRACTOR,
  SUB_CONTRACTOR,
  SECOND_TIER_CONTRACTOR
} from 'constant';
import IconPlus from 'assets/plus.svg';
import FooterLink from 'components/FooterLink';
import { v4 as uuid } from 'uuid';
import { HandleGetUserProfessionRequest, HandleGetUserTradeLicenseRequest } from 'api/callsHandler';
import { getRequestDefault } from 'utils/calls';
import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PersonalInformation from 'views/Profile/PersonalInformation';
import Location from 'views/Profile/Location';
import Language from 'views/Profile/Language';
import JobSeekerUploads from 'views/Profile/JobSeekerUploads';
import RecruiterUploads from 'views/Profile/RecruiterUploads';
import OtherUploads from 'views/Profile/OtherUploads';
import About from 'views/Profile/About';
import Industry from 'views/Profile/Industry';
import RecruiterCompanyContactInformation from 'views/Profile/RecruiterCompanyContactInformation';
import OtherCompanyContactInformation from 'views/Profile/OtherCompanyContactInformation';
import Professions from 'views/Profile/Professions';
import TradeAffiliation from 'views/Profile/TradeAffiliation';
import ConstructionTrades from 'views/Profile/ConstructionTrades';
import SkillSet from 'views/Profile/SkillSet';
import Education from 'views/Profile/Education';
import Experience from 'views/Profile/Experience';
import TradeLicenseCertificates from 'views/Profile/TradeLicenseCertificates';
import ConstructionBond from 'views/Profile/ConstructionBond';
import MinorityOwnedCertified from 'views/Profile/MinorityOwnedCertified';
import RecruiterGeneralContractorCompanyInformation from 'views/Profile/RecruiterGeneralContractorCompanyInformation';
import Subcontractor2ndTierSubcontractorCompanyInformation from 'views/Profile/Subcontractor2ndTierSubcontractorCompanyInformation';
import SidebarMenu from 'components/SidebarMenu';
import { usePostMultipleMutation } from 'store/slices/apiSlice';

import {
  patchCompanyRequest,
  patchProfileJsonRequest,
  patchProfileRequest,
  patchUserProfessionRequest,
  patchUserTradeLicenseRequest,
  postCompanyRequest,
  postUserProfessionRequest,
  postUserTradeLicenseRequest
} from 'utils/calls';
import { routerPath } from 'router/Routerlist';

const ContractorProfile = () => {
  const [selectedState, setSelectedState] = useState();
  const [tradeLicenses, setTradeLicenses] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [profileData, setProfileData] = useState(undefined);
  const [imageArray, setImageArray] = useState([]);
  const [isFetchProfileTriggered, setFetchProfileTriggered] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector(authState);
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    // name: yup.string().required('Required'),
    name: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    age: yup.number().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.typeError('Age must be number!.').required('Required'),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    gender: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    company_name: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.notRequired().nullable(),
      otherwise: (schema) => schema.required('Required')
    }),
    company_email: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.notRequired().nullable()
      // otherwise: (schema) => schema.required('Required')
    }),
    company_website: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.notRequired().nullable(),
      otherwise: (schema) =>
        schema
          .notRequired()
          .nullable()
          .test(
            'is-valid-url',
            'Please enter a valid website URL',
            (value) =>
              !value || /^(https?:\/\/)?([\w\d\-]+\.)+[\w\d\-]+(\/[\w\d\-\.]+)*\/?$/.test(value)
          )
    }),
    company_zipcode: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.notRequired().nullable(),
      otherwise: (schema) =>
        schema
          .required('Required')
          .matches(/^[0-9]{5}/, { message: 'Enter correct zipcode!', excludeEmptyString: true })
    }),
    company_city: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.notRequired().nullable(),
      otherwise: (schema) => schema.typeError('Required').required('Required')
    }),
    company_state: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.notRequired().nullable(),
      otherwise: (schema) => schema.typeError('Required').required('Required')
    }),
    company_phone_number: yup.string().when([], {
      is: () => [JOB_SEEKER, RECRUITER].includes(profileData?.data?.user_type),
      then: (schema) => schema.notRequired().nullable(),
      otherwise: (schema) =>
        schema
          .nullable()
          .test(
            'is-valid-phone',
            'Phone number must be 10 digits',
            (value) => !value || (/^\d+$/.test(value) && value.length === 10)
          )
    }),
    company_fax: yup.string().when([], {
      is: () => [JOB_SEEKER, RECRUITER].includes(profileData?.data?.user_type),
      then: (schema) => schema.notRequired().default(0).nullable(),
      otherwise: (schema) => schema.typeError('Fax number must be number.')
    }),
    travel_distance: yup.string().when([], {
      is: () => [SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(profileData?.data?.user_type),
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    distance: yup.string().notRequired().nullable(),
    zipcode: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) =>
        schema
          .required('Required')
          .matches(/^[0-9]{5}/, { message: 'Enter correct zipcode!', excludeEmptyString: true }),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    city: yup.number().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.typeError('City must be Required').required('Required'),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    state: yup.number().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.typeError('Required').required('Required'),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    language: yup.number().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.typeError('Required').required('Required'),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    secondary_language: yup.number().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.typeError('Required').nullable(),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    company_about: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.notRequired().nullable(),
      otherwise: (schema) => schema.required('Required')
    }),
    about_me: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    industry: yup
      .array()
      .default([])
      .when([], {
        is: () => profileData?.data?.user_type === RECRUITER,
        then: (schema) => schema.notRequired().nullable(),
        otherwise: (schema) => schema.required('Required')
      }),
    construction_trade: yup
      .array()
      .default([])
      .when([], {
        is: () => [SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(profileData?.data?.user_type),
        then: (schema) => schema.required('Required'),
        otherwise: (schema) => schema.notRequired().nullable()
      }),
    labor_affiliation: yup.string().when([], {
      is: () => [RECRUITER, GENERAL_CONTRACTOR].includes(profileData?.data?.user_type),
      then: (schema) => schema.notRequired().nullable(),
      otherwise: (schema) => schema.required('Required')
    }),
    construction_bond: yup.boolean().when([], {
      is: () => [SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(profileData?.data?.user_type),
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    minority_owned_certificate: yup.boolean().when([], {
      is: () => [SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(profileData?.data?.user_type),
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    user_skill_set: yup
      .array()
      .default([])
      .when([], {
        is: () => profileData?.data?.user_type === JOB_SEEKER,
        then: (schema) => schema.required('Required'),
        otherwise: (schema) => schema.notRequired().nullable()
      }),
    user_education: yup
      .array()
      .default([])
      .when([], {
        is: () => profileData?.data?.user_type === JOB_SEEKER,
        then: (schema) => schema.required('Required'),
        otherwise: (schema) => schema.notRequired().nullable()
      }),
    experience: yup.string().when([], {
      is: () => profileData?.data?.user_type === JOB_SEEKER,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired().nullable()
    })
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    trigger,
    getFieldState
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      company_logo: DEFAULT_IMAGE,
      image_objects: DEFAULT_IMAGE,
      profile_image: DEFAULT_IMAGE,
      resume: DEFAULT_IMAGE,
      industry: [],
      construction_bond: false,
      gender: null,
      minority_owned_certificate: false
    }
  });

  const { data: userTradeLicenseData, mutate: userTradeLicenseMutate } =
    HandleGetUserTradeLicenseRequest();

  const { data: userProfessionData, mutate: userProfessionMutate } =
    HandleGetUserProfessionRequest();

  const [multipleUpload] = usePostMultipleMutation();

  useEffect(() => {
    function initUserTradeLicenseArray() {
      if (userTradeLicenseData) {
        setTradeLicenses(
          userTradeLicenseData?.data?.results
            .map((item) => ({
              id: String(item.id),
              trade_license: item.trade_license
            }))
            .concat(
              [...Array(6 - userTradeLicenseData?.data?.count).keys()].map(() => ({
                id: `tmp_${uuid()}`,
                trade_license: ''
              }))
            )
        );
      }
    }
    initUserTradeLicenseArray();
  }, [userTradeLicenseData]);

  useEffect(() => {
    function initUserProfessionArray() {
      if (userProfessionData) {
        setProfessions(
          userProfessionData?.data?.results
            .map((item) => ({
              id: String(item.id),
              profession: item.profession
            }))
            .concat(
              [...Array(6 - userProfessionData?.data?.count).keys()].map(() => ({
                id: `tmp_${uuid()}`,
                profession: ''
              }))
            )
        );
      }
    }
    initUserProfessionArray();
  }, [userProfessionData]);

  const { authenticated } = auth;

  async function fetchProfile() {
    getRequestDefault(`/profile/${authenticated?.id}/`).then((res) => {
      setProfileData(res);
    });
  }

  useEffect(() => {
    if (isFetchProfileTriggered) {
      fetchProfile();
      setFetchProfileTriggered(false);
    }
  }, [isFetchProfileTriggered]);

  // useEffect(() => {
  //   if (isFetchProfileTriggered) {
  //     fetchProfile();

  //   }

  // }, [isFetchProfileTriggered]);

  useEffect(() => {
    function initData() {
      if (profileData) {
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: true
          }
        });
        setValue('name', profileData?.data?.name ?? '');
        setValue('age', profileData?.data?.age ?? null);
        setValue('gender', profileData?.data?.gender ?? '');
        // -------------------------------------------
        setValue('company_name', profileData?.data?.company?.name ?? '');
        setValue('company_website', profileData?.data?.company?.website ?? '');
        setValue('company_zipcode', profileData?.data?.company?.zipcode ?? '');
        setValue('company_city', profileData?.data?.company?.city ?? null);
        setValue('company_state', profileData?.data?.company?.state ?? null);
        setValue('company_email', profileData?.data?.company?.email ?? '');
        setValue('company_phone_number', profileData?.data?.company?.phone_number ?? '');
        setValue('company_fax', profileData?.data?.company?.fax ?? '');
        setValue('travel_distance', profileData?.data?.company?.travel_distance ?? '');
        // -------------------------------------------
        setValue('distance', profileData?.data?.distance ?? '---');
        setValue('zipcode', profileData?.data?.zipcode ?? '');
        setValue('city', profileData?.data?.city ?? null);
        setValue('state', profileData?.data?.state ?? null);
        // -------------------------------------------
        if (profileData?.data?.user_type !== JOB_SEEKER && profileData?.data?.company?.state) {
          setSelectedState(profileData?.data?.company?.state);
        }
        if (profileData?.data?.user_type == JOB_SEEKER && profileData?.data?.state) {
          setSelectedState(profileData?.data?.state);
        }
        // -------------------------------------------
        setValue(
          'language',
          profileData?.data?.language?.length > 0 ? profileData?.data?.language?.[0]?.id : null
        );
        setValue(
          'secondary_language',
          profileData?.data?.secondary_language?.length > 0
            ? profileData?.data?.secondary_language?.[0]?.id
            : null
        );
        // -------------------------------------------
        setValue('company_logo', profileData?.data?.company?.logo ?? DEFAULT_IMAGE);
        setValue('image_objects', DEFAULT_IMAGE);
        setValue('profile_image', profileData?.data?.profile_image ?? DEFAULT_IMAGE);
        setValue('resume', profileData?.data?.resume ?? DEFAULT_IMAGE);
        // -------------------------------------------
        setValue('company_about', profileData?.data?.company?.about ?? '');
        setValue('about_me', profileData?.data?.about_me ?? '');
        // -------------------------------------------
        setValue('industry', profileData?.data?.industry?.map((item) => item.id) ?? []);
        // -------------------------------------------
        setValue(
          'construction_trade',
          profileData?.data?.construction_trade?.map((item) => item.id) ?? []
        );
        // -------------------------------------------
        setValue('labor_affiliation', profileData?.data?.labor_affiliation ?? '');
        // -------------------------------------------
        setValue('construction_bond', profileData?.data?.construction_bond ?? false);
        // -------------------------------------------
        setValue(
          'minority_owned_certificate',
          profileData?.data?.minority_owned_certificate ?? false
        );
        // -------------------------------------------
        setValue('user_skill_set', profileData?.data?.user_skill_set?.map((item) => item.id) ?? []);
        // -------------------------------------------
        setValue('user_education', profileData?.data?.user_education?.map((item) => item.id) ?? []);
        // -------------------------------------------
        setValue('experience', profileData?.data?.experience ? +profileData?.data?.experience : '');

        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: false
          }
        });
      }
    }
    initData();
  }, [dispatch, profileData, setValue]);

  const onSubmitProfile = async (data) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    });

    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    });

    let companyObject = {
      year: profileData?.data?.company?.year ?? moment(new Date()).format('YYYY-MM-DD'),
      image_objects: []
    };

    let profileObject = {
      name: data.name
    };

    let profileJsonObject = {};

    if (profileData?.data?.user_type === JOB_SEEKER) {
      // profileObject['name'] = data.name;
      profileObject['age'] = data.age;
      profileObject['gender'] = data.gender;
      profileObject['zipcode'] = data.zipcode;
      profileObject['city'] = data.city;
      profileObject['state'] = data.state;
      profileObject['about_me'] = data.about_me;
    }

    if (profileData?.data?.user_type === JOB_SEEKER) {
      profileJsonObject['language'] = [data?.language];
      profileJsonObject['secondary_language'] = [data?.secondary_language];
      profileJsonObject['distance'] = data.distance === '---' ? null : data.distance;
      profileJsonObject['user_skill_set'] = data?.user_skill_set;
      profileJsonObject['user_education'] = data?.user_education;
      profileJsonObject['experience'] = data?.experience;
    }

    if (profileData?.data?.user_type !== RECRUITER) {
      profileJsonObject['industry'] = data?.industry;
    }

    if ([SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(profileData?.data?.user_type)) {
      profileJsonObject['construction_trade'] = data?.construction_trade;
    }

    if (profileData?.data?.user_type !== JOB_SEEKER) {
      companyObject['name'] = data.company_name;
      companyObject['website'] = data.company_website;
      companyObject['zipcode'] = data.company_zipcode;
      companyObject['city'] = data.company_city;
      companyObject['state'] = data.company_state;
      companyObject['email'] = data.company_email;
      companyObject['about'] = data.company_about;
    }

    if (![JOB_SEEKER, RECRUITER].includes(profileData?.data?.user_type)) {
      companyObject['phone_number'] = data.company_phone_number;
      companyObject['fax'] = data.company_fax;
    }

    if ([SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(profileData?.data?.user_type)) {
      companyObject['travel_distance'] = data.travel_distance;
    }

    if (
      [JOB_SEEKER, RECRUITER].includes(profileData?.data?.user_type) &&
      data?.profile_image &&
      typeof data?.profile_image !== 'string'
    ) {
      profileObject['profile_image'] = data?.profile_image;
    }

    if (
      profileData?.data?.user_type === JOB_SEEKER &&
      data?.resume &&
      typeof data?.resume !== 'string'
    ) {
      profileObject['resume'] = data?.resume;
    }

    if (![RECRUITER, GENERAL_CONTRACTOR].includes(profileData?.data?.user_type)) {
      profileObject['labor_affiliation'] = data?.labor_affiliation;
    }

    if ([SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(profileData?.data?.user_type)) {
      profileObject['construction_bond'] = data?.construction_bond;
      profileObject['minority_owned_certificate'] = data?.minority_owned_certificate;
    }
    if (
      ![JOB_SEEKER, RECRUITER].includes(profileData?.data?.user_type) &&
      data?.company_logo &&
      typeof data?.company_logo !== 'string'
    ) {
      companyObject['logo'] = data?.company_logo;
    }

    if (
      ![JOB_SEEKER, RECRUITER].includes(profileData?.data?.user_type) &&
      data?.image_objects &&
      typeof data?.image_objects !== 'string'
    ) {
      imageArray.map((item, index) => {
        companyObject.image_objects.push(item);
      });
    }

    if (
      [JOB_SEEKER, RECRUITER].includes(profileData?.data?.user_type) &&
      data?.user_media &&
      typeof data?.user_media !== 'string'
    ) {
      await multipleUpload(imageArray);
    }

    Promise.all([
      profileData?.data?.user_type !== JOB_SEEKER
        ? profileData?.data?.company?.id
          ? patchCompanyRequest(profileData?.data?.company?.id, companyObject).catch((err) => err)
          : postCompanyRequest(companyObject).catch((err) => err)
        : null,
      profileData?.data?.user_type === JOB_SEEKER
        ? professions
            .filter((item) => String(item.id).startsWith('tmp') && item.profession.trim() !== '')
            .map((item) =>
              postUserProfessionRequest({ profession: item.profession }).catch((err) => err)
            )
        : null,

      // // Update Trade licenses
      profileData?.data?.user_type === JOB_SEEKER
        ? professions
            .filter((item) => !String(item.id).startsWith('tmp') && item.profession.trim() !== '')
            .map((item) =>
              patchUserProfessionRequest(item.id, { profession: item.profession }).catch(
                (err) => err
              )
            )
        : null,

      ![RECRUITER, GENERAL_CONTRACTOR].includes(profileData?.data?.user_type)
        ? tradeLicenses
            .filter((item) => String(item.id).startsWith('tmp') && item.trade_license.trim() !== '')
            .map((item) =>
              postUserTradeLicenseRequest({ trade_license: item.trade_license }).catch((err) => err)
            )
        : null,

      // // Update Trade licenses
      ![RECRUITER, GENERAL_CONTRACTOR].includes(profileData?.data?.user_type)
        ? tradeLicenses
            .filter(
              (item) => !String(item.id).startsWith('tmp') && item.trade_license.trim() !== ''
            )
            .map((item) =>
              patchUserTradeLicenseRequest(item.id, { trade_license: item.trade_license }).catch(
                (err) => err
              )
            )
        : null,

      patchProfileRequest(authenticated?.id, profileObject).catch((err) => err),
      patchProfileJsonRequest(authenticated?.id, profileJsonObject).catch((err) => err)
    ])
      .then((data, status) => {
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'alertSeverity',
            value: 'success'
          }
        });

        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'errors',
            value: ['Profile updated successfully']
          }
        });

        const errorRes = data.filter((res) => res?.status >= 400).map((res) => res.body);

        if (errorRes.length === 0) {
          fetchProfile();
          userTradeLicenseMutate();
          userProfessionMutate();
          navigate(routerPath.jobs.alljob);
        } else {
          return errorRes;
        }
      })
      .then((res) => {
        if (res?.length > 0) {
          const reader = res[0].getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return reader.read().then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return;
                  }
                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
              }
            }
          });
        }
      })
      .then((stream) => {
        if (stream) {
          return new Response(stream);
        }
      })
      // Create an object URL for the response
      .then(async (res) => {
        if (res) {
          throw new Error(await res.text());
        }
      })
      .catch((err) => {
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'alertSeverity',
            value: 'error'
          }
        });

        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'errors',
            value: [err.message]
          }
        });
      })
      .finally(() => {
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: false
          }
        });
      });
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'state' || name === 'company_state') {
        setSelectedState(value[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleTriggerFetchProfile = () => setFetchProfileTriggered(true);

  return (
    <Box>
      <div className="wrapper">
        <div className="user-dashboard">
          {/* <div className="header-second">
          <HeaderLogo />
        </div> */}
          <SidebarMenu name={profileData} />

          <div className="content-dashboard">
            <Container className="text-black">
              <Grid container>
                <Grid item xs={12} sx={{ mt: 6 }}>
                  <Stack direction={'row'} spacing={2}>
                    {/* <IconButton
                      onClick={() => {
                        if (profileData?.data?.name !== null) {
                          navigate(routerPath.settings.profilepreference);
                        }
                      }}>
                      <ArrowBackIcon />
                    </IconButton> */}
                    <Typography fontSize={'28px'} sx={{ fontWeight: '600' }}>
                      {profileData?.data?.user_type} Profile
                    </Typography>
                  </Stack>
                </Grid>
                {profileData?.data?.user_type === JOB_SEEKER && (
                  <PersonalInformation control={control} errors={errors} loading={loading} />
                )}
                {profileData?.data?.user_type === RECRUITER && (
                  <RecruiterCompanyContactInformation
                    control={control}
                    errors={errors}
                    loading={loading}
                  />
                )}
                {[SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(
                  profileData?.data?.user_type
                ) && (
                  <Subcontractor2ndTierSubcontractorCompanyInformation
                    control={control}
                    errors={errors}
                    loading={loading}
                    selectedState={selectedState}
                  />
                )}
                {profileData?.data?.user_type === GENERAL_CONTRACTOR && (
                  <RecruiterGeneralContractorCompanyInformation
                    control={control}
                    errors={errors}
                    loading={loading}
                    selectedState={selectedState}
                  />
                )}
                {profileData?.data?.user_type !== RECRUITER &&
                  profileData?.data?.user_type !== JOB_SEEKER && (
                    <OtherCompanyContactInformation
                      control={control}
                      errors={errors}
                      loading={loading}
                    />
                  )}
                {profileData?.data?.user_type === JOB_SEEKER && (
                  <Location
                    control={control}
                    errors={errors}
                    loading={loading}
                    selectedState={selectedState}
                  />
                )}
                {profileData?.data?.user_type === JOB_SEEKER && (
                  <Language control={control} errors={errors} loading={loading} />
                )}

                <Grid container>
                  <Grid item md={4}>
                    {profileData?.data?.user_type === JOB_SEEKER ? (
                      <JobSeekerUploads
                        control={control}
                        errors={errors}
                        profileData={profileData?.data}
                        setImageArray={setImageArray}
                        imageArray={imageArray}
                        handleTriggerFetchProfile={handleTriggerFetchProfile}
                      />
                    ) : profileData?.data?.user_type === RECRUITER ? (
                      <RecruiterUploads
                        control={control}
                        errors={errors}
                        profileData={profileData?.data}
                        setImageArray={setImageArray}
                        imageArray={imageArray}
                        handleTriggerFetchProfile={handleTriggerFetchProfile}
                      />
                    ) : (
                      <OtherUploads
                        control={control}
                        errors={errors}
                        profileData={profileData?.data}
                        setImageArray={setImageArray}
                        imageArray={imageArray}
                        handleTriggerFetchProfile={handleTriggerFetchProfile}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item md={8}>
                  <About
                    control={control}
                    errors={errors}
                    title={
                      profileData?.data?.user_type === JOB_SEEKER
                        ? 'About me'
                        : profileData?.data?.user_type === RECRUITER
                        ? 'About'
                        : 'About The Company'
                    }
                    subtitle={
                      profileData?.data?.user_type === JOB_SEEKER
                        ? 'Description:'
                        : profileData?.data?.user_type === RECRUITER
                        ? 'Input description text'
                        : 'Description:'
                    }
                    param={
                      profileData?.data?.user_type === JOB_SEEKER ? 'about_me' : 'company_about'
                    }
                    loading={loading}
                  />
                </Grid>
                {profileData?.data?.user_type === RECRUITER && (
                  <RecruiterGeneralContractorCompanyInformation
                    control={control}
                    errors={errors}
                    loading={loading}
                    selectedState={selectedState}
                  />
                )}
                {profileData?.data?.user_type !== RECRUITER && (
                  <Industry control={control} errors={errors} />
                )}
                {(profileData?.data?.user_type === SUB_CONTRACTOR ||
                  profileData?.data?.user_type === SECOND_TIER_CONTRACTOR) && (
                  <ConstructionTrades control={control} errors={errors} />
                )}
                {profileData?.data?.user_type === JOB_SEEKER && (
                  <Professions
                    fetchProfile={fetchProfile}
                    professions={professions}
                    setProfessions={setProfessions}
                    userProfessionMutate={userProfessionData}
                  />
                )}
                <Grid item md={4}>
                  {profileData?.data?.user_type !== RECRUITER &&
                    profileData?.data?.user_type !== GENERAL_CONTRACTOR && (
                      <TradeAffiliation
                        control={control}
                        errors={errors}
                        title={
                          profileData?.data?.user_type === JOB_SEEKER
                            ? 'Trade Affiliation'
                            : 'Labor Affiliation'
                        }
                      />
                    )}
                </Grid>
                <Grid item md={4}>
                  {(profileData?.data?.user_type === SUB_CONTRACTOR ||
                    profileData?.data?.user_type === SECOND_TIER_CONTRACTOR) && (
                    <ConstructionBond control={control} errors={errors} />
                  )}
                </Grid>
                <Grid item md={4}>
                  {(profileData?.data?.user_type === SUB_CONTRACTOR ||
                    profileData?.data?.user_type === SECOND_TIER_CONTRACTOR) && (
                    <MinorityOwnedCertified control={control} errors={errors} />
                  )}
                </Grid>
                {profileData?.data?.user_type === JOB_SEEKER && (
                  <SkillSet control={control} errors={errors} />
                )}
                {profileData?.data?.user_type === JOB_SEEKER && (
                  <Education control={control} errors={errors} />
                )}
                {profileData?.data?.user_type === JOB_SEEKER && (
                  <Experience control={control} errors={errors} />
                )}
                <Grid container>
                  {profileData?.data?.user_type !== RECRUITER &&
                    profileData?.data?.user_type !== GENERAL_CONTRACTOR && (
                      <TradeLicenseCertificates
                        fetchProfile={fetchProfile}
                        setTradeLicenses={setTradeLicenses}
                        tradeLicenses={tradeLicenses}
                        userTradeLicenseMutate={userTradeLicenseMutate}
                      />
                    )}
                </Grid>
                <Grid item md={12}>
                  <button
                    style={{ marginTop: 20 }}
                    className="btn btn-success mr-1"
                    onClick={() =>
                      trigger().then((res, err) => {
                        if (res) {
                          handleSubmit(onSubmitProfile)();
                        } else {
                          // debugger;
                          let elem = errors[Object.keys(errors)[0]]?.ref;

                          console.log('field state', getFieldState('name'), err);
                          console.log('error object', getFieldState('name').error);
                          console.log(errors);
                        }
                      })
                    }>
                    <img src={IconPlus} className="bottom mr-1" alt="Pluse_Icon" /> Finish
                  </button>
                </Grid>
              </Grid>
            </Container>

            <Box item md={12} mt={6}>
              <FooterLink />
            </Box>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default ContractorProfile;

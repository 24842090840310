import React from 'react';
import './App.css';
import 'styles/index.scss';

import { Provider } from 'react-redux';
import configureStore from 'store/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { RootRouter } from 'router/RootRouter';

import { CookiesProvider } from 'react-cookie';
import index from 'styles/index.scss';
import mui from 'styles/homepage/scss/mui.scss';
import styles from 'styles/homepage/scss/styles.scss';
import MainLoaderPage from 'components/MainLoader/Page';
import { ThemeProvider } from '@mui/material';
import { theme } from 'theme/theme';
import { BrowserRouter } from 'react-router-dom';

const { persistor, store } = configureStore();

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <CookiesProvider>
                <RootRouter />
                <MainLoaderPage />
              </CookiesProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </>
  );
}

export default App;

import React, { useEffect, useMemo, useState } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import FooterDashboard from 'components/FooterLink/Dashboard';
import { connect, useDispatch } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import IconArrow from 'assets/images/arrow-left.svg';
import { Box, Grid } from '@mui/material';
import Images from 'assets/images/plumbing-with-different-tools.png';
import IconPdf from 'assets/images/pdf.svg';
import IconFile from 'assets/file.svg';
import StarOff from 'assets/start-outline.svg';

import IconDollar from 'assets/dollar.svg';
import IconTime from 'assets/time.svg';
import IconStar from 'assets/images/star.svg';
import ImageProfile from 'assets/images/profile-img-2.jpg';
import IconLocation from 'assets/location.svg';

import {
  useGetDetailProjectsQuery,
  useGetDetailPerProjectQuery,
  usePatchMyProjectMutation,
  usePostAddCardMutation,
  usePostPaymentChargeMutation,
  usePostSubmitReportMutation,
  usePostQuickApplyProjectMutation
} from 'store/slices/apiSlice';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { HandleGetAllCardsRequest } from 'api/callsHandler';
import ChargePaymentComponent from 'components/Payment/charge';
import FeaturedJobList from 'components/Job/FeaturedJobList';
import PricingList from 'components/Job/PricingList';
import { errorValidationHandler } from 'utils/errorHandler';
import { JOB_SEEKER, POST_TYPE, RECRUITER } from 'constant';
import stateData from 'constant/state.json';
import cityData from 'constant/city.json';
import { routerPath } from 'router/Routerlist';
import { formatDescription } from 'utils/helper';
import { api } from 'utils/axiosApi';

const subscribeProjectSteps = ['details', 'post-price', 'post-featured', 'charge-project'];

const ProjectDetail = ({ auth }) => {
  const userData = auth?.authenticated;
  console.log(userData, 'userdataaa');
  const [isCancelingSubscriptionThisProject, setIsCancelingSubscriptionThisProject] =
    useState(false);
  const [isSubscribingThisProject, setIsSubscribingThisProject] = useState(false);
  const [isReSubscribingThisProject, setIsReSubscribingThisProject] = useState(false);
  const [activePage, setActivePage] = useState(subscribeProjectSteps[0]);
  const [errorPrice, setErrorPrice] = useState(false);
  const [isCameFromSearchPage, setIsCameFromSearchPage] = useState(undefined);
  const [postSubmitReport] = usePostSubmitReportMutation();
  const [
    postQuickApplyJob,
    {
      isLoading: loadingApplyJob,
      isError: isErrorApplyJob,
      error: errorApplyJob,
      isSuccess: isSuccessQuickApply
    }
  ] = usePostQuickApplyProjectMutation();
  const isImage = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase().split('?')[0]; // Get the file extension from the URL
    const imageExtensions = ['png', 'jpeg', 'jpg', 'gif'];
    const videoExtensions = ['mp4', 'mov', 'wmv', 'avi'];

    if (imageExtensions.includes(extension)) {
      return true;
    } else if (videoExtensions.includes(extension)) {
      return false;
    } else {
      return false;
    }
  };

  // const { isFavorite } = route?.params || {};

  const [payload, setPayload] = useState({
    files: [],
    photos: [],
    category: null,
    estimated_start_date: new Date(),
    isAgreeRule: false,
    sub_id: undefined,
    addon_id: undefined
  });

  const dispatch = useDispatch();

  const { project_id } = useParams();
  const location = useLocation();

  let isFavorite = location?.state?.isFavorite;

  const {
    data: projectDetailDataSearchPage,
    isLoading: projectDetailIsLoadingSearchPage,
    refetch: projectDetailRefetchSearchPage,
    currentData: projectDetailCurrentDataSearchPage
  } = useGetDetailProjectsQuery({
    id: project_id,
    cancelCall: [undefined, false].includes(isCameFromSearchPage)
  });

  const {
    data: projectDetailDataManagePage,
    isLoading: projectDetailIsLoadingManagePage,
    refetch: projectDetailRefetchManagePage,
    currentData: projectDetailCurrentDataManagePage
  } = useGetDetailPerProjectQuery({
    id: project_id,
    cancelCall: [undefined, true].includes(isCameFromSearchPage)
  });

  const {
    data: allCardsData,
    isLoading: isLoadingGetAllCards,
    mutate: refetchGetAllCards
  } = HandleGetAllCardsRequest();

  useEffect(() => {
    if (location.pathname.includes('manage')) {
      setIsCameFromSearchPage(false);
    } else {
      setIsCameFromSearchPage(true);
    }
  }, [location, setIsCameFromSearchPage]);

  const detailPerProject = useMemo(
    () => (isCameFromSearchPage ? projectDetailDataSearchPage : projectDetailDataManagePage),
    [projectDetailDataSearchPage, projectDetailDataManagePage, isCameFromSearchPage]
  );
  const isLoadingProject = useMemo(
    () =>
      isCameFromSearchPage ? projectDetailIsLoadingSearchPage : projectDetailIsLoadingManagePage,
    [projectDetailIsLoadingSearchPage, projectDetailIsLoadingManagePage, isCameFromSearchPage]
  );
  const refetch = useMemo(
    () => (isCameFromSearchPage ? projectDetailRefetchSearchPage : projectDetailRefetchManagePage),
    [projectDetailRefetchSearchPage, projectDetailRefetchManagePage, isCameFromSearchPage]
  );
  const currentData = useMemo(
    () =>
      isCameFromSearchPage
        ? projectDetailCurrentDataSearchPage
        : projectDetailCurrentDataManagePage,
    [projectDetailCurrentDataSearchPage, projectDetailCurrentDataManagePage, isCameFromSearchPage]
  );

  const [
    postPaymentCharge,
    {
      isSuccess: isSuccessPaymentCharge,
      error: paymentChargeError,
      isError: isErrorPaymentCharge,
      isLoading: isLoadingPaymentCharge
    }
  ] = usePostPaymentChargeMutation();

  const [
    postAddCard,
    {
      data: addCardData,
      isSuccess: isSuccessAddCard,
      error: addCardError,
      isError: isErrorAddCard,
      isLoading: isLoadingAddCard
    }
  ] = usePostAddCardMutation();
  const [
    patchMyProject,
    {
      isLoading: loadingPatchProject,
      isError: isErrorPatchProject,
      error: errorPatchProject,
      isSuccess: successPatchProject
    }
  ] = usePatchMyProjectMutation();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value:
          loadingPatchProject ||
          isLoadingAddCard ||
          isLoadingPaymentCharge ||
          isLoadingGetAllCards ||
          isLoadingProject
      }
    });
  }, [
    loadingPatchProject,
    isLoadingAddCard,
    isLoadingPaymentCharge,
    isLoadingGetAllCards,
    isLoadingProject
  ]);

  useEffect(() => {
    if (
      // isErrorAddCard ||
      isErrorPaymentCharge ||
      errorPrice ||
      addCardError ||
      paymentChargeError
    ) {
      const message = errorValidationHandler(
        addCardError?.data ||
          paymentChargeError?.data ||
          errorPrice ||
          addCardError ||
          paymentChargeError
      );

      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: message?.alert
        }
      });
    }
  }, [
    // isErrorAddCard,
    isErrorPaymentCharge,
    addCardError,
    paymentChargeError || errorPrice
  ]);

  useEffect(() => {
    if (
      currentData &&
      (isSubscribingThisProject || isCancelingSubscriptionThisProject || isReSubscribingThisProject)
    ) {
      // debugger;
      if (isSubscribingThisProject && !currentData?.is_paid) {
        setActivePage(subscribeProjectSteps[1]);
      } else if (
        isCancelingSubscriptionThisProject &&
        currentData?.is_paid &&
        currentData?.is_reoccurring
      ) {
        patchMyProject({ id: project_id, is_reoccurring: false });
        setIsCancelingSubscriptionThisProject(false);
      } else if (
        isReSubscribingThisProject &&
        currentData?.is_paid &&
        !currentData?.is_reoccurring
      ) {
        patchMyProject({ id: project_id, is_reoccurring: true });
        setIsReSubscribingThisProject(false);
      } else {
        dispatch({
          type: CHANGE_ATTRIBUTE,
          payload: {
            key: 'alertSeverity',
            value: 'error'
          }
        });
        dispatch({
          type: CHANGE_ATTRIBUTE,
          payload: {
            key: 'errors',
            value: ['Wrong payment action!']
          }
        });
      }
    }
  }, [
    project_id,
    currentData,
    isSubscribingThisProject,
    setIsSubscribingThisProject,
    isCancelingSubscriptionThisProject,
    setIsCancelingSubscriptionThisProject,
    isReSubscribingThisProject,
    setIsReSubscribingThisProject,
    setActivePage,
    patchMyProject,
    dispatch
  ]);

  useEffect(() => {
    if (successPatchProject) {
      refetch();
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: ['Project has been updated!']
        }
      });
    }
  }, [successPatchProject]);

  const onContinuePage = () => {
    if (activePage === subscribeProjectSteps[1] && !payload?.isAgreeRule) {
      setErrorPrice(['Please check that you agree on rules for project posting']);
      return false;
    }

    if (activePage === subscribeProjectSteps[1] && !payload?.sub_id) {
      setErrorPrice(['Please select one of the packages']);
      return false;
    }

    if (activePage === subscribeProjectSteps[1] && payload?.charge_job !== '0') {
      setErrorPrice(null);
      setActivePage(subscribeProjectSteps[2]);
    } else if (activePage === subscribeProjectSteps[2]) {
      setErrorPrice(null);
      setActivePage(subscribeProjectSteps[3]);
      // } else {
      //   setErrorPrice(['Please Select following price.']);
    }
  };

  const onBackPage = () => {
    if (activePage === subscribeProjectSteps[3]) {
      setActivePage(subscribeProjectSteps[2]);
    } else if (activePage === subscribeProjectSteps[2]) {
      setActivePage(subscribeProjectSteps[1]);
    } else if (activePage === subscribeProjectSteps[1]) {
      setActivePage(subscribeProjectSteps[0]);
    }
  };

  // const cha = () => {
  //   // debugger;
  //   postPaymentCharge({
  //     // p_id: dataJobCreated?.id,
  //     sub_id: payload?.sub_id,
  //     // token: token?.id,
  //     // token: 'tok_1NdEWvLKF6Em1NNrqQeoeJe4',
  //     card_id: 'card_1NsQRQLKF6Em1NNrofMKDvgS',
  //     addon_id: payload?.addon_id,
  //     payment_type: 'job',
  //     job_id
  //   });
  // };

  const onSuccessCharge = (token) => {
    postAddCard({
      token: token.id
    });
  };
  const OnHandleApply = async (ele) => {
    if (ele?.user?.job_applications_notification) {
      const data = {
        email: ele?.user?.email,
        message: `You have a new applicant for the project ${ele.name} and the applicant name is ${userData?.name}`,
        subject: 'New Applicant'
      };
      await postSubmitReport(data);
    }
    const payload = {
      name: ele.name,
      description: ele.description,
      price: ele.price,
      deadline: ele.deadline,
      is_price_negotiated: ele.is_price_negotiated,
      is_deadline_negotiated: ele.is_deadline_negotiated,
      zip_code: ele.zip_code,
      region: ele.region,
      counter: ele.counter,
      is_paid: ele.is_paid,
      term: ele.term,
      start_date: ele.start_date,
      is_reoccurring: ele.is_reoccurring,
      sub_days: ele.sub_days,
      sub_price: ele.sub_price,
      trade: ele.trade,
      city: ele.city,
      state: ele.state,
      sub_category: ele.sub_category,
      applicants: ele.applicants,
      project_id: ele.id,
      title: ele.name,
      distance: ele.distance
    };

    try {
      await postQuickApplyJob(payload).unwrap();
      refetch();
      // viewAllProjects({
      //   ...filter,
      //   is_paid: true
      // });
      // refetchProjects();
      await dispatch(api.util.invalidateTags(['Post']));
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    if (isSuccessAddCard && isSubscribingThisProject) {
      if (allCardsData?.data?.data?.length > 0) {
        // debugger;
        postPaymentCharge({
          // p_id: dataJobCreated?.id,
          sub_id: payload?.sub_id,
          // token: token?.id,
          // token: 'tok_1NdEWvLKF6Em1NNrqQeoeJe4',
          card_id: allCardsData.data.data[0].id,
          addon_id: payload?.addon_id,
          payment_type: 'project',
          project_id
        });

        setIsSubscribingThisProject(false);
      } else {
        refetchGetAllCards();
      }
    }
  }, [isSuccessAddCard, allCardsData, isSubscribingThisProject]);

  useEffect(() => {
    if (isSuccessPaymentCharge) {
      refetch();
      setActivePage(subscribeProjectSteps[0]);
    }
  }, [isSuccessPaymentCharge]);

  const stateName = useMemo(
    () => stateData?.find((item) => item.state_id == detailPerProject?.state)?.state_name ?? '',
    [detailPerProject]
  );

  const cityName = useMemo(
    () => cityData[2].data.find((city) => city.city_id == detailPerProject?.city)?.city_name ?? '',
    [detailPerProject]
  );

  const userStateName = useMemo(
    () =>
      stateData?.find((item) => item.state_id == detailPerProject?.user?.state)?.state_name ?? '',
    [detailPerProject]
  );

  const userCityName = useMemo(
    () =>
      cityData[2].data.find((city) => city.city_id == detailPerProject?.user?.city)?.city_name ??
      '',
    [detailPerProject]
  );

  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner-wrapper project-detail">
              {activePage ===
                subscribeProjectSteps[subscribeProjectSteps.findIndex((d) => d === 'details')] && (
                <>
                  <div className="nav-heading">
                    <h1 className="title-2 d-flex mt-0">
                      <a href="javascript:void(0)" onClick={() => navigate(-1)}>
                        <img src={IconArrow} alt="" />
                      </a>
                      <span className="pl-1">Project Details</span>
                    </h1>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <h3 className="subtitle">{detailPerProject?.name}</h3>
                      <p
                        style={{
                          wordBreak: 'break-word'
                        }}>
                        {formatDescription(detailPerProject?.description)}
                      </p>
                      <hr />
                      <h4 className="subtitle-2">Photos and Files</h4>
                      <div className="project-photo">
                        {!!detailPerProject?.images &&
                          detailPerProject?.images?.map(({ image, id }) => (
                            <div className="photo-item" key={id}>
                              <div className="images-box">
                                {isImage(image) ? (
                                  <img src={image} alt={`photo-${id}`} />
                                ) : (
                                  <a href={image} target="_blank" rel="noopener noreferrer">
                                    <img
                                      src={IconFile}
                                      alt={`file-${id}`}
                                      style={{ objectFit: 'contain' }}
                                    />
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>

                      {/* <h4 className="subtitle-2 hide">Files</h4> */}

                      <div className="files mb-4 hide">
                        <div className="file-container">
                          <img src={IconPdf} alt="" />
                        </div>
                        <div className="file-container">
                          <img src={IconPdf} alt="" />
                        </div>
                        <div className="file-container">
                          <img src={IconPdf} alt="" />
                        </div>
                      </div>

                      <div className="mb-4">
                        <div className="list-icon">
                          <div className="ico-pps">
                            <img src={IconFile} alt="" />
                          </div>
                          <div>Terms: {detailPerProject?.term} </div>
                        </div>
                        <div className="list-icon">
                          <div className="ico-pps">
                            <img src={IconLocation} alt="" />
                          </div>
                          <div>
                            location: {stateName} - {cityName}
                          </div>
                        </div>
                        <div className="list-icon">
                          <div className="ico-pps">
                            <img src={IconDollar} alt="" />
                          </div>
                          <div>
                            Price: ${' '}
                            {detailPerProject?.price === null ? 'TBA' : detailPerProject?.price}
                          </div>
                        </div>
                        <div className="list-icon">
                          <div className="ico-pps">
                            <img src={IconTime} alt="" />
                          </div>
                          <div>Estimated Start Date: {detailPerProject?.start_date}</div>
                        </div>
                      </div>

                      {/* <h4 className="subtitle-2">Trade</h4> */}
                      {/* <div className="mb-4">
                        {!!detailPerProject?.sub_category &&
                          detailPerProject?.sub_category?.map((e, idx) => (
                            <span className="tag-label grey" key={idx}>
                              {e.category.name} - {e.sub_category}
                            </span>
                          ))}
                      </div> */}

                      <Grid xs={12} container spacing={2}>
                        {detailPerProject?.id &&
                          !detailPerProject?.is_paid &&
                          !isCameFromSearchPage && (
                            <Grid item xs={5}>
                              <button
                                className="btn btn-success w-100"
                                onClick={() => {
                                  setIsSubscribingThisProject(true);
                                  refetch();
                                  // setActivePage(subscribeProjectSteps[1]);
                                }}>
                                Subscribe
                              </button>
                            </Grid>
                          )}
                        {detailPerProject?.id &&
                          detailPerProject?.is_paid &&
                          detailPerProject?.is_reoccurring &&
                          !isCameFromSearchPage && (
                            <Grid item xs={5}>
                              <button
                                className={`btn btn-danger w-100`}
                                onClick={() => {
                                  // patchMyProject({ id: job_id, is_reoccurring: false });
                                  setIsCancelingSubscriptionThisProject(true);
                                  refetch();
                                }}>
                                Cancel subscription
                              </button>
                            </Grid>
                          )}
                        {detailPerProject?.id &&
                          detailPerProject?.is_paid &&
                          !detailPerProject?.is_reoccurring &&
                          !isCameFromSearchPage && (
                            <Grid item xs={5}>
                              <button
                                className={`btn btn-success w-100`}
                                onClick={() => {
                                  // patchMyProject({ id: job_id, is_reoccurring: false });
                                  setIsReSubscribingThisProject(true);
                                  refetch();
                                }}>
                                Re-subscribe
                              </button>
                            </Grid>
                          )}
                      </Grid>

                      {/* <h3 className="subtitle">
                        Other open Available Project Ads by the Contractor (*static)
                      </h3> */}
                      {/* <div className="d-flex">
                        <div className="underline">Plumbing team needed</div>
                        <div className="pl-2">
                          <p className="text-grey">
                            <strong>$3.450</strong>
                          </p>
                        </div>
                      </div> */}
                      {/* <div className="d-flex">
                        <div className="underline">Roof repair on a large square</div>
                        <div className="pl-2">
                          <p className="text-grey">
                            <strong>Negotiated</strong>
                          </p>
                        </div>
                      </div> */}
                    </Grid>
                    <Grid item md={4}>
                      <div className="side-detail">
                        <Grid container justify="flex-end" className="mb-2">
                          <button className="btn fav-btn ml-auto">
                            {isFavorite === true ? (
                              <img src={IconStar} alt="" />
                            ) : (
                              <img src={StarOff} alt="" />
                            )}
                          </button>
                        </Grid>
                        <button
                          className="btn btn-primary w-100"
                          disabled={
                            detailPerProject?.applicants?.find((x) => x.user.id == userData.id)
                              ?.id ||
                            userData?.user_type === JOB_SEEKER ||
                            userData?.user_type === RECRUITER
                              ? true
                              : false
                          }
                          style={{
                            backgroundColor: detailPerProject?.applicants?.find(
                              (x) => x.user.id == userData.id
                            )?.id
                              ? '#2b4257'
                              : '#2b4257'
                          }}
                          onClick={() => {
                            OnHandleApply({
                              name: detailPerProject?.name,
                              description: detailPerProject?.description,
                              user: userData.id,
                              is_price_negotiated: detailPerProject?.is_price_negotiated,
                              start_date: detailPerProject?.start_date,
                              price: detailPerProject?.price,
                              deadline: detailPerProject?.deadline,
                              created_at: detailPerProject?.created_at,
                              id: detailPerProject?.id,
                              is_paid: detailPerProject?.is_paid,
                              city: detailPerProject?.city,
                              state: detailPerProject?.state,
                              trade: detailPerProject?.trade,
                              sub_category: detailPerProject?.sub_category,
                              counter: detailPerProject?.counter,
                              is_deadline_negotiated: detailPerProject?.is_deadline_negotiated,
                              is_reoccurring: detailPerProject?.is_reoccurring,
                              region: detailPerProject?.region,
                              sub_days: detailPerProject?.sub_days,
                              sub_price: detailPerProject?.sub_price,
                              term: detailPerProject?.term,
                              zip_code: detailPerProject?.zip_code,
                              applicants: detailPerProject?.applicants,
                              distance: 0
                            });
                          }}>
                          {loadingApplyJob
                            ? 'Loading'
                            : detailPerProject?.applicants?.find((x) => x.user.id == userData.id)
                                ?.id
                            ? 'Applied'
                            : 'Quick Apply'}
                        </button>
                        <h3 className="mt-3">About the Contractor</h3>

                        <div className="profile-project-detail">
                          <div
                            className="profile-img"
                            style={{
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              // navigate(
                              //   routerPath.users.usersprofiles.replace(
                              //     ':id',
                              //     detailPerProject?.user?.id
                              //   ),
                              //   {
                              //     state: { userInfo: detailPerProject?.user }
                              //   }
                              // );
                              navigate(
                                routerPath.applicants.view.replace(
                                  ':id',
                                  detailPerProject?.user?.id
                                )
                              );
                            }}>
                            <div className="images-box">
                              <img
                                src={
                                  detailPerProject?.user?.profile_image?.split('?')?.[0] ||
                                  detailPerProject?.user?.company?.logo ||
                                  ImageProfile
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="w-100 pl-1">
                            <p>{detailPerProject?.user?.name}</p>
                            {userStateName && userCityName && (
                              <div className="d-flex">
                                <div>
                                  <img src={IconLocation} alt="" />
                                </div>
                                <div className="pl-1">
                                  {userStateName} - {userCityName}
                                </div>
                              </div>
                            )}
                            <p className="text-grey profile-role">
                              {detailPerProject?.user?.user_type}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
              {activePage ===
                subscribeProjectSteps[
                  subscribeProjectSteps.findIndex((d) => d === 'post-price')
                ] && (
                <>
                  <h2 className="title-2 mb-4">Post Project Opening</h2>
                  <PricingList
                    onContinue={onContinuePage}
                    onBackPage={onBackPage}
                    fieldName={'charge_job'}
                    payload={payload}
                    onUpdatePayload={(newValue) => {
                      setPayload({
                        ...payload,
                        ...newValue
                      });
                    }}
                    onAggreePostingRule={(value) => {
                      setPayload({
                        ...payload,
                        isAgreeRule: value
                      });
                    }}
                    onClickBack={() => {
                      setActivePage((prev) => prev - 1);
                    }}
                    type={POST_TYPE.PROJECT}
                  />
                </>
              )}
              {activePage ===
                subscribeProjectSteps[
                  subscribeProjectSteps.findIndex((d) => d === 'post-featured')
                ] && (
                <>
                  <h2 className="title-2 mb-4">Feature Project Post</h2>
                  <FeaturedJobList
                    onContinue={onContinuePage}
                    onBackPage={onBackPage}
                    fieldName={'charge_job_featured'}
                    payload={payload}
                    onUpdatePayload={(newValue) => {
                      setPayload({
                        ...payload,
                        ...newValue
                      });
                    }}
                  />
                </>
              )}

              {activePage ===
                subscribeProjectSteps[
                  subscribeProjectSteps.findIndex((d) => d === 'charge-project')
                ] && (
                <ChargePaymentComponent
                  onSuccessCharge={onSuccessCharge}
                  onClickBack={() => {
                    setActivePage(subscribeProjectSteps[subscribeProjectSteps.length - 2]);
                  }}
                  payload={payload}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);

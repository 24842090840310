import { createSlice } from '@reduxjs/toolkit'
import { api } from './apiSlice'


const defaultState = {
  authToken: null
}

export const appSlice = createSlice({
  name: 'app',
  initialState: defaultState,
  reducers: {
    setAuth: ( state,action) => {
      state.authToken = action.payload
    },
    resetAuth: state => {
      console.log({
        state
      })
      state.authToken = null
    },
  },
  extraReducers: builder => {
    // builder
    //   .addMatcher(api.endpoints.getUserProfile.matchFulfilled, ( state,{ payload }) => {
    //     console.log({
    //       payload
    //     })
    //   })
  }
})

export const {
  setAuth,
  resetAuth,
} = appSlice.actions

export const appState = state => state.app;
export const authState = state => state.auth;

export default appSlice.reducer
import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant';
import { apiGetModulesTerm, apiGetModulesPrivacy } from 'api/jobs.api';

export const actionToProps = {
  actionGetModulesTermPrivacy:
    (params = { mode: 'privacy' }) =>
    async (dispatch, getState) => {
      // // debugger;
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: true
        }
      });

      try {
        const { data } =
          params.mode === 'privacy' ? await apiGetModulesPrivacy() : await apiGetModulesTerm();
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: params.mode === 'privacy' ? 'privacyPolicy' : 'termsAndCondition',
            value: data.results[0]
          }
        });

        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: false
          }
        });

        return data;
      } catch (err) {
        let errorMessage = [];
        let message = err.message ? err.message : 'Something went Wrong!';
        if (err.response) {
          message = err.response.data.message;
        }

        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: false
          }
        });
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'alertSeverity',
            value: 'error'
          }
        });
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'errors',
            value: errorMessage.concat(message)
          }
        });

        return false;
      } finally {
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: false
          }
        });
      }
    }
};

import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu'
import FooterDashboard from 'components/FooterLink/Dashboard'
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Box, Container, Grid, makeStyles } from '@mui/material';
import HeaderLogo from 'components/Logo/Homepage';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import iconPicture from 'assets/icon-camera.svg'
import iconPdf from 'assets/pdf-icon.svg'
import IconPlus from 'assets/plus.svg';


const CompanyContact = () => {

    const [picture, setPicture] = useState('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=')

    function uploadImage(e) {
        setPicture(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <>
            <div className="header-second">
                <HeaderLogo />
            </div>
            <Container>
                <div className="content-inner-wrapper form-company-contact">
                    <h3 className="subtitle">
                        Company contact
                        information
                    </h3>

                    <div className="mb-1">
                        <label>First name</label>
                        <input type="text" className="form-control" placeholder='Enter first name' />
                    </div>
                    <div className="mb-1">
                        <label>Last name</label>
                        <input type="text" className="form-control" placeholder='Enter last name' />
                    </div>
                    <div className="mb-1">
                        <label>Email</label>
                        <input type="email" className="form-control" placeholder='Enter email' />
                    </div>

                    <h3 className="mt-3">
                        Uploads
                    </h3>
                    <div className="uploads-container mb-3">
                        <input type="file" onChange={uploadImage} />
                        <img src={picture} alt="" className='upload-preview' />
                        <div className='uploads-label'>
                            <img src={iconPicture} alt="" />
                            <div>Upload Profile
                                Picture</div>
                        </div>
                    </div>
                    <h3 className="mt-3">
                        About
                    </h3>
                    <div className="mb-1">
                        <label>Input description text</label>
                        <textarea className='form-control' cols="30" rows="3" placeholder='Text'></textarea>
                    </div>

                    <h3 className="mt-3">
                        Company information
                    </h3>
                    <div className="mb-1">
                        <label>Company name</label>
                        <input type="text" className="form-control" placeholder='Enter name' />
                    </div>
                    <div className="mb-1">
                        <label>Website</label>
                        <input type="text" className="form-control" placeholder='Your website' />
                    </div>
                    <div className="mb-1">
                        <label>Zip code</label>
                        <input type="text" className="form-control" placeholder='Enter ZIP' />
                    </div>
                    <div className="mb-1">
                        <label>City</label>
                        <input type="text" className="form-control" placeholder='Enter city' />
                    </div>
                    <div className="mb-2">
                        <label>State </label>
                        <input type="text" className="form-control" placeholder='Enter state' />
                    </div>
                    <button className='btn btn-primary w-100'>Finish</button>
                </div>

            </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(CompanyContact)
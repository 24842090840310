import React from "react";
import { Grid } from '@mui/material';

const ListGroup = (props) => {
    const {
        name,
        desc
    } = props
    return (
        <Grid container spacing={0} className="list-group">
            <Grid item xs={3}>{name}</Grid>
            <Grid item xs={9}>{desc} </Grid>
        </Grid>
    )
}


export default ListGroup;
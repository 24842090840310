import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { connect } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import Checkbox from '@mui/material/Checkbox';
import { Grid } from '@mui/material';
import CardPrice from 'components/CardPrice';
import IconBike from 'assets/bike.svg';
import IconPlane from 'assets/plane-icon.svg';
import IconRocket from 'assets/rocket.svg';
import IconPlus from 'assets/plus.svg';
import { Link } from 'react-router-dom';

const PostJobRecruiter = () => {
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner-wrapper">
              <h2 className="title-2 mb-4">Post Job Opening</h2>

              <div className="text-dark mb-1">
                <Checkbox
                  sx={{
                    pl: '0'
                  }}
                />
                I have read and agree with <a href="#"> Rules for posting jobs</a> (required)
              </div>

              <Grid container spacing={3}>
                <Grid item md={4}>
                  <CardPrice
                    title="7 Day Job Post"
                    icon={IconBike}
                    price="$99.95"
                    dataId="price1"
                    radioName="price"
                    input={
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter promo code"
                      />
                    }>
                    <ul>
                      <li>Launch 7 Day Job Post Ad</li>
                      <li>Be Seen by Thousands of Job Candidates From Coast to Coast</li>
                      <li>Find Professionals in Your Industry</li>
                      <li>Find Professionals in Your Industry</li>
                      <li>Direct Message to Those Who Have Applied</li>
                      <li>Review Your Job Candidates Professional Detailed Profile</li>
                      <li>Have Your Job Post Seen on Job Candidates Home Pages</li>
                    </ul>
                  </CardPrice>
                </Grid>
                <Grid item md={4}>
                  <CardPrice
                    title="14 Day Job Post"
                    icon={IconPlane}
                    price="$169.95"
                    dataId="price2"
                    radioName="price"
                    input={
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter promo code"
                      />
                    }>
                    <ul>
                      <li>Launch 7 Day Job Post Ad</li>
                      <li>Be Seen by Thousands of Job Candidates From Coast to Coast</li>
                      <li>Find Professionals in Your Industry</li>
                      <li>Find Professionals in Your Industry</li>
                      <li>Direct Message to Those Who Have Applied</li>
                      <li>Review Your Job Candidates Professional Detailed Profile</li>
                      <li>Have Your Job Post Seen on Job Candidates Home Pages</li>
                    </ul>
                  </CardPrice>
                </Grid>
                <Grid item md={4}>
                  <CardPrice
                    title="30 Day Job Post"
                    icon={IconRocket}
                    price="$299.95"
                    dataId="price3"
                    radioName="price"
                    input={
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter promo code"
                      />
                    }>
                    <ul>
                      <li>Launch 7 Day Job Post Ad</li>
                      <li>Be Seen by Thousands of Job Candidates From Coast to Coast</li>
                      <li>Find Professionals in Your Industry</li>
                      <li>Find Professionals in Your Industry</li>
                      <li>Direct Message to Those Who Have Applied</li>
                      <li>Review Your Job Candidates Professional Detailed Profile</li>
                      <li>Have Your Job Post Seen on Job Candidates Home Pages</li>
                    </ul>
                  </CardPrice>
                </Grid>
              </Grid>

              <div className="text-center mt-4">
                <button className="btn btn-primary mr-1">Back</button>
                <Link to={'/recruiter/post-job-feature'}>
                  <button className="btn btn-success ml-1">
                    <img src={IconPlus} className="top mr-1" /> Continue
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(PostJobRecruiter);

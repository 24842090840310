import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { setupAxios } from 'utils/axiosConfig';

export const loginApi = {
  root: '/api/users/authentication-fake',
  signin: '/api/v1/login/',
  // signin: '/rest-auth/login/',
  signup: '/api/v1/signup/',
  resetEmailPassword: '/rest-auth/password/reset/',
  changePassword: '/rest-auth/password/change/',
  profileCompleteness: '/api/v1/company/'
};

export function apiPostSignin(payload) {
  setupAxios();
  return axios.post(loginApi.signin, payload);
}
export function apiPostProfileCompleteness(payload) {
  setupAxios();
  return axios.post(loginApi.profileCompleteness, payload, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}
export function apiPostSignup(payload) {
  setupAxios();
  return axios.post(loginApi.signup, payload);
}
export function apiPostResetEmailPassword(payload) {
  setupAxios();
  return axios.post(loginApi.resetEmailPassword, payload);
}

export function apiPostChangePassword(payload) {
  setupAxios();
  return axios.post(loginApi.changePassword, payload);
}

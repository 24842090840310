import React, { useState, useRef, useEffect } from 'react';
import SidebarMenu from 'components/SidebarMenu'
import { connect } from 'react-redux'
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import Drawer from '@mui/material/Drawer';
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { actionToProps as projectsAction } from 'store/reducers/news/actions/actionGetProjects';
import { actionToProps as profileAction } from 'store/reducers/news/actions/actionGetProfile';
import {
    routerPath
} from 'router/Routerlist'

import FilterIcon from 'assets/Filter.svg';
import IconCalendar from 'assets/calendar.svg';
import IconEye from 'assets/show.svg';
import IconLocation from 'assets/location.svg';
import IconBookmark from 'assets/Bookmark.svg';
import IconBookmarkActive from 'assets/Bookmark-active.svg';
import IconDollar from 'assets/dollar.svg';
import IconTime from 'assets/time.svg';
import IconBack from 'assets/arrow-back.svg';
import fileDw from 'assets/file-dw.jpg';
import moment from 'moment';
import { getRelativeTime } from 'utils/date';
import CardList from 'components/Job/CardList';
import FooterDashboard from 'components/FooterLink/Dashboard'

const Projects = (props) => {
    const {
        actionGetProjects,
        actionGetSpecialistProfile,
        history,
        auth: {
          authenticated,
        },
        news: {
            projects
        }
    } = props

    const [isLoading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [right, rightOpen] = useState(false);
    const [projectsDetail, setProjectsDetail] = useState(null);

    const drawerRight = () => {
        rightOpen(!open);
    };

    const closeDrawer = () => {
        rightOpen();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async() => {
        const response = await actionGetProjects();

        return response
    }

    const handleFilter = () => {
        fetchData()
        setAnchorEl(null);
    };
    const handleSendMessage = (project) => {
        history.push(`${routerPath.messages.messages}/?ref=project&refid=${project.id}&refuid=${project.user?.id}&title=${encodeURI(project.title)}`)
    }

    return (
        <>

            <div className="wrapper">
                <div className="user-dashboard">
                    <SidebarMenu />
                    <div className="content-dashboard">
                        <div className="dahboard-header">
                            <div className="mui-row d-flex items-center">
                                <div className="mui-col-xs-4">
                                    <h1>Projects</h1>
                                </div>
                                <div className='mui-col-xs-4'>
                                    <div className="input-inline-group is-right">
                                        <input type="text" className="form-control" placeholder="Search" />
                                        <span>
                                            <button onClick={handleFilter} className="btn">
                                                <img src="/images/icon-search.svg" alt="" />
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div className="mui-col-xs-4">
                                    <span className='pointer' onClick={handleClick}><img src={FilterIcon} alt="" /></span>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <div className='modal'>
                                            <div className='modal-header'>
                                                <h3>Set your preferences</h3>
                                            </div>
                                            <div className='modal-content'>
                                                <h4 className='text-dark mt-0'>Show</h4>
                                                <div className='label-outline active'>Large Building Construction</div>
                                                <div className='label-outline'>Roofing and gutters</div>
                                                <div className='label-outline'>Windows & Doors</div>
                                                <div className='label-outline'>Foundations</div>
                                                <div className='label-outline'>Addition & Remodeling</div>
                                                <div className='label-outline'>HVAC</div>

                                                <div className='mui-row d-flex items-center'>
                                                    <div className='mui-col-xs-6'>
                                                        <h4 className='text-dark m-0'>Distance</h4>
                                                    </div>
                                                    <div className='mui-col-xs-6 mui--text-right text-14 text-dark'>
                                                        15 miles away
                                                    </div>
                                                </div>
                                                <Slider defaultValue={30} />

                                                <div className='form-group'>
                                                    <label className='text-14'>ZIP code</label>
                                                    <input className='form-control form-control-sm zp--12' placeholder='Enter ZIP code' />
                                                </div>

                                                <div className='mui--text-center mt-2'>
                                                    <button onClick={handleFilter} className='btn btn-dark mr-1'>Done</button>
                                                    <button onClick={handleClose} className='btn btn-outline-dark ml-1'>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                        <div className='content-inner'>
                            {/* <span className='btn btn-dark' onClick={drawerRight}>Open Left drawer</span> */}
                            {!!projects.length && projects.map((ele, key) => {
                                ele = {
                                    ...ele,
                                    key: key,
                                    title: ele.name,
                                    tags: ele.sub_category.map(sb => { return sb.sub_category }),
                                    address: `${ele?.zipcode || "67519"}, ${ele?.country || "Australia"}`,
                                    views: Math.floor((Math.random() * 10) + 1),
                                    isBookmark: !!((Math.round(Math.random() * 10)) % 2 == 0),
                                    since: new Date(new Date(2015, 0, 1).getTime() + Math.random() * (new Date().getTime() - new Date(2015, 0, 1).getTime())),
                                    createdAt: new Date(new Date().setDate(new Date().getDate() - 10) + Math.random() * (new Date().getTime() - new Date().setDate(new Date().getDate() - 10))),
                                    onClick: async () => {
                                      console.log({
                                        ele
                                      });
                                      // const aboutClient = await actionGetSpecialistProfile(ele.user.id)
                                      const projectDetailWithUser = {
                                        ...ele,
                                        contact: {
                                          ...ele.user,
                                          address: 'N/A',
                                          member_since: new Date()
                                        }
                                      }
                                      setProjectsDetail(projectDetailWithUser);
                                      drawerRight()
                                    }
                                }
                                return (
                                    <CardList {...ele} />
                                )
                            })}

                            <Drawer
                                anchor={`right`}
                                open={right}
                                className="detail-project"
                            >
                                <div className='dw-container'>
                                    <div className='dw-header'>
                                        <span className='pointer' onClick={closeDrawer}>
                                            <img src={IconBack} />
                                        </span>
                                    </div>
                                    <div className='dw-content'>
                                        <div className='card project-list'>
                                            <div className='mui-row d-flex flex-wrap'>
                                                <div className='mui-col-sm-8'>
                                                    <div className='project-content'>
                                                        <h4 className='project-title'>{projectsDetail?.title}</h4>
                                                        <p>
                                                            {projectsDetail?.description}
                                                        </p>
                                                        <hr />

                                                        <h4>Files</h4>
                                                        <div className='mui-row mui-row-sm file-container'>
                                                            {projectsDetail?.images?.map((ele, idx) => {
                                                                const img = ele?.image?.split('?')[0];

                                                                return (
                                                                    <div key={idx} className='mui-col-md-2 mb-1 file-item'>
                                                                        <div className='file-img'>
                                                                            <img src={img} className="middle" />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <hr />

                                                        <div className='d-flex mb-1'>
                                                            <div className='ico-pps'><img src={IconDollar} /> </div>
                                                            <div>Price: {projectsDetail?.price}</div>
                                                        </div>
                                                        <hr />
                                                        <div className='d-flex'>
                                                            <div className='ico-pps'><img src={IconTime} /></div>
                                                            <div>Est. Time: {projectsDetail?.estTime}</div>
                                                        </div>
                                                        <hr />
                                                        <h4>Skills and expertise</h4>
                                                        <div className='project-cat'>
                                                            {projectsDetail?.tags?.map((t, k) => {
                                                                return (
                                                                    <div key={k} className='project-cat-item'>{t}</div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mui-col-md-4 pl-0'>
                                                    <div className='project-price'>
                                                      {
                                                        projectsDetail?.contact.id && projectsDetail?.contact.id !== authenticated.id && (
                                                          <button className='btn btn-dark btn-block' onClick={()=>handleSendMessage(projectsDetail)}>
                                                              Send Message
                                                          </button>
                                                        )
                                                      }

                                                        <h4 className='mt-2'>About the client</h4>

                                                        <div className='mui-row mui-row-sm'>
                                                            <div className='mui-col-xs-3'>
                                                                <div className='card-img'>
                                                                    <img src={fileDw} className="w-100" />
                                                                </div>
                                                            </div>
                                                            <div className='mui-col-xs-9 member-dsc'>
                                                                <p>{projectsDetail?.contact?.name}</p>
                                                                <p><img src={IconLocation} className="sub" />{projectsDetail?.contact.address}</p>
                                                                <p className='text-12'>Member since {moment(projectsDetail?.contact.member_since).format("ll")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Drawer>
                        </div>
                        <FooterDashboard />
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction, ...projectsAction, ...profileAction }
export default connect(mapStateToProps, mapDispatchToProps)(Projects)

import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { CircularProgress, Grid, Stack } from '@mui/material';
import CardPrice from 'components/CardPrice';
import IconPlus from 'assets/plus.svg';
import { Link } from 'react-router-dom';
import { CARD_TYPE, featuredUrgentJobList } from 'constant';
import { HandleGetAddonsRequest } from 'api/callsHandler';
import IconBike from 'assets/bike.svg';

const PriceList = ({
  price,
  name,
  id,
  features,
  onChangePrice,
  cardType,
  payload,
  promoCode = true
}) => (
  <Grid item md={4}>
    <CardPrice
      title={name}
      icon={IconBike}
      price={price}
      dataId={id}
      radioName={'Hi'}
      onChangeRadio={() => onChangePrice(id)}
      cardType={cardType}
      payload={payload}
      input={
        promoCode && (
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter promo code"
          />
        )
      }>
      <ul>
        {features.map((f) => (
          <li>{f.name}</li>
        ))}
      </ul>
    </CardPrice>
  </Grid>
);

const FeaturedJobList = ({ onContinue, onBackPage, onUpdatePayload, fieldName, payload }) => {
  const handleChangePrice = (id) => {
    // // debugger;
    let updatedValue = {};
    const findByPrice = addonsData?.data?.results?.find((k) => k.id === id);

    updatedValue['charge_job_featured'] = findByPrice.price;
    updatedValue['addon_id'] = payload.addon_id === id ? 0 : id;

    onUpdatePayload(updatedValue);
  };

  const { data: addonsData, isLoading: addonsIsLoading } = HandleGetAddonsRequest();

  return (
    <>
      {addonsIsLoading ? (
        <Stack sx={{ width: '100%' }} alignItems={'center'}>
          <CircularProgress />
        </Stack>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {addonsData?.data?.results?.map((e, idx) => (
            <PriceList
              {...e}
              promoCode={false}
              onChangePrice={handleChangePrice}
              key={idx}
              cardType={CARD_TYPE.ADDON}
              payload={payload}
            />
          ))}
        </Grid>
      )}

      <div className="text-center mt-4">
        <button className="btn btn-primary mr-1" onClick={onBackPage}>
          Back
        </button>
        <button className="btn btn-success ml-1" onClick={onContinue}>
          <img src={IconPlus} className="top mr-1" /> Continue
        </button>
      </div>
    </>
  );
};

export default FeaturedJobList;

import React from 'react';
import HeaderLogo from 'assets/logo_second_version.png';
import { routerPath } from 'router/Routerlist';
import { useNavigate } from 'react-router-dom';

const HomepageLogo = (props) => {
  const navigate = useNavigate();
  return (
    <a className="header-logo cursor-pointer" onClick={() => navigate(routerPath.homepage.root)}>
      {/* <HeaderLogo /> */}
      <img src={HeaderLogo} alt="logo" />
    </a>
  );
};

export default HomepageLogo;

import React, { useEffect, useState, useRef } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { connect, useDispatch } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { actionToProps as jobsAction } from 'store/reducers/news/actions/actionGetAllJobs';
import { routerPath } from 'router/Routerlist';

import FilterIcon from 'assets/Filter.svg';
import IconCalendar from 'assets/calendar.svg';
import IconEye from 'assets/show.svg';
import IconLocation from 'assets/location.svg';
import IconBookmarkActive from 'assets/Bookmark-active.svg';
import IconBookmark from 'assets/Bookmark.svg';
import IconDollar from 'assets/dollar.svg';
import IconTime from 'assets/time.svg';
import IconPlus from 'assets/plus.svg';
import IconHelmet from 'assets/helmet.svg';
import { getRelativeTime } from 'utils/date';
import FooterLinkDashboard from 'components/FooterLink/Dashboard';
import DashboardHeaderJobVacancy from 'components/DashboardHeader/JobVacancy';
import CardList from 'components/Job/CardList';
import JobListCard from 'components/JobListCard';
import Image from 'assets/images/plumbing-with-different-tools.png';
import Pagination from '@mui/material/Pagination';
import { Box, Grid } from '@mui/material';
import {
  useDeleteFavoriteJobMutation,
  useGetAllJobsQuery,
  useGetViewManageJobPostQuery,
  useGetMyFavoriteJobListQuery,
  usePostFavouriteJobMutation,
  usePostQuickApplyJobMutation
} from 'store/slices/apiSlice';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { useNavigate } from 'react-router-dom';
import * as cx from 'classnames';
import { errorValidationHandler } from 'utils/errorHandler';
import HeaderContentTop from 'components/HeaderContent/topHeader';
import moment from 'moment';
import ProjectCard from 'components/ProjectCard';

const defaultFilter = {
  limit: 20,
  offset: 0
};

const MyJob = (props) => {
  const {
    history,
    actionGetAllJobs,
    news: { jobs }
  } = props;
  const [filter, setFilter] = useState(defaultFilter);
  const {
    data: allJobResult,
    isLoading,
    isError,
    isSuccess,
    refetch
  } = useGetViewManageJobPostQuery(filter);
  const { data: myFavoriteJob, refetch: refetchFavoriteJob } = useGetMyFavoriteJobListQuery();
  const [
    deleteFavoriteJob,
    { isLoading: isLoadingDeleteFavorite, error: errorDeleteingFavoriteJob }
  ] = useDeleteFavoriteJobMutation();
  const dispatch = useDispatch();

  console.log({
    myFavoriteJob
  });

  useEffect(() => {
    refetch();
  }, [filter]);

  useEffect(() => {
    refetch();
    refetchFavoriteJob();
  }, []);

  const navigate = useNavigate();

  const [
    postQuickApplyJob,
    {
      isLoading: loadingApplyJob,
      isError: isErrorApplyJob,
      error: errorApplyJob,
      isSuccess: isSuccessQuickApply
    }
  ] = usePostQuickApplyJobMutation();

  const [
    postFavouriteJob,
    {
      isLoading: loadingFavouriteJob,
      isError: isErrorFavouriteJob,
      error: errorFavouriteJob,
      isSuccess: isSuccessPostFavorite
    }
  ] = usePostFavouriteJobMutation();

  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value: isLoading || loadingApplyJob || loadingFavouriteJob || isLoadingDeleteFavorite
      }
    });
  }, [isLoading, loadingApplyJob, loadingFavouriteJob, isLoadingDeleteFavorite]);

  console.log({
    isLoading,
    loadingApplyJob,
    loadingFavouriteJob,
    isLoadingDeleteFavorite
  });

  useEffect(() => {
    if (isErrorApplyJob || isErrorFavouriteJob) {
      const message = errorValidationHandler(errorApplyJob?.data || errorFavouriteJob?.data);
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: message?.alert
        }
      });
    }
  }, [isErrorApplyJob, isErrorFavouriteJob]);

  useEffect(() => {
    if (isSuccessQuickApply) {
      const message = 'you have successfully applied for this job';

      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: [message]
        }
      });
    }
  }, [isSuccessQuickApply]);

  const onQuickApply = (ele) => {
    const payload = {
      job_id: ele.id,
      user_id: ele.user?.id,
      city: ele?.city,
      description: ele?.description,
      distance: 10,
      state: ele?.state,
      title: ele?.title
    };
    postQuickApplyJob(payload);
    console.log('elerjelkjrelk', ele);
    navigate(routerPath.jobs.applicants.replace(':job_id', ele.id));
  };

  useEffect(() => {
    if (isSuccessPostFavorite) {
      refetchFavoriteJob();
      refetch();
    }
  }, [isSuccessPostFavorite]);

  const onAddFavourite = (ele) => {
    const payload = {
      job: ele.id
    };

    postFavouriteJob(payload);
  };

  const onDeleteFavorite = async (ids) => {
    const deleteTheJob = ids.map((e) => {
      return deleteFavoriteJob(e);
    });

    await Promise.all([...deleteTheJob]);
    refetchFavoriteJob();
  };

  console.log('allJobResult', allJobResult?.results);
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <HeaderContentTop
              title={'Manage Job Post'}
              buttonTitle="Post A Job"
              handleClick={() => {
                navigate(routerPath.jobs.createjob);
              }}
            />
            <div className="content-inner" style={{ marginTop: 30 }}>
              {!!allJobResult && allJobResult?.results?.length === 0 && (
                <div className="empty-project">
                  <img src={IconHelmet} alt="IconHelmet" />
                  <p className="mt-2">
                    You have not posted the Job yet. <br />
                    Let's start right now!
                  </p>
                  <button
                    onClick={() => navigate(routerPath.jobs.createjob)}
                    className="btn btn-success mt-2">
                    <img src={IconPlus} className="sub mr-1" />
                    Post Job Vacancy
                  </button>
                </div>
              )}
              {!!allJobResult &&
                allJobResult?.results?.map((ele, key) => (
                  <ProjectCard
                    className={cx({
                      featured: ele.is_featured,
                      urgent: ele.is_urgent
                    })}
                    isFavorite={myFavoriteJob?.results?.map((e) => e.job).includes(ele.id)}
                    image={ele.job_category.image || Image}
                    name={ele.title}
                    id={ele.id}
                    isPaid={ele.is_paid}
                    createdAt={ele.posted}
                    stateId={ele?.state}
                    cityId={ele?.city}
                    price={ele?.price || ''}
                    estimated_start_date={ele?.start_date}
                    description={ele.description}
                    hasAction={true}
                    actionTitle={'View Applicants list'}
                    onActionClick={() => {
                      navigate(routerPath.jobs.applicants.replace(':job_id', ele.id));
                    }}
                    address={`${ele.state}, ${ele.city}`}
                    tags={[ele.job_category.name]}
                    onClick={() => {
                      navigate(routerPath.jobs.manageDetail.replace(':job_id', ele.id));
                    }}
                  />
                ))}
              <Grid container justify="flex-end" className="mt-4">
                <Pagination
                  count={Math.ceil(allJobResult?.count / filter.limit)}
                  color="success"
                  variant="outlined"
                  onChange={(e, page) => {
                    setFilter({
                      ...filter,
                      offset: (page - 1) * filter.limit
                    });
                  }}
                />
              </Grid>
            </div>

            <FooterLinkDashboard />
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction, ...jobsAction };
export default connect(mapStateToProps, mapDispatchToProps)(MyJob);

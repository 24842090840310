import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant'
import { addDoc, collection, getDocs, where, doc, onSnapshot, query, orderBy, Timestamp }from "firebase/firestore";

export const actionToProps = {
  actionSubmitMessage:  (payload) => async (dispatch, getState) => {
    return true
    // let errorValidations = []
    // Object.keys(payload).map((e, idx)=> {
    //   if ((payload[e] === '' || payload[e] === 0) && e !== 'attachment' && e !== 'attachment_title' && e !== 'attachment_reference'){
    //     errorValidations.push(`${e}: cannot be empty`)
    //   }
    // })
    // if (errorValidations.length > 0){
    //   dispatch({
    //     type: CHANGE_ATTRIBUTE_NEWS,
    //     payload: {
    //       key: 'alertSeverity',
    //       value: 'error'
    //     }
    //   })
    //   dispatch({
    //     type: CHANGE_ATTRIBUTE_NEWS,
    //     payload: {
    //       key: 'errors',
    //       value: errorValidations
    //     }
    //   })
    //   return false
    // }
    // dispatch({
    //   type: CHANGE_ATTRIBUTE_NEWS,
    //   payload: {
    //     key: 'errors',
    //     value: []
    //   }
    // })
    // try {
    //   const result = await addDoc(collection(firestoreDb, docsName), {
    //       ...payload,
    //       created_at:Timestamp.now().toDate().toISOString()
    //   });
    //   dispatch({
    //       type: CHANGE_ATTRIBUTE_NEWS,
    //       payload: {
    //           key: 'alertSeverity',
    //           value: 'success'
    //       }
    //   })
    //   console.log("create resultx",result);
    //   return result
    // } catch ({ message, response }) {

    //   dispatch({
    //     type: CHANGE_ATTRIBUTE_NEWS,
    //     payload: {
    //       key: 'alertSeverity',
    //       value: 'error'
    //     }
    //   })
    //   dispatch({
    //     type: CHANGE_ATTRIBUTE_NEWS,
    //     payload: {
    //       key: 'errors',
    //       value: [message]
    //     }
    //   })

    //   return false
    // }
    // finally {

    //   dispatch({
    //     type: CHANGE_ATTRIBUTE_NEWS,
    //     payload: {
    //       key: 'isPageLoading',
    //       value: false
    //     }
    //   })
    // }
  },
  fetchMessagesList:  (params) => async (dispatch, getState) => {
    return true
    // dispatch({
    //   type: CHANGE_ATTRIBUTE_NEWS,
    //   payload: {
    //     key: 'errors',
    //     value: []
    //   }
    // })
    // try {
    //   const {
    //     auth: {
    //       authenticated
    //     }
    //   } = getState()

    //     const user_id = authenticated?.id

    //     const q = query(collection(firestoreDb, docsName), where('participants', 'array-contains', user_id));

    //     // const querySnapshot = getDocs(q).then(d=>{
    //     //     d.forEach(row=>{
    //     //         console.log("muncul result", row.data());
    //     //     })
    //     //     return d
    //     // });
    //     const filterByRecipient = (list)=>{
    //       list = list.sort( (b,a) => (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0) )
    //       list.map(d=>{
    //         d.opponents = +d.participants.find(p=>p != user_id)
    //       })
    //       let messages = list.filter((item, index, self) =>
    //         index === self.findIndex((t) => (
    //         t.opponents === item.opponents
    //       )))
    //       messages.forEach((d,i)=>{
    //         let convo = list.filter(l=>l.opponents == d.opponents).sort( (a,b) => (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0) )
    //         Object.assign(d, {...d,conversations:JSON.parse(JSON.stringify(convo))})
    //       })
    //       return messages;
    //     }
    //     const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //       const cities = [];
    //       querySnapshot.forEach((doc) => {
    //           cities.push(doc.data());
    //       });
    //       console.log('msgg',filterByRecipient(cities));
    //       dispatch({
    //           type: CHANGE_ATTRIBUTE_NEWS,
    //           payload: {
    //               key: 'messages',
    //               value: filterByRecipient(cities)
    //           }
    //       })
    //     });


    //     // dispatch({
    //     //     type: CHANGE_ATTRIBUTE_NEWS,
    //     //     payload: {
    //     //         key: 'alertSeverity',
    //     //         value: 'success'
    //     //     }
    //     // })
    //   return null
    // } catch ({ message, response }) {

    //   dispatch({
    //     type: CHANGE_ATTRIBUTE_NEWS,
    //     payload: {
    //       key: 'alertSeverity',
    //       value: 'error'
    //     }
    //   })
    //   dispatch({
    //     type: CHANGE_ATTRIBUTE_NEWS,
    //     payload: {
    //       key: 'errors',
    //       value: [message]
    //     }
    //   })


    //   return false
    // }
    // finally {

    //   dispatch({
    //     type: CHANGE_ATTRIBUTE_NEWS,
    //     payload: {
    //       key: 'isPageLoading',
    //       value: false
    //     }
    //   })
    // }
  },
}

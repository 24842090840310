import React from 'react';
import { Button, FormControl, FormHelperText, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

function ControlledMultiSelect({ control, errors, label, param, marginTop = 2, id }) {
  return (
    <Controller
      name={param}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl
          error={errors?.[param]}
          variant="standard"
          sx={{ mt: marginTop, width: '100%' }}>
          <Button
            onClick={() =>
              onChange(value?.includes(id) ? value.filter((item) => item !== id) : [...value, id])
            }
            variant="outlined"
            className="form-control form-control-sm"
            placeholder="Enter trade"
            sx={{
              borderRadius: '8px',
              border: ({ palette }) => `1px solid ${palette.customColors.gray[500]}`,
              '&:hover': {
                border: ({ palette }) => `1px solid ${palette.customColors.gray[500]}`,
                backgroundColor: ({ palette }) =>
                  value?.includes(id) ? palette.customColors.green[400] : 'transparent'
              },
              backgroundColor: ({ palette }) =>
                value?.includes(id) ? palette.customColors.green[400] : 'transparent'
            }}>
            <Typography
              fontSize={'13px'}
              textTransform={'capitalize'}
              sx={{
                color: ({ palette }) =>
                  value?.includes(id) ? 'common.white' : palette.customColors.gray[500]
              }}>
              {label}
            </Typography>
          </Button>
          <FormHelperText id="component-error-text">{errors?.[param]?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default ControlledMultiSelect;

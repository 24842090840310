import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actionToProps as modulesAction } from 'store/reducers/news/actions/actionGetModulesTermPrivacy';

import IconBack from 'assets/line-arrow-left.svg';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { decrement, increment, fetchPosts } from 'store/slices/counterSlice';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const Privacy = (props) => {
  const {
    actionGetModulesTermPrivacy,
    news: { privacyPolicy }
  } = props;

  const count = useSelector((state) => state.counter.value);
  const postStatus = useSelector((state) => state.counter.status);
  const posts = useSelector((state) => state.counter.posts);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await actionGetModulesTermPrivacy();
    })();

    if (postStatus === 'idle') {
      dispatch(fetchPosts());
    }
  }, [actionGetModulesTermPrivacy, dispatch, postStatus]);

  return (
    <>
      <div className="wrapper">
        <div className="mui-container">
          <div className="dw-container">
            <div className="dw-header pt-4" style={{ position: 'fixed', width: '100%' }}>
              <h3 className="m-0 single-title">
                <span
                  className="pointer mr-2"
                  onClick={() => {
                    navigate(-1);
                  }}>
                  <img src={IconBack} alt="back" />
                </span>
                Privacy Policy
              </h3>
            </div>
            <div className="dw-content ">
              {/* <div className="card" style={{ height: '75vh', padding: '0.1em' }}> */}
              {/* {posts && (
                  <ul>
                    {posts.map((post) => (
                      <li>{post.title}</li>
                    ))}
                  </ul>
                )} */}
              {/* <div className="card-body p-4" style={{ textAlign: 'justify' }}>
                  <p dangerouslySetInnerHTML={{ __html: privacyPolicy?.body }} />
                </div> */}
              {/* <iframe
                  style={{ width: '100%', height: '100%', borderRadius: '20px' }}
                  // className="h-full w-full mt-4 overflow-auto scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thin scrollbar-thumb-my-gray-900 scrollbar-track-true-gray-700"
                  src={`data:application/pdf;base64,${privacyPolicy?.body}`}
                /> */}
              <div style={{ marginTop: '10em' }}>{parse(privacyPolicy?.body ?? '')}</div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...modulesAction };
export default connect(mapStateToProps, mapDispatchToProps)(Privacy);

import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { connect } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import IconArrow from 'assets/arrow-left-outline.svg';
import ListPrice from 'components/ListPrice';
import IconPaypal from 'assets/paypall.svg';
import IconCreditCard from 'assets/cretit-card.svg';
import IconVisa from 'assets/visa.svg';
import { Grid } from '@mui/material';

const ProjectAds = () => {
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="dahboard-header">
              <h1>
                <a href="#">
                  <img src={IconArrow} className="sub mr-1" alt="" />
                </a>
                Project Ads
              </h1>
            </div>
            <div className="content-inner">
              <div className="job-ads">
                <div className="mb-2">
                  <ListPrice
                    price="$79.95"
                    label="Upgrade"
                    name="jobads"
                    value="ads1"
                    content="Your subscription will auto-renew for the non-discounted price until you cancel via manage job post"
                  />
                </div>
                <div className="divider-ads"></div>

                <div className="mb-4">
                  <div className="total-ads">
                    <div className="list-head">
                      <div className="price">$94.85</div>
                      <div className="label-name">Total Charge</div>
                    </div>
                  </div>
                </div>

                {/* <h4 className="ads-payment-title mb-3">Select payment method</h4>

                <div className="nav-payment mb-3">
                  <div className="item">
                    <img src={IconPaypal} alt="" />
                  </div>
                  <div className="item active">
                    <img src={IconCreditCard} alt="" />
                  </div>
                </div> */}

                <div className="card-visa">
                  <div className="text-right">
                    <img src={IconVisa} alt="" />
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <label>Card Number</label>
                      <input type="text" className="form-control text-center" />
                    </Grid>
                    <Grid item xs={7}>
                      <label>Cardholder Name</label>
                      <input type="text" className="form-control text-center" />
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <label>Valid Thru</label>
                          <input type="text" className="form-control text-center" />
                        </Grid>
                        <Grid item xs={6}>
                          <div className="text-right">
                            <label>CVV</label>
                          </div>
                          <input type="password" className="form-control text-right" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <button className="btn btn-primary btn-payment">Pay by Card</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(ProjectAds);

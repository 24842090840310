import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import FooterDashboard from 'components/FooterLink/Dashboard';
import { connect } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Box, Grid } from '@mui/material';
import IconPlus from 'assets/plus.svg';
import IconImages from 'assets/icon-images.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const AvailableProject = () => {
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner-wrapper">
              <h2 className="title-2 mb-4">Available Project Creation</h2>
              <h3 className="title-3">Project Name</h3>
              <p className="text-dark mb-1">
                Come up with a title for your assignment. The title should attract attention and
                reflect the essence of the task.
              </p>

              <div className="mb-3">
                <label className="text-dark">Project Name</label>
                <input type="text" className="form-control" placeholder="Enter name" />
              </div>

              <h3 className="title-3">Trade</h3>
              <Grid container spacing={2} className="mb-3">
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Acoustical/ Specialty Ceilings"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Fire Protection"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Masonary/ EFIS/ Stucco/ Waterproofing"
                  />
                </Grid>

                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Asphalt/ Curbing"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Flooring/ Tile"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Other"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Cement/ Concrete "
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Finish/ Trim/ Cabinets/ Counter Tops"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Painter/ Wallpaper/ Caulking"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Civil/ Road Construction"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Handyman"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Plumber/ Pipefitter"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Demolition"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Heavy Equipment/ Site Devoplment"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Roofer/ Solar"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Doors/ Windows/ Glazer "
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="HVAC/Sheet Metal"
                  />
                </Grid>

                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Structual Steel"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Drywall/ Framing/ Finishing/ Plaster"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Insulation"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Trucking/ Transportation"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Electrician/ Low Voltage"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Landscaping/ Irrigation/ Tree Service"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    className="text-dark"
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1
                        }}
                      />
                    }
                    label="Wood Framing/ Trusses / Siding"
                  />
                </Grid>
              </Grid>

              <h3 className="title-3">Project Description</h3>
              <div className="mb-3">
                <label className="text-dark">Description</label>
                <textarea
                  className="form-control"
                  cols="30"
                  rows="10"
                  placeholder="Enter description"></textarea>
              </div>

              <h3 className="title-3">Files</h3>
              <div className="mb-3 d-flex">
                <div>
                  <button className="btn btn-success mr-1">
                    <img src={IconPlus} className="top mr-1" /> Add File
                  </button>
                  <div className="text-11 mt-1">You can upload 5 files</div>
                </div>
                <div>
                  <button className="btn btn-success mr-1">
                    <img src={IconImages} className="top mr-1" /> Add Photos
                  </button>
                  <div className="text-11 mt-1">You can upload up to 5 photos</div>
                </div>
              </div>

              <h3 className="title-3">Budget</h3>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <label className="text-dark">Price</label>
                  <input type="text" className="form-control" placeholder="Enter amount or TBA " />
                </Grid>
                <Grid>
                  <div className="spacing-dollar">$</div>
                </Grid>
                <Grid item xs={3}>
                  <label className="text-dark">Terms</label>
                  <input type="text" className="form-control" placeholder="Hourly" />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <label className="text-dark">Estimated Start Date</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Estimated Start Date "
                  />
                </Grid>
              </Grid>

              <Box sx={{ pt: 6 }}>
                <button className="btn btn-primary">Continue</button>
              </Box>
            </div>
            {/* <FooterDashboard /> */}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(AvailableProject);

import { Grid, Typography } from '@mui/material';
import React from 'react';
import InputField from 'components/input/InputField';

function About({ control, errors, loading, param, title, subtitle }) {
  return (
    <Grid container mt={2} columnSpacing={2}>
      <Grid item xs={12} className="mb-2">
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          {title}
        </Typography>
      </Grid>

      <Grid item md={12} className="mb-2">
        <InputField
          control={control}
          errors={errors}
          label={subtitle}
          param={param}
          type={'text'}
          marginTop={1}
          disabled={loading}
          styleInput={{ minHeight: '140px' }}
          multiline={true}
          placeholder={'Enter text'}
          maxRows={4}
        />
      </Grid>
    </Grid>
  );
}

export default About;

import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { HandleGetUserExperienceRequest } from 'api/callsHandler';
import ControlledRadio2 from 'components/input/ControlledRadio2';

function Experience({ control, errors }) {
  const { data: userExperienceData } = HandleGetUserExperienceRequest();

  return (
    <Grid container mt={4} spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          Experience
        </Typography>
      </Grid>

      {userExperienceData?.data?.results?.length === 0 && (
        <Grid item xs={12}>
          <Typography
            fontSize={'14px'}
            variant="h4"
            sx={{ color: ({ palette }) => palette.customColors.gray[500] }}>
            - Empty -
          </Typography>
        </Grid>
      )}

      <Stack direction={'row'} spacing={2} alignItems={'center'} padding={2}>
        {userExperienceData?.data?.results?.map(({ experience, id }) => (
          <Box key={id}>
            <ControlledRadio2
              key={id}
              id={id}
              control={control}
              errors={errors}
              label={experience}
              param={'experience'}
              marginTop={0}
            />
          </Box>
        ))}
      </Stack>
    </Grid>
  );
}

export default Experience;

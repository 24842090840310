import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import Slider from '@mui/material/Slider';
import { routerPath } from 'router/Routerlist';
import { Link } from 'react-router-dom';
import filterIcon from './../../../assets/images/filter.svg';
import { connect, useSelector } from 'react-redux';
import IconLogo from 'assets/logoicon.png';
import { authState } from 'store/slices/appSlice';
import {
  useGetViewManageJobPostQuery,
  useViewApplicantsProfileMutation
} from 'store/slices/apiSlice';
import { refDbRoomName } from 'views/Messages';
import { onValue, db, ref } from 'utils/firebase';

const JobSeekerMenu = (props) => {
  const {
    action: { goToPage }
  } = props;

  const { data: allJobResult } = useGetViewManageJobPostQuery();
  // const [
  //   viewApplicantsProfile,
  //   { data: profileResult, isSuccess, refetch: refetchUserProfile, isLoading: isLoadingViewProfile }
  // ] = useViewApplicantsProfileMutation();
  // const { data: myFavoriteJob, refetch: refetchFavoriteJob } = useGetMyFavoriteJobListQuery();

  // navigate(routerPath.jobs.applicants.replace(':job_id', ele.id));
  const { authenticated } = useSelector(authState);
  const jobAdminMenu = [
    {
      link: routerPath.jobs.createjob,
      title: 'Post Job Openings'
    },
    // {
    //   link: routerPath.jobs.applicants.replace(':job_id', authenticated?.company?.id),

    //   // link: `/job/${authenticated?.company?.id}/applicants`,
    //   title: 'Job Applicants'
    // },
    {
      link: routerPath.jobs.manageJob,
      title: 'Manage Job Posts'
    }
  ];

  const projectAdminMenu = [
    {
      link: routerPath.projects.postProject,
      title: 'Post Available Projects'
    },
    {
      link: routerPath.projects.manageProject,
      title: 'Manage Available Projects'
    }
  ];

  useEffect(() => {
    getChatRoomUser();
  }, []);

  const [unReadMessageCounter, setUnReadMessageCounter] = useState(0);
  const onAddRoom = (data) => {
    let counter = 0;
    Object.values(data).map((e) => {
      counter += e.hasUnRead;
    });

    setUnReadMessageCounter(counter);
  };

  const getChatRoomUser = () => {
    const query = ref(db, `${refDbRoomName}/${authenticated?.id}`);

    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        onAddRoom(data);
      }
    });
  };

  // console.log('AllJOBRESult,,', authenticated);

  return (
    <div className="sidebar-item">
      <ul className="nav-sidebar ">
        <li>
          <a
            className="nav-item"
            href="javascript:void(0)"
            onClick={async () => {
              await localStorage.removeItem('workium_jobFilter');
              goToPage(routerPath.jobs.alljob)
            }}>
            Search Jobs
          </a>
        </li>
        {authenticated?.user_type !== 'Job Seeker' && (
          <>
            {jobAdminMenu.map((e, idx) => (
              <li key={idx}>
                <a className="nav-item" href="javascript:void(0)" onClick={() => goToPage(e.link)}>
                  {e.title}
                </a>
              </li>
            ))}
          </>
        )}
        <li>
          <a
            className="nav-item"
            href="javascript:void(0)"
            onClick={() => goToPage(routerPath.messages.index)}>
            Messages
            {unReadMessageCounter > 0 && <div className="badge">{unReadMessageCounter}</div>}
          </a>
        </li>
        <li>
          <a
            className="nav-item"
            href="javascript:void(0)"
            onClick={() => goToPage(routerPath.projects.search)}>
            Search Available Projects
          </a>
        </li>
        {authenticated?.user_type !== 'Job Seeker' && (
          <>
            {projectAdminMenu.map((e, idx) => (
              <li key={idx}>
                <a className="nav-item" href="javascript:void(0)" onClick={() => goToPage(e.link)}>
                  {e.title}
                </a>
              </li>
            ))}
          </>
        )}
        <li>
          <a
            className="nav-item"
            href="javascript:void(0)"
            onClick={() => goToPage(routerPath.favorite.view)}>
            Favorites
          </a>
        </li>
        <li>
          <a
            className="nav-item"
            href="javascript:void(0)"
            onClick={() => {
              window.location.href = `https://workium.com/blogs/`;
            }}>
            Blogs
          </a>
        </li>
        <li>
          <a
            className="nav-item"
            href="javascript:void(0)"
            onClick={() => goToPage(routerPath.settings.index)}>
            Settings
          </a>
        </li>
      </ul>
      {/* <button className="btn btn-success">
        <img src={IconLogo} className="top" alt="" /> Download App
      </button> */}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(JobSeekerMenu);

import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant'
import { apiGetAllJobs } from 'api/jobs.api'

  export const actionToProps = {
    actionGetAllJobs: (params) => async (dispatch, getState) => {

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: true
        }
      })

      try {
        const { data } = await apiGetAllJobs(params)

        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'jobs',
            value: data.results
          }
        })

        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: false
          }
        })

        return data
      } catch (err) {
        let errorMessage = []
        let message = err.message ? err.message : 'Something went Wrong!';
        if (err.response){
          message = err.response.data.message
        }

        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: false
          }
        })
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'alertSeverity',
            value: 'error'
          }
        })
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'errors',
            value: errorMessage.concat(message)
          }
        })

        return false
      }
      finally {
  
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: false
          }
        })
      }
    },
  }

import React, { useEffect, useState } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import FooterDashboard from 'components/FooterLink/Dashboard';
import { connect, useDispatch, useSelector } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconStar from 'assets/star-off.svg';
import ProjectCard from 'components/ProjectCard';
import {
  api,
  useAddFavoriteProjectMutation,
  useDeleteFavoriteJobMutation,
  useDeleteFavoriteProjectMutation,
  useGetDetailJobsQuery,
  useGetDetailJobViewMutation,
  useGetFavouriteProjectsQuery,
  useGetMyFavoriteJobListQuery,
  usePostQuickApplyJobMutation,
  usePostQuickApplyProjectMutation
} from 'store/slices/apiSlice';
import JobListCard from 'components/JobListCard';
import { routerPath } from 'router/Routerlist';
import { useNavigate } from 'react-router-dom';
import * as cx from 'classnames';
import { CHANGE_ATTRIBUTE } from 'store/reducers/auth/auth.constant';
import { HandleGetFavoriteAvailableProjectsRequest } from 'api/callsHandler';
import moment from 'moment';
import { useGeolocated } from 'react-geolocated';
import { calculateDistance } from 'utils/helper';
import { JOB_SEEKER, RECRUITER } from 'constant';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const JobFavoriteTab = () => {
  const { data, isLoading, isSuccess, refetch: refetchFavorite } = useGetMyFavoriteJobListQuery();
  const [getDetailJobView, { data: viewJob, isLoadingDetailJob }] = useGetDetailJobViewMutation();
  const [deleteFavoriteJob, { isSuccess: successDeletedJob }] = useDeleteFavoriteJobMutation();
  const [jobs, setJobs] = useState([]);
  const userData = useSelector((state) => state.auth.authenticated);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [postQuickApplyJob, { isLoading: loadingApplyJob, isSuccess: isSuccessQuickApply }] =
    usePostQuickApplyJobMutation();

  useEffect(() => {
    getAllDetailFavoriteJobs();
  }, [isSuccess]);

  useEffect(() => {
    getAllDetailFavoriteJobs();
  }, [data]);

  useEffect(() => {
    if (successDeletedJob || isSuccessQuickApply) {
      refetchFavorite();
    }
  }, [successDeletedJob, isSuccessQuickApply]);

  const getAllDetailFavoriteJobs = async () => {
    const allDetailJobs = data?.results?.map((e) => {
      return getDetailJobView(e.job);
    });
    const datas = allDetailJobs?.length
      ? await Promise.all([...allDetailJobs]).catch((d) =>
          console.log({
            d
          })
        )
      : [];

    console.log({
      datas
    });
    let concatJobs = [];
    datas.map(({ data }) => {
      if (data?.id) {
        if (!concatJobs.map((e) => e.id).includes(data.id)) {
          concatJobs.push(data);
        }
      }
    });

    console.log({
      concatJobs
    });

    setJobs(concatJobs);
  };

  const onDeleteFavorite = async (ids) => {
    const deleteTheJob = ids.map((e) => {
      return deleteFavoriteJob(e);
    });

    await Promise.all([...deleteTheJob]);
  };

  const onQuickApply = async (ele) => {
    const payload = {
      job_id: ele.id,
      user_id: ele.user?.id,
      city: ele?.city,
      description: ele?.description,
      distance: 10,
      state: ele?.state,
      title: ele?.title
    };

    await postQuickApplyJob(payload);
    await dispatch(api.util.invalidateTags(['Job']));
    getAllDetailFavoriteJobs();
  };

  return (
    <>
      {!!data && data.count > 0 && jobs.length ? (
        jobs.map((ele, idx) => {
          return (
            <JobListCard
              className={cx({
                featured: ele?.is_featured,
                urgent: ele?.is_urgent
              })}
              // isFavorite
              isFavorite={data?.results?.map((e) => e?.job)?.includes(ele?.id)}
              image={ele.images?.[0]?.image?.split('?')[0] || ele.job_category.image || Image}
              title={ele.title}
              id={ele.id}
              stateId={ele?.state}
              cityId={ele?.city}
              quickApplyTitle={
                ele.applicants?.find((x) => x.user.id == userData?.id)?.user?.id
                  ? 'Applied'
                  : 'Quick Apply'
              }
              onAddFavourite={() => {
                const jobDeletedItems = data.results
                  .map((e) => {
                    if (e.job === ele.id) {
                      return e;
                    }
                  })
                  .filter((f) => f);

                onDeleteFavorite(jobDeletedItems.map((f) => f.id));
              }}
              posted={moment(ele.posted).fromNow()}
              location={`${ele.state}, ${ele.city}`}
              desc={ele.description}
              onClick={() => {
                navigate(routerPath.jobs.detail.replace(':job_id', ele.id), {
                  state: {
                    isFavorite: data?.results?.map((e) => e?.job).includes(ele?.id)
                  }
                });
              }}
              onQuickApply={() => {
                onQuickApply(ele);
              }}
              disableButton={loadingApplyJob}
              key={idx}
              user={ele.user}
            />
          );
        })
      ) : (
        <>
          <div className="text-center mt-5">
            <img src={IconStar} alt="" />
            <p className="text-dark mt-2">No favorites added yet</p>
          </div>
        </>
      )}
    </>
  );
};

const FavoritePage = ({ auth }) => {
  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.authenticated);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false
    },
    userDecisionTimeout: 5000
  });

  const {
    data: favoriteAvailableProjectsData,
    refetch,
    mutate: favoriteAvailableProjectsMutate
  } = HandleGetFavoriteAvailableProjectsRequest('', value === 1);

  const [
    postQuickApplyJob,
    {
      isLoading: loadingApplyJob,
      isError: isErrorApplyJob,
      error: errorApplyJob,
      isSuccess: isSuccessQuickApply
    }
  ] = usePostQuickApplyProjectMutation();

  const { data: myFavorite, refetch: refetchFavorite } = useGetFavouriteProjectsQuery();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [
    deleteFavoriteProject,
    { isLoading: isLoadingDeleteFavorite, error: errorDeleteingFavoriteJob }
  ] = useDeleteFavoriteProjectMutation();

  const onDeleteFavorite = async (ids) => {
    const deleteTheJob = ids.map((e) => {
      return deleteFavoriteProject(e);
    });

    await Promise.all([...deleteTheJob]);
    favoriteAvailableProjectsMutate();
  };

  const OnHandleApple = async (ele) => {
    const distance = calculateDistance(coords?.latitude, coords?.longitude, ele?.lat, ele?.long);
    const payload = {
      name: ele.name,
      description: ele.description,
      price: ele.price,
      deadline: ele.deadline,
      is_price_negotiated: ele.is_price_negotiated,
      is_deadline_negotiated: ele.is_deadline_negotiated,
      zip_code: ele.zip_code,
      region: ele.region,
      counter: ele.counter,
      is_paid: ele.is_paid,
      term: ele.term,
      start_date: ele.start_date,
      is_reoccurring: ele.is_reoccurring,
      sub_days: ele.sub_days,
      sub_price: ele.sub_price,
      trade: ele.trade,
      city: ele.city,
      state: ele.state,
      sub_category: ele.sub_category,
      applicants: ele.applicants,
      project_id: ele.id,
      title: ele.name,
      distance: distance
    };

    try {
      await postQuickApplyJob(payload).unwrap();
      favoriteAvailableProjectsMutate();
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner pt-2">
              <h1 className="title-2 mt-0 mb-3">Favorites</h1>
              <Tabs
                value={value}
                onChange={handleChange}
                className="nav-tab"
                sx={{
                  color: '#9AB650'
                }}>
                <Tab label="Job Openings" {...a11yProps(0)} />
                <Tab label="Available Projects" {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <JobFavoriteTab />
              </TabPanel>
              <TabPanel value={value} index={1}>
                {favoriteAvailableProjectsData?.data?.results?.map((item) => {
                  return (
                    <Box key={item.id}>
                      <ProjectCard
                        name={item?.project?.name}
                        description={item?.project?.description}
                        tags={item?.project?.sub_category?.map((tag) => tag.sub_category)}
                        address={`${item?.project?.zip_code ?? ''}${
                          item?.project?.zip_code ? ',' : ''
                        } ${item?.project?.region ?? ''}`}
                        price={item?.project?.price}
                        deadline={item?.project?.deadline}
                        disabled={
                          item?.project?.applicants?.find((x) => x.user.id == userData.id)?.id ||
                          userData?.user_type === JOB_SEEKER ||
                          userData?.user_type === RECRUITER
                            ? true
                            : false
                        }
                        createdAt={item?.project?.created_at}
                        stateId={item?.project?.state}
                        cityId={item?.project?.city}
                        actionTitle={
                          item?.project?.applicants?.find((x) => x.user.id == userData?.id)?.user
                            ?.id
                            ? 'Applied'
                            : 'Quick Apply'
                        }
                        onActionClick={() => {
                          OnHandleApple(item?.project);
                        }}
                        estimated_start_date={item?.project?.start_date}
                        onAddFavourite={() => {
                          const jobDeletedItems = myFavorite.results
                            .map((e) => {
                              if (e?.project?.id === item?.project?.id) {
                                return e;
                              }
                            })
                            .filter((f) => f);

                          // // debugger;
                          onDeleteFavorite(jobDeletedItems.map((f) => f.id));
                        }}
                        isFavorite={myFavorite?.results
                          ?.map((e) => e?.project?.id)
                          .includes(item?.project?.id)}
                        posted={moment(item.posted).fromNow()}
                        onClick={() => {
                          navigate(
                            routerPath.projects.detail.replace(':project_id', item?.project?.id),
                            {
                              state: {
                                isFavorite: myFavorite?.results
                                  ?.map((e) => e?.project?.id)
                                  .includes(item?.project?.id)
                              }
                            }
                          );
                        }}
                      />
                    </Box>
                  );
                })}
                {/* <ProjectCard
                                    name="HVAC contractor for Atrium Business Center"
                                    description="Material, human and time resources, with various technical and organizational difficulties, are used to carry out design and construction and installation work (CMP). At the same time, situations often arise when a contractor for a project may act in bad faith, take another more profitable order and not start work on a previously concluded contract"
                                    tags={['Large Building Construction', 'HVAC']}
                                    address="10005, New York, NY"
                                    price="Negotiated"
                                    deadline="2 weeks"
                                /> */}
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FavoritePage;

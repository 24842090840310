import * as yup from 'yup';

const signupSchema = yup.object().shape({
  email: yup.string().email('Please provide a valid email.').required('This field is required.'),
  password: yup.string().required('This field is required.').min(8),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Does not match with password!')
    .required('This field is required.')
});

export default signupSchema;

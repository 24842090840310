import {
  getRequestDefault,
  patchProfileJsonRequest,
  patchProfileRequest,
  putProfileRequest
} from 'utils/calls';
import useSWR from 'swr';

export function HandleGetProfileRequest(params, id) {
  const { data, error, isValidating, mutate } = useSWR(
    id ? `/profile/${id}/${params}` : null,
    getRequestDefault,
    {
      fallbackData: undefined
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetUserTradeLicenseRequest() {
  const { data, error, isValidating, mutate } = useSWR(`/user-trade-license/`, getRequestDefault, {
    fallbackData: undefined
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetUserProfessionRequest() {
  const { data, error, isValidating, mutate } = useSWR(`/user-profession/`, getRequestDefault, {
    fallbackData: undefined
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetLanguagesRequest() {
  const { data, error, isValidating, mutate } = useSWR(`/language/`, getRequestDefault, {
    fallbackData: undefined
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetUserConstructionTradeRequest() {
  const { data, error, isValidating, mutate } = useSWR(
    `/user-construction-trade/`,
    getRequestDefault,
    {
      fallbackData: undefined
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetUserSkillSetRequest() {
  const { data, error, isValidating, mutate } = useSWR(`/user-skill-set/`, getRequestDefault, {
    fallbackData: undefined
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetUserEducationRequest() {
  const { data, error, isValidating, mutate } = useSWR(`/user-education/`, getRequestDefault, {
    fallbackData: undefined
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetUserExperienceRequest() {
  const { data, error, isValidating, mutate } = useSWR(`/user-experience/`, getRequestDefault, {
    fallbackData: undefined
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetUserIndustryRequest() {
  const { data, error, isValidating, mutate } = useSWR(`/user-industry/`, getRequestDefault, {
    fallbackData: undefined
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetUserLaborAffiliationRequest() {
  const { data, error, isValidating, mutate } = useSWR(
    `/user-labor-affiliation/`,
    getRequestDefault,
    {
      fallbackData: undefined
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetUserTradeAffiliationRequest() {
  const { data, error, isValidating, mutate } = useSWR(
    `/user-trade-affiliation/`,
    getRequestDefault,
    {
      fallbackData: undefined
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function handlePatchProfileRequest(id, requestBody, cb_success, cb_error, cb_finally) {
  patchProfileRequest(id, requestBody)
    .then((res) => {
      cb_success(res);
    })
    .catch((err) => {
      cb_error(err);
    })
    .finally(() => cb_finally());
}

export function handlePatchProfileJsonRequest(id, requestBody, cb_success, cb_error, cb_finally) {
  patchProfileJsonRequest(id, requestBody)
    .then((res) => {
      cb_success(res);
    })
    .catch((err) => {
      cb_error(err);
    })
    .finally(() => cb_finally());
}

export function HandleGetFavoriteAvailableProjectsRequest(params, id) {
  const { data, error, isValidating, mutate } = useSWR(
    id ? `/favourite-project/` : null,
    getRequestDefault,
    {
      fallbackData: undefined
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetSubscriptionRequest(id = undefined) {
  const { data, error, isValidating, mutate } = useSWR(
    `/subscription/${id ? `${id}/` : ''}`,
    getRequestDefault,
    {
      fallbackData: undefined
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetAddonsRequest(id = undefined) {
  const { data, error, isValidating, mutate } = useSWR(
    `/addon/${id ? `${id}/` : ''}`,
    getRequestDefault,
    {
      fallbackData: undefined
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetPromoCodeRequest(code = undefined) {
  const { data, error, isValidating, mutate } = useSWR(`/coupon/`, getRequestDefault, {
    fallbackData: undefined
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetAllCardsRequest() {
  const { data, error, isValidating, mutate } = useSWR(`/get-all-cards/`, getRequestDefault, {
    fallbackData: undefined
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

export function HandleGetJobDetailsRequest(jobId) {
  const { data, error, isValidating, mutate } = useSWR(`/jobs/${jobId}/`, getRequestDefault, {
    fallbackData: undefined
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate
  };
}

import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import IconPlus from 'assets/plus.svg';
import classNames from 'classnames';
import IconDelete from 'assets/x-rounded.svg';
import IconFile from 'assets/file.svg';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.chooseButtonRef = React.createRef();
    this.state = {
      imageItems: this.props.defaultValue ? this.props.defaultValue : []
    };
  }
  async onUploadImage(files) {
    const { imageItems } = this.state;

    const { onUploadImage, apiUpload, singleUpload, onAlert, onLoader, maxUpload } = this.props;

    if (maxUpload === imageItems.length) return false;

    let newItem = {
      id: uuid(),
      url: URL.createObjectURL(files[0]),
      file: files[0]
    };

    if (apiUpload) {
      onLoader(true);
      const data = await apiUpload({
        payload: files[0]
      });

      onLoader(false);

      if (!data.response) {
        onAlert(data?.error ?? data);
        return false;
      }
      if (data.response && data.response.data && data.response.data.attachment_id) {
        newItem.id = data.response.data.attachment_id;
      }
    }

    let updateImageItems = imageItems;

    if (singleUpload) {
      updateImageItems = [newItem];
    }

    if (!singleUpload) {
      updateImageItems.push(newItem);
    }

    this.setState({
      imageItems: updateImageItems
    });

    if (onUploadImage) {
      onUploadImage(updateImageItems);
    }
  }
  getFileType = (extension) => {
    const imageExtensions = ['png', 'jpeg', 'jpg', 'gif'];
    const videoExtensions = ['mp4', 'mov', 'wmv', 'avi'];

    if (imageExtensions.includes(extension)) {
      return 'image';
    } else if (videoExtensions.includes(extension)) {
      return 'video';
    } else {
      return 'file'; // Assume any other file type is a general file
    }
  };

  onDeleteImage(index) {
    const { imageItems } = this.state;

    const { onDeleteImage } = this.props;

    imageItems.splice(index, 1);

    this.setState({
      imageItems: imageItems
    });

    onDeleteImage(imageItems);
  }
  render() {
    const { imageItems } = this.state;
    console.log(imageItems, 'image');
    const {
      buttonName,
      boxType,
      defaultAvatar,
      buttonTitle,
      singleUpload,
      className,
      caption,
      maxUpload,
      disabled = false
    } = this.props;

    return (
      <>
        <Dropzone
          ref={this.chooseButtonRef}
          onDrop={(acceptedFiles) => this.onUploadImage(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <div
              className={classNames({
                'dropzoneContent upload-container cursor-pointer': true,
                'preview-content-type file-container': boxType === 'preview'
              })}>
              {imageItems.length > 0 &&
                imageItems.map((file, idx) => {
                  let extension = file?.file
                    ? file.file.path.split('.').pop().toLowerCase()
                    : 'jpeg';
                  // let type = ['png', 'jpeg', 'jpg', 'gif'].includes(extension) ? 'image' : 'video';
                  let type = this.getFileType(extension);
                  return (
                    <div
                      className={classNames({
                        'dropzonePreview view': true,
                        'file-item': boxType === 'preview'
                      })}
                      key={idx}>
                      <>
                        {type === 'video' ? (
                          <video src={file.url} alt={'image-preview-' + idx} />
                        ) : type === 'image' ? (
                          <img src={file.url} alt={'image-preview-' + idx} />
                        ) : (
                          <img
                            src={IconFile}
                            alt={`file-${idx}`}
                            style={{ objectFit: 'contain' }}
                          />
                        )}
                      </>

                      {boxType === 'box' && (
                        <button
                          onClick={(e) => {
                            this.onDeleteImage(idx);
                          }}>
                          <CloseIcon />
                        </button>
                      )}
                      {boxType === 'preview' && (
                        <div className="delete-file" onClick={() => this.onDeleteImage(idx)}>
                          <img src={IconDelete} alt="delete-icon" />
                        </div>
                      )}
                    </div>
                  );
                })}
              <div {...getRootProps()} className="dropzonePreview button-upload">
                <input {...getInputProps()} className="ssr-only" />
                {boxType === 'preview' && (
                  <>
                    <button
                      type="button"
                      className={`btn btn-${disabled ? 'grey' : 'success'} mb-1`}
                      disabled={false}>
                      <img src={IconPlus} className="sub mr-1" alt="plus icon" />
                      {buttonTitle}
                      {!singleUpload && '(s)'}
                    </button>
                    <div className="text-grey">
                      You can upload {maxUpload - imageItems.length}{' '}
                      {imageItems.length !== 0 && 'more'} files
                    </div>
                  </>
                )}
                {boxType === 'box' && (
                  <>
                    <div className="upload-icon"></div>
                    <div className="upload-label mt-1">{caption}</div>
                  </>
                )}
              </div>
            </div>
          )}
        </Dropzone>
      </>
    );
  }
}

UploadImage.defaultProps = {
  maxUpload: 5,
  buttonName: 'default',
  inputName: 'image',
  singleUpload: true,
  apiUpload: false,
  defaultAvatar: false,
  boxType: 'box',
  className: '',
  onUploadImage: false,
  buttonTitle: 'Add File',
  onDeleteImage: () => {
    return false;
  },
  onAlert: () => {
    return false;
  },
  onLoader: () => {
    return false;
  },
  totalShow: 0
};

// UploadImage.propTypes = {
//   buttonName: PropTypes.any,
//   boxType: PropTypes.string,
//   className: PropTypes.string,
//   totalShow: PropTypes.any,
//   defaultAvatar: PropTypes.any,
//   singleUpload: PropTypes.bool,
//   inputName: PropTypes.string,
//   apiUpload: PropTypes.oneOfType([
//     PropTypes.func,
//     PropTypes.bool,
//   ]),
//   defaultValue: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.number,
//       ]),
//       url: PropTypes.string,
//       value: PropTypes.any,
//     })),
//   onUploadImage: PropTypes.oneOfType([
//     PropTypes.func,
//     PropTypes.bool,
//   ]),
//   onDeleteImage: PropTypes.func,
// };

export default UploadImage;

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import {ReactComponent as CloseIcon} from 'assets/x-close-icon.svg';
import { connect } from 'react-redux'
import Loading from 'components/Loading/index'

import { withRouter } from "react-router";
import {
  apiPostResetEmailPassword
} from 'api/login.api'
import { actionToProps as authAction } from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action'
import { defaultState as authDefaultState } from 'store/reducers/auth/auth.reducer'
import {
  routerPath
} from 'router/Routerlist'

const SendPassword = (props) =>{
  const loginForm = React.useRef(null)

  const {
    actionPostForgotPassword,
    changeNewsAttribute,
    changeAuthAttribute,
    auth:{
      validationFormResetPassword,
      formResetPassword
    },
    open,
    handleClose,
    maxWidth,
    fullWidth
  } = props

  React.useEffect(()=> {
    changeAuthAttribute({
      key: 'validationFormResetPassword',
      value: authDefaultState.validationFormResetPassword
    })
  }, [open])


  const handleSubmitApiResetPassword = async (e) => {
    e.preventDefault()

    let clearPayload = {}
    let payload = {
      email: loginForm.current.querySelector('input#email').value,
    }
    let initFormResetPassword = {
      email: {
        value: payload.email,
        error: false
      }
    }
    Object.keys(initFormResetPassword).map((e) => {
      clearPayload = {
        ...clearPayload,
        [e]: initFormResetPassword[e].value
      }
    })

    const success = await actionPostForgotPassword(clearPayload)
  }

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
    >
      <div className="icon-close cursor-pointer" onClick={handleClose}>
        <CloseIcon />
      </div>
      <div className="forgot-dialog">
      <DialogTitle>Forgot your password?</DialogTitle>
      <DialogContent>
        <DialogContentText>
        Just enter your email address below and we will send you a link to reset your password
        </DialogContentText>


        <form ref={loginForm} noValidate onSubmit={handleSubmitApiResetPassword} className="form-input">
          <TextField
            id="email"
            label="Email"
            placeholder="Email Address"
            fullWidth
            variant="outlined"
            className={validationFormResetPassword?.email && validationFormResetPassword?.email?.success ? `success-field` : ''}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={()=> {
              changeAuthAttribute({
                key: 'validationFormResetPassword',
                value: {
                  ...validationFormResetPassword,
                  email: {
                    ...validationFormResetPassword.email,
                    error: false
                  }
                }
              })
            }}
            error={validationFormResetPassword?.email?.error ? true : false}
            helperText={validationFormResetPassword?.email?.error ? validationFormResetPassword?.email?.error : false}
          />
          <br/>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="main-dark font-poppins text-transform-capitalize"
          >
            Reset Password
          </Button>
        </form>

      </DialogContent>
      </div>
    </Dialog>
  )
}
SendPassword.defaultProps = {
  open: false,
  maxWidth: 'md',
  fullWidth: true
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      news: state.news,
    }
}
const mapDispatchToProps = {...authAction, ...newsAction}
export default connect(mapStateToProps, mapDispatchToProps)(SendPassword)

import { useMemo } from 'react';
import { CircularProgress, Stack } from '@mui/material';

import IconArrow from 'assets/arrow-left-outline.svg';
import ListPrice from 'components/ListPrice';
import { environment } from 'config/environment';
import { loadStripe } from '@stripe/stripe-js';
import { Elements as StripeElements } from '@stripe/react-stripe-js';
import Checkout from './checkout';
import { HandleGetAddonsRequest, HandleGetSubscriptionRequest } from 'api/callsHandler';
import { aroundDecimalValueToCustomDecimal } from 'utils/helper';
import { useLocation } from 'react-router-dom';

const stripe = loadStripe(environment.STRIPE_PUBLISH_KEY);

const ChargePaymentComponent = ({
  onClickBack,
  payload,
  onSuccessCharge,
  postType,
  addCardError
}) => {
  const { data: subscriptionData, isLoading: subscriptionIsLoading } = HandleGetSubscriptionRequest(
    payload?.sub_id
  );
  const location = useLocation();

  const { data: addonsData, isLoading: addonsIsLoading } = HandleGetAddonsRequest(
    payload?.addon_id
  );

  const subscriptionPrice = useMemo(() => subscriptionData?.data?.price ?? 0, [subscriptionData]);

  const addonsPrice = useMemo(() => addonsData?.data?.price ?? 0, [addonsData]);

  const decimalDiscountedPercentage = useMemo(
    () => payload?.coupon?.discount_percent / 100,
    [payload?.coupon?.discount_percent]
  );

  const totalPrice = useMemo(
    () => subscriptionPrice + addonsPrice + (postType == 'job' ? 4.95 : 0),
    [subscriptionPrice, addonsPrice]
  );
  const totalPriceWithDiscount = useMemo(
    () => totalPrice - totalPrice * decimalDiscountedPercentage,
    [totalPrice, decimalDiscountedPercentage]
  );

  return (
    <>
      <div className="dahboard-header">
        <h1>
          <a href="javascript:void(0);" onClick={onClickBack}>
            <img src={IconArrow} className="sub mr-1" alt="" />
          </a>
          {location.pathname.includes('projects') ? 'Project' : 'Job'} Ads
        </h1>
      </div>
      <div className="content-inner">
        <div className="job-ads">
          {payload.coupon?.code && (
            <div className="mb-2">
              <ListPrice
                price={payload?.coupon?.discount_percent + '%'}
                label="Discount"
                name="jobads"
                value="ads3"
              />
            </div>
          )}
          {subscriptionIsLoading ? (
            <Stack sx={{ width: '100%' }} alignItems={'center'}>
              <CircularProgress />
            </Stack>
          ) : (
            <div className="mb-2">
              <ListPrice
                onClick={onClickBack}
                price={`$${subscriptionPrice}`}
                label={subscriptionData?.data?.name}
                name=""
                value={subscriptionData?.data?.id}
                content={
                  'Your subscription will auto-renew for the non-discounted price until you cancel via manage job post '
                }
              />
            </div>
          )}
          {addonsIsLoading ? (
            <Stack sx={{ width: '100%' }} alignItems={'center'}>
              <CircularProgress />
            </Stack>
          ) : (
            <div className="mb-2">
              {addonsPrice > 0 && (
                <ListPrice
                  price={`$${addonsPrice}`}
                  label={addonsData?.data?.name}
                  name=""
                  value={addonsData?.data?.id}
                  content={
                    '7 day upgrade will auto-renew at the same price until you cancel via manage job post page'
                  }
                />
              )}
            </div>
          )}
          {postType === 'job' && (
            <div className="mb-2">
              <ListPrice price="$4.95" label="Credit card fee" name="jobads" value="ads3" />
            </div>
          )}

          <div className="divider-ads"></div>

          <div className="mb-4">
            <div className="total-ads">
              <div className="list-head">
                <div className="price">{`$${aroundDecimalValueToCustomDecimal(
                  totalPriceWithDiscount ? totalPriceWithDiscount : totalPrice
                )}`}</div>
                <div className="label-name">Total Charge</div>
              </div>
            </div>
          </div>

          {/* <h4 className="ads-payment-title mb-3">Select payment method</h4> */}

          {/* <div className="nav-payment mb-3">
            <div className="item">
              <img src={IconPaypal} alt="" />
            </div>
            <div className="item active">
              <img src={IconCreditCard} alt="" />
            </div>
          </div> */}
          <StripeElements stripe={stripe}>
            <Checkout
              onSuccessCharge={onSuccessCharge}
              addCardError={addCardError}
              price={totalPriceWithDiscount ? totalPriceWithDiscount : totalPrice}
            />
          </StripeElements>
        </div>
      </div>
    </>
  );
};

export default ChargePaymentComponent;

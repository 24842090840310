import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { routerPath } from 'router/Routerlist';
import { useNavigate } from 'react-router-dom';

const LabelBottomNavigation = (props) => {
  const [value, setValue] = React.useState(props.active);
  const navigate = useNavigate();

  return (
    <div className="dashboard-footer">
      <ul className="mui-list--inline mb-0">
        <li>
          {/* <a className="cursor-pointer" onClick={() => navigate(routerPath.footer.about)}>
            About
          </a> */}
        </li>
        <li>
          <a className="cursor-pointer" onClick={() => navigate(routerPath.footer.terms)}>
            Terms
          </a>
        </li>
        <li>
          <a className="cursor-pointer" onClick={() => navigate(routerPath.footer.privacy)}>
            Privacy
          </a>
        </li>
      </ul>
    </div>
  );
};
export default LabelBottomNavigation;

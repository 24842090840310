import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_AUTH } from 'store/reducers/auth/auth.constant'
import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant'
import {
  apiGetProfile,
  apiGetFeaturedProfile,
  apiGetDetailProfile
} from 'api/profile.api'

export const actionToProps = {
  actionGetProfile: (params) => async (dispatch, getState) => {
    const {
      auth: {
        authenticated
      }
    } = getState()

    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {

      const { data } = await apiGetDetailProfile(params?.userId, params)
      
      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'authenticated',
          value: {
            ...authenticated,
            ...data
          }
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })


      return data
    } catch ({ message, response }) {
      const { data } = response

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: [message]
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
    finally {

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })
    }
  },actionGetSpecialistProfile: (id,params) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {

      const {
        data
      } = await apiGetDetailProfile(id,params)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'specialistProfile',
          value: data
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })


      return data
    } catch ({ message, response }) {

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: [message]
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
    finally {

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })
    }
  },
  actionGetFeaturedProfile: (params) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {

      const {
        data: {
          data
        }
      } = await apiGetFeaturedProfile(params)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'featuredProfiles',
          value: data
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })


      return data
    } catch ({ message, response }) {
      const { data } = response

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: [message]
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
    finally {

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })
    }
  }
}

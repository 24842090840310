import * as constants from './auth.constant';

export const defaultState = {
  authenticated: false,
  token: null,
  validationFormLogin: {
    email: {
      error: false,
      value: ''
    },
    password: {
      error: false,
      value: ''
    }
  },
  validationFormResetPassword: {
    email: {
      error: false,
      value: ''
    },
    new_password1: {
      error: false,
      value: ''
    },
    new_password2: {
      error: false,
      value: ''
    }
  },
  formProfileCompleteness: {
    email: {
      error: false,
      value: ''
    },
    phone: {
      error: false,
      value: ''
    },
    fax: {
      error: false,
      value: ''
    },
    company_logo_pictures: {
      error: false,
      value: {
        id: null,
        url: '',
        file: null
      }
    },
    project_pictures: {
      error: false,
      value: {
        id: null,
        url: '',
        file: null
      }
    },
    year: {
      error: false,
      value: '2022'
    },
    about: {
      error: false,
      value: ''
    }
  },
  formSignUp: {
    // firstname: {
    //   error: false,
    //   value: ''
    // },
    // lastname: {
    //   error: false,
    //   value: ''
    // },
    // city: {
    //   error: false,
    //   value: ''
    // },
    email: {
      error: false,
      value: ''
    },
    password: {
      error: false,
      value: ''
    },
    confirm_password: {
      error: false,
      value: ''
    }
  },
  profile: null
};
const ACTION_HANDLERS = {
  [constants.LOGOUT]: (state, action) => {
    return { ...state, ...defaultState };
  },
  [constants.CHANGE_ATTRIBUTE]: (state, action) => {
    const { value, key } = action.payload;

    const updateState = {
      ...state,
      [key]: value
    };

    return Object.assign({}, state, updateState);
  },
  [constants.INIT]: (state, action) => {
    return { ...state, ...action.payload };
  }
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const SnackbarAlert = (props) => {
    const {
      isOpen,
      handleClose,
      children,
      severity
    } = props
    return (
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={3000}
            onClose={handleClose}
            open={isOpen}
          >
            <Alert severity={severity} sx={{ width: '100%' }}>
              {children}
            </Alert>
          </Snackbar>
        </>
    );
}
SnackbarAlert.defaultProps = {
  severity: 'error'
}
export default SnackbarAlert;

import { CARD_TYPE } from 'constant';
import React from 'react';

export default function CardPrice(props) {
  const {
    title,
    icon,
    price,
    children,
    dataId,
    radioName,
    input,
    onChangeRadio,
    payload,
    cardType,
    discount
  } = props;

  const finalPrice = price > 0 ? (discount ? (price - (price * discount) / 100).toFixed(2) : price.toFixed(2)) : '0.00';
  const textDecoration = discount ? 'line-through' : 'none';

  return (
    <div className="card-price">
      <div>
        <div className="card-price-heading">
          <div>
            <h3>{title}</h3>
          </div>
          <div>
            <img src={icon} height="35" alt="" />
          </div>
        </div>
        <div className="card-price-body">{children}</div>
      </div>
      <div className="card-price-footer">
        <div className="text-center mb-3">
          <div className="price">
            <span style={{ textDecoration }}>${price}</span>
            <br />
            {discount && (
              <span style={{ fontSize: '1.5rem' }}>
                You get a {discount}% discount. Final price: <strong>${finalPrice}</strong>
              </span>
            )}
          </div>
          <div className="select">
            <input
              type="radio"
              id={dataId}
              name={radioName}
              onClick={onChangeRadio}
              // value={price}
              value={dataId}
              // value={payload?.sub_id ?? payload?.addon_id}
              checked={
                cardType === CARD_TYPE.SUB && payload?.sub_id === dataId
                  ? true
                  : cardType === CARD_TYPE.ADDON && payload?.addon_id === dataId
                  ? true
                  : false
              }
            />
            <label htmlFor={dataId}>Select</label>
          </div>
        </div>
        {input}
      </div>
    </div>
  );
}

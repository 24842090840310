import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import FooterDashboard from 'components/FooterLink/Dashboard';
import { connect } from 'react-redux';
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Box, Grid } from '@mui/material';
import ProjectCard from 'components/ProjectCard';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const JobSeekerSearchProject = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="dahboard-header">
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <h1>Search Available Projects</h1>
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'right' }}>
                  <button type="button" onClick={handleClick} className="btn btn-success">
                    Search Filter
                  </button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'end'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}>
                    <div className="modal">
                      <div className="modal-header">
                        <h3>Set your preferences</h3>
                      </div>
                      <div className="modal-content">
                        <h4 className="text-dark mt-0">Trade</h4>
                        <div className="label-outline active">Large Building Construction</div>
                        <div className="label-outline">Roofing and gutters</div>
                        <div className="label-outline">Windows & Doors</div>
                        <div className="label-outline">Foundations</div>
                        <div className="label-outline">Addition & Remodeling</div>
                        <div className="label-outline">HVAC</div>

                        <div className="mui-row d-flex items-center">
                          <div className="mui-col-xs-6"></div>
                          <div className="mui-col-xs-6 mui--text-right text-14 text-dark"></div>
                        </div>
                        <h4 className="text-dark mt-2 mb-0">Distance</h4>
                        <Grid container spacing={2}>
                          <Grid item xs={6} className="text-14">
                            0 miles
                          </Grid>
                          <Grid item xs={6} className="text-14">
                            <Box display="flex" justifyContent="flex-end">
                              200 miles
                            </Box>
                          </Grid>
                        </Grid>

                        <Slider defaultValue={30} />

                        <Grid container spacing={2}>
                          <Grid item xs={5}>
                            <div className="form-group">
                              <label className="text-14">State</label>
                              <input
                                className="form-control form-control-sm zp--12"
                                placeholder="Enter State"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={5}>
                            <div className="form-group">
                              <label className="text-14">City</label>
                              <input
                                className="form-control form-control-sm zp--12"
                                placeholder="Enter City"
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <div className="mt-2">
                          <button className="btn btn-primary mr-1">Search</button>
                          <button onClick={handleClose} className="btn btn-outline-dark mr-2">
                            Cancel
                          </button>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  p: 0,
                                  pr: 1
                                }}
                              />
                            }
                            label="Include All Opportunities Nation Wide"
                          />
                        </div>
                      </div>
                    </div>
                  </Popover>
                </Grid>
              </Grid>
            </div>
            <div className="content-inner pt-2">
              <h1 className="title-2 mt-0 mb-3">Favorites</h1>
              <ProjectCard
                name="HVAC contractor for Atrium Business Center"
                description="Material, human and time resources, with various technical and organizational difficulties, are used to carry out design and construction and installation work (CMP). At the same time, situations often arise when a contractor for a project may act in bad faith, take another more profitable order and not start work on a previously concluded contract"
                tags={['Large Building Construction', 'HVAC']}
                address="10005, New York, NY"
                price="Negotiated"
                deadline="2 weeks"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(JobSeekerSearchProject);

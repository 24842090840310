import React, { useState, useRef, useMemo } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { connect } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { routerPath } from 'router/Routerlist';
import IconArrow from 'assets/images/arrow-left.svg';

import { Link } from 'react-router-dom';

import spcImage from 'assets/spec-img.jpg';
import BookmarkIconActive from 'assets/Bookmark-active.svg';
import BookmarkIcon from 'assets/Bookmark.svg';
import StarIcon from 'assets/star.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import stateData from 'constant/state.json';
import cityData from 'constant/city.json';

const UserProfile = ({ props, route }) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let userInfo = location.state.userInfo;

  const stateName = useMemo(
    () => stateData?.find((item) => item.state_id == userInfo?.company?.state)?.state_name ?? '',
    [userInfo]
  );

  const cityName = useMemo(
    () => cityData[2].data.find((city) => city.city_id == userInfo?.company?.city)?.city_name ?? '',
    [userInfo]
  );

  //   const { userInfo } = route?.params;

  console.log('userInfouserId', userInfo);
  // console.log('userInfouserId', userInfo?.company.logo);

  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="dahboard-header">
              <div className="mui-row d-flex items-center">
                <button
                  onClick={() => navigate(-1)}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer'
                  }}>
                  <img src={IconArrow} alt="back" />
                </button>
                <div className="mui-col-xs-6">
                  <h1>
                    {userInfo?.name}
                    {/* <span className="h-star">
                      {' '}
                      <img src={StarIcon} className="middle" />
                    </span>
                    <span className="h-star">
                      {' '}
                      <img src={StarIcon} className="middle" />
                    </span>
                    <span className="h-star">
                      {' '}
                      <img src={StarIcon} className="middle" />
                    </span>
                    <span className="h-star">
                      {' '}
                      <img src={StarIcon} className="middle" />
                    </span>
                    <span className="h-star">
                      {' '}
                      <img src={StarIcon} className="middle" />
                    </span> */}
                  </h1>
                </div>
                {/* <div className="mui-col-xs-6 mui--text-right">
                  <ul className="mui-list--inline mb-0">
                    <li>
                      <a href="#">
                        <span className="pr-1">
                          <img src="/images/icon-comment.svg" alt="" />
                        </span>
                        Comments
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>
                          <img src="/images/icon-setting.svg" alt="" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>
                          <img src="/images/icon-category.svg" alt="" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="content-inner">
              <h3 className="title-small">{userInfo?.user_type}</h3>
              {userInfo?.company?.logo && (
                <div className="user-pp">
                  <img src={userInfo?.company?.logo} alt="Logo" />
                  {/* <div className="user-fav">
                  <img src={userInfo?.company?.logo} alt="Logo" />
                </div> */}
                </div>
              )}
              <hr />

              {/* <h3 className="title-small">Work & Education</h3>
              <p>Megalion Building Corporation</p>
              <p>University of Strathclyde</p> */}
              <h3 className="title-small">Phone Number</h3>
              <p>{userInfo?.company?.phone_number}</p>
              <hr />

              {/* <h3 className="title-small">Work & Education</h3>
<p>Megalion Building Corporation</p>
<p>University of Strathclyde</p> */}
              <h3 className="title-small">Website</h3>
              <p>{userInfo?.company?.website}</p>
              <hr />

              <h3 className="title-small">
                <span className="mr-1">Location</span>
              </h3>
              {stateName && (
                <p>
                  {stateName} , {cityName}
                </p>
              )}
              <div className="map-container mt-2">
                <img src="/images/map.png" className="w-100" alt="" />
              </div>
              {/* <hr />
              <h3 className="title-small">Lines of work</h3>
              <p>General Contractor</p>
              <div className="option">
                <div className="option-item active">Construction of large buildings</div>
                <div className="option-item active">Roof repair</div>
                <div className="option-item active">Architectural design</div>
                <div className="option-item active">Project Documentation</div>
              </div>
              <hr /> */}
              <h3 className="title-small">Personal Info</h3>
              <p>{userInfo?.company?.about}</p>
              <hr />
              <h3 className="title-small">Languages</h3>
              <div className="option">
                <div className="option-item active">English</div>
                {/* <div className="option-item active">Spanish</div> */}
              </div>
            </div>
            {/* <div className="dashboard-footer">
              <ul className="mui-list--inline mb-0">
                <li>
                  <a>About</a>
                </li>
                <li>
                  <a>Terms</a>
                </li>
                <li>
                  <a>Privacy</a>
                </li>
                <li>
                  <a>Help</a>
                </li>
                <li>
                  <a>Press</a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction };
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

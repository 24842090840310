import { CircularProgress, Grid } from '@mui/material';
import { useMemo } from 'react';
import IconPin from 'assets/location.svg';
import IconCalendar from 'assets/calendar.svg';
import IconStar from 'assets/start-outline.svg';
import IconStarfill from 'assets/star.svg';
import stateData from 'constant/state.json';
import cityData from 'constant/city.json';
import { api, usePatchRatingMutation, useRatingMutation } from 'store/slices/apiSlice';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export default function JobApplicantList(props) {
  const dispatch = useDispatch();
  const { job_id } = useParams();
  const userData = useSelector((state) => state.auth.authenticated);
  const { name, cityId, stateId, date, onRemove, onMessage, onView, userId, rating } = props;

  const [giveRating, { isLoading: isLoadingRating }] = useRatingMutation();
  const [updateRating, { isLoading: updateRatingLoading }] = usePatchRatingMutation();

  const stateName = useMemo(
    () => stateData?.find((item) => item.state_id == stateId)?.state_name ?? '',
    [stateId]
  );

  const cityName = useMemo(
    () => cityData[2].data.find((city) => city.city_id == cityId)?.city_name ?? '',
    [cityId]
  );

  const maxRating = 5;

  const userRating = rating;
  const filledStars = rating ? rating?.rating : 0;

  const giveRatingHandler = async (rating) => {
    const payload = {
      rating: rating + 1,
      given_by: userData?.id,
      user: userId,
      job: Number(job_id)
    };

    if (filledStars > 0) {
      await updateRating({ id: userRating?.id, data: payload });
    } else {
      await giveRating({ id: job_id, data: payload });
    }
    await dispatch(api.util.invalidateTags(['Rating']));
  };

  return (
    <div className="job-applicant-list">
      <Grid container spacing={2}>
        <Grid item md={4}>
          {name ? (
            <h4 className="name">{name}</h4>
          ) : (
            <h4 className="name" style={{ visibility: 'hidden' }}>
              no name
            </h4>
          )}
          <span className="mr-2">
            <img src={IconPin} className="sub" alt="" /> {`${stateName}, ${cityName}`}
          </span>
          <span className="mr-2">
            <img src={IconCalendar} className="sub" alt="" /> {date}
          </span>
        </Grid>
        <Grid item md={8}>
          <Grid container spacing={2} alignItems="center" justify="flex-end">
            <Grid
              item
              className="pr-3"
              style={{
                width: '120px'
              }}>
              <p className="rate-title">Rating:</p>
              {isLoadingRating || updateRatingLoading ? (
                <div
                  style={{
                    marginLeft: '20px'
                  }}>
                  <CircularProgress color="inherit" size={10} />
                </div>
              ) : (
                Array.from(Array(maxRating).keys()).map((item, index) => (
                  <img
                    key={index}
                    src={index < filledStars ? IconStarfill : IconStar}
                    alt=""
                    onClick={() => {
                      giveRatingHandler(item);
                    }}
                  />
                ))
              )}
            </Grid>
            <Grid item>
              <button className="btn btn-grey" onClick={onRemove}>
                Remove Candidate form List
              </button>
            </Grid>
            <Grid item>
              <button className="btn btn-primary" onClick={onMessage}>
                Message
              </button>
            </Grid>
            <Grid item>
              <button className="btn btn-primary" onClick={onView}>
                View Profile
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

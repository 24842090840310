import React from 'react';
import SidebarMenu from 'components/SidebarMenu';
import FooterDashboard from 'components/FooterLink/Dashboard';
import { connect } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import ListGroup from 'components/ListGroup';
import { Box } from '@mui/material';
import IconPlus from 'assets/plus.svg';
import ProfileImage from 'assets/images/profile-img-2.jpg';
import ProjectPicture from 'components/Profile/projectpicture';
const JobSeekerProfile = () => {
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner-wrapper">
              <h2 className="title-2">Esther Howard</h2>
              <h3 className="subtitle">Job Seeker</h3>
              <div className="profile-images">
                <img src={ProfileImage} alt="" />
              </div>
              <Box sx={{ pt: 3 }}>
                <h3 className="subtitle">Personal Information</h3>
                <ListGroup name="Name: " desc="Esther Howard" />
                <ListGroup name="Age: " desc="21" />
                <ListGroup name="Gender: " desc="Male" />
              </Box>
              <Box sx={{ pt: 3 }}>
                <h3 className="subtitle">Location</h3>
                <ListGroup name="City: " desc="Los Angeles" />
                <ListGroup name="State: " desc="California" />
                <ListGroup name="Zip Code: " desc="13243234" />
                <ListGroup name="Travel Distance: " desc="21,5km" />
              </Box>
              <Box sx={{ pt: 3, pb: 3 }}>
                <h3 className="subtitle">Language</h3>
                <ListGroup name="Primary Language: " desc="English" />
                <ListGroup name="Secondary Language: " desc="Spanish" />
              </Box>

              <button className="btn btn-success">
                <img src={IconPlus} className="bottom mr-1" /> Edit Resume
              </button>

              <Box sx={{ py: 3 }}>
                <h3 className="subtitle">Project Pictures</h3>
                <ProjectPicture />
              </Box>

              <Box sx={{ py: 3 }}>
                <h3 className="subtitle">About Me</h3>
                <p>
                  We are an overarching term describing various forms of construction projects such
                  as design, build, and remodel. Each facet of general construction generally
                  involves a general contractor who will oversee every part of the process. Equipped
                  with specialized skills essential for the different stages of your project,
                  general contractors are the thread that tie all parts of your project together to
                  ensure all needs are met.
                </p>
              </Box>
              <Box sx={{ pt: 1 }}>
                <h3 className="subtitle">Industry</h3>
                <span className="tag-label">Commercial Construction</span>
                <span className="tag-label">Commercial Construction</span>
              </Box>
              <Box sx={{ pt: 1 }}>
                <h3 className="subtitle">Profession</h3>
                <span className="tag-label">Carpenter</span>
                <span className="tag-label">Plumber</span>
              </Box>
              <Box sx={{ pt: 1 }}>
                <h3 className="subtitle">Trade Affiliation</h3>
                <span className="tag-label">Union</span>
              </Box>
              <Box sx={{ pt: 1 }}>
                <h3 className="subtitle">Skill Set</h3>
                <span className="tag-label">New to the Industry</span>
                <span className="tag-label">Labor / Tender</span>
                <span className="tag-label">Apprentice</span>
              </Box>
              <Box sx={{ pt: 1 }}>
                <h3 className="subtitle">Education</h3>
                <span className="tag-label">Bachelors Degree</span>
                <span className="tag-label">Graduate Degree</span>
              </Box>
              <Box sx={{ pt: 1 }}>
                <h3 className="subtitle">Experience</h3>
                <span className="tag-label">3-5 yrs</span>
              </Box>
              <Box sx={{ pt: 1 }}>
                <h3 className="subtitle">Trade Licence / Certifificates</h3>
                <span className="tag-label">Licence 01</span>
                <span className="tag-label">Certificates 01</span>
              </Box>

              <Box sx={{ pt: 2 }}>
                <button className="btn btn-success mr-1">
                  <img src={IconPlus} className="bottom mr-1" /> Edit
                </button>
                <button className="btn btn-success">Save</button>
              </Box>
            </div>
            <FooterDashboard />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(JobSeekerProfile);

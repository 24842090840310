import React from 'react';
import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { Controller } from 'react-hook-form';

function PasswordField({
  control,
  errors,
  label,
  param,
  disabled = false,
  styleInput,
  placeholder,
  showError = true
}) {
  return (
    <Controller
      name={param}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl error={errors?.[param]} variant="standard" sx={{ mt: 1, width: '100%' }}>
          <FormLabel sx={{ color: 'text.primary', m: 0 }}>{label}</FormLabel>
          <OutlinedInput
            type={'password'}
            value={value || ''}
            onChange={onChange}
            disabled={disabled}
            sx={{ height: '44px', ...styleInput }}
            placeholder={placeholder || label}
          />
          {showError && (
            <FormHelperText id="component-error-text">{errors?.[param]?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default PasswordField;

import React, { useState, useEffect } from 'react';
import SidebarMenu from 'components/SidebarMenu'
import FooterDashboard from 'components/FooterLink/Dashboard'
import { connect, useDispatch } from 'react-redux'
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Box, Grid } from '@mui/material';
import ProjectCard from 'components/ProjectCard';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useGetMyProjectsQuery } from 'store/slices/apiSlice';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useNavigation } from 'react-router-dom';
import { routerPath } from 'router/Routerlist';

const HeaderContentTop = ({
  title,
  buttonTitle,
  handleClick,
}) => {
    return (
        <div className="dahboard-header">
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <h1>{title}</h1>
                </Grid>
                <Grid item xs={5} style={{ textAlign: "right" }}>
                    <button type='button' onClick={handleClick} className='btn btn-success'>{buttonTitle}</button>
                </Grid>
            </Grid>
        </div>
    );
}

HeaderContentTop.defaultProps = {
  title: 'Manage',
  buttonTitle: 'New post',
  handleClick: ()=> void(0)
}
export default HeaderContentTop
import { Box, Grid, Typography } from '@mui/material';
import { IconButton, Stack } from '@mui/material';
import MultiImageUpload from 'components/input/MultiImageUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react';
import DeleteJobSeekerModel from './DeleteJobSeekerModel';

function RecruiterUploads({
  control,
  errors,
  setImageArray,
  imageArray,
  profileData,
  handleTriggerFetchProfile
}) {
  const [isDialogOpen, setOpenDialog] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState(-1);

  const handleCloseDialog = () => setOpenDialog(false);

  const handleResetSelectedId = () => setSelectedPhotoId(-1);

  return (
    <>
      <DeleteJobSeekerModel
        isOpen={isDialogOpen}
        handleClose={handleCloseDialog}
        id={selectedPhotoId}
        handleResetSelectedId={handleResetSelectedId}
        handleTriggerFetchProfile={handleTriggerFetchProfile}
      />

      <Grid container mt={6} columnSpacing={2}>
        <Grid item xs={12} className="mb-2">
          <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
            Uploads
          </Typography>
        </Grid>
        <Box sx={{ padding: 2 }} className="d-flex flex-wrap mb-3">
          <Box>
            <Typography sx={{ mb: 2 }}>Upload Profile Picture</Typography>

            <Stack direction={'row'} alignItems={'center'} spacing={3}>
              {profileData?.user_media &&
                profileData?.user_media?.map((e, idx) => (
                  <div
                    className="picture-item"
                    style={{ width: '120px', height: '120px', position: 'relative' }}
                    key={idx}>
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: -20,
                        right: -20,
                        zIndex: 1
                      }}
                      onClick={() => {
                        setSelectedPhotoId(e.id);
                        setOpenDialog(true);
                      }}>
                      <CancelIcon sx={{ color: 'red' }} />
                    </IconButton>
                    <div className="images-box">
                      <img src={e.image.split('?')[0]} alt="" />
                    </div>
                  </div>
                ))}

              {(!profileData?.user_media || profileData?.user_media?.length < 5) && (
                <MultiImageUpload
                  control={control}
                  errors={errors}
                  label={'Upload Project Pictures'}
                  param={'user_media'}
                  multiple={true}
                  noOfUploadedImages={profileData?.user_media?.length ?? 0}
                  setImageArray={setImageArray}
                  imageArray={imageArray}
                />
              )}
            </Stack>
          </Box>
        </Box>
      </Grid>
    </>
  );
}

export default RecruiterUploads;

import axios from 'axios';
import { setupAxios } from 'utils/axiosConfig';

export const jobsApi = {
  root: 'https://fakerapi.it/api/v1/companies?_quantity=12',
  jobs: '/api/v1/jobs/',
  allJobs: '/api/v1/all-jobs/',
  jobsApply: '/api/v1/jobs/apply/',
  categories: '/api/v1/job-categories/',
  terms: '/modules/terms-and-conditions/',
  privacyPolicy: '/modules/privacy-policy/'
};

export function apiGetModulesTerm() {
  setupAxios(false);
  return axios.get(jobsApi.terms);
}

export function apiGetModulesPrivacy() {
  setupAxios(false);
  return axios.get(jobsApi.privacyPolicy);
}

export function apiGetJobs(params) {
  setupAxios();
  return axios.get(jobsApi.jobs, { params });
}
export function apiGetAllJobs(params) {
  setupAxios();
  return axios.get(jobsApi.allJobs);
}
export function apiGetAllJobDetail({ id }) {
  setupAxios();
  return axios.get(`${jobsApi.allJobs}${id}/`);
}

export function apiGetJobsDetail(id) {
  setupAxios();
  return axios.get(`${jobsApi.jobs}${id}/`);
}

export function apiPostJobs(payload) {
  setupAxios();
  return axios.post(jobsApi.jobs, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });
}

export function apiPostApplyJobs(payload) {
  setupAxios();
  return axios.post(jobsApi.jobsApply, payload);
}

export function apiGetJobCategories(params) {
  setupAxios();
  return axios.get(jobsApi.categories);
}

import { Box, Stack, Typography } from '@mui/material';
import { memo } from 'react';

const ErrorComponent = memo(({ fields, setError, response }) => {
  let errorsForRender = [];

  if (typeof response !== 'string') {
    for (const key in response) {
      if (Object.hasOwnProperty.call(response, key)) {
        let errors = response[key];

        if (fields.includes(key)) {
          if (typeof errors === 'string') {
            errors = [errors];
          }

          errors = errors.map((err, index) => <p key={index}>{err}</p>);

          setError(key, {
            type: 'manual',
            message: errors
          });
        } else {
          if (typeof errors === 'string') {
            errorsForRender.push(errors);
          } else {
            errors.forEach((err) => {
              errorsForRender.push(err);
            });
          }
        }
      }
    }
  } else {
    errorsForRender.push(response);
  }

  if (errorsForRender.length) {
    return (
      <Box
        sx={{
          border: '1px solid red',
          borderRadius: '10px',
          paddingX: 4,
          mt: 2,
          paddingY: 2
        }}
        className="alert alert-danger"
        role="alert">
        {errorsForRender.map((err, index) => (
          <Stack direction={'row'} alignItems={'flex-start'} spacing={1} key={index}>
            <Typography color={'error'}>
              <b>Error:</b>
            </Typography>
            <Typography color={'error'}>{err}</Typography>
          </Stack>
        ))}
      </Box>
    );
  }

  return <div></div>;
});

export default ErrorComponent;

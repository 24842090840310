export function matchIsNumeric(text) {
  const regex = /^[0-9]+$/;

  return text === '' || text.match(regex);
}

export const getImageUrlOfAwsToQuestionMark = (url) => {
  return !url ? url : url.substring(0, url.indexOf('?'));
};

export const aroundDecimalValueToCustomDecimal = (value, numOfDecimals = 2) => {
  return Number(value).toFixed(numOfDecimals);
};

export function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert degrees to radians
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceInKm = R * c; // Distance in kilometers
  // Convert kilometers to miles (1 kilometer = 0.621371 miles)
  const distanceInMiles = distanceInKm * 0.621371;
  return distanceInMiles;
}

export const formatDescription = (text) => {
  return text?.split('\n')?.map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
};

export function getLastMessageTime() {
  return localStorage.getItem('workium_lastMessageTime');
}

export function saveLastMessageTime(time) {
  localStorage.setItem('workium_lastMessageTime', time);
}

import { Button, FormControl, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import React, { memo, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant';
import { deleteUserProfessionRequest, deleteUserTradeLicenseRequest } from 'utils/calls';

function TradeLicenseCertificates({
  tradeLicenses,
  setTradeLicenses,
  userTradeLicenseMutate,
  fetchProfile
}) {
  const dispatch = useDispatch();

  const handleTradeLicenses = (id, value) => {
    setTradeLicenses((prev) => {
      return prev.map((item) => {
        if (String(item.id) === String(id)) {
          return { ...item, trade_license: value };
        } else {
          return item;
        }
      });
    });
  };

  const handleDeleteTradeLicense = (id) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    });

    deleteUserTradeLicenseRequest(id).finally(() => {
      // profileMutate();
      fetchProfile();
      userTradeLicenseMutate();
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });
    });
  };

  return (
    <Grid container mt={4} spacing={2}>
      <Grid item xs={12} className="mb-2">
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          Trade License / Certificates
        </Typography>
      </Grid>
      {tradeLicenses.map((item) => (
        <Grid item xs={4} key={item.id}>
          <TradeLicense
            key={item.id}
            item={item}
            handleChange={handleTradeLicenses}
            handleDeleteTradeLicense={handleDeleteTradeLicense}
          />
        </Grid>
      ))}
    </Grid>
  );
}

const TradeLicense = memo(({ item, handleChange, handleDeleteTradeLicense }) => {
  const [isEdit, setEdit] = useState(false);

  const isNew = useMemo(() => (String(item.id).startsWith('tmp') ? true : false), [item?.id]);
  const error = useMemo(
    () => (!isNew && item.trade_license.trim() === '' ? "Empty isn't allowed" : undefined),
    [item]
  );

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={2}>
      {isEdit || isNew ? (
        // <Grid md={4} item>
        <FormControl error={error} sx={{ flexGrow: 1 }}>
          <input
            type="text"
            value={item.trade_license}
            onChange={(e) => handleChange(item.id, e.target.value)}
            className="form-control form-control-sm"
            placeholder="Trade licence / certificates info"
          />
          <FormHelperText id="component-error-text">{error}</FormHelperText>
        </FormControl>
      ) : (
        // </Grid>
        <Typography flexGrow={1}>{item.trade_license}</Typography>
      )}
      {!isNew && (
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Button onClick={() => setEdit((prev) => !prev)}>{isEdit ? 'Cancel' : 'Edit'}</Button>
          <Button color="error" onClick={() => handleDeleteTradeLicense(item.id)}>
            Delete
          </Button>
        </Stack>
      )}
    </Stack>
  );
});

export default TradeLicenseCertificates;

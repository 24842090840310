import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_AUTH } from 'store/reducers/auth/auth.constant';
import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant';
import { errorValidationHandler } from 'utils/errorHandler';
import { defaultState as authDefaultState } from 'store/reducers/auth/auth.reducer';
import {
  apiPostSignin,
  apiPostResetEmailPassword,
  apiPostProfileCompleteness
} from 'api/login.api';

export const actionToProps = {
  changeAuthAttribute:
    ({ key, value, type = CHANGE_ATTRIBUTE_AUTH }) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: type,
          payload: { key: key, value: value }
        });
        resolve(value);
      });
    },
  changeIsPageLoading: () => (dispatch) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: false
      }
    });
  },
  actionPostProfileCompleteness: (payload) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    });
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    });

    try {
      const { data } = await apiPostProfileCompleteness(payload);
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'formProfileCompleteness',
          value: authDefaultState.formProfileCompleteness
        }
      });

      return data;
    } catch ({ message, response }) {
      const { data } = response;
      const errorHandlerData = errorValidationHandler(data);

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData.alert
        }
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });

      return false;
    } finally {
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });
    }
  },
  actionPostForgotPassword: (payload) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    });
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    });

    try {
      const { data } = await apiPostResetEmailPassword(payload);

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'validationFormResetPassword',
          value: {
            email: {
              success: true,
              error: data?.detail
            }
          }
        }
      });

      return data;
    } catch ({ message, response }) {
      const { data } = response;
      const errorHandlerData = errorValidationHandler(data);

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: [message]
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'validationFormResetPassword',
          value: errorHandlerData.validation
        }
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });

      return false;
    } finally {
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });
    }
  }
};

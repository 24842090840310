import React from 'react';
import SidebarMenu from 'components/SidebarMenu'
import FooterDashboard from 'components/FooterLink/Dashboard'
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import ListGroup from 'components/ListGroup';
import { Box, Grid } from '@mui/material';
import IconSearch from 'assets/images/icon-search.svg';
import IconLocation from 'assets/images/map-pin.svg';
import JobListCard from 'components/JobListCard';
import Image from 'assets/images/plumbing-with-different-tools.png';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
const JobSeekerHome = () => {
    return (
        <>
            <div className="wrapper">
                <div className="user-dashboard">
                    <SidebarMenu />
                    <div className="content-dashboard dashboard-job">
                        <div className="dahboard-header header-search">
                            <div className="d-flex">
                                <div className='w-100'>
                                    <Grid container spacing={2}>
                                        <Grid item md={4}>
                                            <div className="input-inline-group">
                                                <input type="text" className="form-control" placeholder='Job Title' />
                                                <span className='pl-1 pr-1'><img src={IconSearch} style={{ width: "20px" }} alt="" /></span>
                                            </div>
                                        </Grid>
                                        <Grid item md={4}>
                                            <div className="input-inline-group">
                                                <input type="text" className="form-control" placeholder='State' />
                                                <span className='pl-1 pr-1'><img src={IconLocation} style={{ width: "20px" }} alt="" /></span>
                                            </div>
                                        </Grid>
                                        <Grid item md={4}>
                                            <div className="input-inline-group">
                                                <input type="text" className="form-control" placeholder='City' />
                                                <span className='pl-1 pr-1'><img src={IconLocation} style={{ width: "20px" }} alt="" /></span>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className='col-btn'>
                                    <button className='btn btn-success'>Search Job</button>
                                </div>
                            </div>
                        </div>
                        <div className="content-inner">
                            <JobListCard
                                image={Image}
                                title="Associate Civil Engineer"
                                posted="Posted 8 hours ago"
                                location="10005, New York, NY"
                                desc="Material, human and time resources, with various technical and organizational difficulties, are used to carry out design and construction and installation work (CMP). At the same time, situations often arise when a contractor for a project may act in bad faith, take another more profitable order and not start work on a previously concluded contract...."
                            />
                            <JobListCard
                                image={Image}
                                title="Associate Civil Engineer"
                                posted="Posted 8 hours ago"
                                location="10005, New York, NY"
                                desc="Material, human and time resources, with various technical and organizational difficulties, are used to carry out design and construction and installation work (CMP). At the same time, situations often arise when a contractor for a project may act in bad faith, take another more profitable order and not start work on a previously concluded contract...."
                            />
                            <JobListCard
                                image={Image}
                                title="Roughing Framer and Foundation Tradesmen needed. Full Time"
                                posted="Posted 8 hours ago"
                                location="10005, New York, NY"
                                desc="Material, human and time resources, with various technical and organizational difficulties, are used to carry out design and construction and installation work (CMP). At the same time, situations often arise when a contractor for a project may act in bad faith, take another more profitable order and not start work on a previously concluded contract...."
                            />
                            <JobListCard
                                image={Image}
                                title="Construction Superintendent Commercial Interiors"
                                posted="Posted 8 hours ago"
                                location="10005, New York, NY"
                                desc="Material, human and time resources, with various technical and organizational difficulties, are used to carry out design and construction and installation work (CMP). At the same time, situations often arise when a contractor for a project may act in bad faith, take another more profitable order and not start work on a previously concluded contract...."
                            />
                            <JobListCard
                                image={Image}
                                title="Associate Civil Engineer"
                                posted="Posted 8 hours ago"
                                location="10005, New York, NY"
                                desc="Material, human and time resources, with various technical and organizational difficulties, are used to carry out design and construction and installation work (CMP). At the same time, situations often arise when a contractor for a project may act in bad faith, take another more profitable order and not start work on a previously concluded contract...."
                            />

                            <Grid container justify="flex-end" className='mt-4'>
                                <Pagination count={10} color="success" variant="outlined"/>
                            </Grid>
                        </div>
                        <FooterDashboard />
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(JobSeekerHome)
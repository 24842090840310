import { getCookie } from 'react-use-cookie';
import { api, apiHost, apiHostNoAuth, apiNoAuth } from './axiosApi';
import { environment } from 'config/environment';

export function getRequestDefault(url) {
  return api.get(url);
}

export function getRequestHostDefault(url) {
  return apiHost.get(url);
}

export function getRequestHostNoAuthDefault(url) {
  return apiHostNoAuth.get(url);
}

export function getRequestNoAuthDefault(url) {
  return apiNoAuth.get(url);
}

export function postVerifyOTPRequest(requestBody) {
  return api.post('email/verify-user/', requestBody);
}

export function deleteUserTradeLicenseRequest(id) {
  return api.delete(`/user-trade-license/${id}/`);
}

export function deleteUserProfessionRequest(id) {
  return api.delete(`/user-profession/${id}/`);
}

export function deleteUserConstructionTradeRequest(id) {
  return api.delete(`/user-construction-trade/${id}/`);
}

export function deleteUserIndustryRequest(id) {
  return api.delete(`/user-industry/${id}/`);
}

export function postUserConstructionTradeRequest(object) {
  return api.post(`/user-construction-trade/`, object);
}

export function postUserIndustryRequest(object) {
  return api.post(`/user-industry/`, object);
}

export function postUserTradeLicenseRequest(object) {
  return api.post(`/user-trade-license/`, object);
}

export function patchUserTradeLicenseRequest(id, object) {
  return api.patch(`/user-trade-license/${id}/`, object);
}

export function postUserProfessionRequest(object) {
  return api.post(`/user-profession/`, object);
}

export function patchUserProfessionRequest(id, object) {
  return api.patch(`/user-profession/${id}/`, object);
}

export function postUserLanguageRequest(object) {
  return api.post(`/profile/add-language/`, object);
}

export function putProfileRequest(id, object) {
  const authToken = getCookie(environment.ACCESS_TOKEN_COOKIE);
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Token ${authToken}`);

  const formdata = new FormData();
  Object.keys(object)
    // .filter((item) => item !== 'profile_image')
    .forEach((value) => {
      formdata.append(value, object[value]);
    });

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: formdata
  };
  return fetch(`${environment.BASE_API_URL}/api/v1/profile/${id}/`, requestOptions);
}

export function patchProfileJsonRequest(id, object) {
  return api.patch(`/profile/${id}/`, object);
}

export function patchProfileRequest(id, object) {
  const authToken = getCookie(environment.ACCESS_TOKEN_COOKIE);
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Token ${authToken}`);

  const formdata = new FormData();
  Object.keys(object)
    // .filter((item) => item !== 'profile_image')
    .forEach((value) => {
      formdata.append(value, object[value]);
    });

  const requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: formdata
  };
  return fetch(`${environment.BASE_API_URL}/api/v1/profile/${id}/`, requestOptions);
}

export function postCompanyRequest(object) {
  const authToken = getCookie(environment.ACCESS_TOKEN_COOKIE);
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Token ${authToken}`);

  const formdata = new FormData();
  Object.keys(object)
    .filter((key) => key !== 'image_objects')
    .forEach((value) => {
      formdata.append(value, object[value]);
    });
  if (object?.image_objects) {
    Array.from(object?.image_objects)?.forEach((value) => {
      formdata.append('image_objects', value);
    });
  }

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
  };
  return fetch(`${environment.BASE_API_URL}/api/v1/company/`, requestOptions);
}

export function patchCompanyRequest(id, object) {
  const authToken = getCookie(environment.ACCESS_TOKEN_COOKIE);
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Token ${authToken}`);

  const formdata = new FormData();
  Object.keys(object)
    .filter((key) => key !== 'image_objects')
    .forEach((value) => {
      formdata.append(value, object[value]);
    });

  if (object?.image_objects) {
    Array.from(object?.image_objects)?.forEach((value) => {
      formdata.append('image_objects', value);
    });
  }

  const requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: formdata
  };
  return fetch(`${environment.BASE_API_URL}/api/v1/company/${id}/`, requestOptions);
}

export function deleteCompanyMediaRequest(id) {
  const authToken = getCookie(environment.ACCESS_TOKEN_COOKIE);
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Token ${authToken}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders
  };
  return fetch(`${environment.BASE_API_URL}/api/v1/company-media/${id}/`, requestOptions);
}


export function deleteJobSeekerMediaRequest(id) {
  const authToken = getCookie(environment.ACCESS_TOKEN_COOKIE);
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Token ${authToken}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders
  };
  return fetch(`${environment.BASE_API_URL}/api/v1/user-media/${id}/`, requestOptions);
}
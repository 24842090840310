import bicycleIcon from '../assets/bicycle.svg';
import planeIcon from '../assets/plane.svg';
import shuttleIcon from '../assets/shuttle.svg';
import IconBike from '../assets/bike.svg';
import IconPlane from '../assets/plane-icon.svg';
import IconBuilding from 'assets/building.svg';
import IconRocket from '../assets/rocket.svg';

export const initialTags = [
  { id: 1, label: 'Drawings / Diagrams' },
  { id: 2, label: 'Heat supply / Heating / Ventilation' },
  { id: 3, label: 'Technical inspection and measurements' },
  { id: 4, label: 'Design of facilities' },
  { id: 5, label: 'Transport facilities' },
  { id: 6, label: 'Engineering surveys' },
  { id: 7, label: 'Building structures' },
  { id: 8, label: 'Power supply' },
  { id: 9, label: 'Lighting engineering ' },
  { id: 10, label: 'Industrial and civil engineering' },
  { id: 11, label: 'Low-voltage systems' },
  { id: 12, label: 'Development of estimate documentation' },
  { id: 13, label: 'Water supply and sewerage' },
  { id: 14, label: 'Mechanical engineering' },
  { id: 15, label: 'Simulation model' }
];

export const searchTrade = [
  { id: 1, label: 'Acoustical/ Specialty Ceilings' },
  { id: 2, label: 'Asphalt/ Curbing' },
  { id: 3, label: 'Cement/ Concrete' },
  { id: 4, label: 'Civil/ Road Construction' },
  { id: 5, label: 'Demolition' },

  { id: 7, label: 'Doors/ Windows/ Glazer' },
  { id: 8, label: 'Drywall/ Framing/ Finishing/ Plaster' },
  { id: 9, label: 'Electrician/ Low Voltage' },
  { id: 10, label: 'Fire Protection' },
  { id: 11, label: 'Flooring/ Tile' },
  { id: 12, label: 'Finish/ Trim/ Cabinets/ Counter Tops' },
  { id: 13, label: 'Handyman' },
  { id: 14, label: 'Heavy Equipment/ Site Devoplment' },
  { id: 15, label: 'HVAC/Sheet Metal' },
  { id: 16, label: 'Landscaping/ Irrigation/ Tree Service' },
  { id: 17, label: 'Masonary/ EFIS/ Stucco/ Waterproofing' },
  { id: 18, label: 'Other' },
  { id: 19, label: 'Painter/ Wallpaper/ Caulking' },
  { id: 20, label: 'Plumber/ Pipefitter' },
  { id: 21, label: 'Roofer/ Solar' },
  { id: 22, label: 'Structual Steel' },
  { id: 21, label: 'Trucking/ Transportation' },
  { id: 22, label: 'Wood Framing/ Trusses / Siding' }
];

export const jobCreationStep = [
  { id: 1, label: 'Category' },
  { id: 2, label: 'Vacancy Name' },
  { id: 3, label: 'Description' },
  { id: 4, label: 'Budget' },
  { id: 5, label: 'Posting Ad' }
];

export const projectCreationStep = [
  { id: 1, label: 'Category' },
  { id: 2, label: 'Project Name' },
  { id: 3, label: 'Description' },
  { id: 4, label: 'Budget' },
  { id: 5, label: 'Posting Ad' }
];

export const projectPricingAd = [
  {
    id: 1,
    name: 'starter',
    icon: bicycleIcon,
    price: 'free',
    items: [
      'Publishing to the project feed',
      'Sending notifications to freelancers by subscription'
    ]
  },
  {
    id: 2,
    name: 'pro',
    icon: planeIcon,
    price: '$99',
    items: [
      'Publishing to the project feed',
      'Sending notifications to freelancers by subscription',
      'Priority placement of the project feed for a day',
      'Highlighting in the project feed for a day'
    ]
  },
  {
    id: 3,
    name: 'enterprise',
    icon: shuttleIcon,
    price: '$199',
    items: [
      'Publishing to the project feed',
      'Sending notifications to freelancers by subscription',
      'Priority placement of the project feed for a day',
      'Highlighting in the project feed for a day',
      'Description of super features and services'
    ]
  }
];

export const jobPricingAd = [
  {
    id: 1,
    name: 'starter',
    icon: bicycleIcon,
    price: 'free',
    items: [
      'Publishing to the project feed',
      'Sending notifications to freelancers by subscription'
    ]
  },
  {
    id: 2,
    name: 'pro',
    icon: planeIcon,
    price: '$29',
    items: [
      'Publishing to the project feed',
      'Sending notifications to freelancers by subscription',
      'Priority placement of the project feed for a day',
      'Highlighting in the project feed for a day'
    ]
  },
  {
    id: 3,
    name: 'enterprise',
    icon: shuttleIcon,
    price: '$59',
    items: [
      'Publishing to the project feed',
      'Sending notifications to freelancers by subscription',
      'Priority placement of the project feed for a day',
      'Highlighting in the project feed for a day',
      'Description of super features and services'
    ]
  }
];

export const jobPostPricing = [
  {
    id: 1,
    value: 'primary',
    title: 'If you advertise a job (1 job) it will cost you',
    price: 79.95,
    expiration: 7,
    symbol: '$',
    frequent: 'days'
  }
];
export const jobPostAdditionalPricing = [
  {
    id: 2,
    value: 'feature',
    title: 'Feature',
    price: 9.95,
    expiration: 7,
    symbol: '$',
    frequent: 'days'
  },
  {
    id: 3,
    value: 'urgent',
    title: 'Urgent',
    price: 4.95,
    expiration: 7,
    symbol: '$',
    frequent: 'days'
  }
];

export const industryOptionsForm = [
  {
    id: 1,
    title: 'Residential Construction'
  },
  {
    id: 2,
    title: 'Commercial Construction'
  },
  {
    id: 3,
    title: 'Civil Construction'
  }
];

export const tradeAffiliation = [
  {
    id: 1,
    title: 'Union'
  },
  {
    id: 2,
    title: 'Non Union'
  }
];

export const skillSetsOptions = [
  {
    id: 1,
    title: 'New to the Industry'
  },
  {
    id: 2,
    title: 'Labor / Tender'
  },
  {
    id: 3,
    title: 'Apprentice'
  },
  {
    id: 4,
    title: 'Journeyman'
  },
  {
    id: 5,
    title: 'Foreman'
  },
  {
    id: 6,
    title: 'Superintendent'
  },
  {
    id: 7,
    title: 'Office Personel'
  }
];

export const educationOptions = [
  {
    id: 1,
    title: 'High School'
  },
  {
    id: 2,
    title: 'Some College'
  },
  {
    id: 3,
    title: 'Associates Degree'
  },
  {
    id: 4,
    title: 'Bachelors Degree'
  },
  {
    id: 5,
    title: 'Graduate Degree'
  },
  {
    id: 6,
    title: 'PHD / Post Doctoral'
  }
];

export const experienceOptions = [
  {
    id: 1,
    title: '0-1 yrs'
  },
  {
    id: 2,
    title: '1-3 yrs'
  },
  {
    id: 3,
    title: '3-5 yrs'
  },
  {
    id: 4,
    title: '5-10 yrs'
  },
  {
    id: 5,
    title: '10+ yrs'
  }
];

export const states = [
  {
    id: 1,
    name: 'Washington, DC'
  },
  {
    id: 2,
    name: 'some other state'
  }
];

export const cities = [
  {
    id: 1,
    name: 'New York'
  },
  {
    id: 2,
    name: 'some other city'
  },
  {
    id: 3,
    name: 'please contact api dev to add new cities'
  }
];
export const terms = [
  {
    id: 1,
    name: 'Hourly'
  },
  {
    id: 2,
    name: 'Contract'
  },
  {
    id: 3,
    name: 'Piecework'
  }
];

export const priceListItems = [
  {
    price: 99.95,
    title: '7 Day Job Post',
    period: 7,
    icon: IconBike,
    dataId: 'price1',
    radioName: 'price',
    features: [
      'Launch 7 Day Job Post Ad',
      'Be Seen by Thousands of Job Candidates From Coast to Coast',
      'Find Professionals in Your Industry',
      'Direct Message to Those Who Have Applied',
      'Review Your Job Candidates Professional Detailed Profile',
      'Have Your Job Post Seen on Job Candidates Home Pages'
    ]
  },
  {
    price: 169.95,
    title: '14 Day Job Post',
    period: 14,
    icon: IconPlane,
    dataId: 'price2',
    radioName: 'price',
    features: [
      'Launch 14 Day Job Post Ad',
      'Be Seen by Thousands of Job Candidates From Coast to Coast',
      'Find Professionals in Your Industry',
      'Direct Message to Those Who Have Applied',
      'Review Your Job Candidates Professional Detailed Profile',
      'Have Your Job Post Seen on Job Candidates Home Pages'
    ]
  },
  {
    price: 299.95,
    title: '30 Day Job Post',
    period: 30,
    icon: IconRocket,
    dataId: 'price3',
    radioName: 'price',
    features: [
      'Launch 30 Day Job Post Ad',
      'Be Seen by Thousands of Job Candidates From Coast to Coast',
      'Find Professionals in Your Industry',
      'Direct Message to Those Who Have Applied',
      'Review Your Job Candidates Professional Detailed Profile',
      'Have Your Job Post Seen on Job Candidates Home Pages'
    ]
  }
];

export const projectPriceListItems = [
  {
    price: 0,
    title: 'Starter',
    period: 30,
    icon: IconBuilding,
    dataId: 'projectStarter',
    radioName: 'projectPrice',
    features: [
      'Post Available Project For 30 Days',
      'Re-Subcontract Your Work and Grow Your Business and Profits',
      'Grow Your Trade Partner Network',
      'Have Your Ad Seen On Other Contractors Home Pages'
    ]
  },
  {
    price: 7.95,
    title: 'Pro',
    period: 30,
    icon: IconRocket,
    dataId: 'projectPro',
    radioName: 'projectPrice',
    features: [
      'Post Available Project For 30 Days',
      'Re-Subcontract Your Work and Grow Your Business and Profits',
      'Grow Your Trade Partner Network',
      'Have Your Ad Seen On Other Contractors Home Pages',
      'Get your ad distributed on multiple construction social media platforms'
    ]
  }
];

export const featuredUrgentJobList = [
  {
    price: 14.95,
    title: 'Feature Job Post',
    icon: IconPlane,
    banner: 'is_featured',
    dataId: 'price1',
    radioName: 'price',
    features: [
      'Feature Your Job Post For 7 Days',
      'Priority Job Ad Placement',
      'Be Seen by More Candidates',
      'Drive More Traffic to Your Ad'
    ]
  },
  {
    price: 6.95,
    title: 'Urgent Banner',
    icon: IconRocket,
    banner: 'is_urgent',
    dataId: 'price2',
    radioName: 'price',
    features: [
      'Add an Urgent Banner to Your Job Post for 7 Days',
      'Show Your Candidates That You Are Looking to Fill This Opening Immediately'
    ]
  }
];

export const YES_NO_ARRAY = [
  {
    title: 'Yes',
    id: true
  },
  {
    title: 'No',
    id: false
  }
];

export const MALE_FEMALE = [
  {
    title: 'Male',
    id: 'Male'
  },
  {
    title: 'Female',
    id: 'Female'
  }
];

export const LABOR_AFFILIATION_ARRAY = [
  {
    title: 'Union',
    id: 'Union'
  },
  {
    title: 'Non union',
    id: 'Non-Union'
  }
];

export const DEFAULT_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=';

export const POST_TYPE = {
  JOB: 'JOB',
  PROJECT: 'PROJECT'
};

export const CARD_TYPE = {
  SUB: 'SUB',
  ADDON: 'ADDON'
};

export const COUNTRY_ID = 231;

export const TRAVEL_DISTANCE = [
  { distance_id: '---', distance_name: '---', id: '---' },
  { distance_id: '0-25 miles', distance_name: '0-25 miles', id: '0-25 miles' },
  { distance_id: '25-50 miles', distance_name: '25-50 miles', id: '25-50 miles' },
  { distance_id: '50-75 miles', distance_name: '50-75 miles', id: '50-75 miles' },
  { distance_id: '75-100 miles', distance_name: '75-100 miles', id: '75-100 miles' },
  { distance_id: '100+ miles', distance_name: '100+ miles', id: '100+ miles' }
];

export const GENERAL_CONTRACTOR = 'General Contractor';
export const SUB_CONTRACTOR = 'Sub Contractor';
export const SECOND_TIER_CONTRACTOR = '2nd Tier Subcontractor';
export const RECRUITER = 'Recruiter';
export const JOB_SEEKER = 'Job Seeker';

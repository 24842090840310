import React from 'react';
import { routerPath } from 'router/Routerlist'
import FilterIcon from 'assets/Filter.svg';
import ArrowDownIcon from 'assets/arrow-down.svg';
import { connect } from 'react-redux'
import classNames from 'classnames'
import searchIcon from "./../../assets/images/icon-search.svg"


const DashboardHeader = (props) => {
  const [filterActive, toggleFilter] = React.useState(false);
  const {
    title,
    news: {
      specialistFilter
    }
  } = props

  return (

        <div className="dahboard-header">
            <div className="mui-row d-flex items-center">
                <div className={classNames({
                  ['mui-col-xs-4']: props.isSearchShown,
                  ['mui-col-xs-6']: !props.isSearchShown
                })}>
                    <h1>{title}</h1>
                </div>
                <div className="mui-col-xs-4">
                    <div className={
                      classNames({
                        ["input-inline-group is-right"]: true,
                        "hide": !props.isSearchShown
                      })}>
                        <input type="text" className="form-control" placeholder="Search" />
                        <span>
                            <button className="btn">
                              <img src={searchIcon} alt=""/>
                            </button>
                        </span>
                    </div>
                </div>
                <div className="mui-col-xs-4 hide">
                    <ul className="mui-list--inline mb-0">
                        <li>
                            <a href="#" className="cursor-pointer" onClick={()=>props.onFilter()}>
                                <span><img src={FilterIcon} alt="" /></span>
                            </a>
                        </li>
                        <li className='dropdown text-14'>
                            <a onClick={() => toggleFilter(!filterActive)} className="cursor-pointer">
                                {specialistFilter?.status || "All"}
                                <span className='img-as'>
                                    <img src={ArrowDownIcon} className="img-arrow" />
                                </span>
                            </a>
                            { filterActive && <div className='dropdown-list'>
                                <div className='dropdown-item' onClick={()=>toggleFilter(!filterActive)}>All</div>
                                <div className='dropdown-item' onClick={()=>toggleFilter(!filterActive)}>New</div>
                                <div className='dropdown-item' onClick={()=>toggleFilter(!filterActive)}>Online now</div>
                             </div>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        </div>
  )
}

DashboardHeader.defaultProps = {
  onFilter: ()=> false,
  title: 'Specialist',
  isSearchShown: true,
}

const mapStateToProps = (state) => {
    return {
        news: state.news,
    }
}
const mapDispatchToProps = null
export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader)

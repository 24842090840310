import { Grid, Typography } from '@mui/material';
import { HandleGetUserConstructionTradeRequest } from 'api/callsHandler';
import ControlledMultiSelect from 'components/input/ControlledMultiSelect';
import React from 'react';

function ConstructionTrades({ control, errors }) {
  const { data: userConstructionTradeData } = HandleGetUserConstructionTradeRequest();

  return (
    <Grid container mt={4} spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          Construction Trades
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          fontSize={'14px'}
          variant="h4"
          sx={{ color: ({ palette }) => palette.customColors.gray[500] }}>
          Select all that Apply
        </Typography>
      </Grid>

      <Grid item xs={12} className="">
        {userConstructionTradeData?.data?.results?.length === 0 && (
          <Typography
            fontSize={'14px'}
            variant="h4"
            sx={{ color: ({ palette }) => palette.customColors.gray[500] }}>
            - Empty -
          </Typography>
        )}
      </Grid>

      {userConstructionTradeData?.data?.results?.map(({ construction_trade, id }) => (
        <Grid item md={3} key={id}>
          <ControlledMultiSelect
            key={id}
            id={id}
            control={control}
            errors={errors}
            label={construction_trade}
            param={'construction_trade'}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default ConstructionTrades;

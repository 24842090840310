import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';

export default (props) => {
  const { open } = props;

  return (
    <div
      className={classNames({
        hide: open ? false : true,
        'loading zIndex999': true
      })}>
      <Backdrop open={open}>
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
};

import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu'
import FooterDashboard from 'components/FooterLink/Dashboard'
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Box, Container, Grid, makeStyles } from '@mui/material';
import HeaderLogo from 'components/Logo/Homepage';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import iconUpload from 'assets/upload.svg'
import iconPdf from 'assets/pdf-icon.svg'
import IconPlus from 'assets/plus.svg';


const SubcontractorProfile = () => {
    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const [fileLogo, setFileLogo] = useState('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=')
    const [fileProject, setFileProject] = useState('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=')

    function uploadImage(e) {
        setFileLogo(URL.createObjectURL(e.target.files[0]));
    }
    function uploadImage2(e) {
        setFileProject(URL.createObjectURL(e.target.files[0]));
    }
    return (
        <>
            <div className="header-second">
                <HeaderLogo />
            </div>
            <Container>
                <div className="content-inner-wrapper form-profile">
                    <h3 className="subtitle mb-2">Subcontractor Profile</h3>

                    <Grid container spacing={3}>
                        <Grid item md={10} className="mb-2">
                            <h4>Company Information</h4>
                            <Grid container spacing={3}>
                                <Grid item md={6}>
                                    <label>Company name:</label>
                                    <input type="text" className="form-control" placeholder='Enter company name' />
                                </Grid>
                                <Grid item md={6}>
                                    <label>City:</label>
                                    <input type="text" className="form-control" placeholder='Enter city' />
                                </Grid>
                                <Grid item md={6}>
                                    <label>State:</label>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            sx={{
                                                height: '45px',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                    <label>Website: </label>
                                    <input type="text" className="form-control" placeholder='Enter your website' />
                                </Grid>
                                <Grid item md={6}>
                                    <label>ZIP Code: </label>
                                    <input type="text" className="form-control" placeholder='Enter ZIP code' />
                                </Grid>
                                <Grid item md={6}>
                                    <label>Travel distance:</label>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            sx={{
                                                height: '45px',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <h4 className='mt-4'>Company Contact Information</h4>
                            <Grid container spacing={3}>
                                <Grid item md={6}>
                                    <label>Phone number: </label>
                                    <input type="text" className="form-control" placeholder='Enter phone number' />
                                </Grid>
                                <Grid item md={6}>
                                    <label>Fax number: </label>
                                    <input type="text" className="form-control" placeholder='Enter fax number' />
                                </Grid>
                                <Grid item md={6}>
                                    <label>Email address: </label>
                                    <input type="text" className="form-control" placeholder='Enter email address' />
                                </Grid>

                            </Grid>
                            <h4 className='mt-4'>Uploads</h4>
                            <div className="d-flex flex-wrap mb-3">
                                <div className="uploads-container mr-2">
                                    <input type="file" onChange={uploadImage} />
                                    <img src={fileLogo} alt="" className='upload-preview' />
                                    <div className='uploads-label'>
                                        <img src={iconUpload} alt="" />
                                        <div>Upload Company Logo</div>
                                    </div>
                                </div>
                                <div className="uploads-container mr-2">
                                    <input type="file" onChange={uploadImage2} />
                                    <img src={fileProject} alt="" className='upload-preview' />
                                    <div className='uploads-label'>
                                        <img src={iconUpload} alt="" />
                                        <div>Upload Project Pictures</div>
                                    </div>
                                </div>
                            </div>

                            <h4 className='mt-4'>About The Company</h4>
                            <Grid container spacing={3}>
                                <Grid item md={12} className="mb-2">
                                    <label>Description:</label>
                                    <textarea className='form-control' rows="10" placeholder='Enter text'></textarea>
                                </Grid>
                            </Grid>

                            <h4 className='mt-3'>Industry</h4>
                            <Grid container spacing={3}>
                                <Grid item md={10} className="mb-2">
                                    <label>Select all that Apply:</label>
                                    <div className="d-flex">
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check' />
                                            <label htmlFor='check'>Residential Construction</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check2' />
                                            <label htmlFor='check2'>Commercial Construction</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check3' />
                                            <label htmlFor='check3'>Civil Construction</label>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <h4 className='mt-3'>Construction Trades</h4>
                            <label>Select all that Apply:</label>
                            <Grid container spacing={1}>
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter trade' />
                                </Grid>
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter trade' />
                                </Grid>
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter trade' />
                                </Grid>
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter trade' />
                                </Grid>
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter trade' />
                                </Grid>
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter trade' />
                                </Grid>
                            </Grid>

                            <h4 className="mt-3">Labor Affiliation</h4>
                            <div className="d-flex">
                                <div className='check-btn mr-1'>
                                    <input type="checkbox" id='check4' />
                                    <label htmlFor='check4'>Union</label>
                                </div>
                                <div className='check-btn mr-1'>
                                    <input type="checkbox" id='check5' />
                                    <label htmlFor='check5'>Non Union</label>
                                </div>
                            </div>

                            <h4 className="mt-3">Construction Bond</h4>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="yes" control={<Radio sx={{ pl: '0' }} />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio sx={{ pl: '0' }} />} label="No" />
                            </RadioGroup>

                            <h4 className="mt-3">Minority - Owned Certified</h4>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="yes" control={<Radio sx={{ pl: '0' }} />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio sx={{ pl: '0' }} />} label="No" />
                            </RadioGroup>

                            <h4 className='mt-3'>Trade Licence / Certifificates</h4>
                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                            </Grid>

                            <div className="mt-4">
                                <button className='btn btn-success mr-1'><img src={IconPlus} className='bottom mr-1' /> Finish</button>
                            </div>
                        </Grid>
                    </Grid>









                </div>

            </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(SubcontractorProfile)
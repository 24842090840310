import FooterLink from 'components/FooterLink';
import NavbarMenuTop from 'components/NavbarMenuTop';
import SignUpTypePage from 'components/pages/signuptype';
import { useLocation, useParams } from 'react-router-dom';

const SignUpType = (props) => {
  const params = useLocation();
  console.log(params, 'params');
  return (
    <>
      <NavbarMenuTop page="user-type" />
      <div className="main-content">
        <SignUpTypePage formData={params.state.formPayload} />
      </div>
      <FooterLink />
    </>
  );
};
export default SignUpType;

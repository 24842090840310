import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from 'assets/close-icon.svg';
import delAccount from 'assets/account-delete.svg';
import { useDispatch } from 'react-redux';
import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant';
import { deleteJobSeekerMediaRequest } from 'utils/calls';

function DeleteJobSeekerModel({
  isOpen,
  handleClose,
  id,
  handleResetSelectedId,
  handleTriggerFetchProfile
}) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleDelete = () => {
    if (id !== -1) {
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: true
        }
      });
      setLoading(true);
      deleteJobSeekerMediaRequest(id)
        .then(() => {
          dispatch({
            type: CHANGE_ATTRIBUTE_NEWS,
            payload: {
              key: 'alertSeverity',
              value: 'success'
            }
          });

          dispatch({
            type: CHANGE_ATTRIBUTE_NEWS,
            payload: {
              key: 'errors',
              value: ['Photo deleted successfully']
            }
          });
        })
        .catch(() => {
          dispatch({
            type: CHANGE_ATTRIBUTE_NEWS,
            payload: {
              key: 'alertSeverity',
              value: 'error'
            }
          });

          dispatch({
            type: CHANGE_ATTRIBUTE_NEWS,
            payload: {
              key: 'errors',
              value: ['Failed to delete the Photo']
            }
          });
        })
        .finally(() => {
          handleClose();
          setLoading(false);
          handleTriggerFetchProfile();
          dispatch({
            type: CHANGE_ATTRIBUTE_NEWS,
            payload: {
              key: 'isPageLoading',
              value: false
            }
          });
          handleResetSelectedId();
        });
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <div className="modal modal-sm">
        <div className="modal-header">
          <h3>Delete Photo</h3>
          <div className="modal-close" onClick={handleClose}>
            <img src={CloseIcon} />
          </div>
        </div>
        <div className="modal-content mui--text-center">
          <img src={delAccount} className="mt-3" />
          <p className="allert-mm mt-3 mb-3">Are you sure you want to delete this photo?</p>
          <div className="mui--text-center">
            <button
              className="btn btn-outline-dark mr-1"
              onClick={handleClose}
              disabled={isLoading}>
              Cancel
            </button>
            <button className="btn btn-dark mr-1" onClick={handleDelete} disabled={isLoading}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteJobSeekerModel;

import { Grid, Typography } from '@mui/material';
import React from 'react';
import ControlledMultiSelect from 'components/input/ControlledMultiSelect';
import { HandleGetUserIndustryRequest } from 'api/callsHandler';

function Industry({ control, errors }) {
  const { data: userIndustryData } = HandleGetUserIndustryRequest();

  return (
    <Grid container mt={2} spacing={2}>
      <Grid item md={12}>
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          Industry
        </Typography>
      </Grid>
      <Grid item xs={12} className="mb-2">
        <Typography
          fontSize={'14px'}
          variant="h4"
          sx={{ color: ({ palette }) => palette.customColors.gray[500] }}>
          Select all that Apply
        </Typography>
      </Grid>

      {userIndustryData?.data?.results?.length === 0 && (
        <Grid item xs={12}>
          <Typography
            fontSize={'14px'}
            variant="h4"
            sx={{ color: ({ palette }) => palette.customColors.gray[500] }}>
            - Empty -
          </Typography>
        </Grid>
      )}

      {userIndustryData?.data?.results?.map(({ industry, id }) => (
        <Grid item md={3} key={id}>
          <ControlledMultiSelect
            key={id}
            id={id}
            control={control}
            errors={errors}
            label={industry}
            param={'industry'}
            marginTop={0}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default Industry;

import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu'
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import Checkbox from '@mui/material/Checkbox';
import { Grid } from '@mui/material';
import CardPrice from 'components/CardPrice';
import IconBike from 'assets/bike.svg'
import IconPlane from 'assets/plane-icon.svg'
import IconRocket from 'assets/rocket.svg'
import IconPlus from 'assets/plus.svg';
import { Link } from 'react-router-dom';

const PostJobFeatureRecruiter = () => {

    return (
        <>
            <div className="wrapper">
                <div className="user-dashboard">
                    <SidebarMenu />
                    <div className="content-dashboard">
                        <div className='content-inner-wrapper'>
                            <h2 className='title-2 mb-4'>Feature Job Post</h2>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item md={4}>
                                    <CardPrice
                                        title="Feature Job Post"
                                        icon={IconPlane}
                                        price="$169.95"
                                        dataId="price2"
                                        radioName="price"
                                    >
                                        <ul>
                                            <li>
                                                Feature Your Job Post For 7 Days
                                            </li>
                                            <li>
                                                Priority Job Ad Placement
                                            </li>
                                            <li>
                                                Be Seen by More Candidates
                                            </li>
                                            <li>
                                                Drive More Traffic to Your Ad
                                            </li>
                                        </ul>
                                    </CardPrice>
                                </Grid>
                                <Grid item md={4}>
                                    <CardPrice
                                        title="Urgent Banner"
                                        icon={IconRocket}
                                        price="$299.95"
                                        dataId="price3"
                                        radioName="price"
                                    >
                                        <ul>
                                            <li>
                                                Add an Urgent Banner to Your Job Post for 7 Days
                                            </li>
                                            <li>
                                                Show Your Candidates That You Are Looking to Fill This Opening Immediately
                                            </li>
                                        </ul>
                                    </CardPrice>
                                </Grid>
                            </Grid>

                            <div className="text-center mt-4">
                                <Link to={"/recruiter/post-job"}>
                                    <button className='btn btn-primary mr-1'>Back</button>
                                </Link>
                                <Link to={"/recruiter/job-post-created"}>
                                    <button className='btn btn-success ml-1'><img src={IconPlus} className='top mr-1' /> Continue</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(PostJobFeatureRecruiter)
import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import ControlledMultiSelect from 'components/input/ControlledMultiSelect';
import { HandleGetUserSkillSetRequest } from 'api/callsHandler';

function SkillSet({ control, errors }) {
  const { data: userSkillSetData } = HandleGetUserSkillSetRequest();

  return (
    <Grid container mt={4} spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          Skill Set
        </Typography>
      </Grid>

      {userSkillSetData?.data?.results?.length === 0 && (
        <Grid item xs={12}>
          <Typography
            fontSize={'14px'}
            variant="h4"
            sx={{ color: ({ palette }) => palette.customColors.gray[500] }}>
            - Empty -
          </Typography>
        </Grid>
      )}
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        padding={2}
        whiteSpace={'normal'}
        rowGap={1}
        flexWrap={'wrap'}>
        {userSkillSetData?.data?.results?.map(({ skill_set, id }) => (
          <Box width={'auto'} key={id}>
            <ControlledMultiSelect
              key={id}
              id={id}
              control={control}
              errors={errors}
              label={skill_set}
              param={'user_skill_set'}
              marginTop={0}
            />
          </Box>
        ))}
      </Stack>
    </Grid>
  );
}

export default SkillSet;

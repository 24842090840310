export const routerPath = {
  homepage: {
    root: '/',
    workiumHomePage: 'https://workium.com'
  },
  auth: {
    signin: '/users/signin',
    signupType: '/users/signup-type',
    signup: '/users/signup',
    usertype: '/users/account-type',
    tokenInput: '/users/token'
  },
  projects: {
    search: '/search-projects',
    detail: '/projects-detail/:project_id',
    applicants: '/projects/:project_id/applicants',
    manageDetail: '/manage-projects-detail/:project_id',
    postProject: '/projects/create-new',
    manageProject: '/projects/manage-project'
    // applicants: '/projects/applicants'
  },
  applicants: {
    view: '/applicants/:id'
  },
  settings: {
    index: '/settings',
    dashboard: '/settings/dashboard',
    companyContactInformation: '/settings/company-contact-information',
    companyUpload: '/settings/upload-file',
    companyEstablised: '/settings/company-establised',
    companyAbout: '/settings/about-company',
    companyInformation: '/settings/company-information',
    profile: '/settings/profile',
    profilegallery: '/settings/profile/gallery',
    profilegallerydetail: '/settings/profile/gallerydetail',
    profilepreference: '/settings/profile-preference',
    specialist: '/settings/specialist',
    specialistDetail: '/settings/specialist/:id',
    projects: '/settings/projects',
    myprojects: '/projects/myproject',
    createprojects: '/projects/createproject',
    createprojectsname: '/projects/create-project-name',
    createprojectsdescription: '/projects/create-project-description',
    createprojectsbudget: '/projects/create-project-budget',
    createprojectspostingad: '/projects/create-project-postingad',
    createprojectssave: '/projects/create-project-save',
    createprojectsdetail: '/projects/detail/:id',
    constructionproject: '/settings/projects/constructionproject',
    editProfile: '/settings/edit-profile'
  },
  jobs: {
    manageJob: '/job/manage-job',
    createjob: '/job/create-job',
    applyjob: '/job/applyjob',
    applicants: '/job/:job_id/applicants',
    applications: '/job/applications',
    alljob: '/job/all-jobs',
    myjob: '/job/myjob',
    createjobcategory: '/job/create-job-category',
    createjobname: '/job/create-job-name',
    createjobdescription: '/job/create-job-description',
    createjobbudget: '/job/create-job-budget',
    createjobpostingad: '/job/create-job-postingad',
    createjobsave: '/job/create-job-save',
    createjobdetail: '/job/create-job-detail/:id',
    detail: '/job-detail/:job_id',
    manageDetail: '/manage-job-detail/:job_id',
    postingrules: '/job/posting-rules'
  },
  messages: {
    index: '/messages',
    chatRoomDetail: '/messages/:profile_id/job/:job_id',
    chatRoomProjectDetail: '/messages/:profile_id/project/:project_id',
    chatRoomPrivate: '/messages/:profile_id/private/me'
  },
  favorites: {
    favorites: '/favorites/projects',
    favotitesspecialists: '/favorites/specialists'
  },
  users: {
    userprofile: '/user/profile',
    usersprofiles: '/user/profile/:id',
    usergallery: '/user/gallery',
    usergallerydetail: '/user/gallery/detail',
    comments: '/user/comments'
  },
  payment: {
    checkout: '/payment/checkout'
  },
  recruiter: {
    recruiter: '/recruiter',
    postjob: '/recruiter/post-job',
    postjobfeature: '/recruiter/post-job-feature',
    createdjob: '/recruiter/job-post-created'
  },
  nearby: {
    professionalnearby: '/professional-nearby'
  },
  visitors: {
    visitors: '/visitors'
  },
  search: {
    root: '/search'
  },
  news: {
    detail: '/news/:id'
  },
  purchase: {
    history: '/purchase/history'
  },
  footer: {
    about: '/about',
    terms: '/terms',
    privacy: '/privacy',
    help: '/help',
    press: '/press'
  },
  favorite: {
    view: '/favorites'
  },
  jobseeker: {
    home: '/jobseeker',
    profile: '/jobseeker/profile',
    settings: '/jobseeker/profile/settings',
    projectdetail: '/jobseeker/projectdetail',
    seekerfavorite: '/jobseeker/favorite',
    seekersearch: '/jobseeker/search-project',
    seekerjobdetail: '/jobseeker/jobdetail',
    profileoutside: '/jobseeker/profileoutside',
    profileoutsideedit: '/jobseeker/profileoutside-edit',
    postjob: '/post-job',
    companycontact: '/company-contact',
    contractorprofile: '/contractor-profile',
    availableproject: '/available-project-creation',
    subcontractorprofile: '/subcontractor-profile',
    tiersubcontractorprofile: '/tier-subcontractor-profile',
    jobapplicants: '/job-applicants',
    privacypolicy: '/privacy-policy',
    terms: '/terms-and-conditions',
    jobpostingrule: '/job-and-project-posting-rules'
  },
  subcontractor: {
    jobads: '/subcontractor/job-ads',
    projectads: '/subcontractor/project-ads'
  },
  contractor: {
    availableprojectcreation: '/contractor/available-project-creation',
    createdproject: '/contractor/created-project'
  }
};

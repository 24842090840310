import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { appState } from 'store/slices/appSlice';
import { HandleGetProfileRequest } from 'api/callsHandler';
import { CircularProgress, Stack } from '@mui/material';

const NotVerifiedRoutes = ({ auth }) => {
  const { authToken } = useSelector(appState);
  const { authenticated } = auth;
  const location = useLocation();

  const { data: profileData } = HandleGetProfileRequest('', authenticated?.id);

  if (!authToken) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return !profileData ? (
    <Stack justifyContent={'center'} alignItems={'center'} sx={{ height: '100vh' }}>
      <CircularProgress />
    </Stack>
  ) : !profileData?.data?.is_email_verified ? (
    location.state?.from?.pathname ? (
      <Navigate to={location.state?.from?.pathname} />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};
export default connect(mapStateToProps, null)(NotVerifiedRoutes);

import { initializeApp } from 'firebase/app';
import {
  getDatabase,
  onValue as onValueFirebase,
  ref as refFirebase,
  off as offFirebase,
  goOffline
} from 'firebase/database';
import { getDocs, query, collection, getFirestore } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STOREAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};
const app = initializeApp(config);
export default app;
export const onValue = onValueFirebase;
export const off = offFirebase;
export { goOffline };
export const ref = refFirebase;
export const db = getDatabase(app);
export const dbFireStore = getFirestore(app);

const auth = getAuth();

export const signInFirebase = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const signUpFirebase = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

import axios from "axios";
import { setupAxios } from "utils/axiosConfig";

export const projectsApi = {
  root: "/api/v1/all-projects/",
  submit: "/api/v1/projects/",
  myProjects: "/api/v1/projects/",
  category: "/api/v1/project-category/",
  subcategory: "/api/v1/project-subcategory/",
};

export function apiGetProjects(params) {
  setupAxios();
  return axios.get(projectsApi.root, { params });
}
export function apiGetProjectCategory() {
  setupAxios();
  return axios.get(projectsApi.category);
}
export function apiGetProjectSubCategory() {
  setupAxios();
  return axios.get(projectsApi.subcategory);
}

export function apiPostProjectCreation(payload) {
  setupAxios();
  return axios.post(projectsApi.submit, payload, {
     headers: { "Content-Type": "multipart/form-data", 'Content-Type': 'application/json', 'Accept':'application/json' },
  })
  // return axios.post(projectsApi.submit, payload);
}

export function apiPutProjectCreation(payload, id) {
  setupAxios();
  return axios.put(`${projectsApi.submit}${id}/`, payload, {
     headers: { "Content-Type": "multipart/form-data", 'Content-Type': 'application/json', 'Accept':'application/json' },
  })
  // return axios.post(projectsApi.submit, payload);
}

export function apiDeleteProjectCreation(id) {
  setupAxios();
  return axios.delete(`${projectsApi.submit}${id}/`);
}

export function apiGetMyProjects(params) {
  setupAxios();
  return axios.get(projectsApi.myProjects, { params });
}

export function apiGetMyProjectsDetail(id) {
  setupAxios();
  return axios.get(`${projectsApi.myProjects}${id}/`);
}

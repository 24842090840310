import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant'
import { apiGetProjects } from 'api/projects.api'
  
  export const actionToProps = {
    actionGetProjects: (params) => async (dispatch, getState) => {
  
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: true
        }
      })
  
      try {
        const { data: { results }, status } = await apiGetProjects(params)
  
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'projects',
            value: results
          }
        })
  
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: false
          }
        })
  
        return results
      } catch (err) {
        let errorMessage = []
        let message = err.message ? err.message : 'Something went Wrong!';
        if (err.response){
          message = err.response.data.message
        }
  
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: false
          }
        })
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'alertSeverity',
            value: 'error'
          }
        })
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'errors',
            value: errorMessage.concat(message)
          }
        })
  
        return false
      }
      finally {
  
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'isPageLoading',
            value: false
          }
        })
      }
    },
  }
  
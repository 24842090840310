import React from "react";

import IconCalendar from "assets/calendar.svg";
import IconEye from "assets/show.svg";
import IconLocation from "assets/location.svg";
import IconBookmark from "assets/Bookmark.svg";
import IconBookmarkActive from "assets/Bookmark-active.svg";
import IconDollar from "assets/dollar.svg";
import IconTime from "assets/time.svg";
import { getRelativeTime } from "utils/date";

const CardList = (props) => {
    const {
        onClick,
        name,
        description,
        createdAt,
        views,
        address,
        tags,
        isBookmark,
        price,
        deadline,
    } = props;

    return (
        <div
            onClick={onClick}
            className="card project-list pointer"
        >
            <div className="mui-row d-flex flex-wrap">
                <div className="mui-col-sm-9">
                    <div className="project-content">
                        <h4 className="project-title">{name}</h4>
                        <p>{description}</p>

                        <div className="project-meta">
                            <span>
                                <img src={IconCalendar} /> Posted{" "}
                                {getRelativeTime(createdAt)}
                            </span>
                            <span>
                                <img src={IconEye} /> {views} views
                            </span>
                            <span>
                                <img src={IconLocation} /> {address}
                            </span>
                        </div>
                        <div className="project-cat">
                            {tags?.map((t, k) => {
                                return (
                                    <div key={k} className="project-cat-item">
                                        {t}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="mui-col-md-3">
                    <div className="project-price">
                        <button className="btn btn-circle">
                            <img
                                src={
                                    !isBookmark
                                        ? IconBookmark
                                        : IconBookmarkActive
                                }
                            />
                        </button>
                        <div className="price-inner">
                            <div className="d-flex mb-1">
                                <div className="ico-pps">
                                    <img src={IconDollar} />{" "}
                                </div>
                                <div>Price: {price}</div>
                            </div>
                            <div className="d-flex">
                                <div className="ico-pps">
                                    <img src={IconTime} />
                                </div>
                                <div>Est. Time: {deadline}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardList;

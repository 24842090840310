import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actionToProps as modulesAction } from 'store/reducers/news/actions/actionGetModulesTermPrivacy';
import IconBack from 'assets/line-arrow-left.svg';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const Terms = (props) => {
  const {
    actionGetModulesTermPrivacy,
    news: { termsAndCondition }
  } = props;
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await actionGetModulesTermPrivacy({ mode: 'terms' });
  };

  return (
    <>
      <div className="wrapper">
        <div className="mui-container">
          <div className="dw-container">
            <div className="dw-header pt-4" style={{ position: 'fixed', width: '100%' }}>
              <h3 className="m-0 single-title">
                <span
                  className="pointer mr-2"
                  onClick={() => {
                    navigate(-1);
                  }}>
                  <img src={IconBack} />
                </span>
                Terms and Conditions
              </h3>
            </div>
            <div className="dw-content">
              {/* <div className="card" style={{ height: '75vh', padding: '0.1em' }}> */}
              {/* <div className="card-body p-4">
                  <p dangerouslySetInnerHTML={{ __html: termsAndCondition?.body }} />
                </div> */}
              {/* <iframe
                  style={{ width: '100%', height: '100%', borderRadius: '20px' }}
                  src={`data:application/pdf;base64,${termsAndCondition?.body}`}
                /> */}
              <div style={{ marginTop: '10em' }}>{parse(termsAndCondition?.body ?? '')}</div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...modulesAction };
export default connect(mapStateToProps, mapDispatchToProps)(Terms);

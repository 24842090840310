import React, { useState, useRef, useEffect, useMemo } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { connect, useDispatch, useSelector } from 'react-redux';
import FooterDashboard from 'components/FooterLink/Dashboard';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import ListGroup from 'components/ListGroup';
import IconPlus from 'assets/plus.svg';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import CloseIcon from 'assets/close-icon.svg';
import delAccount from 'assets/account-delete.svg';
import logoutIcon from 'assets/logout.svg';
// import ProfileImage from 'assets/images/profile-img-2.jpg';
import ProfileImage from 'assets/images/user.svg';
import PlaceholderImage from 'assets/images/placeholder.jpg';

import stateData from 'constant/state.json';
import cityData from 'constant/city.json';

import ProjectPicture from 'components/Profile/projectpicture';
import { makeStyles } from 'tss-react/mui';
import {
  useGetMyCompanyQuery,
  useGetUserProfileQuery,
  useGetUserTradeLicencesQuery,
  useGetWorkiumCompanyListQuery,
  useViewApplicantsProfileMutation
} from 'store/slices/apiSlice';
import { appState } from 'store/slices/appSlice';
import { Navigate, useNavigate } from 'react-router-dom';
import { routerPath } from 'router/Routerlist';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { HandleGetProfileRequest } from 'api/callsHandler';
import { JOB_SEEKER, RECRUITER, SECOND_TIER_CONTRACTOR } from 'constant';
import { formatDescription } from 'utils/helper';

const label = { inputProps: { 'aria-label': 'Switch' } };

const ProfileGalleryDetail = (props) => {
  const {
    auth: { authenticated }
  } = props;
  const { classes } = useStyles();

  // const { data: profileDataSwr } = HandleGetProfileRequest('', authenticated?.id);

  const [
    viewApplicantsProfile,
    { data: profileResult, isSuccess, refetch: refetchUserProfile, isLoading: isLoadingViewProfile }
  ] = useViewApplicantsProfileMutation();

  const { data: dataTradeLicense, isSuccess: isSuccessTradeLicense } =
    useGetUserTradeLicencesQuery();
  const {
    data: dataCompanyList,
    isSuccess: isSuccessCompanyDataList,
    refetch: refetchCompanyListWorkium
  } = useGetWorkiumCompanyListQuery();
  const [companyData, setCompanyData] = useState([]);

  const {
    data: dataCompany,
    isSuccess: isSuccessCompanyData,
    refetch: refetchCompanyList
  } = useGetMyCompanyQuery();

  const { authToken } = useSelector(appState);
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const deleteClose = () => {
    setOpen(false);
  };
  const deleteAccount = () => {
    setOpen(!open);
  };
  const logoutClose = () => {
    setOpenLogout(false);
  };
  const logoutAccout = () => {
    setOpenLogout(!openLogout);
  };

  useEffect(() => {
    viewApplicantsProfile(authenticated?.id);
    refetchCompanyList();
  }, []);

  // useEffect(() => {
  //   if (profileDataSwr?.data && profileDataSwr?.data?.is_first_login) {
  //     navigate(routerPath.settings.editProfile);
  //   }
  // }, [profileDataSwr]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value: isLoadingViewProfile
      }
    });
  }, [isLoadingViewProfile]);

  useEffect(() => {
    if (isSuccessCompanyData) {
      if (dataCompany.count > 0) {
        const myCompany = dataCompany.results;
        setCompanyData(myCompany[0] ? myCompany[0] : []);
      }
    }
  }, [isSuccessCompanyData]);

  // const profileData = profileResult;
  const { data: profileDataResponse } = HandleGetProfileRequest('', authenticated?.id);
  const profileData = useMemo(() => profileDataResponse?.data ?? undefined, [profileDataResponse]);

  const stateName = useMemo(
    () => stateData?.find((item) => item.state_id == profileData?.company?.state)?.state_name ?? '',
    [profileData]
  );

  const cityName = useMemo(
    () =>
      cityData[2].data.find((city) => city.city_id == profileData?.company?.city)?.city_name ?? '',
    [profileData]
  );

  const statesName = useMemo(
    () => stateData?.find((item) => item.state_id == profileData?.state)?.state_name ?? '',
    [profileData]
  );

  const citysName = useMemo(
    () => cityData[2].data.find((city) => city.city_id == profileData?.city)?.city_name ?? '',
    [profileData]
  );

  const _renderCompanyInformation = () => {
    console.log(profileData?.user_type, 'profileData?.user_type');
    return (
      <Box sx={{ pt: 3 }}>
        <h3 className="subtitle">Company Information</h3>
        <ListGroup
          name="Company name: "
          desc={companyData?.name !== 'undefined' || null ? companyData?.name : '-'}
        />
        <ListGroup name="City: " desc={cityName || '-'} />
        <ListGroup name="State: " desc={stateName || '-'} />
        <ListGroup name="Website: " desc={companyData?.website || '-'} />
        <ListGroup name="Zip Code: " desc={companyData?.zipcode || '-'} />
        {profileData?.user_type !== 'Recruiter' &&
          profileData?.user_type !== 'General Contractor' && (
            <ListGroup name="Travel distance: " desc={`${companyData?.travel_distance || '-'}`} />
          )}
      </Box>
    );
  };

  const _renderCompanyContactInformation = () => (
    <Box sx={{ pt: 3 }}>
      <h3 className="subtitle">Company Contact Information</h3>
      <ListGroup name="First name: " desc={profileData?.name?.split(' ')?.[0] || '-'} />
      <ListGroup
        name="Last name: "
        desc={profileData?.name?.split(' ').splice(1).join(' ') || '-'}
      />
      <ListGroup name="Email: " desc={companyData?.email || '-'} />
    </Box>
  );

  const _renderCompanyContactInformationContractor = () => (
    <Box sx={{ pt: 3 }}>
      <h3 className="subtitle">Company Contact Information</h3>
      <ListGroup name="Phone number: " desc={profileData?.company?.phone_number || '-'} />
      <ListGroup name="Fax number: " desc={profileData?.company?.fax || '-'} />
      <ListGroup name="Email address: " desc={companyData?.email || '-'} />
    </Box>
  );
  const _renderLocation = () => (
    <>
      <Box sx={{ pt: 3 }}>
        <h3 className="subtitle">Personal Information</h3>
        <ListGroup name="Name: " desc={profileData?.name || '-'} />
        <ListGroup name="Age: " desc={profileData?.age || '-'} />
        <ListGroup name="Gender: " desc={profileData?.gender || '-'} />
      </Box>
      <Box sx={{ pt: 3 }}>
        <h3 className="subtitle">Location</h3>
        <ListGroup name="City: " desc={citysName || '-'} />
        <ListGroup name="State: " desc={statesName || '-'} />
        <ListGroup name="Zip Code: " desc={profileData?.zipcode || '-'} />
        <ListGroup name="Travel Distance: " desc={profileData?.distance || '-'} />
      </Box>
      <Box sx={{ pt: 3, pb: 3 }}>
        <h3 className="subtitle">Language</h3>
        <ListGroup
          name="Primary Language: "
          desc={profileData?.language?.map((e) => e.name).join(',') || '-'}
        />
        <ListGroup
          name="Secondary Language: "
          desc={profileData?.secondary_language?.map((e) => e.name).join(',') || '-'}
        />
      </Box>
    </>
  );

  const _renderProjectPictures = () => (
    <Box sx={{ py: 3 }}>
      <h3 className="subtitle">Project Pictures</h3>
      <div className="project-picture">
        {/* {profileData?.user_media &&
          profileData?.user_media.map((e, idx) => (
            <div className="picture-item" key={idx}>
              <div className="images-box">
                <img src={e.image.split('?')[0]} alt="" />
              </div>
            </div>
          ))} */}
        {console.log('profileData', profileData?.user_media)}
        {profileData?.company &&
          profileData?.company?.images?.map((e, idx) => (
            <div className="picture-item" key={idx}>
              <div className="images-box">
                <img src={e.image.split('?')[0]} alt="" />
              </div>
            </div>
          ))}
        <div className="pl-2">
          <button
            className="btn btn-success ml-3"
            onClick={() => {
              navigate(routerPath.settings.editProfile);
            }}>
            <img src={IconPlus} className="bottom mr-1" alt="IconPlus" /> Edit Picture
          </button>
        </div>
      </div>
    </Box>
  );

  const _renderResume = () => (
    <Box sx={{ py: 3 }}>
      <h3 className="subtitle">Project Pictures</h3>
      <div className="project-picture">
        {profileData?.user_media &&
          profileData?.user_media?.map((e, idx) => (
            <div className="picture-item" key={idx}>
              <div className="images-box">
                <img src={e.image.split('?')[0]} alt="" />
              </div>
            </div>
          ))}

        <div className="pl-2">
          <button
            className="btn btn-success ml-3"
            onClick={() => {
              navigate(routerPath.settings.editProfile);
            }}>
            <img src={IconPlus} className="bottom mr-1" alt="IconPlus" /> Edit Picture
          </button>
        </div>
      </div>
    </Box>
  );

  const _renderAboutCompany = () => (
    <Box sx={{ py: 3 }}>
      <h3 className="subtitle">About the Company</h3>
      <p>{formatDescription(companyData?.about || '-')}</p>
    </Box>
  );

  const _renderAboutMe = () => (
    <Box sx={{ py: 3 }}>
      <h3 className="subtitle">About Me</h3>
      <p>
        {formatDescription(
          profileData?.user_type === 'Job Seeker'
            ? profileData?.about_me || '-'
            : profileData?.company?.about || '-'
        )}
      </p>
    </Box>
  );

  const _renderIndustry = () => (
    <Box sx={{ pt: 1 }}>
      <h3 className="subtitle">Industry</h3>
      {/* {profileData?.user_industry &&
        profileData?.user_industry
          .filter((e) => e.industry)
          .map(({ industry, id }, idx) => (
            <span className="tag-label" key={id}>
              {industry}
            </span>
          ))} */}

      {profileData?.industry &&
        profileData?.industry
          .filter((e) => e.industry)
          .map(({ industry, id }, idx) => (
            <span className="tag-label" key={id}>
              {industry}
            </span>
          ))}
    </Box>
  );

  const _renderProfession = () => (
    <Box sx={{ pt: 1 }}>
      <h3 className="subtitle">Profession</h3>
      {profileData?.user_profession &&
        profileData?.user_profession.map(({ profession, id }, idx) => (
          <span className="tag-label" key={id}>
            {profession}
          </span>
        ))}
    </Box>
  );

  const _renderTradeAffiliation = () => (
    <Box sx={{ pt: 1 }}>
      <h3 className="subtitle">Trade Affiliation</h3>
      <span className="tag-label">Union</span>
    </Box>
  );

  const _renderSkillSet = () => (
    <Box sx={{ pt: 1 }}>
      <h3 className="subtitle">Skill Set</h3>
      {profileData?.user_skill_set &&
        profileData?.user_skill_set.map(({ skill_set, id }, idx) => (
          <span className="tag-label" key={id}>
            {skill_set}
          </span>
        ))}
    </Box>
  );
  const _renderEducation = () => (
    <Box sx={{ pt: 1 }}>
      <h3 className="subtitle">Education</h3>
      {profileData?.user_education &&
        profileData?.user_education.map(({ education, id }, idx) => (
          <span className="tag-label" key={id}>
            {education}
          </span>
        ))}
    </Box>
  );

  const _renderExperience = () => (
    <Box sx={{ pt: 1 }}>
      <h3 className="subtitle">Experience</h3>
      <span className="tag-label">{profileData?.experience_name || '-'}</span>
    </Box>
  );

  const _renderTradeOrCertificates = () => (
    <Box sx={{ pt: 1 }}>
      <h3 className="subtitle">Trade Licence / Certifificates</h3>
      {profileData?.user_trade_license &&
        profileData?.user_trade_license.map(({ trade_license, id }, idx) => (
          <span className="tag-label" key={id}>
            {trade_license}
          </span>
        ))}
    </Box>
  );

  const _renderMinorityOwnedCompany = () => (
    <Box sx={{ pt: 1 }}>
      <h3 className="subtitle">Minority - Owned Certified</h3>
      <span className="tag-label">{profileData?.minority_owned_certificate ? 'Yes' : 'No'}</span>
    </Box>
  );

  const _renderConstructionBond = () => (
    <Box sx={{ pt: 1 }}>
      <h3 className="subtitle">Construction Bond</h3>
      <span className="tag-label">{profileData?.construction_bond ? 'Yes' : 'No'}</span>
    </Box>
  );

  const _renderLaborAffiliation = () => (
    <Box sx={{ pt: 1 }}>
      <h3 className="subtitle">Labor Affiliation</h3>
      {/* {profileData?.user_labor_affiliation &&
        profileData?.user_labor_affiliation.map(({ labor_affiliation, id }, idx) => (
          <span className="tag-label" key={id}>
            {labor_affiliation}
          </span>
        ))}
      {!profileData?.user_labor_affiliation?.length && '-'} */}
      <span className="tag-label">{profileData?.labor_affiliation || '-'}</span>
    </Box>
  );

  const _renderConstructionTrade = () => (
    <Box sx={{ pt: 1 }}>
      <h3 className="subtitle">Construction Trades</h3>
      {/* {profileData?.construction_trade &&
        profileData?.user_construction_trade.map(({ construction_trade, id }, idx) => (
          <span className="tag-label" key={id}>
            {construction_trade}
          </span>
        ))}
      {!profileData?.user_construction_trade?.length && '-'} */}

      {profileData?.construction_trade &&
        profileData?.construction_trade.map(({ construction_trade, id }, idx) => (
          <span className="tag-label" key={id}>
            {construction_trade}
          </span>
        ))}
      {!profileData?.construction_trade?.length && '-'}
      {/* <span className="tag-label">{profileData?.construction_trade || '-'}</span> */}
    </Box>
  );

  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu name={profileData} />
          <div className="content-dashboard">
            <div className="content-inner-wrapper">
              <h2 className="title-2">{profileData?.name}</h2>
              <h3 className="subtitle">{profileData?.user_type}</h3>
              <div className="profile-images">
                <img
                  src={
                    profileData?.user_type === JOB_SEEKER || profileData?.user_type === RECRUITER
                      ? profileData?.profile_image?.split('?')[0] ?? PlaceholderImage
                      : profileData?.company?.logo?.split('?')[0] ?? PlaceholderImage
                  }
                  alt="companyLogo"
                />
              </div>

              {['Sub Contractor', SECOND_TIER_CONTRACTOR].includes(profileData?.user_type) &&
                [
                  _renderCompanyInformation(),
                  _renderCompanyContactInformationContractor(),
                  _renderProjectPictures(),
                  _renderAboutCompany(),
                  _renderIndustry(),
                  _renderConstructionTrade(),
                  _renderLaborAffiliation(),
                  _renderConstructionBond(),
                  _renderMinorityOwnedCompany()
                  // _renderTradeOrCertificates()
                ].map((e, index) => <div key={index}>{e}</div>)}
              {profileData?.user_type === 'Recruiter' &&
                [
                  _renderCompanyContactInformation(),
                  _renderAboutMe(),
                  _renderCompanyInformation()
                ].map((e, index) => <div key={index}>{e}</div>)}

              {profileData?.user_type === 'General Contractor' &&
                [
                  _renderCompanyInformation(),
                  // _renderCompanyContactInformation(),
                  _renderCompanyContactInformationContractor(),
                  _renderProjectPictures(),
                  _renderAboutCompany(),
                  _renderIndustry()
                ].map((e, index) => <div key={index}>{e}</div>)}
              {profileData?.user_type === JOB_SEEKER &&
                [
                  _renderLocation(),
                  _renderResume(),
                  _renderAboutMe(),
                  _renderIndustry(),
                  _renderProfession(),
                  _renderTradeAffiliation(),
                  _renderSkillSet(),
                  _renderEducation(),
                  _renderExperience(),
                  _renderTradeOrCertificates()
                ].map((e, index) => <div key={index}>{e}</div>)}
              <Box sx={{ pt: 2 }}>
                <button
                  className="btn btn-success mr-1"
                  onClick={() => {
                    navigate(routerPath.settings.editProfile);
                  }}>
                  <img src={IconPlus} className="bottom mr-1" /> Edit
                </button>
                {/* <button className="btn btn-success">Save</button> */}
              </Box>
            </div>
            <FooterDashboard />
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={deleteClose} aria-labelledby="responsive-dialog-title">
        <div className="modal modal-sm">
          <div className="modal-header">
            <h3>Delete Account?</h3>
            <div className="modal-close" onClick={deleteClose}>
              <img src={CloseIcon} />
            </div>
          </div>
          <div className="modal-content mui--text-center">
            <img src={delAccount} className="mt-3" />
            <p className="allert-mm mt-3 mb-3">Are you sure you want to delete your account?</p>
            <div className="mui--text-center">
              <button className="btn btn-outline-dark mr-1">Cancel</button>
              <button className="btn btn-dark mr-1">Delete</button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog open={openLogout} onClose={logoutClose} aria-labelledby="responsive-dialog-title">
        <div className="modal modal-sm">
          <div className="modal-header">
            <h3>Log Out?</h3>
            <div className="modal-close" onClick={logoutClose}>
              <img src={CloseIcon} alt="closeIcon" />
            </div>
          </div>
          <div className="modal-content mui--text-center">
            <img src={logoutIcon} className="mt-3" alt="logOutIcon" />
            <p className="allert-mm mt-3 mb-3">Are you sure you want to Log Out?</p>
            <div className="mui--text-center">
              <button className="btn btn-outline-dark mr-1">Cancel</button>
              <button className="btn btn-dark mr-1">Sign Out</button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles()({
  imageProfile: {
    width: 200,
    height: 200
  }
});
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction };
export default connect(mapStateToProps, mapDispatchToProps)(ProfileGalleryDetail);

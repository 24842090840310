import React, { useState, useRef } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { connect, useDispatch, useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import FooterDashboard from 'components/FooterLink/Dashboard';
import { useGetUserProfileQuery } from 'store/slices/apiSlice';
import { appState, resetAuth } from 'store/slices/appSlice';
import { Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';

import CloseIcon from 'assets/close-icon.svg';
import delAccount from 'assets/account-delete.svg';
import logoutIcon from 'assets/logout.svg';

const label = { inputProps: { 'aria-label': 'Switch' } };

const ProfileSettings = (props) => {
  const { data, isSuccess } = useGetUserProfileQuery();

  const { authToken } = useSelector(appState);
  console.log({
    data,
    isSuccess,
    authToken
  });

  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const dispatch = useDispatch();
  const deleteClose = () => {
    setOpen(false);
  };
  const deleteAccount = () => {
    setOpen(!open);
  };
  const logoutClose = () => {
    setOpenLogout(false);
  };
  const logoutAccout = () => {
    setOpenLogout(!openLogout);
  };
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner pt-3">
              <h3 className="title-small mt-0">Your Basic Info</h3>
              <div className="box-preference">
                <div className="preference-list">
                  <div className="mui-row mui-row d-flex items-center">
                    <div className="mui-col-xs-4">First Name</div>
                    <div className="mui-col-xs-8 text-dark">Damien</div>
                  </div>
                </div>
                <div className="preference-list">
                  <div className="mui-row mui-row d-flex items-center">
                    <div className="mui-col-xs-4">Last Name</div>
                    <div className="mui-col-xs-8 text-dark">Savio</div>
                  </div>
                </div>
                <div className="preference-list">
                  <div className="mui-row mui-row d-flex items-center">
                    <div className="mui-col-xs-4">Email</div>
                    <div className="mui-col-xs-8 text-dark">d............o@example.com</div>
                  </div>
                </div>
                <div className="preference-list">
                  <div className="mui-row mui-row d-flex items-center">
                    <div className="mui-col-xs-4">Password</div>
                    <div className="mui-col-xs-8 text-dark">**********</div>
                  </div>
                </div>
              </div>

              <h3 className="title-small mt-4">Notifications</h3>
              <div className="box-preference">
                <div className="preference-list">
                  <div className="mui-row d-flex items-center">
                    <div className="mui-col-xs-4">Messages</div>
                    <div className="mui-col-xs-8 text-dark">
                      <Switch {...label} defaultChecked />
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="title-small mt-4">Change Password </h3>

              <Grid container spacing={2}>
                <Grid item lg={4}>
                  <div className="mb-2">
                    <label>Current Password</label>
                    <input type="text" className="form-control" placeholder="Password" />
                  </div>
                  <div className="mb-2">
                    <label>New Password</label>
                    <input type="text" className="form-control" placeholder="Password" />
                  </div>
                  <div className="mb-2">
                    <label>Confirm New Password</label>
                    <input type="text" className="form-control" placeholder="Password" />
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          pr: 1,
                          // color: pink[800],
                          '&.Mui-checked': {
                            color: '#fff'
                          }
                        }}
                      />
                    }
                    label="Show Password"
                  />
                </Grid>
              </Grid>
              <div className="mt-3">
                <button className="btn btn-primary mr-2">Update</button>
                <button className="btn btn-outline-secondary">Cancel</button>
              </div>
              <div className="mt-3">
                <a className="text-danger underline pointer mr-4" onClick={deleteAccount}>
                  Delete Account
                </a>
                <a className="text-dark underline pointer" onClick={logoutAccout}>
                  Log Out
                </a>
              </div>
              <Dialog open={open} onClose={deleteClose} aria-labelledby="responsive-dialog-title">
                <div className="modal modal-sm">
                  <div className="modal-header">
                    <h3>Delete Account?</h3>
                    <div className="modal-close" onClick={deleteClose}>
                      <img src={CloseIcon} />
                    </div>
                  </div>
                  <div className="modal-content mui--text-center">
                    <img src={delAccount} className="mt-3" />
                    <p className="allert-mm mt-3 mb-3">
                      Are you sure you want to delete your account?
                    </p>
                    <div className="mui--text-center">
                      <button className="btn btn-outline-dark mr-1">Cancel</button>
                      <button className="btn btn-dark mr-1">Delete</button>
                    </div>
                  </div>
                </div>
              </Dialog>

              <Dialog
                open={openLogout}
                onClose={logoutClose}
                aria-labelledby="responsive-dialog-title">
                <div className="modal modal-sm">
                  <div className="modal-header">
                    <h3>Log Out?</h3>
                    <div className="modal-close" onClick={logoutClose}>
                      <img src={CloseIcon} />
                    </div>
                  </div>
                  <div className="modal-content mui--text-center">
                    <img src={logoutIcon} className="mt-3" />
                    <p className="allert-mm mt-3 mb-3">Are you sure you want to Log Out?</p>
                    <div className="mui--text-center">
                      <button className="btn btn-outline-dark mr-1">Cancel</button>
                      <button
                        className="btn btn-dark mr-1"
                        onClick={() => {
                          dispatch(resetAuth());
                          window.localStorage.removeItem('persist:root');
                        }}>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog>
            </div>
            <FooterDashboard />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction };
export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);

import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import { ReactComponent as FacebookIcon } from 'assets/fb-logo.svg';
import { ReactComponent as GoogleIcon } from 'assets/google-logo.svg';

import useCookie from 'react-use-cookie';
import { connect, useDispatch } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { actionToProps as signInAction } from 'store/reducers/auth/actions/actionPostSignin';
import { actionToProps as occupationAction } from 'store/reducers/news/actions/actionGetOccupations';

import { actionToProps as profileAction } from 'store/reducers/news/actions/actionGetProfile';
import { routerPath } from 'router/Routerlist';
import { environment } from 'config/environment';

import FooterLink from 'components/FooterLink';
import SendPasswordDialog from 'components/pages/signin/send-password';
import NavbarMenuTop from 'components/NavbarMenuTop';
import { setAuth } from 'store/slices/appSlice';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';

const SignIn = (props) => {
  const {
    auth: { validationFormLogin },
    news: { errors, validationForm },
    history,
    changeNewsAttribute,
    actionGetOccupation,
    actionGetProfile,
    actionPostSignIn
  } = props;

  const [isPasswordSendOpen, setIsPasswordSendOpen] = useState(false);
  const [userToken, setUserToken] = useCookie(environment.ACCESS_TOKEN_COOKIE, '0');
  const loginForm = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    changeNewsAttribute({
      key: 'validationForm',
      value: validationFormLogin
    });
  }, [validationFormLogin]);

  const handleOpenSendPassword = () => {
    setIsPasswordSendOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      email: loginForm.current.querySelector('input#email').value,
      username: loginForm.current.querySelector('input#email').value,
      password: loginForm.current.querySelector('input#password').value
    };

    const success = await actionPostSignIn(payload);
    if (success) {
      // let redirectLink = location.state?.from?.pathname || routerPath.jobs.alljob;
      let redirectLink = routerPath.jobs.alljob;
      setUserToken(success.token);
      const [profile, workLines] = await Promise.all([
        actionGetProfile({
          userId: success?.user?.id
        }),
        actionGetOccupation()
      ]);

      console.log({
        profile,
        workLines
      });
      // debugger;
      if (profile?.is_first_login) {
        redirectLink = routerPath.settings.editProfile;
      }
      dispatch(setAuth(success.token));
      setTimeout(() => {
        navigate(redirectLink);
      }, 300);
    }
  };
  return (
    <>
      <NavbarMenuTop />
      <div className="main-content signin-page">
        <Container component="main" maxWidth="xs">
          <div className="login">
            <div className="text-left title">
              <h1>Sign in to Workium</h1>
              <span>
                Please enter your sign in details.
                <a
                  href="https://workium.com/"
                  target="_blank"
                  // to={routerPath.auth.signup}
                >
                  <Typography
                    component={'span'}
                    sx={{
                      mx: 0.5,

                      textDecoration: 'underline',
                      ':hover': {
                        cursor: 'pointer'
                      }
                    }}
                    style={{
                      color: theme.palette.customColors.blue[100]
                    }}>
                    Sign up
                  </Typography>
                </a>
                here if you are not registered yet.
              </span>
            </div>

            <form ref={loginForm} noValidate onSubmit={handleSubmit} className="form-input">
              <TextField
                id="email"
                label="Login"
                placeholder="Email Address"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                error={validationForm.username && validationForm.username.error ? true : false}
                helperText={
                  validationForm.username && validationForm.username.error
                    ? validationForm.username.error
                    : false
                }
              />
              <TextField
                id="password"
                label="Password"
                placeholder="Password"
                type="password"
                fullWidth
                className={validationForm.non_field_errors ? 'error-field' : ''}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                error={validationForm.password && validationForm.password.error ? true : false}
                helperText={
                  validationForm.password && validationForm.password.error
                    ? validationForm.password.error
                    : false ||
                      (validationForm.non_field_errors && validationForm.non_field_errors.error)
                    ? errors.non_field_errors.error
                    : false
                }
              />
              <Grid container>
                <Grid item xs>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                </Grid>
              </Grid>
              <br />
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                style={{
                  fontSize: '1.1rem'
                }}
                className="main-dark font-poppins text-transform-capitalize">
                Sign in
              </Button>
              <div className="text-center forgot-password">
                <div
                  className="text-underline font-poppins color-secondary-text cursor-pointer"
                  onClick={handleOpenSendPassword}
                  style={{
                    color: theme.palette.customColors.blue[100]
                  }}>
                  Forgot your password?
                </div>
              </div>
              {/* <div className="text-center optional-login">
                <div className="font-poppins color-main-dark text-uppercase">or</div>
              </div> */}
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                startIcon={<FacebookIcon />}
                className="blue-google color-white font-poppins text-transform-capitalize">
                Sign In via Facebook
              </Button> */}
              {/* <br /> */}
              {/* <br /> */}
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                startIcon={<GoogleIcon />}
                className="main-white font-poppins text-transform-capitalize">
                Sign In via Google
              </Button> */}
            </form>
          </div>
          <SendPasswordDialog
            open={isPasswordSendOpen}
            handleClose={() => setIsPasswordSendOpen(false)}
          />
        </Container>
      </div>
      <FooterLink />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = {
  ...authAction,
  ...newsAction,
  ...signInAction,
  ...profileAction,
  ...occupationAction
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

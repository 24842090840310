import React, { useState, useRef, useEffect } from 'react';
import Loading from 'components/Loading/index';
import { connect, useDispatch } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import FooterLink from 'components/FooterLink';
import NavbarMenuTop from 'components/NavbarMenuTop';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MuiOtpInput } from 'mui-one-time-password-input';
import OtpInput from 'react-otp-input';
import { matchIsNumeric } from 'utils/helper';
import { resetAuth } from 'store/slices/appSlice';
import { handlePostVerifyOTPRequest } from 'utils/callsHandler';
import ErrorComponent from 'components/Error/ErrorComponent';
import { routerPath } from 'router/Routerlist';
import { useNavigate } from 'react-router-dom';

const HomepageView = ({ auth }) => {
  const { authenticated } = auth;
  const [isLoading, setLoading] = useState(false);
  const [backendErrors, setBackendErrors] = useState(null);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateChar = (value) => {
    return matchIsNumeric(value);
  };

  const defaultValues = {
    otp: ''
  };

  const schema = yup.object().shape({
    otp: yup.string().required('Please enter the 6-digits OTP')
  });

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    getValues,
    formState: { errors }
  } = useForm({ defaultValues, mode: 'onBlur', resolver: yupResolver(schema) });

  const errorsArray = Object.keys(errors);

  const onSubmit = (data) => {
    setLoading(true);
    // // debugger;
    handlePostVerifyOTPRequest(
      { token: data.otp, email: authenticated.email },
      (res) => {
        // // debugger;
        navigate(routerPath.settings.editProfile);
      },
      (err) => {
        // // debugger;
        setBackendErrors({
          response: err.response?.data?.message,
          fields: Object.keys(data),
          setError
        });
      },
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    function checkRef() {
      if (ref?.current) {
        ref?.current?.children[0]?.children[0]?.children[0]?.focus();
      }
    }
    checkRef();
  }, [ref?.current]);

  return (
    <Stack height={'100vh'} justifyContent={'space-between'}>
      <Box>
        <NavbarMenuTop />
      </Box>
      <Container>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={10} lg={8} xl={4}>
            <Typography textAlign={'center'} fontSize={'28px'} sx={{ fontWeight: '600', mb: 2 }}>
              Token Input
            </Typography>
            <Typography
              textAlign={'center'}
              sx={{ fontSize: '12px', fontWeight: 400, color: 'text.secondary' }}>
              Please enter the token from your email to verify account
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box my={8}>
                <Controller
                  name={'otp'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <OtpInput
                      value={value}
                      onChange={onChange}
                      numInputs={6}
                      ref={ref}
                      renderSeparator={<span> </span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          style={{ width: '3em', height: '3em', margin: 10, textAlign: 'center' }}
                        />
                      )}
                      containerStyle={{ justifyContent: 'center' }}
                    />
                    // <MuiOtpInput
                    //   length={6}
                    //   value={value}
                    //   onChange={onChange}
                    //   validateChar={validateChar}
                    //   // TextFieldsProps={{ disabled: !isRequestVerifyCodeSent }}
                    //   //   paddingX={10}
                    //   className={'Mui-focused'}
                    //   ref={ref}
                    // />
                  )}
                />
              </Box>
              {errors['otp']?.message ? (
                <FormHelperText sx={{ color: 'error.main' }}>
                  {errors['otp']?.message}
                </FormHelperText>
              ) : null}
              {backendErrors !== null ? <ErrorComponent {...backendErrors} /> : ''}

              <Button
                disabled={isLoading}
                fullWidth
                type="submit"
                variant="contained"
                sx={{
                  mt: 2,
                  borderRadius: '5px',
                  backgroundColor: ({ palette }) => palette.customColors.darkBlue
                }}
                // endIcon={isLoading ? <CircularProgress size={18} sx={{ mr: 1 }} /> : <></>}
              >
                <Typography textTransform={'capitalize'}>Submit</Typography>
              </Button>
              <Button
                disabled={isLoading}
                fullWidth
                onClick={() => {
                  dispatch(resetAuth());
                  window.localStorage.removeItem('persist:root');
                }}
                variant="outlined"
                sx={{
                  mt: 2,
                  borderRadius: '5px',
                  border: ({ palette }) => `1px solid ${palette.customColors.gray[700]}`
                }}
                // endIcon={isLoading ? <CircularProgress size={18} sx={{ mr: 1 }} /> : <></>}
              >
                <Typography
                  textTransform={'capitalize'}
                  sx={{ color: ({ palette }) => palette.customColors.gray[700] }}>
                  Cancel
                </Typography>
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
      <Box>
        <FooterLink location={'local'} />
      </Box>
      <Loading open={isLoading} />
    </Stack>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction };
export default connect(mapStateToProps, mapDispatchToProps)(HomepageView);

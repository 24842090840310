import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant'
import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_AUTH } from 'store/reducers/auth/auth.constant'
import { defaultState as defaultStateAuth } from 'store/reducers/auth/auth.reducer'
import { apiPostChangePassword } from 'api/login.api'
import { errorValidationHandler } from 'utils/errorHandler'

export const actionToProps = {
  actionPostChangePassword: (payload) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {

      const { data } = await apiPostChangePassword(payload)


      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: ['Password successfully changed!']
        }
      })

      // dispatch({
      //   type: CHANGE_ATTRIBUTE_AUTH,
      //   payload: {
      //     key: 'validationFormResetPassword',
      //     value: {
      //       email: {
      //         success: true,
      //         error: data.detail
      //       }
      //     }
      //   }
      // })


      return data
    } catch ({ message, response }) {
      const { data } = response
      const errorHandlerData = errorValidationHandler(data)
      console.log({
        errorHandlerData
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: [message, errorHandlerData.alert[0]]
        }
      })
      // dispatch({
      //   type: CHANGE_ATTRIBUTE_AUTH,
      //   payload: {
      //     key: 'validationFormResetPassword',
      //     value: errorHandlerData.validation
      //   }
      // })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
    finally {

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })
    }
  },
}

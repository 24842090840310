import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu'
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import IconFlag from 'assets/flag.svg'

const CreatedJobRecruiter = () => {

    return (
        <>
            <div className="wrapper">
                <div className="user-dashboard">
                    <SidebarMenu />
                    <div className="content-dashboard">
                        <div className="dahboard-header">
                            <h1>Job Post Created </h1>
                        </div>
                        <div className='content-inner'>
                            <div className="text-center mt-5">
                                <img src={IconFlag} alt="" />
                                <h4 className='created-text'>Job Post Has Been Successfully Published</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(CreatedJobRecruiter)
import React from 'react';
import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { Controller } from 'react-hook-form';

function InputField({
  control,
  errors,
  label,
  param,
  type,
  styleInput,
  styleText,
  multiline = false,
  maxRows = 1,
  placeholder,
  autoComplete = '',
  pattern = '',
  marginTop = 2,
  disabled = false,
  startAdornment = undefined,
  notchedBorderColor = undefined
}) {
  return (
    <Controller
      name={param}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl
          error={errors?.[param]}
          variant="standard"
          sx={{ mt: marginTop, width: '100%' }}>
          {label && (
            <FormLabel sx={{ color: 'text.primary', m: 0, ...styleText, marginBottom: 1 }}>
              {label}
            </FormLabel>
          )}
          <OutlinedInput
            type={type}
            // value={value || (type === 'number' ? 0 : '')}
            value={value}
            onChange={onChange}
            sx={{
              height: '44px',
              borderRadius: '8px',
              ...styleInput
              // '& .MuiOutlinedInput-notchedOutline': {
              //   border: ({ palette }) =>
              //     `1.5px solid ${
              //       notchedBorderColor
              //         ? notchedBorderColor
              //         : palette.customColors.grey['Light-Grey']
              //     }`
              // },
              // '&:hover': {
              //   borderColor: ({ palette }) => palette.primary.main
              // }
            }}
            multiline={multiline}
            maxRows={maxRows}
            placeholder={placeholder}
            autoComplete={autoComplete}
            pattern={pattern}
            disabled={disabled}
            // notched
            startAdornment={startAdornment}
          />
          <FormHelperText id="component-error-text">{errors?.[param]?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default InputField;

import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { CircularProgress, Grid, Stack } from '@mui/material';
import CardPrice from 'components/CardPrice';
import IconBike from 'assets/bike.svg';
import IconPlus from 'assets/plus.svg';
import { CARD_TYPE, POST_TYPE } from 'constant';
import { HandleGetPromoCodeRequest, HandleGetSubscriptionRequest } from 'api/callsHandler';

const PriceList = ({
  price,
  name,
  id,
  features,
  type,
  onChangePrice,
  payload,
  cardType,
  onVerifyPromoCode
}) => {
  const [promoCode, setPromoCode] = useState('');
  const [isPromoCodeValid, setIsPromoCodeValid] = useState();
  const [promoCodeData, setPromoCodeData] = useState();
  const { data: couponsData, isLoading: couponsIsLoading } = HandleGetPromoCodeRequest();
  const sortedFeatures = features.sort((a, b) => a.order - b.order);

  const verifyPromoCode = (promoCode) => {
    const founded = couponsData?.data?.results?.find((e) => e.code === promoCode);
    if (founded) {
      setIsPromoCodeValid(true);
      setPromoCodeData(founded);
      onVerifyPromoCode && onVerifyPromoCode(founded);
    } else {
      setIsPromoCodeValid(false);
    }
  };

  useEffect(() => {
    if (promoCode === '') {
      setIsPromoCodeValid();
    }
  }, [promoCode]);

  return (
    <Grid item md={4}>
      <CardPrice
        title={name}
        icon={IconBike}
        discount={isPromoCodeValid && promoCodeData?.discount_percent}
        price={price}
        dataId={id}
        radioName={'Hi'}
        payload={payload}
        onChangeRadio={() => onChangePrice(id)}
        cardType={cardType}
        input={
          <div
            className="text-center"
            style={{
              display: 'flex',
              gap: '1rem',
              alignItems: 'center'
            }}>
            <input
              type="text"
              style={{
                color:
                  isPromoCodeValid === false
                    ? 'red'
                    : isPromoCodeValid === true && promoCode
                    ? 'green'
                    : 'black'
              }}
              onChange={(e) => setPromoCode(e.target.value)}
              className="form-control form-control-sm"
              placeholder="Enter promo code"
            />
            <button
              className="btn btn-primary mr-1 w-full"
              onClick={() => {
                verifyPromoCode(promoCode);
              }}>
              Verify
            </button>
          </div>
        }>
        <ul>
          {sortedFeatures.map((f) => (
            <li>{f.name}</li>
          ))}
        </ul>
      </CardPrice>
    </Grid>
  );
};

const PricingList = ({
  onContinue,
  onBackPage,
  onUpdatePayload,
  fieldName,
  onAggreePostingRule,
  type = POST_TYPE.JOB,
  payload,
  onVerifyPromoCode
}) => {
  const onContinueWithPrice = (price) => {
    onContinue(price);
  };

  const { data: subscriptionData, isLoading: subscriptionIsLoading } =
    HandleGetSubscriptionRequest();
  const subscriptionType = type === POST_TYPE.JOB ? 'job' : 'project';
  const handleChangePrice = (id) => {
    let updatedValue = {};
    const findByPrice = subscriptionData?.data?.results?.find((k) => k.id === id);
    updatedValue['period'] = findByPrice.period;
    updatedValue['charge_job'] = findByPrice.price;
    updatedValue['sub_id'] = payload.sub_id === id ? 0 : id;

    onUpdatePayload(updatedValue);
  };

  return (
    <>
      <div className="text-dark mb-1">
        <Checkbox
          sx={{
            pl: '0'
          }}
          onChange={(e) => {
            onAggreePostingRule(e.target.checked);
          }}
          checked={payload?.isAgreeRule ?? false}
        />
        I have read and agree with{' '}
        <a href="/job/posting-rules" target="_blank">
          {' '}
          {`Rules for posting ${type === POST_TYPE.JOB ? 'jobs' : 'projects'}`}
        </a>
        (required)
      </div>

      {subscriptionIsLoading ? (
        <Stack sx={{ width: '100%' }} alignItems={'center'}>
          <CircularProgress />
        </Stack>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {/* {subscriptionData?.data?.results?.map((e, index) => { */}
          {subscriptionData?.data?.results
            ?.filter((sub) => sub.type === subscriptionType)
            .map((e, index) => {
              return (
                <PriceList
                  {...e}
                  onChangePrice={handleChangePrice}
                  key={index}
                  type={type === POST_TYPE.JOB ? 'jobs' : 'projects'}
                  payload={payload}
                  cardType={CARD_TYPE.SUB}
                  onVerifyPromoCode={onVerifyPromoCode}
                />
              );
            })}
        </Grid>
      )}

      <div className="text-center mt-4">
        <button className="btn btn-primary mr-1" onClick={onBackPage}>
          Back
        </button>
        <button className="btn btn-success ml-1" onClick={onContinueWithPrice}>
          <img src={IconPlus} className="top mr-1" /> Continue
        </button>
      </div>
    </>
  );
};

export default PricingList;

import { useEffect, useState, useMemo } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  useGetAllRatingQuery,
  useGetDetailSpecificProjectsQuery,
  usePostRemoveProjectApplicantMutation
} from 'store/slices/apiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { routerPath } from 'router/Routerlist';
import { errorValidationHandler } from 'utils/errorHandler';
import stateData from 'constant/state.json';
import cityData from 'constant/city.json';
import moment from 'moment';
import IconArrow from 'assets/arrow-left-outline.svg';
import ProjectApplicantList from 'components/Project/ProjectApplicantList';

const ProjectApplicants = () => {
  const [open, setOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { project_id } = useParams();

  const {
    data: detailSpecificProjects,
    isLoading,
    refetch,
    isFetching
  } = useGetDetailSpecificProjectsQuery({ id: project_id });

  const {
    data: getAllRating,
    isLoading: isLoadingRating,
    isFetching: isFetchingRating,
    refetch: refetchRating
  } = useGetAllRatingQuery({
    project_id
  });

  const [
    postRemoveProjectApplicant,
    {
      data,
      isLoading: isLoadingRemoveApplicants,
      isError: isErrorRemoveApplicant,
      error: errorRemoveApplicant,
      isSuccess
    }
  ] = usePostRemoveProjectApplicantMutation();

  useEffect(() => {
    refetch();
    refetchRating();
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isErrorRemoveApplicant) {
      const message = errorValidationHandler(errorRemoveApplicant?.data);
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: message?.alert
        }
      });
    }
  }, [isErrorRemoveApplicant]);

  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value: isLoading || isFetching || isLoadingRemoveApplicants
      }
    });
  }, [isLoading, isFetching, isLoadingRemoveApplicants]);

  const stateName = useMemo(
    () =>
      stateData?.find((item) => item.state_id == detailSpecificProjects?.state)?.state_name ?? '',
    [detailSpecificProjects]
  );

  const cityName = useMemo(
    () =>
      cityData[2].data.find((city) => city.city_id == detailSpecificProjects?.city)?.city_name ??
      '',
    [detailSpecificProjects]
  );

  console.log('detailProjects', detailSpecificProjects);

  // const postRemoveApplicant({
  //   applicant_id: selectedApplicant?.applicant_id,
  //   project_id
  // });

  const handleDeleteProject = () => {
    console.log('working or no?');

    postRemoveProjectApplicant({
      applicant_id: selectedApplicant?.applicant_id,
      project_id
    });
    setOpen(false);
    // setTimeout(() => {
    //   setOpen(false);
    //   // window.location.reload(true);
    // }, 1000);
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  const onClickBack = () => {
    navigate(routerPath.projects.manageProject);
  };
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div
              className="dahboard-header"
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <a href="javascript:void(0);" onClick={onClickBack}>
                <img src={IconArrow} className="sub mr-1" alt="" />
              </a>
              <h1>Project Applicants</h1>
            </div>
            <div className="content-inner inner-padding pt-2">
              <div className="breadcrumb mb-4">
                <div>{detailSpecificProjects?.title} </div>
                {detailSpecificProjects?.state || detailSpecificProjects?.city ? (
                  <div className="active">&nbsp;{`/ ${stateName || ''}, ${cityName || ''}`}</div>
                ) : null}
              </div>
              {!!detailSpecificProjects?.applicants &&
                detailSpecificProjects?.applicants?.map(
                  (
                    {
                      user: { email, id: user_id, name, rating },
                      company,
                      id,
                      city,
                      state,
                      applied_date
                    },
                    idx
                  ) => {
                    const filterRating = getAllRating?.results?.find(
                      (item) => item?.user === user_id
                    );

                    return (
                      <ProjectApplicantList
                        key={idx}
                        name={name ? name : company?.name || ''}
                        userId={user_id}
                        cityId={city}
                        stateId={state}
                        rating={filterRating}
                        date={moment(new Date(applied_date)).format('MMMM DD, YYYY')}
                        onRemove={() => {
                          handleClickOpen();
                          setSelectedApplicant({
                            email,
                            user_id: user_id,
                            applicant_id: id,
                            project_id
                          });
                        }}
                        onMessage={''}
                        onMessage={() => {
                          navigate(
                            routerPath.messages.chatRoomProjectDetail
                              .replace(':project_id', project_id)
                              .replace(':profile_id', user_id)
                          );
                        }}
                        onView={() => {
                          navigate(routerPath.applicants.view.replace(':id', user_id));
                        }}
                      />
                    );
                  }
                )}

              <Dialog open={open} onClose={handleClose} maxWidth={'xs'}>
                <DialogContent>
                  <div className="text-center">
                    <h4 className="subtitle">
                      Do You Want to Delete {selectedApplicant?.email || 'Someone'} from the list
                    </h4>
                    <button
                      className="btn btn-primary mr-1 ml-1"
                      // onClick={() => {
                      //   handleDeleteProject();
                      // }}

                      onClick={() => handleDeleteProject()}>
                      Yes
                    </button>
                    <button className="btn btn-primary mr-1 ml-1" onClick={handleClose}>
                      No
                    </button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectApplicants;

import { Grid, Typography } from '@mui/material';
import ControlledSelect from 'components/input/ControlledSelect';
import InputField from 'components/input/InputField';
import { COUNTRY_ID, TRAVEL_DISTANCE } from 'constant';
import React from 'react';
import stateData from 'constant/state.json';
import cityData from 'constant/city.json';

function Subcontractor2ndTierSubcontractorCompanyInformation({
  control,
  errors,
  loading,
  selectedState
}) {
  return (
    <Grid container mt={6} columnSpacing={2}>
      <Grid item xs={12} className="mb-2">
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          Company Information
        </Typography>
      </Grid>

      <Grid item md={4} className="mb-2">
        <InputField
          control={control}
          errors={errors}
          label={'Company name:'}
          param={'company_name'}
          type={'text'}
          marginTop={1}
          disabled={loading}
        />
      </Grid>

      <Grid item md={4} className="mb-2">
        <ControlledSelect
          control={control}
          errors={errors}
          label={'State'}
          param={'company_state'}
          arrayRecrods={stateData
            .filter((item) => +item.country_id == COUNTRY_ID)
            .map((record) => ({
              value: record.state_id,
              label: record.state_name
            }))}
          marginTop={1}
          disabled={loading}
        />
      </Grid>
      <Grid item md={4} className="mb-2">
        <ControlledSelect
          control={control}
          errors={errors}
          label={'City'}
          param={'company_city'}
          arrayRecrods={cityData[2].data
            .filter((city) => city.state_id == selectedState)
            .map((record) => ({
              value: record.city_id,
              label: record.city_name
            }))}
          marginTop={1}
          disabled={loading}
        />
      </Grid>
      <Grid item md={4} className="mb-2">
        <InputField
          control={control}
          errors={errors}
          label={'Zip code:'}
          param={'company_zipcode'}
          // type={'text'}
          marginTop={1}
          disabled={loading}
        />
      </Grid>
      <Grid item md={4} className="mb-2">
        <InputField
          control={control}
          errors={errors}
          label={'Website:'}
          param={'company_website'}
          type={'text'}
          marginTop={1}
          disabled={loading}
        />
      </Grid>
      <Grid item md={4} className="mb-2">
        <ControlledSelect
          control={control}
          errors={errors}
          label={'Travel distance:'}
          param={'travel_distance'}
          arrayRecrods={TRAVEL_DISTANCE.map((record) => ({
            value: record.distance_id,
            label: record.distance_name
          }))}
          marginTop={1}
          disabled={loading}
        />
      </Grid>
    </Grid>
  );
}

export default Subcontractor2ndTierSubcontractorCompanyInformation;

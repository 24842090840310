import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  Stack,
  Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

function ControlledRadio({ control, errors, label, param, marginTop = 2, id }) {
  return (
    <Controller
      name={param}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FormControl
          error={errors?.[param]}
          variant="standard"
          sx={{ mt: marginTop, width: '100%' }}>
          <Stack direction={'row'} alignItems={'center'}>
            <FormControlLabel
              sx={{ margin: 0 }}
              value={id}
              control={
                <Radio
                  checked={value === id}
                  onChange={() => {
                    onChange(id);
                  }}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
            />
            <Typography fontSize={'16px'}>{label}</Typography>
          </Stack>
          <FormHelperText id="component-error-text">{errors?.[param]?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default ControlledRadio;

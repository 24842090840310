import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { actionToProps as specialistAction } from 'store/reducers/news/actions/actionGetSpecialists';
import { actionToProps as occupationAction } from 'store/reducers/news/actions/actionGetOccupations';
import { routerPath } from 'router/Routerlist';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import HeaderLogo from 'components/Logo/Homepage';
import PlaceholderImage from './../../assets/images/placeholder.jpg';
import JobSeekerMenu from './JobSeekerMenu';
import { useGetAllProjectsQuery } from 'store/slices/apiSlice';
import { HandleGetProfileRequest } from 'api/callsHandler';
import { JOB_SEEKER, RECRUITER } from 'constant';

const LabelBottomNavigation = (props) => {
  const [value, setValue] = useState(30);
  const [filterActive, toggleFilter] = useState(false);
  const [selectedFilter, setFilter] = useState(null);

  const {
    data: resultData,
    isLoading,
    isError,
    isSuccess,
    refetch
  } = useGetAllProjectsQuery({
    limit: 20,
    offset: 0
  });

  const {
    // actionGetProfile,
    // actionGetFeaturedProfile,
    actionGetOccupation,
    actionGetSpecialist,
    changeNewsAttribute,
    auth: { authenticated, profile },
    news: { specialistFilter, occupations, featuredProfiles }
  } = props;

  const { data: profileData } = HandleGetProfileRequest('', authenticated?.id);

  const navigate = useNavigate();

  useEffect(() => {
    resetFilter();
    fetchData();
  }, []);

  const fetchData = async () => {
    setFilter(specialistFilter);
    // actionGetProfile();
    // actionGetFeaturedProfile();
    // actionGetOccupation();
  };
  const resetFilter = () => {
    setValue(15);
    setFilter({
      occupation: [],
      distance: 15
    });
    changeNewsAttribute({
      key: 'specialistFilter',
      value: {
        occupation: [],
        distance: 15
      }
    });
    toggleFilter(false);
  };

  const handleFilterApply = () => {
    changeNewsAttribute({
      key: 'specialistFilter',
      value: {
        ...selectedFilter
      }
    });
    toggleFilter(false);
    actionGetSpecialist(selectedFilter);
  };

  const selectedOccupation = (occupation) => {
    return !!(
      selectedFilter?.occupation.length &&
      selectedFilter?.occupation?.find((item) => item === occupation.id)
    );
  };

  const toggleOccupation = (occupation) => {
    let found = selectedFilter?.occupation.length
      ? selectedFilter.occupation.findIndex((d) => d === occupation.id)
      : -1;
    let occupated = [];

    if (found >= 0) {
      occupated = selectedFilter.occupation || [];
      occupated.splice(found, 1);
    } else {
      occupated = selectedFilter.occupation || [];
      occupated.push(occupation.id);
    }
    setFilter({
      ...selectedFilter,
      occupation: occupated
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSliderEnd = (event, newValue) => {
    setFilter({
      ...selectedFilter,
      distance: newValue
    });
  };

  const goToPage = (path) => {
    // props.history.push(path);
    navigate(path);
  };
  const groupOfAction = {
    handleChange: (e, val) => handleChange(e, val),
    handleSliderEnd: (e, val) => handleSliderEnd(e, val),
    goToPage: (path) => goToPage(path),
    toggleOccupation: (occupation) => toggleOccupation(occupation),
    selectedOccupation: (occupation) => selectedOccupation(occupation),
    handleFilterApply: () => handleFilterApply(),
    resetFilter: () => resetFilter(),
    toggleFilter: (param) => toggleFilter(param),
    setFilter: (param) => setFilter(param),
    value,
    occupations,
    filterActive,
    selectedFilter
  };

  // console.log('SideBar!!', authenticated);
  return (
    <div className="sidebar">
      <div className="sidebar-logo mui--text-center">
        <HeaderLogo />
      </div>
      <div className="sidebar-item">
        <div
          className="d-flex items-center mb-1 cursor-pointer"
          onClick={() => goToPage(routerPath.settings.profilepreference)}>
          <div>
            <div className="profile-img">
              <Avatar
                src={
                  profileData?.data?.user_type === JOB_SEEKER ||
                  profileData?.data?.user_type === RECRUITER
                    ? profileData?.data?.profile_image?.split('?')[0] ?? PlaceholderImage
                    : profileData?.data?.company?.logo?.split('?')[0] ?? PlaceholderImage
                }
                className="img-fluid"
                variant="rounded"
              />
            </div>
          </div>
          <div className="w-100 pl-1">
            <div className="profile-name">
              {profileData?.data?.user_type === JOB_SEEKER
                ? profileData?.data?.name ?? 'Unnamed'
                : profileData?.data?.company?.name ?? 'Unnamed'}
            </div>
            <div className="profile-reputation">{profileData?.data?.user_type}</div>
          </div>
        </div>
      </div>
      <JobSeekerMenu action={groupOfAction} />

      <div className="sidebar-item project-available">
        <h4>Available Projects</h4>

        <ul className="nav-sidebar">
          {!!resultData?.results?.length &&
            resultData?.results?.map((e, idx) => (
              <li key={idx}>
                <a
                  className="nav-item"
                  onClick={() => navigate(routerPath.projects.detail.replace(/:project_id/, e.id))}>
                  {e.name}
                </a>
              </li>
            ))}
        </ul>
      </div>

      {/* {authenticated?.user_type?.toLowerCase() == "recruiter" ? <RecruiterMenu action={groupOfAction} /> : <ContractorMenu action={groupOfAction} />} */}
      {/*<RecruiterMenu action={groupOfAction} />
            <div className="sidebar-item">
                <p className="font-weight-600 mb-2 mt-1">Featured Pros</p>
                <div className="pros-row">
                    { featuredProfiles.length && featuredProfiles.map((d,i)=>
                        <div className="pros-item" key={i}>
                            <div className="pros-img">
                                <img src={d?.image || profileIcon} className="img-fluid" alt={`${d?.firstname} ${d?.lastname}`} />
                            </div>
                        </div>
                    )}
                </div>
            </div>*/}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = {
  ...authAction,
  // ...profileAction,
  ...specialistAction,
  ...occupationAction,
  ...newsAction
};
export default connect(mapStateToProps, mapDispatchToProps)(LabelBottomNavigation);

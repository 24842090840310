import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant'
import { apiGetSpecialist } from 'api/specialist.api'

export const actionToProps = {
  actionGetSpecialist: (params) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    })
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    })

    try {

      const { data } = await apiGetSpecialist(params)

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'specialists',
          value: data.results
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })


      return data
    } catch ({ message, response }) {
      const { data } = response

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      })
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: [message]
        }
      })

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })

      return false
    }
    finally {

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      })
    }
  }
}

import React, { useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import SidebarMenu from 'components/SidebarMenu';
import { connect, useDispatch, useSelector } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import IconArrow from 'assets/images/arrow-left.svg';
import { CircularProgress, Grid } from '@mui/material';
import IconFile from 'assets/file.svg';
import IconDollar from 'assets/dollar.svg';
import IconTime from 'assets/time.svg';
import IconStar from 'assets/images/star.svg';
import IconLocation from 'assets/location.svg';
import StarOff from 'assets/start-outline.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useDeleteFavoriteJobMutation,
  useDeleteMyJobMutation,
  useGetDetailJobsQuery,
  useGetDetailSpecificJobsQuery,
  useGetMyFavoriteJobListQuery,
  usePatchMyJobMutation,
  usePostAddCardMutation,
  usePostFavouriteJobMutation,
  usePostPaymentChargeMutation,
  usePostQuickApplyJobMutation,
  usePostAimwelEventMutation,
  usePostSubmitReportMutation,
  useViewAllJobsMutation
} from 'store/slices/apiSlice';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { errorValidationHandler } from 'utils/errorHandler';
import { routerPath } from 'router/Routerlist';
import FeaturedJobList from 'components/Job/FeaturedJobList';
import PricingList from 'components/Job/PricingList';
import ChargePaymentComponent from 'components/Payment/charge';
import { HandleGetAllCardsRequest } from 'api/callsHandler';
import stateData from 'constant/state.json';
import cityData from 'constant/city.json';
import ReactHtmlParser from 'react-html-parser';
import { formatDescription } from 'utils/helper';

const subscribeJobSteps = ['details', 'post-price', 'post-featured', 'charge-job'];

const defaultFilter = {
  limit: 20,
  search: '',
  city: '',
  state: '',
  offset: 0
};

const JobDetail = () => {
  const [isDeletingThisJob, setIsDeletingThisJob] = useState(false);
  const [isCancelingSubscriptionThisJob, setIsCancelingSubscriptionThisJob] = useState(false);
  const [isSubscribingThisJob, setIsSubscribingThisJob] = useState(false);
  const [isReSubscribingThisJob, setIsReSubscribingThisJob] = useState(false);
  const [activePage, setActivePage] = React.useState(subscribeJobSteps[0]);
  const [errorPrice, setErrorPrice] = useState(false);
  const [isCameFromSearchPage, setIsCameFromSearchPage] = useState(undefined);
  const [filter, setFilter] = useState(defaultFilter);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [payload, setPayload] = useState({
    upload:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=',
    start_date: new Date(),
    charge_job: '0',
    charge_job_featured: '0',
    isAgreeRule: false,
    sub_id: undefined,
    addon_id: undefined
  });

  const { job_id } = useParams();
  const location = useLocation();
  const userData = useSelector((state) => state.auth.authenticated);
  const [postSubmitReport] = usePostSubmitReportMutation();

  // const {
  //   data: allCardsData,
  //   isLoading: isLoadingGetAllCards,
  //   refetch: refetchGetAllCards
  // } = useGetAllCardsQuery({ id: job_id });

  const {
    data: allCardsData,
    isLoading: isLoadingGetAllCards,
    mutate: refetchGetAllCards
  } = HandleGetAllCardsRequest();

  // Start
  // Get Job details from either /jobs or /all-jobs
  const {
    data: jobDetailDataSearchPage,
    isLoading: jobDetailIsLoadingSearchPage,
    refetch: jobDetailRefetchSearchPage,
    currentData: jobDetailCurrentDataSearchPage
  } = useGetDetailJobsQuery({
    id: job_id,
    cancelCall: [undefined, false].includes(isCameFromSearchPage)
  });

  const {
    data: jobDetailDataManagePage,
    isLoading: jobDetailIsLoadingManagePage,
    refetch: jobDetailRefetchManagePage,
    currentData: jobDetailCurrentDataManagePage
  } = useGetDetailSpecificJobsQuery({
    id: job_id,
    cancelCall: [undefined, true].includes(isCameFromSearchPage)
  });

  const getUrl = () => {
    return location.pathname; // or location.search for query params
  };

  const logToConsole = (message) => {
    console.log(message);
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const sendPixel = async (event_type) => {
    const redirectQueryParams = await localStorage.getItem('redirectQueryParams');
    if (!redirectQueryParams) {
      return;
    }
    const params = new URLSearchParams(redirectQueryParams);
    const queryParamsJson = {};

    for (const [key, value] of params.entries()) {
      queryParamsJson[key] = value;
    }

    //send pixel event with event type apply
    const encodeUriComponent = encodeURIComponent;

    // Retrieve cookies
    const sessionCookieName = '_aimwel_session';
    const paramsCookieName = '_aimwel_params';

    let sessionIdFromCookie = getCookie(sessionCookieName);

    if (!sessionIdFromCookie) {
      sessionIdFromCookie = Math.random().toString(36).substring(2);
      Cookies.set(sessionCookieName, sessionIdFromCookie, { expires: 0.5, path: '/' }); // 30 mins
    }

    const body = {
      brand: 'workium',
      timestamp: Math.floor(Date.now() / 1000),
      session_id: sessionIdFromCookie || '',
      event_type: event_type,
      job_id: job_id,
      // client_side: 'true',
      ...queryParamsJson
    };

    if (queryParamsJson?.aw_id) {
      postAimwelEvent(body);
      // await sendPixel(
      //   body,
      //   () => console.log('Event successfully sent.'),
      //   (error) => console.log('Event sending failed.', error)
      // );
    }
    // fetch('/aimwel/', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(body)
    // })
    //   .then((response) => {
    //     if (response.ok) {
    //       onSuccess();
    //     } else {
    //       onFailure(response);
    //     }
    //   })
    //   .catch((error) => {
    //     onFailure(error);
    //   });
  };

  // Function to send event when the job detail page is viewed
  const sendAimwelEvent = async () => {
    await sendPixel('view');
  };

  useEffect(() => {
    // Trigger the event on component load
    sendAimwelEvent();
  }, [job_id]);

  useEffect(() => {
    if (location.pathname.includes('manage')) {
      setIsCameFromSearchPage(false);
    } else {
      setIsCameFromSearchPage(true);
    }
  }, [location, setIsCameFromSearchPage]);

  const jobDetailData = useMemo(
    () => (isCameFromSearchPage ? jobDetailDataSearchPage : jobDetailDataManagePage),
    [jobDetailDataSearchPage, jobDetailDataManagePage, isCameFromSearchPage]
  );
  const isLoadingJob = useMemo(
    () => (isCameFromSearchPage ? jobDetailIsLoadingSearchPage : jobDetailIsLoadingManagePage),
    [jobDetailIsLoadingSearchPage, jobDetailIsLoadingManagePage, isCameFromSearchPage]
  );
  const refetch = useMemo(
    () => (isCameFromSearchPage ? jobDetailRefetchSearchPage : jobDetailRefetchManagePage),
    [jobDetailRefetchSearchPage, jobDetailRefetchManagePage, isCameFromSearchPage]
  );
  const currentData = useMemo(
    () => (isCameFromSearchPage ? jobDetailCurrentDataSearchPage : jobDetailCurrentDataManagePage),
    [jobDetailCurrentDataSearchPage, jobDetailCurrentDataManagePage, isCameFromSearchPage]
  );

  // End

  const navigate = useNavigate();

  const [postQuickApplyJob, { data, isLoading: loadingApplyJob, isSuccess, isError, error }] =
    usePostQuickApplyJobMutation();

  const [postAimwelEvent, { data: aimwelEventResponse }] = usePostAimwelEventMutation();
  console.log(aimwelEventResponse, 'aimwelEventResponse');
  const [
    deleteMyJob,
    {
      isLoading: loadingDeleteJob,
      isError: isErrorDeleteJob,
      error: errorDeleteJob,
      isSuccess: successDeleteJob
    }
  ] = useDeleteMyJobMutation();

  const [
    patchMyJob,
    {
      isLoading: loadingPatchJob,
      isError: isErrorPatchJob,
      error: errorPatchJob,
      isSuccess: successPatchJob
    }
  ] = usePatchMyJobMutation();

  const [
    postPaymentCharge,
    {
      isSuccess: isSuccessPaymentCharge,
      error: paymentChargeError,
      isError: isErrorPaymentCharge,
      isLoading: isLoadingPaymentCharge
    }
  ] = usePostPaymentChargeMutation();

  const [
    postAddCard,
    {
      data: addCardData,
      isSuccess: isSuccessAddCard,
      error: addCardError,
      isError: isErrorAddCard,
      isLoading: isLoadingAddCard
    }
  ] = usePostAddCardMutation();

  const onQuickApply = async () => {
    console.log('applying', 'applying');
    if (!jobDetailData?.user) {
      await sendPixel('apply');

      //redirect in a new tab
      return window.open(jobDetailData?.job_url, '_blank');
      // return (window.location.href = jobDetailData?.job_url);
    }

    if (jobDetailData?.user?.job_applications_notification) {
      const data = {
        email: jobDetailData?.user?.email,
        message: `Hi there,\n\n\nI trust you're doing well. I'm writing to express my keen interest in the ${jobDetailData?.title} position listed on Workium. \n\nI invite you to review my profile and message me via Workium with your preferred method of contact if you believe that I could be a good fit for your opening. \n\nI'm excited about the opportunity to discuss how my skills align with your requirements. \n\nThank you for considering my application.\n\n\nBest regards,\n\nWorkium Team`,
        subject: `Application for ${jobDetailData?.title} Position`
      };

      await postSubmitReport(data);
    }

    const payload = {
      job_id,
      city: jobDetailData?.city,
      description: jobDetailData?.description,
      distance: 10,
      state: jobDetailData?.state,
      title: jobDetailData?.title
    };

    postQuickApplyJob(payload);
    await sendPixel('apply');
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value:
          loadingApplyJob ||
          loadingDeleteJob ||
          loadingPatchJob ||
          isLoadingAddCard ||
          isLoadingPaymentCharge ||
          isLoadingGetAllCards ||
          isLoadingJob
      }
    });
  }, [
    loadingApplyJob,
    loadingDeleteJob,
    loadingPatchJob,
    isLoadingAddCard,
    isLoadingPaymentCharge,
    isLoadingGetAllCards,
    isLoadingJob
  ]);

  useEffect(() => {
    if (
      currentData &&
      (isSubscribingThisJob || isCancelingSubscriptionThisJob || isReSubscribingThisJob)
    ) {
      if (isSubscribingThisJob && !currentData?.is_paid) {
        setActivePage(subscribeJobSteps[1]);
      } else if (
        isCancelingSubscriptionThisJob &&
        currentData?.is_paid &&
        currentData?.is_reoccurring
      ) {
        patchMyJob({ id: job_id, is_reoccurring: false });
        setIsCancelingSubscriptionThisJob(false);
      } else if (isReSubscribingThisJob && currentData?.is_paid && !currentData?.is_reoccurring) {
        patchMyJob({ id: job_id, is_reoccurring: true });
        setIsReSubscribingThisJob(false);
      } else {
        dispatch({
          type: CHANGE_ATTRIBUTE,
          payload: {
            key: 'alertSeverity',
            value: 'error'
          }
        });
        dispatch({
          type: CHANGE_ATTRIBUTE,
          payload: {
            key: 'errors',
            value: ['Wrong payment action!']
          }
        });
      }
    }
  }, [
    job_id,
    currentData,
    isSubscribingThisJob,
    setIsSubscribingThisJob,
    isCancelingSubscriptionThisJob,
    setIsCancelingSubscriptionThisJob,
    isReSubscribingThisJob,
    setIsReSubscribingThisJob,
    setActivePage,
    patchMyJob,
    dispatch
  ]);

  useEffect(() => {
    if (successDeleteJob) {
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: ['Job has been deleted!']
        }
      });
      setTimeout(() => {
        navigate(routerPath.jobs.manageJob);
      }, 300);
    }
  }, [successDeleteJob]);

  useEffect(() => {
    if (successPatchJob) {
      if (isDeletingThisJob) {
        deleteMyJob(job_id);
      } else {
        refetch();
        dispatch({
          type: CHANGE_ATTRIBUTE,
          payload: {
            key: 'alertSeverity',
            value: 'success'
          }
        });
        dispatch({
          type: CHANGE_ATTRIBUTE,
          payload: {
            key: 'errors',
            value: ['Job has been updated!']
          }
        });
      }
    }
  }, [successPatchJob, isDeletingThisJob, deleteMyJob]);

  useEffect(() => {
    if (
      isError ||
      isErrorDeleteJob ||
      isErrorPatchJob ||
      // isErrorAddCard ||
      isErrorPaymentCharge ||
      errorPrice
    ) {
      const message = errorValidationHandler(
        error?.data?.detail ||
          errorDeleteJob?.data ||
          errorPatchJob?.data ||
          errorPatchJob?.data ||
          paymentChargeError?.data ||
          errorPrice
      );
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: message?.alert
        }
      });
    }
  }, [
    isError,
    errorPrice,
    isErrorDeleteJob,
    isErrorPatchJob,
    // isErrorAddCard,
    isErrorPaymentCharge,
    error,
    errorDeleteJob,
    errorPatchJob,
    // addCardError,
    paymentChargeError
  ]);

  useEffect(() => {
    if (isDeletingThisJob) {
      patchMyJob({ id: job_id, is_reoccurring: false });
    }
  }, [isDeletingThisJob, job_id, patchMyJob]);

  const onContinuePage = () => {
    // debugger;
    if (activePage === subscribeJobSteps[1] && !payload?.isAgreeRule) {
      setErrorPrice(['Please check that you agree on rules for job posting']);
      return false;
    }

    if (activePage === subscribeJobSteps[1] && !payload?.sub_id) {
      setErrorPrice(['Please select one of the packages']);
      return false;
    }

    if (activePage === subscribeJobSteps[1] && payload?.charge_job !== '0') {
      setErrorPrice(null);
      setActivePage(subscribeJobSteps[2]);
    } else if (activePage === subscribeJobSteps[2]) {
      setErrorPrice(null);
      setActivePage(subscribeJobSteps[3]);
      // } else {
      //   setErrorPrice(['Please Select following price.']);
    }
  };

  const onBackPage = () => {
    if (activePage === subscribeJobSteps[3]) {
      setActivePage(subscribeJobSteps[2]);
    } else if (activePage === subscribeJobSteps[2]) {
      setActivePage(subscribeJobSteps[1]);
    } else if (activePage === subscribeJobSteps[1]) {
      setActivePage(subscribeJobSteps[0]);
    }
  };

  // const cha = () => {
  //   // debugger;
  //   postPaymentCharge({
  //     // p_id: dataJobCreated?.id,
  //     sub_id: payload?.sub_id,
  //     // token: token?.id,
  //     // token: 'tok_1NdEWvLKF6Em1NNrqQeoeJe4',
  //     card_id: 'card_1NsQRQLKF6Em1NNrofMKDvgS',
  //     addon_id: payload?.addon_id,
  //     payment_type: 'job',
  //     job_id
  //   });
  // };

  const onSuccessCharge = (token) => {
    postAddCard({
      token: token.id
    });
  };
  useEffect(() => {
    if (isSuccessAddCard && isSubscribingThisJob) {
      if (allCardsData?.data?.data?.length > 0) {
        // debugger;
        postPaymentCharge({
          // p_id: dataJobCreated?.id,
          sub_id: payload?.sub_id,
          // token: token?.id,
          // token: 'tok_1NdEWvLKF6Em1NNrqQeoeJe4',
          card_id: allCardsData?.data?.data?.[0]?.id,
          addon_id: payload?.addon_id,
          payment_type: 'job',
          job_id
        });

        setIsSubscribingThisJob(false);
      } else {
        refetchGetAllCards();
        // setTimeout(() => {
        //   postPaymentCharge({
        //     // p_id: dataJobCreated?.id,
        //     sub_id: payload?.sub_id,
        //     // token: token?.id,
        //     // token: 'tok_1NdEWvLKF6Em1NNrqQeoeJe4',
        //     card_id: allCardsData?.data?.data?.[0]?.id,
        //     addon_id: payload?.addon_id,
        //     payment_type: 'job',
        //     job_id
        //   });
        //   setIsSubscribingThisJob(false);
        // }, 2000);
      }
    }
  }, [isSuccessAddCard, allCardsData, isSubscribingThisJob]);

  useEffect(() => {
    if (isSuccessPaymentCharge) {
      refetch();
      setActivePage(subscribeJobSteps[0]);
    }
  }, [isSuccessPaymentCharge]);

  // const stateName = useMemo(
  //   () =>
  //     stateData?.find((item) =>
  //       item.state_id === jobDetailData?.state ? jobDetailData?.state : jobDetailData?.state
  //     )?.state_name ?? '',
  //   [jobDetailData]
  // );

  // const cityName = useMemo(
  //   () =>
  //     cityData[2].data.find((city) =>
  //       city.city_id === jobDetailData?.city ? jobDetailData?.city : jobDetailData?.city
  //     )?.city_name ?? '',
  //   [jobDetailData]
  // );

  const stateName = useMemo(
    () => stateData?.find((item) => item.state_id == jobDetailData?.state)?.state_name ?? '',
    [jobDetailData]
  );

  const cityName = useMemo(
    () => cityData[2].data.find((city) => city.city_id == jobDetailData?.city)?.city_name ?? '',
    [jobDetailData]
  );

  const { data: myFavoriteJob, refetch: refetchMyFavoriteJob } = useGetMyFavoriteJobListQuery();
  const [
    deleteFavoriteJob,
    {
      isLoading: isLoadingDeleteFavorite,
      error: errorDeleteingFavoriteJob,
      isSuccess: isSuccessDeleteFavoriteJob
    }
  ] = useDeleteFavoriteJobMutation();

  const [
    postFavouriteJob,
    {
      isLoading: loadingFavouriteJob,
      isError: isErrorFavouriteJob,
      error: errorFavouriteJob,
      isSuccess: isSuccessFavoriteJob
    }
  ] = usePostFavouriteJobMutation();

  const [
    viewAllJobs,
    {
      data: allJobResult,
      isLoading,
      isError: isErrorGetAllJobs,
      error: errorGetAllJob,
      isSuccess: isSuccessGetAllJob,
      refetch: refetchAllJob,
      isFetching: isFetchingAllJob
    }
  ] = useViewAllJobsMutation();

  let isFavorite = myFavoriteJob?.results?.find((e) => e.job == job_id) ? true : false;

  const onAddFavourite = (ele) => {
    const payload = {
      job: ele.id
    };

    postFavouriteJob(payload);
  };

  const onFavourite = async () => {
    setFavoriteLoading(true);
    const foundFavorite = myFavoriteJob?.results?.find((e) => e.job == job_id);
    if (foundFavorite) {
      await deleteFavoriteJob(foundFavorite.id);
    } else {
      await onAddFavourite(jobDetailDataSearchPage);
    }
    refetchMyFavoriteJob();
    setFavoriteLoading(false);
  };

  useEffect(() => {
    viewAllJobs({ ...filter, is_paid: true });
    refetchMyFavoriteJob();
  }, []);

  useEffect(() => {
    if (isSuccessFavoriteJob || isSuccessDeleteFavoriteJob) {
      refetchMyFavoriteJob();
    }
  }, [isSuccessFavoriteJob, isSuccessDeleteFavoriteJob]);

  const renderDesc = useMemo(
    () => ReactHtmlParser(jobDetailData?.description),
    [jobDetailData?.description]
  );

  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner-wrapper project-detail">
              {activePage ===
                subscribeJobSteps[subscribeJobSteps.findIndex((d) => d === 'details')] && (
                <>
                  <div className="nav-heading">
                    <h1 className="title-2 d-flex mt-0">
                      <a href="javascript:void(0);" onClick={() => navigate(-1)}>
                        <img src={IconArrow} alt="back" />
                      </a>
                      <span className="pl-1">Job Post Detail</span>
                    </h1>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <h3 className="subtitle">{jobDetailData?.title}</h3>
                      <span
                        style={{
                          wordBreak: 'break-word'
                        }}>
                        {jobDetailData?.user !== null
                          ? formatDescription(jobDetailData?.description)
                          : renderDesc}
                      </span>

                      <hr />

                      {jobDetailData?.user !== null && (
                        <>
                          <h4 className="subtitle mt-4">Job Information</h4>
                          <hr className="m-0" />
                          <div className="preference-list">
                            <div className="mui-row mui-row d-flex items-center">
                              <div className="mui-col-xs-4">Company name:</div>
                              <div className="mui-col-xs-8 text-dark">
                                {jobDetailData?.company_name}
                              </div>
                            </div>
                          </div>
                          <div className="preference-list">
                            <div className="mui-row mui-row d-flex items-center">
                              <div className="mui-col-xs-4">City:</div>
                              <div className="mui-col-xs-8 text-dark">{cityName}</div>
                            </div>
                          </div>
                          <div className="preference-list">
                            <div className="mui-row mui-row d-flex items-center">
                              <div className="mui-col-xs-4">State:</div>
                              <div className="mui-col-xs-8 text-dark">{stateName}</div>
                            </div>
                          </div>
                          <div className="preference-list">
                            <div className="mui-row mui-row d-flex items-center">
                              <div className="mui-col-xs-4">Website:</div>
                              <div className="mui-col-xs-8 text-dark">{jobDetailData?.website}</div>
                            </div>
                          </div>
                          <div className="preference-list">
                            <div className="mui-row mui-row d-flex items-center">
                              <div className="mui-col-xs-4">ZIP Code:</div>
                              <div className="mui-col-xs-8 text-dark">
                                {jobDetailData?.zip_code}
                              </div>
                            </div>
                          </div>

                          <h4 className="subtitle-2 mt-4">Photos</h4>
                          <div className="project-photo">
                            {!!jobDetailData?.images?.length &&
                              jobDetailData?.images.map(({ image }, idx) => (
                                <div className="photo-item" key={idx}>
                                  <div className="images-box">
                                    <img src={image.split('?')[0]} alt="" />
                                  </div>
                                </div>
                              ))}
                          </div>

                          {/* <hr />

                      <h4 className="subtitle-2">Files</h4>

                      <div className="files mb-4">
                        <div className="file-container">
                          <img src={IconPdf} alt="" />
                        </div>
                        <div className="file-container">
                          <img src={IconPdf} alt="" />
                        </div>
                        <div className="file-container">
                          <img src={IconPdf} alt="" />
                        </div>
                      </div>

                      <div className="files mb-4">
                        <div className="file-container">
                          <img src={IconPdf} alt="" />
                        </div>
                        <div className="file-container">
                          <img src={IconPdf} alt="" />
                        </div>
                        <div className="file-container">
                          <img src={IconPdf} alt="" />
                        </div>
                      </div> */}

                          <div className="mb-4">
                            <div className="list-icon">
                              <div className="ico-pps">
                                <img src={IconFile} alt="" />
                              </div>
                              <div>Terms: {jobDetailData?.term}</div>
                            </div>
                            <div className="list-icon">
                              <div className="ico-pps">
                                <img src={IconDollar} alt="" />
                              </div>
                              <div
                                style={{
                                  display: 'flex'
                                }}>
                                Price:{' '}
                                {jobDetailData?.price && (
                                  <div
                                    style={{
                                      marginLeft: '7px'
                                    }}>
                                    ${jobDetailData?.price}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="list-icon">
                              <div className="ico-pps">
                                <img src={IconTime} alt="" />
                              </div>
                              <div>Estimated Start Date: {jobDetailData?.start_date}</div>
                            </div>

                            {/* trade  */}
                            <div
                              className="list-icon__no_bottom_border"
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}>
                              <div className="subtitle-2">Trade:</div>
                              {/* <div>{jobDetailData?.term}</div> */}
                              {jobDetailData?.trade && (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                  }}>
                                  <div
                                    style={{
                                      width: 'auto',
                                      background: '#f1f1f1',
                                      borderRadius: '6px',
                                      padding: '8px 15px',
                                      color: '#346c5c',
                                      marginRight: '10px',
                                      marginBottom: '10px',
                                      fontSize: '14px !important',
                                      marginTop: '16px'
                                    }}>
                                    {jobDetailData?.trade}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {/* <h4 className="subtitle-2">Trade</h4>
                      <div className="mb-4">
                        <span className="tag-label grey">Large Building Construction</span>
                        <span className="tag-label grey">HVAC</span>
                      </div> */}

                      <Grid xs={12} container spacing={2}>
                        {!isCameFromSearchPage && (
                          <Grid item xs={5}>
                            <button
                              className="btn btn-primary w-100"
                              onClick={() => {
                                setIsDeletingThisJob(true);
                              }}>
                              Delete Job
                            </button>
                          </Grid>
                        )}
                        {jobDetailData?.id && !jobDetailData?.is_paid && !isCameFromSearchPage && (
                          <Grid item xs={5}>
                            <button
                              className="btn btn-success w-100"
                              onClick={() => {
                                setIsSubscribingThisJob(true);
                                refetch();
                                // setActivePage(subscribeJobSteps[1]);
                              }}>
                              Subscribe
                            </button>
                          </Grid>
                        )}
                        {jobDetailData?.id &&
                          jobDetailData?.is_paid &&
                          jobDetailData?.is_reoccurring &&
                          !isCameFromSearchPage && (
                            <Grid item xs={5}>
                              <button
                                className={`btn btn-danger w-100`}
                                onClick={() => {
                                  // patchMyJob({ id: job_id, is_reoccurring: false });
                                  setIsCancelingSubscriptionThisJob(true);
                                  refetch();
                                }}>
                                Cancel subscription
                              </button>
                            </Grid>
                          )}
                        {jobDetailData?.id &&
                          jobDetailData?.is_paid &&
                          !jobDetailData?.is_reoccurring &&
                          !isCameFromSearchPage && (
                            <Grid item xs={5}>
                              <button
                                className={`btn btn-success w-100`}
                                onClick={() => {
                                  // patchMyJob({ id: job_id, is_reoccurring: false });
                                  setIsReSubscribingThisJob(true);
                                  refetch();
                                }}>
                                Re-subscribe
                              </button>
                            </Grid>
                          )}
                      </Grid>

                      {/* <h3 className="subtitle">
                        Other open Available Project Ads by the Contractor
                      </h3>
                      <div className="d-flex">
                        <div className="underline">Plumbing team needed</div>
                        <div className="pl-2">
                          <p className="text-grey">
                            <strong>$3.450</strong>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="underline">Roof repair on a large square</div>
                        <div className="pl-2">
                          <p className="text-grey">
                            <strong>Negotiated</strong>
                          </p>
                        </div>
                      </div> */}
                    </Grid>
                    <Grid item md={4}>
                      <div className="side-detail">
                        <Grid container justify="flex-end" className="mb-2">
                          <div className="ml-auto">
                            {favoriteLoading ? (
                              <CircularProgress color="primary" size={26} />
                            ) : (
                              <button
                                className="btn fav-btn ml-auto"
                                onClick={onFavourite}
                                disabled={favoriteLoading}>
                                <img src={isFavorite ? IconStar : StarOff} alt="" />
                              </button>
                            )}
                          </div>
                        </Grid>
                        <button
                          disabled={isSuccess ? true : false}
                          className="btn btn-primary w-100"
                          onClick={onQuickApply}>
                          {jobDetailData?.user ? (isSuccess ? 'Applied' : 'Quick Apply') : 'Apply'}
                        </button>

                        {jobDetailData?.user?.id && (
                          <>
                            <h3 className="mt-3">About the Contractor</h3>

                            <div className="profile-project-detail">
                              <div className="profile-img">
                                <div
                                  className="images-box"
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    navigate(
                                      routerPath.applicants.view.replace(
                                        ':id',
                                        jobDetailData?.user?.id
                                      )
                                    );
                                  }}>
                                  <img
                                    src={
                                      jobDetailData?.user?.profile_image?.split('?')?.[0]
                                        ? jobDetailData?.user?.profile_image?.split('?')?.[0]
                                        : jobDetailData?.user?.company?.logo?.split('?')?.[0]
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="w-100 pl-1">
                                <p>{jobDetailData?.user?.name}</p>
                                {stateName && (
                                  <div className="d-flex">
                                    <div>
                                      <img src={IconLocation} alt="" />
                                    </div>
                                    <div className="pl-1">
                                      {' '}
                                      {jobDetailData?.user?.city} - {jobDetailData?.user?.state}
                                      {/* {stateName} - {cityName} */}
                                    </div>
                                  </div>
                                )}

                                <p className="text-grey profile-role">
                                  {jobDetailData?.user?.user_type}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        {/* <hr /> */}

                        {/* <div className="d-flex mb-1">
                          <div style={{ width: '30px' }}>
                            <img src={IconCalendar} alt="" />
                          </div>
                          <div>Posted 8 hours ago</div>
                        </div> */}
                        {/* <div className="d-flex mb-1">
                          <div style={{ width: '30px' }}>
                            <img src={IconLocation} alt="" />
                          </div>
                          <div>10005, New York, NY</div>
                        </div> */}
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
              {activePage ===
                subscribeJobSteps[subscribeJobSteps.findIndex((d) => d === 'post-price')] && (
                <>
                  <h2 className="title-2 mb-4">Post Job Opening</h2>
                  <PricingList
                    onContinue={onContinuePage}
                    onBackPage={onBackPage}
                    fieldName={'charge_job'}
                    payload={payload}
                    onUpdatePayload={(newValue) => {
                      setPayload({
                        ...payload,
                        ...newValue
                      });
                    }}
                    onAggreePostingRule={(value) => {
                      setPayload({
                        ...payload,
                        isAgreeRule: value
                      });
                    }}
                    onClickBack={() => {
                      setActivePage((prev) => prev - 1);
                    }}
                  />
                </>
              )}
              {activePage ===
                subscribeJobSteps[subscribeJobSteps.findIndex((d) => d === 'post-featured')] && (
                <>
                  <h2 className="title-2 mb-4">Feature Job Post</h2>
                  <FeaturedJobList
                    onContinue={onContinuePage}
                    onBackPage={onBackPage}
                    fieldName={'charge_job_featured'}
                    payload={payload}
                    onUpdatePayload={(newValue) => {
                      setPayload({
                        ...payload,
                        ...newValue
                      });
                    }}
                  />
                </>
              )}

              {activePage ===
                subscribeJobSteps[subscribeJobSteps.findIndex((d) => d === 'charge-job')] && (
                <ChargePaymentComponent
                  onSuccessCharge={onSuccessCharge}
                  onClickBack={() => {
                    setActivePage(subscribeJobSteps[subscribeJobSteps.length - 2]);
                  }}
                  addCardError={addCardError}
                  payload={payload}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(JobDetail);

import axios from "axios";
import { setupAxios } from "utils/axiosConfig";

export const profileApi = {
    profile: '/api/v1/profile/',
    featuredProfile: 'https://fakerapi.it/api/v1/persons?_quantity=8&_gender=male',
    worklines:"https://fakerapi.it/api/v1/custom?_quantity=9&customfield1=pokemon",
    addWorklines:"/api/v1/profile/add-workline/",
    languages:"/api/v1/language/",
    gallery:"https://fakerapi.it/api/v1/products?_quantity=13",
    addLanguage:"/api/v1/profile/add-language/",
    addWorkEdu:"https://reqres.in/api/users",
    addPersonalInfo:"https://reqres.in/api/users",
    editNotification:"https://reqres.in/api/users",
    editPrivacy:"https://reqres.in/api/users",
    editInvisibility:"https://reqres.in/api/users",
    deleteAccount:"https://reqres.in/api/users",
};

export const apiPutProfileUpdate = (payload) => {
  setupAxios()
  const { id, ...clearPayload } = payload
  return axios.put(`${profileApi.profile}${payload.id}/`, clearPayload)
}

export function apiGetProfile(params) {
  setupAxios();
  return axios.get(profileApi.profile, { params });
}

export function apiGetDetailProfile(id,params) {
  setupAxios();
  return axios.get(profileApi.profile+`${id}/`, { params });
}
export function apiGetFeaturedProfile(params) {
  return axios.get(profileApi.featuredProfile, { params });
}

export function apiGetAllWorklines(params) {
  return axios.get(profileApi.worklines, { params });
}

export function apiGetLanguages(params) {
  return axios.get(profileApi.languages, { params });
}

export function apiGetGallery(params) {
  return axios.get(profileApi.gallery, { params });
}

export function apiPostWorkline(payload) {
  return axios.post(profileApi.addWorklines, payload);
}

export function apiPostPersonalInfo(payload) {
  return axios.post(profileApi.addPersonalInfo, payload);
}

export function apiPostProfileLocation(payload) {
  return axios.post(profileApi.addPersonalInfo, payload);
}

export function apiPostWorkEducation(payload) {
  return axios.post(profileApi.addWorkEdu, payload);
}

export function apiPostLanguage(payload) {
  return axios.post(profileApi.addLanguage, payload);
}

export function apiPostInvisibility(payload) {
  return axios.post(profileApi.editInvisibility, payload);
}

export function apiPostPrivacy(payload) {
  return axios.post(profileApi.editPrivacy, payload);
}

export function apiPostNotification(payload) {
  return axios.post(profileApi.editNotification, payload);
}
export function apiPostDeleteAccount(payload) {
  return axios.post(profileApi.deleteAccount, payload);
}

import { useState } from 'react';
import { Box, FormControl, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';
import iconUpload from 'assets/upload.svg';

function MultiImageUpload({
  control,
  errors,
  label,
  param,
  marginTop = 0,
  multiple = false,
  noOfUploadedImages = 0,
  max = 5,
  setImageArray,
  imageArray
}) {
  const [loading, setLoading] = useState(true);
  const [localErrors, setLocalErrors] = useState();

  const maxUpload = max - noOfUploadedImages;

  return (
    <Controller
      name={param}
      control={control}
      rules={{ required: 'Picture is required' }}
      render={({ field: { value, onChange, ...field } }) => {
        return (
          <FormControl
            error={!!errors?.[param]}
            variant="standard"
            sx={{
              mt: marginTop,
              gap: 1
            }}>
            <Box className="uploads-container mr-2" sx={{ position: 'relative' }}>
              <input
                type="file"
                multiple={multiple}
                {...field}
                onChange={(event) => {
                  if (!multiple) {
                    setImageArray([event.target.files[0]]);
                    onChange(event.target.files[0]);
                    setLocalErrors();
                  } else if (multiple && imageArray?.length + 1 <= maxUpload) {
                    setImageArray([...imageArray, ...Array.from(event.target.files)]);
                    onChange(imageArray);
                    setLocalErrors();
                  } else {
                    setLocalErrors('Max number of images: 5');
                  }
                }}
              />

              {!multiple &&
                imageArray &&
                imageArray.map((file, index) => {
                  return (
                    <Box
                      className="uploads-container"
                      sx={{
                        width: '137px'
                      }}>
                      <img
                        key={index}
                        src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                        alt={`Preview ${index + 1}`}
                        className="upload-preview"
                        onLoad={() => setLoading(false)}
                      />
                    </Box>
                  );
                })}

              <div className="uploads-label">
                <img src={iconUpload} alt="" />
                <div>{label}</div>
              </div>
            </Box>

            {multiple &&
              imageArray &&
              imageArray.map((file, index) => {
                return (
                  <Box
                    className="uploads-container"
                    sx={{
                      width: '137px'
                    }}>
                    <img
                      key={index}
                      src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                      alt={`Preview ${index + 1}`}
                      className="upload-preview"
                      onLoad={() => setLoading(false)}
                    />
                  </Box>
                );
              })}

            <FormHelperText id="component-error-text" sx={{ color: 'red' }}>
              {errors?.[param]?.message || localErrors}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

export default MultiImageUpload;

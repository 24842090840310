import React, { useState, useEffect } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import FooterDashboard from 'components/FooterLink/Dashboard';
import { connect, useDispatch } from 'react-redux';
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Box, Grid } from '@mui/material';
import ProjectCard from 'components/ProjectCard';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  useGetMyProjectsQuery,
  useGetFavouriteProjWithMutateMutation
} from 'store/slices/apiSlice';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useNavigation } from 'react-router-dom';
import { routerPath } from 'router/Routerlist';
import IconHelmet from 'assets/building.svg';
import IconPlus from 'assets/plus.svg';
import HeaderContentTop from 'components/HeaderContent/topHeader';

const defaultFilter = {
  limit: 8,
  offset: 0
};

const ManageAvailableProject = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [filter, setFilter] = useState(defaultFilter);

  const [
    getFavouriteProjWithMutate,
    {
      data: resultDataFave,
      // isLoading: loadinggetFavouriteProjWithMutate,
      isSuccess: isSuccessgetFavouriteProjWithMutate,
      isError: isErrorgetFavouriteProjWithMutate,
      error: errorgetFavouriteProjWithMutate
    }
  ] = useGetFavouriteProjWithMutateMutation();

  const {
    data: resultData,
    isLoading,
    isError,
    isSuccess,
    refetch
  } = useGetMyProjectsQuery(filter);

  useEffect(() => {
    refetch();
  }, [filter]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value: isLoading
      }
    });
  }, [isLoading]);

  useEffect(() => {
    // refetch();
    getFavouriteProjWithMutate();
    // viewAllProjects({
    //   ...filter,
    //   is_paid: true
    // });
  }, []);

  console.log('resultData', resultData);

  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <HeaderContentTop
              title={'Manage Available Projects'}
              buttonTitle="Create Available Project"
              handleClick={() => {
                navigate(routerPath.projects.postProject);
              }}
            />
            <div className="content-inner pt-2" style={{ marginTop: '45px' }}>
              {!!resultData &&
                resultData?.results?.map(
                  (
                    {
                      name,
                      description,
                      user,
                      is_price_negotiated,
                      start_date,
                      price,
                      is_paid,
                      id,
                      trade,
                      created_at,
                      state,
                      city
                    },
                    idx
                  ) => {
                    console.log('user', user, state, city);
                    return (
                      <ProjectCard
                        name={name}
                        description={description}
                        tags={[]}
                        isFavorite={resultDataFave?.results?.map((e) => e.project.id).includes(id)}
                        address={user.description}
                        // price={is_price_negotiated ? 'Negotiated' : 'Nett'}
                        price={price}
                        deadline={start_date}
                        createdAt={created_at}
                        isPaid={is_paid}
                        key={idx}
                        stateId={state}
                        cityId={city}
                        actionTitle={'View Applicants list'}
                        trade={trade}
                        estimated_start_date={start_date}
                        onActionClick={() => {
                          navigate(routerPath.projects.applicants.replace(':project_id', id));
                        }}
                        onClick={() => {
                          navigate(routerPath.projects.manageDetail.replace(':project_id', id), {
                            state: {
                              isFavorite: resultDataFave?.results
                                ?.map((e) => e?.project?.id)
                                .includes(id)
                            }
                          });
                        }}
                      />
                    );
                  }
                )}

              {!!resultData && resultData?.count === 0 && (
                <div className="empty-project">
                  <img src={IconHelmet} />
                  <p className="mt-2">
                    You have not posted the project yet. <br />
                    Let's start right now!
                  </p>
                  <button
                    className="btn btn-success mt-2"
                    onClick={() => {
                      navigate(routerPath.projects.postProject);
                    }}>
                    <img src={IconPlus} className="sub mr-1" />
                    Create Available Project
                  </button>
                </div>
              )}

              <Grid container justify="flex-end" className="mt-4">
                <Pagination
                  count={Math.ceil(resultData?.count / filter.limit)}
                  color="success"
                  variant="outlined"
                  onChange={(e, page) => {
                    setFilter({
                      ...filter,
                      offset: (page - 1) * filter.limit
                    });
                  }}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ManageAvailableProject;

import React from 'react';

import Images from 'assets/images/plumbing-with-different-tools.png';
import IconPlus from 'assets/plus.svg';

export default function ProjectPicture() {
  return (
    <>
      <div className="project-picture">
        <div className="picture-item">
          <div className="images-box">
            <img src={Images} alt="" />
          </div>
        </div>
        <div className="picture-item">
          <div className="images-box">
            <img src={Images} alt="" />
          </div>
        </div>
        <div className="picture-item">
          <div className="images-box">
            <img src={Images} alt="" />
          </div>
        </div>
        <div className="picture-item">
          <div className="images-box">
            <img src={Images} alt="" />
          </div>
        </div>
        <div className='pl-2'>
          <button className='btn btn-success ml-3'><img src={IconPlus} className='bottom mr-1' /> Edit Picture</button>
        </div>
      </div>

    </>
  )
}
import { postVerifyOTPRequest } from 'utils/calls';

export function handlePostVerifyOTPRequest(requestBody, cb_success, cb_error, cb_finally) {
  postVerifyOTPRequest(requestBody)
    .then(() => {
      cb_success();
    })
    .catch((err) => {
      cb_error(err);
    })
    .finally(() => cb_finally());
}

import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu'
import FooterDashboard from 'components/FooterLink/Dashboard'
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Box, Container, Grid, makeStyles } from '@mui/material';
import HeaderLogo from 'components/Logo/Homepage';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import iconPicture from 'assets/image-icon.svg'
import iconPdf from 'assets/pdf-icon.svg'
import IconPlus from 'assets/plus.svg';


const JobSeekerProfileOutsideEdit = () => {
    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const [picture, setPicture] = useState('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=')

    function uploadImage(e) {
        setPicture(URL.createObjectURL(e.target.files[0]));
    }
    return (
        <>
            <div className="header-second">
                <HeaderLogo />
            </div>
            <Container>
                <div className="content-inner-wrapper form-profile">
                    <h3 className="subtitle">Job Seeker Profile</h3>

                    <Grid container spacing={3}>
                        <Grid item md={10} className="mb-2">
                            <h4>Personal Information</h4>
                            <Grid container spacing={3}>
                                <Grid item md={6} className="mb-2">
                                    <label>Name:</label>
                                    <input type="text" className="form-control" placeholder='Enter name' />
                                </Grid>
                                <Grid item md={6} className="mb-2">
                                    <label>Age:</label>
                                    <input type="text" className="form-control" placeholder='Enter age' />
                                </Grid>
                            </Grid>
                            <label>Gender:</label>
                            <div>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="male" control={<Radio sx={{ pl: '0' }} />} label="Male" />
                                    <FormControlLabel value="female" control={<Radio sx={{ pl: '0' }} />} label="Female" />
                                </RadioGroup>
                            </div>
                            <h4>Location</h4>
                            <Grid container spacing={3}>
                                <Grid item md={6} className="mb-2">
                                    <label>City:</label>
                                    <input type="text" className="form-control" placeholder='Enter city' />
                                </Grid>
                                <Grid item md={6} className="mb-2">
                                    <label>Age:</label>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            sx={{
                                                height: '45px',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item md={6} className="mb-2">
                                    <label>ZIP Code:</label>
                                    <input type="text" className="form-control" placeholder='Enter city' />
                                </Grid>
                                <Grid item md={6} className="mb-2">
                                    <label>Travel distance:</label>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            sx={{
                                                height: '45px',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <h4>Language</h4>
                            <Grid container spacing={3}>
                                <Grid item md={6} className="mb-2">
                                    <label>Primary language:</label>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            sx={{
                                                height: '45px',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} className="mb-2">
                                    <label>Secondary Language:</label>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            sx={{
                                                height: '45px',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <h4>Uploads (Optional)</h4>
                            <div className="d-flex flex-wrap mb-3">
                                <div className="uploads-container mr-2">
                                    <input type="file" onChange={uploadImage} />
                                    {/* <img src={picture} alt="" className='upload-preview' /> */}
                                    <div className='uploads-label'>
                                        <img src={iconPicture} alt="" />
                                        <div>Upload Picture</div>
                                    </div>
                                </div>
                                <div className="uploads-container mr-2">
                                    <input type="file" onChange={uploadImage} />
                                    {/* <img src={picture} alt="" className='upload-preview' /> */}
                                    <div className='uploads-label'>
                                        <img src={iconPdf} alt="" />
                                        <div>Upload Resume</div>
                                    </div>
                                </div>
                            </div>
                            <h4>About Me</h4>
                            <Grid container spacing={3}>
                                <Grid item md={12} className="mb-2">
                                    <label>Description:</label>
                                    <textarea className='form-control' rows="10" placeholder='Enter text'></textarea>
                                </Grid>
                            </Grid>

                            <h4>Industry</h4>
                            <Grid container spacing={3}>
                                <Grid item md={10} className="mb-2">
                                    <label>Select all that Apply:</label>
                                    <div className="d-flex">
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check' />
                                            <label htmlFor='check'>Residential Construction</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check2' />
                                            <label htmlFor='check2'>Commercial Construction</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check3' />
                                            <label htmlFor='check3'>Civil Construction</label>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <h4>Profession</h4>
                            <Grid container spacing={2} className="mb-3">
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter profession' />
                                </Grid>
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter profession' />
                                </Grid>
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter profession' />
                                </Grid>
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter profession' />
                                </Grid>
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter profession' />
                                </Grid>
                                <Grid item md={4}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Enter profession' />
                                </Grid>
                            </Grid>

                            <h4>Trade Affiliation</h4>
                            <Grid container spacing={2}>
                                <Grid item md={12} className="mb-2">
                                    <div className="d-flex flex-wrap">
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check4' />
                                            <label htmlFor='check4'>Union</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check5' />
                                            <label htmlFor='check5'>Non Union</label>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <h4>Skill Set</h4>
                            <Grid container spacing={2}>
                                <Grid item md={12} className="mb-2">
                                    <div className="d-flex flex-wrap">
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check6' />
                                            <label htmlFor='check6'>New to the Industry</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check7' />
                                            <label htmlFor='check7'>Labor / Tender</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check8' />
                                            <label htmlFor='check8'>Apprentice</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check9' />
                                            <label htmlFor='check9'>Journeyman</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check10' />
                                            <label htmlFor='check10'>Foreman</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check11' />
                                            <label htmlFor='check11'>Superintendent</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check12' />
                                            <label htmlFor='check12'>Office Personel</label>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <h4>Education</h4>
                            <Grid container spacing={2}>
                                <Grid item md={12} className="mb-2">
                                    <div className="d-flex flex-wrap">
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check13' />
                                            <label htmlFor='check13'>High School</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check14' />
                                            <label htmlFor='check14'>Some College</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check15' />
                                            <label htmlFor='check15'>Associates Degree</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check16' />
                                            <label htmlFor='check16'>Bachelors Degree</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check17' />
                                            <label htmlFor='check17'>Graduate Degree</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check18' />
                                            <label htmlFor='check18'>PHD / Post Doctoral</label>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <h4>Experience</h4>
                            <Grid container spacing={2}>
                                <Grid item md={12} className="mb-2">
                                    <div className="d-flex flex-wrap">
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check19' />
                                            <label htmlFor='check19'>0-1 yrs</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check20' />
                                            <label htmlFor='check20'>1-3 yrs</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check21' />
                                            <label htmlFor='check21'>3-5 yrs</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check22' />
                                            <label htmlFor='check22'>5-10 yrs</label>
                                        </div>
                                        <div className='check-btn mr-1'>
                                            <input type="checkbox" id='check23' />
                                            <label htmlFor='check23'>10+ yrs</label>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <h4>Trade Licence / Certificates</h4>
                            <Grid container spacing={2} className="mb-3">
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                                <Grid item md={6}>
                                    <input type="text" className="form-control form-control-sm" placeholder='Trade licence / certificates info' />
                                </Grid>
                            </Grid>
                            <button className='btn btn-success mr-1'><img src={IconPlus} className='bottom mr-1' /> Finish</button>
                        </Grid>
                    </Grid>









                </div>

            </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(JobSeekerProfileOutsideEdit)
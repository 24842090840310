// import { combineReducers } from 'redux'

import counterSlice from 'store/slices/counterSlice';
import {
  reducer as AuthReducer,
  defaultState as authInitialState
} from 'store/reducers/auth/auth.reducer';
import {
  reducer as NewsReducer,
  defaultState as newsInitialState
} from 'store/reducers/news/news.reducer';
import { api } from './slices/apiSlice';
import { appSlice } from './slices/appSlice';

export const reducers = {
  [api.reducerPath]: api.reducer,
  app: appSlice.reducer,
  auth: AuthReducer,
  news: NewsReducer,
  counter: counterSlice
};

export const initialState = {
  auth: authInitialState,
  news: newsInitialState
};

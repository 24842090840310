import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
// import reduxReset from 'redux-reset'

import {reducers, initialState} from './Reducer'
import logger from 'redux-logger'
import {MakeStore, createWrapper, Context, HYDRATE} from 'next-redux-wrapper';
import { environment } from 'config/environment'
import { api } from './slices/apiSlice'

// import { DESTROY_SESSION } from './reducers/auth/auth.constant'

// let composeEnhancers = compose
const enhancers = [];
// const middleware = [thunk];

// if (process.env.NODE_ENV === 'development') {
//   const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

//   if (typeof devToolsExtension === 'function') {
//     enhancers.push(devToolsExtension());
//   }
// }

// const store = createStore(
//   reducers,
//   composeEnhancers(applyMiddleware(thunk))
// )

// redux persist config
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['news','auth', 'app','api']
}


const persistedReducer = persistCombineReducers(persistConfig, reducers)
// compose enchancer here and middleware
let middlewares = [thunk, api.middleware]

// if (environment.APP_ENV === 'local'){
//   middlewares = middlewares.concat(logger)
// }

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);

const store = createStore(persistedReducer, initialState, composedEnhancers)

const makeStore = context => store
export default () => {
  const initializeStore = () => {
    return createStore(persistedReducer, initialState, composedEnhancers)
  }
  const wrapper = createWrapper(makeStore, { debug: true })
  let persistor = persistStore(store)
  return { makeStore, persistor, initializeStore, wrapper, store }
}


// export default store

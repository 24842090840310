import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant';
import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_AUTH } from 'store/reducers/auth/auth.constant';
import { defaultState as defaultStateAuth } from 'store/reducers/auth/auth.reducer';
import { apiPostSignin } from 'api/login.api';
import { errorValidationHandler } from 'utils/errorHandler';
import { signInFirebase } from 'utils/firebase';

export const actionToProps = {
  actionPostSignIn: (payload) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    });
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'errors',
        value: []
      }
    });

    try {
      const { data } = await apiPostSignin(payload);
      const resp = await signInFirebase(payload.email, payload.password);

      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'authenticated',
          value: data.user
        }
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        } 
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });

      return data;
    } catch (error) {
      const errorHandlerData = errorValidationHandler(error?.response?.data || error);
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData?.alert
        }
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });

      return false;
    } finally {
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });
    }
  }
};

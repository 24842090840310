import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Loading from 'components/Loading/index';
import { connect, useDispatch } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as actionPostSignup } from 'store/reducers/auth/actions/actionPostSignup';
import { actionToProps as occupationAction } from 'store/reducers/news/actions/actionGetOccupations';
import { actionToProps as profileAction } from 'store/reducers/news/actions/actionGetProfile';
import TextField from '@mui/material/TextField';
import { ReactComponent as FacebookIcon } from 'assets/fb-logo.svg';
import { ReactComponent as GoogleIcon } from 'assets/google-logo.svg';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { Grid } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routerPath } from 'router/Routerlist';
import { Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import signupSchema from 'validations/signupSchema';
import PasswordField from 'components/input/PasswordField';
import InputField from 'components/input/InputField';
import { setAuth } from 'store/slices/appSlice';
import useCookie from 'react-use-cookie';
import { environment } from 'config/environment';

const SignUpComponent = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState(props.auth.formSignUp);
  const [currentPage, setCurrentPage] = useState('signup');
  const loginForm = useRef(null);
  const [userToken, setUserToken] = useCookie(environment.ACCESS_TOKEN_COOKIE, '0');

  const {
    history,
    news,
    actionPostSignUp,
    changeAuthAttribute,
    actionGetOccupation,
    actionGetProfile
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [verificationMessage, setVerificationMessage] = useState(false);

  const {
    handleSubmit: formHandleSubmit,
    formState: { errors: formErrors },
    setError,
    control,
    setValue
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(signupSchema)
  });

  const onSubmit = (data) => {
    setLoading(true);
    const userType = new URLSearchParams(location.search)
      .get('user-type')
      ?.split('-')
      ?.map((e) => e.charAt(0).toLocaleUpperCase() + e.slice(1));

    let formPayload = {};
    Object.keys(data).forEach((e) => {
      formPayload[e] = { value: data[e], error: false };
    });
    changeAuthAttribute({
      key: 'formSignUp',
      value: formPayload
    }).then(async () => {
      try {
        if (!userType) {
          throw {
            message: 'User Type is required!.'
          };
        }
        const success = await actionPostSignUp({ user_type: userType.join(' ') });
        if (success) {
          // let redirectLink = location.state?.from?.pathname || routerPath.jobs.alljob;
          let redirectLink = routerPath.jobs.alljob;
          setUserToken(success.token);

          const [profile, workLines] = await Promise.all([
            actionGetProfile({
              userId: success?.user?.id
            }),
            actionGetOccupation()
          ]);

          if (profile?.is_first_login) {
            redirectLink = routerPath.settings.editProfile;
          }
          dispatch(setAuth(success.token));
          setTimeout(() => {
            navigate(redirectLink);
          }, 300);
        }
        // if (success) {
        //   navigate(routerPath.homepage.root);
        // }
      } catch (error) {
        dispatch({
          type: CHANGE_ATTRIBUTE,
          payload: {
            key: 'errors',
            value: [error.message]
          }
        });
      }
    });
    // changeAuthAttribute({
    //   key: 'formSignUp',
    //   value: formPayload
    // }).then(async () => {
    //   try {
    //     navigate(routerPath.auth.signupType, { state: { formPayload: formPayload } });
    //   } catch (error) {
    //     dispatch({
    //       type: CHANGE_ATTRIBUTE,
    //       payload: {
    //         key: 'errors',
    //         value: [error.message]
    //       }
    //     });
    //   } finally {
    //     setLoading(false);
    //   }
    // });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const userType = new URLSearchParams(location.search)
      .get('user-type')
      ?.split('-')
      ?.map((e) => e.charAt(0).toLocaleUpperCase() + e.slice(1));
    let payload = {
      firstname: loginForm.current.querySelector('input#firstname').value,
      lastname: loginForm.current.querySelector('input#lastname').value,
      email: loginForm.current.querySelector('input#email').value,
      password: loginForm.current.querySelector('input#password').value,
      confirm_password: loginForm.current.querySelector('input#confirm-password').value
    };

    if (!checkConfirmPassword()) {
      setLoading(false);
      return false;
    }

    let validateBlank = {};
    Object.keys(payload).map((e) => {
      if (payload[e] === '') {
        validateBlank[e] = { error: `${e} can't be blank!` };
      }
    });
    if (Object.keys(validateBlank).length > 0) {
      setLoading(false);
      setErrors({
        ...errors,
        ...validateBlank
      });
      return false;
    }
    const emailValidation = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
    if (!emailValidation.test(payload.email)) {
      setErrors({
        ...errors,
        email: { error: 'email invalid' }
      });
      setLoading(false);
      return false;
    }
    setLoading(false);

    // const { confirm_password, ...clearPayload} = payload
    let formPayload = {};
    Object.keys(payload).map((e) => {
      formPayload[e] = { value: payload[e], error: false };
    });
    changeAuthAttribute({
      key: 'formSignUp',
      value: formPayload
    }).then(async () => {
      try {
        if (!userType) {
          throw {
            message: 'User Type is required!.'
          };
        }
        const success = await actionPostSignUp({ user_type: userType.join(' ') });
        if (success) {
          // let redirectLink = location.state?.from?.pathname || routerPath.jobs.alljob;
          let redirectLink = routerPath.jobs.alljob;
          setUserToken(success.token);

          const [profile, workLines] = await Promise.all([
            actionGetProfile({
              userId: success?.user?.id
            }),
            actionGetOccupation()
          ]);

          if (profile?.is_first_login) {
            redirectLink = routerPath.settings.editProfile;
          }
          dispatch(setAuth(success.token));
          setTimeout(() => {
            navigate(redirectLink);
          }, 300);
        }
        // if (success) {
        //   navigate(routerPath.homepage.root);
        // }
      } catch (error) {
        dispatch({
          type: CHANGE_ATTRIBUTE,
          payload: {
            key: 'errors',
            value: [error.message]
          }
        });
      }
    });
  };

  const checkConfirmPassword = () => {
    setErrors({
      ...errors,
      confirm_password: { error: false }
    });
    let payload = {
      password: loginForm.current.querySelector('input#password').value,
      confirm_password: loginForm.current.querySelector('input#confirm-password').value
    };

    if (payload.password !== payload.confirm_password) {
      setErrors({
        ...errors,
        confirm_password: { error: 'password does not match' }
      });
      return false;
    }
    setErrors({
      ...errors,
      confirm_password: { error: false }
    });

    return true;
  };

  const hanleSuccesSignup = () => {
    navigate(routerPath.homepage.root);
    setVerificationMessage(false);
    console.log('workign!!');
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={`login`}>
        <div className="text-left title">
          <h1>Create New Account</h1>
        </div>
        <Typography></Typography>

        <form ref={loginForm} noValidate onSubmit={handleSubmit} className="form-input">
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {/* <Grid item xs={6}>
              <TextField
                id="firstname"
                label="First Name"
                placeholder="First Name"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                value={errors.firstname?.value}
                onChange={() => setErrors({ ...errors, firstname: false })}
                error={errors.firstname?.error ? true : false}
                helperText={errors.firstname?.error ? errors.firstname?.error : false}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 10 }}>
              <TextField
                id="lastname"
                label="Last Name"
                placeholder="Last Name"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                value={errors.lastname?.value}
                onChange={() => setErrors({ ...errors, lastname: false })}
                error={errors.lastname?.error ? true : false}
                helperText={errors.lastname?.error ? errors.lastname.error : false}
              />
            </Grid> */}
          </Grid>
          {/* <TextField
            id="email"
            label="Email"
            placeholder="Email Address"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            value={errors.email.value}
            onChange={() => setErrors({ ...errors, email: false })}
            error={errors.email.error ? true : false}
            helperText={errors.email.error ? errors.email.error : false}
          />
          <TextField
            id="password"
            label="Create Password"
            placeholder="Password"
            type="password"
            fullWidth
            variant="outlined"
            helperText="Password must be at least 8 character"
            InputLabelProps={{
              shrink: true
            }}
            onChange={() => setErrors({ ...errors, password: false })}
            error={errors.password.error ? true : false}
            helperText={errors.password.error ? errors.password.error : false}
          />
          <TextField
            id="confirm-password"
            label="Confirm Password"
            placeholder="Confirm Password"
            type="password"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            onChange={() => checkConfirmPassword()}
            error={errors.confirm_password.error ? true : false}
            helperText={errors.confirm_password.error ? errors.confirm_password.error : false}
          /> */}
          <InputField
            control={control}
            errors={formErrors}
            label={'Email'}
            param={'email'}
            type={'text'}
            marginTop={1}
            disabled={isLoading}
            placeholder={'Email'}
          />
          <PasswordField
            control={control}
            errors={formErrors}
            label={'Create Password'}
            param={'password'}
            disabled={isLoading}
            showError={false}
          />
          <Typography fontSize={'12px'} mt={-2} mb={2}>
            Your password should be at least 8 characters long
          </Typography>
          <PasswordField
            control={control}
            errors={formErrors}
            label={'Confirm Password'}
            param={'confirm_password'}
            disabled={isLoading}
          />
          <Button
            // type="submit"
            onClick={formHandleSubmit(onSubmit)}
            fullWidth
            variant="contained"
            className="main-dark font-poppins text-transform-capitalize">
            Create Account
          </Button>
          <div className="text-center fontsize-tnc tnc-box">
            <div className="font-poppins color-secondary-text">
              By continuing, you're confirming that you've read and agree to our{' '}
              <Link to={routerPath.footer.terms}>
                <span>Terms and Conditions</span>
              </Link>{' '}
              and{' '}
              <Link to={routerPath.footer.privacy}>
                <span>Privacy Policy</span>
              </Link>
            </div>
          </div>
          {/* <div className="text-center optional-login">
            <div className="font-poppins color-main-dark text-uppercase">or</div>
          </div> */}
          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            startIcon={<FacebookIcon />}
            className="blue-google color-white font-poppins text-transform-capitalize">
            Sign In via Facebook
          </Button> */}
          {/* <br /> */}
          {/* <br /> */}
          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            startIcon={<GoogleIcon />}
            className="main-white font-poppins text-transform-capitalize">
            Sign In via Google
          </Button> */}
        </form>
      </div>
      {/* <Loading open={isLoading} /> */}
      {verificationMessage && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: `rgba(192, 192, 192, 0.3)`,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              backgroundColor: 'white',
              borderRadius: '10px',
              padding: '30px',
              height: '250px',
              width: '400px',
              position: 'relative'
            }}>
            <h3
              style={{
                fontSize: '18px',
                color: '#333333',
                fontWeight: 500
              }}>
              Verification message
            </h3>
            <p
              style={{
                fontSize: '14px',
                color: '#757575',
                fontWeight: 400
              }}>
              Your verification OTP has been sent to <br /> your email address
            </p>

            <div
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '2px',
                position: 'absolute',
                right: '20px',
                top: '20px',
                cursor: 'pointer'
              }}
              onClick={() => hanleSuccesSignup()}>
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.54"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14 1.88047L12.6 0.480469L7 6.08047L1.4 0.480469L0 1.88047L5.6 7.48047L0 13.0805L1.4 14.4805L7 8.88047L12.6 14.4805L14 13.0805L8.4 7.48047L14 1.88047Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = {
  ...authAction,
  ...actionPostSignup,
  ...profileAction,
  ...occupationAction
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUpComponent);

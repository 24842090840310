import { Button, FormControl, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import React, { memo, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant';
import { deleteUserProfessionRequest } from 'utils/calls';

function Professions({ professions, setProfessions, userProfessionMutate, fetchProfile }) {
  const dispatch = useDispatch();

  const handleProfessions = (id, value) => {
    setProfessions((prev) => {
      return prev.map((item) => {
        if (String(item.id) === String(id)) {
          return { ...item, profession: value };
        } else {
          return item;
        }
      });
    });
  };

  const handleDeleteProfession = (id) => {
    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    });

    deleteUserProfessionRequest(id).finally(() => {
      // profileMutate();
      fetchProfile();
      userProfessionMutate();
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });
    });
  };

  return (
    <Grid container mt={4} spacing={2}>
      <Grid item xs={12} className="mb-2">
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          Profession
        </Typography>
      </Grid>
      {professions.map((item) => (
        <Grid item xs={6} key={item.id}>
          <Profession
            key={item.id}
            item={item}
            handleChange={handleProfessions}
            handleDeleteProfession={handleDeleteProfession}
          />
        </Grid>
      ))}
    </Grid>
  );
}

const Profession = memo(({ item, handleChange, handleDeleteProfession }) => {
  const [isEdit, setEdit] = useState(false);

  const isNew = useMemo(() => (String(item.id).startsWith('tmp') ? true : false), [item?.id]);
  const error = useMemo(
    () => (!isNew && item.profession.trim() === '' ? "Empty isn't allowed" : undefined),
    [item]
  );

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={2}>
      {isEdit || isNew ? (
        <FormControl error={error} sx={{ flexGrow: 1 }}>
          <input
            type="text"
            value={item.profession}
            onChange={(e) => handleChange(item.id, e.target.value)}
            className="form-control form-control-sm"
            placeholder="Profession"
          />
          <FormHelperText id="component-error-text">{error}</FormHelperText>
        </FormControl>
      ) : (
        <Typography flexGrow={1}>{item.profession}</Typography>
      )}
      {!isNew && (
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Button onClick={() => setEdit((prev) => !prev)}>{isEdit ? 'Cancel' : 'Edit'}</Button>
          <Button color="error" onClick={() => handleDeleteProfession(item.id)}>
            Delete
          </Button>
        </Stack>
      )}
    </Stack>
  );
});

export default Professions;

import { useRef, useState } from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';

import Container from '@mui/material/Container';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import Loading from 'components/Loading/index';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routerPath } from 'router/Routerlist';
import { actionToProps } from 'store/reducers/auth/actions/actionPostSignup';
import { actionToProps as occupationAction } from 'store/reducers/news/actions/actionGetOccupations';
import { actionToProps as profileAction } from 'store/reducers/news/actions/actionGetProfile';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { setAuth } from 'store/slices/appSlice';
import useCookie from 'react-use-cookie';
import { environment } from 'config/environment';

const SignIn = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [active, setActive] = useState('');
  const loginForm = useRef(null);
  const [userToken, setUserToken] = useCookie(environment.ACCESS_TOKEN_COOKIE, '0');
  const {
    history,
    actionPostSignUp,
    changeAuthAttribute,
    formData,
    actionGetOccupation,
    actionGetProfile
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmitApiSignUp = async (e) => {
    e.preventDefault();
    changeAuthAttribute({
      key: 'formSignUp',
      value: formData
    }).then(async () => {
      try {
        if (active === '') {
          return false;
        }
        const success = await actionPostSignUp({ user_type: active });

        if (success) {
          // let redirectLink = location.state?.from?.pathname || routerPath.jobs.alljob;
          let redirectLink = routerPath.jobs.alljob;
          setUserToken(success.token);

          const [profile, workLines] = await Promise.all([
            actionGetProfile({
              userId: success?.user?.id
            }),
            actionGetOccupation()
          ]);
          console.log({
            profile,
            workLines
          });

          if (profile?.is_first_login) {
            redirectLink = routerPath.settings.editProfile;
          }
          dispatch(setAuth(success.token));
          setTimeout(() => {
            navigate(redirectLink);
          }, 300);
        }
        // console.log(success, 'success');
        // if (success) {
        //   navigate(routerPath.homepage.root);
        // }
      } catch (error) {
        dispatch({
          type: CHANGE_ATTRIBUTE,
          payload: {
            key: 'errors',
            value: [error.message]
          }
        });
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className="login">
        <div className="text-center title almost-done">
          <h1>
            <div className="back cursor-pointer" onClick={() => navigate(routerPath.auth.signup)}>
              <ArrowLeft />
            </div>
            Almost Done...
          </h1>
          <span>You are...</span>
        </div>
        <div className="user-type">
          <div
            onClick={() => setActive('classified_only')}
            className={`title hide type-co ${active === 'classified_only' ? 'active' : ''}`}>
            Classified Only
          </div>
          <div
            onClick={() => setActive('Sub Contractor')}
            className={`title  type-sc ${active === 'Sub Contractor' ? 'active' : ''}`}>
            Sub Contractor
          </div>
          <div
            onClick={() => setActive('2nd Tier Subcontractor')}
            className={`title  type-2tier ${active === '2nd Tier Subcontractor' ? 'active' : ''}`}>
            2 Tier Contractor
          </div>
          <div
            onClick={() => setActive('individual')}
            className={`title hide type-id ${active === 'individual' ? 'active' : ''}`}>
            Individule
          </div>
          <div
            onClick={() => setActive('General Contractor')}
            className={`title type-gc ${active === 'General Contractor' ? 'active' : ''}`}>
            General Contractor
          </div>
          <div
            onClick={() => setActive('Recruiter')}
            className={`title type-vd ${active === 'Recruiter' ? 'active' : ''}`}>
            Recruiter
          </div>
          <div
            onClick={() => setActive('Job Seeker')}
            className={`title type-id ${active === 'Job Seeker' ? 'active' : ''}`}>
            Job Seeker
          </div>
        </div>

        <form ref={loginForm} noValidate onSubmit={handleSubmitApiSignUp} className="form-input">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="main-dark font-poppins text-transform-capitalize">
            Done
          </Button>
        </form>
      </div>
      <Loading open={isLoading} />
    </Container>
  );
};
const mapStateToProps = null;
const mapDispatchToProps = {
  ...authAction,
  ...actionToProps,
  ...profileAction,
  ...occupationAction
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

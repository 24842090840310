import { Grid, Typography } from '@mui/material';
import React from 'react';
import ControlledSelect from 'components/input/ControlledSelect';
import { HandleGetLanguagesRequest } from 'api/callsHandler';

function Language({ control, errors, loading }) {
  const { data: languagesData } = HandleGetLanguagesRequest();

  return (
    <Grid container mt={4} columnSpacing={2}>
      <Grid item xs={12} className="mb-2">
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          Language
        </Typography>
      </Grid>

      <Grid item md={6} className="mb-2">
        <ControlledSelect
          control={control}
          errors={errors}
          label={'Primary language'}
          param={'language'}
          arrayRecrods={
            languagesData?.data?.results?.map((record) => ({
              value: record.id,
              label: record.name
            })) ?? []
          }
          marginTop={1}
          disabled={loading}
        />
      </Grid>
      <Grid item md={6} className="mb-2">
        <ControlledSelect
          control={control}
          errors={errors}
          label={'Secondary language'}
          param={'secondary_language'}
          arrayRecrods={
            languagesData?.data?.results?.map((record) => ({
              value: record.id,
              label: record.name
            })) ?? []
          }
          marginTop={1}
          disabled={loading}
        />
      </Grid>
    </Grid>
  );
}

export default Language;

import { FormControl, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';
import CustomSelect from './CustomSelect';

function ControlledSelect({
  control,
  errors,
  label,
  param,
  marginTop = 3,
  disabled = false,
  arrayRecrods,
  handleChange,
  styleText
}) {
  return (
    <Controller
      name={param}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl error={errors?.[param]} variant="standard" sx={{ width: '100%' }}>
          <CustomSelect
            recordValue={value}
            arrayRecrods={arrayRecrods}
            disabled={disabled}
            marginTop={marginTop}
            onChange={onChange}
            label={label}
            handleChange={handleChange}
            styleText={styleText}
          />
          <FormHelperText id="component-error-text">{errors?.[param]?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default ControlledSelect;

import moment from 'moment'

export const dateDiff = (start, end) => {
  return moment(end).diff(start, "days");
};

export const timeDiff = (start, time = "hours") => {
  return moment().diff(start, time) || 0;
};

export const getRelativeTime = (date) => {
  const hasDiff = (diff) => diff > 0;

  const dayDiff = dateDiff(date);
  const inADay = dayDiff < 1;
  if (inADay) {
    const hour = timeDiff(date, "hours");
    if (hasDiff(hour)) return `${hour} hours ago`;
    const min = timeDiff(date, "minutes");
    if (hasDiff(min)) return `${min} minutes ago`;
    const sec = timeDiff(date, "seconds");
    if (hasDiff(sec)) return `${sec} seconds ago`;
    return "0 seconds ago";
  }
  if (dayDiff === 1) return "yesterday";
  if (dayDiff < 7) return `${dayDiff} days ago`;
  if (dayDiff === 7) return "1 weeks ago";

  return moment(date).format('ll');
};
import { FormControl, Grid, RadioGroup, Stack, Typography } from '@mui/material';
import React from 'react';
import { YES_NO_ARRAY } from 'constant';
import { Controller } from 'react-hook-form';
import ControlledRadio from 'components/input/ControlledRadio';

function ConstructionBond({ control, errors }) {
  return (
    <Grid container mt={4}>
      <Grid item xs={12}>
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          Construction Bond
        </Typography>
      </Grid>
      <Grid item>
        <Controller
          name={'construction_bond'}
          control={control}
          render={({ field: { value } }) => (
            <FormControl fullWidth>
              <RadioGroup value={value}>
                <Stack direction={'row'} alignItems={'center'}>
                  {YES_NO_ARRAY.map(({ title, id }, idx) => (
                    <ControlledRadio
                      key={id}
                      id={id}
                      control={control}
                      errors={errors}
                      label={title}
                      param={'construction_bond'}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default ConstructionBond;

import { FormControl, Grid, RadioGroup, Stack, Typography } from '@mui/material';
import ControlledRadio from 'components/input/ControlledRadio';
import InputField from 'components/input/InputField';
import { MALE_FEMALE } from 'constant';
import React from 'react';
import { Controller } from 'react-hook-form';

function PersonalInformation({ control, errors, loading }) {
  return (
    <Grid container mt={6} columnSpacing={2}>
      <Grid item xs={12} className="mb-2">
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          Personal Information
        </Typography>
      </Grid>

      <Grid item xs={6} className="">
        <InputField
          control={control}
          errors={errors}
          label={'Name:'}
          param={'name'}
          type={'text'}
          marginTop={1}
          placeholder={'Enter name'}
          disabled={loading}
        />
      </Grid>
      <Grid item md={6} className="mb-2">
        <InputField
          control={control}
          errors={errors}
          label={'Age:'}
          param={'age'}
          marginTop={1}
          placeholder={'Enter age'}
          disabled={loading}
        />
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} className="pl-2">
          <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
            Gender:
          </Typography>
        </Grid>
        <Grid item className="pl-1">
          <Controller
            name={'gender'}
            control={control}
            render={({ field: { value } }) => (
              <FormControl fullWidth>
                <RadioGroup value={value}>
                  <Stack direction={'row'} alignItems={'center'}>
                    {MALE_FEMALE.map(({ title, id }, idx) => (
                      <ControlledRadio
                        key={id}
                        id={id}
                        control={control}
                        errors={errors}
                        label={title}
                        param={'gender'}
                      />
                    ))}
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PersonalInformation;

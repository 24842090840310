import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './ProtectedRoutes';
import { routerPath } from './Routerlist';
import Profilepreference from 'views/Profile/profilepreference';
import Signin from 'views/Signin';
import SignUp from 'views/Signup';
import SignUpType from 'views/SignUpType';
import Projects from 'views/Projects';
import JobSeekerProfile from 'views/JobSeeker/profile';
import JobSeekerHome from 'views/JobSeeker/homepage';
import ProfileJobSeekerSettings from 'views/JobSeeker/settings';
import AllJob from 'views/Job/allJob';
import ProjectDetail from 'views/JobSeeker/projectdetail';
import ProjectDetailView from 'views/Projects/projectdetail';
import JobSeekerFavorite from 'views/JobSeeker/favorite';
import JobSeekerSearchProject from 'views/JobSeeker/searchproject';
import JobDetail from 'views/JobSeeker/jobdetail';
import UserProfile from 'views/User/profile';
import JobSeekerProfileOutside from 'views/JobSeeker/profileoutside';
import SearchProject from 'views/Projects/searchProject';
import JobdetailView from 'views/Job/jobdetail';
import JobSeekerProfileOutsideEdit from 'views/JobSeeker/profileoutsideedit';
import PostJob from 'views/JobSeeker/postjob';
import CompanyContact from 'views/JobSeeker/companycontact';
import Tokeninput from 'views/Tokeninput';
import ContractorProfile from 'views/JobSeeker/contractorprofile';
import AvailableProject from 'views/JobSeeker/availableproject';
import SubcontractorProfile from 'views/JobSeeker/subcontractorprofile';
import TierSubcontractorProfile from 'views/JobSeeker/tiersubcontractorprofile';
import EditGeneralContractorProfile from 'views/Profile/editGeneralContractorProfile';
import JobApplicants from 'views/JobSeeker/jobapplicants';
// import PrivacyPolicy from 'views/JobSeeker/privacypolicy';
// import Terms from 'views/JobSeeker/terms';
import PrivacyPolicy from 'views/Privacy';
import Terms from 'views/Terms';
import JobPostingRule from 'views/JobSeeker/jobpostingrule';
import ProfileSettings from 'views/Settings';
import Messages from 'views/Messages';
import PostMyJob from 'views/Job/post-job';
import JobApplicantsView from 'views/Job/applicants';
import AvailableProjectCreation from 'views/Projects/post-project';
import ManageAvailableProject from 'views/Projects/myproject';
import ProjectApplicants from 'views/Projects/applicants';
import Myjob from 'views/Job/myjob';
import ViewProfile from 'views/Profile/viewProfile';
import FavoritePage from 'views/Favorite';
import PostJobRecruiter from 'views/Recruiter/post-job';
import PostJobFeatureRecruiter from 'views/Recruiter/post-job-feature';
import CreatedJobRecruiter from 'views/Recruiter/created-job';
import JobAds from 'views/Subcontractor/job-ads';
import ProjectAds from 'views/Subcontractor/project-ads';
import AvailableProjectCre from 'views/Contractor/available-project';
import CreatedProject from 'views/Contractor/created-project';
import PostingRules from 'views/Job/posting-rules';
import NotVerifiedRoutes from 'router/NotVerifiedRoutes';

export const RootRouter = () => (
  <Routes>
    <Route path={routerPath.footer.privacy} element={<PrivacyPolicy />} />
    <Route path={routerPath.footer.terms} element={<Terms />} />
    <Route element={<PublicRoutes />}>
      <Route path="/" element={<Signin />} />
      <Route path={routerPath.auth.signupType} element={<SignUpType />} />
      <Route path={routerPath.auth.signin} element={<SignUp />} />
      <Route path={routerPath.auth.signup} element={<SignUp />} />
      <Route path={routerPath.jobseeker.privacypolicy} element={<PrivacyPolicy />} />
      <Route path={routerPath.jobseeker.terms} element={<Terms />} />
      {/* <Route path={routerPath.footer.privacy} element={<PrivacyPolicy />} />
      <Route path={routerPath.footer.terms} element={<Terms />} /> */}
    </Route>
    <Route element={<NotVerifiedRoutes />}>
      <Route path={routerPath.auth.tokenInput} element={<Tokeninput />} />
      {/* <Route path={routerPath.footer.privacy} element={<PrivacyPolicy />} />
      <Route path={routerPath.footer.terms} element={<Terms />} /> */}
    </Route>
    <Route element={<PrivateRoutes />}>
      {/* <Route path={routerPath.footer.privacy} element={<PrivacyPolicy />} />
      <Route path={routerPath.footer.terms} element={<Terms />} /> */}
      <Route path={routerPath.settings.profilepreference} element={<Profilepreference />} />
      <Route path={routerPath.projects.search} element={<SearchProject />} />
      <Route path={routerPath.projects.detail} element={<ProjectDetailView />} />
      <Route path={routerPath.projects.manageDetail} element={<ProjectDetailView />} />
      <Route path={routerPath.projects.manageProject} element={<ManageAvailableProject />} />
      <Route path={routerPath.projects.applicants} element={<ProjectApplicants />} />

      <Route path={routerPath.jobs.alljob} element={<AllJob />} />
      <Route path={routerPath.jobs.detail} element={<JobdetailView />} />
      <Route path={routerPath.jobs.manageDetail} element={<JobdetailView />} />
      <Route path={routerPath.jobs.createjob} element={<PostMyJob />} />
      <Route path={routerPath.jobs.applicants} element={<JobApplicantsView />} />
      <Route path={routerPath.projects.applicants} element={<JobApplicantsView />} />
      <Route path={routerPath.jobs.manageJob} element={<Myjob />} />
      {/* // new user profile */}
      <Route path={routerPath.users.usersprofiles} element={<UserProfile />} />

      <Route path={routerPath.applicants.view} element={<ViewProfile />} />

      <Route path={routerPath.settings.index} element={<ProfileSettings />} />
      <Route path={routerPath.settings.companyContactInformation} element={<CompanyContact />} />
      <Route path={routerPath.settings.editProfile} element={<EditGeneralContractorProfile />} />

      <Route path={routerPath.messages.index} element={<Messages />} />
      <Route path={routerPath.messages.chatRoomPrivate} element={<Messages />} />
      <Route path={routerPath.messages.chatRoomDetail} element={<Messages />} />
      <Route path={routerPath.messages.chatRoomProjectDetail} element={<Messages />} />

      <Route path={routerPath.projects.postProject} element={<AvailableProjectCreation />} />

      <Route path={routerPath.favorite.view} element={<FavoritePage />} />

      <Route path={routerPath.jobseeker.home} element={<JobSeekerHome />} />
      <Route path={routerPath.jobseeker.profile} element={<JobSeekerProfile />} />
      <Route path={routerPath.jobseeker.settings} element={<ProfileJobSeekerSettings />} />
      <Route path={routerPath.jobseeker.projectdetail} element={<ProjectDetail />} />
      <Route path={routerPath.jobseeker.seekerfavorite} element={<JobSeekerFavorite />} />
      <Route path={routerPath.jobseeker.seekersearch} element={<JobSeekerSearchProject />} />
      <Route path={routerPath.jobseeker.seekerjobdetail} element={<JobDetail />} />
      <Route path={routerPath.jobseeker.profileoutside} element={<JobSeekerProfileOutside />} />
      <Route
        path={routerPath.jobseeker.profileoutsideedit}
        element={<JobSeekerProfileOutsideEdit />}
      />
      <Route path={routerPath.jobseeker.postjob} element={<PostJob />} />
      <Route path={routerPath.jobseeker.contractorprofile} element={<ContractorProfile />} />
      <Route path={routerPath.jobseeker.availableproject} element={<AvailableProject />} />
      <Route path={routerPath.jobseeker.subcontractorprofile} element={<SubcontractorProfile />} />
      <Route
        path={routerPath.jobseeker.tiersubcontractorprofile}
        element={<TierSubcontractorProfile />}
      />
      <Route path={routerPath.jobseeker.jobapplicants} element={<JobApplicants />} />
      <Route path={routerPath.jobseeker.jobpostingrule} element={<JobPostingRule />} />

      <Route path={routerPath.recruiter.postjob} element={<PostJobRecruiter />} />
      <Route path={routerPath.recruiter.postjobfeature} element={<PostJobFeatureRecruiter />} />
      <Route path={routerPath.recruiter.createdjob} element={<CreatedJobRecruiter />} />

      <Route path={routerPath.subcontractor.jobads} element={<JobAds />} />
      <Route path={routerPath.subcontractor.projectads} element={<ProjectAds />} />

      <Route
        path={routerPath.contractor.availableprojectcreation}
        element={<AvailableProjectCre />}
      />
      <Route path={routerPath.contractor.createdproject} element={<CreatedProject />} />
      <Route path={routerPath.jobs.postingrules} element={<PostingRules />} />
    </Route>
  </Routes>
);

import React, { useEffect, useMemo, useState } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { useDispatch } from 'react-redux';
import { Box, Grid, Stack, TextField } from '@mui/material';
import IconPlus from 'assets/plus.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import {
  useGetProjectCategoriesQuery,
  useGetProjectSubCategoriesQuery,
  usePostAddCardMutation,
  usePostCreateProjectMutation,
  useGetLocationCityAndStateMutation,
  usePostPaymentChargeMutation,
  useUpdateMyProjectMutation
} from 'store/slices/apiSlice';
import UploadImage from 'components/UploadDropZone/Upload';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { routerPath } from 'router/Routerlist';
import { useNavigate } from 'react-router-dom';
import { errorValidationHandler } from 'utils/errorHandler';
import moment from 'moment';
import { POST_TYPE, terms, COUNTRY_ID } from 'constant';
import PricingList from 'components/Job/PricingList';
import FeaturedJobList from 'components/Job/FeaturedJobList';
import ChargePaymentComponent from 'components/Payment/charge';
import { HandleGetAllCardsRequest } from 'api/callsHandler';
import stateData from 'constant/state.json';
import cityData from 'constant/city.json';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from 'react-google-places-autocomplete';
import { memo } from 'react';
import axios from 'axios';

const postProjectSteps = ['post-submit', 'post-price', 'post-featured', 'charge-project'];

const AvailableProjectCreation = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = React.useState(postProjectSteps[0]);
  const [errorPrice, setErrorPrice] = useState(false);
  const [alertCatgroy, setAlertCategory] = useState('');
  const [value, setValue] = useState(null);
  const [position, setPosition] = useState({ lat: 40.7128, lng: -74.006 });
  const [marketPosition, setMarketPosition] = useState({ lat: 40.7128, lng: -74.006 });
  const { data: projectCategories } = useGetProjectCategoriesQuery();
  const [isTba, setIsTba] = useState(false);
  const [projectData, setProjectData] = useState(null);

  const [filterLocation, setFilterLocation] = useState({
    city: '',
    state: ''
  });
  console.log(filterLocation, 'filterLocation');
  const [disableState, setDisableState] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [locationInfo, setLocationInfo] = useState({ state: '', city: '' });
  // const position = { lat: 53.54992, lng: 10.00678 };
  useEffect(() => {
    if (value) {
      geocodeByPlaceId(value.value.place_id).then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          setPosition({
            lat: lat,
            lng: lng
          });
          setMarketPosition({
            lat: lat,
            lng: lng
          });
        });
      });
    }
  }, [value]);

  const [payload, setPayload] = useState({
    files: [],
    photos: [],
    trade: null,
    start_date: new Date(),
    isAgreeRule: false,
    sub_id: undefined,
    addon_id: undefined,
    coupon: {}
  });

  const [
    postCreateProject,
    {
      data: projectCreationData,
      isLoading,
      isError: isErrorProjectCreated,
      errorProjectCreated,
      isSuccess: isSuccessProjectCreated
    }
  ] = usePostCreateProjectMutation();

  const [updateProject] = useUpdateMyProjectMutation();

  const [
    postAddCard,
    {
      data: addCardData,
      isSuccess: isSuccessAddCard,
      error: addCardError,
      isError: isErrorAddCard,
      isLoading: isLoadingAddCard
    }
  ] = usePostAddCardMutation();

  const {
    data: allCardsData,
    isLoading: isLoadingGetAllCards,
    mutate: refetchGetAllCards
  } = HandleGetAllCardsRequest();

  const isProjectSaved = useMemo(
    () =>
      projectCreationData?.id &&
      typeof projectCreationData?.id === 'number' &&
      projectCreationData?.id > 0
        ? true
        : false,
    [projectCreationData]
  );

  const [
    postPaymentCharge,
    {
      isSuccess: isSuccessPaymentCharge,
      error: paymentChargeError,
      isError: isErrorPaymentCharge,
      isLoading: isLoadingPaymentCharge
    }
  ] = usePostPaymentChargeMutation();

  const { data: projectSubCategory } = useGetProjectSubCategoriesQuery();

  useEffect(() => {
    if (projectSubCategory) {
      // // debugger;
    }
  }, projectSubCategory);

  const handleUploadFile = (images) => {
    setPayload({
      ...payload,
      files: images
    });
  };
  const handleUploadPhoto = (images) => {
    setPayload({
      ...payload,
      photos: images
    });
  };

  const handleChangeCheckbox = (event) => {
    // let selectedValues = payload.trade
    // if (!selectedValues.includes(event.target.value)) {
    //   selectedValues = selectedValues.concat(event.target.value)
    // }
    // else {
    //   selectedValues.splice(selectedValues.findIndex(e => e === event.target.value),1)
    // }
    setPayload({
      ...payload,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeDate = (newValue) => {
    setPayload({
      ...payload,
      start_date: newValue
    });
  };

  const initialValues = {
    name: '',
    description: '',
    price: '',
    term: ''
    // trade: payload.category
    //   deadline: '',
  };

  const validationSchema = yup.object({
    name: yup.string().required('Required'),
    description: yup.string().required('Required'),
    price: yup.number().typeError('Must be a number').notRequired(),
    term: yup.string().required('Required')
    // category: yup.number().required('Required')

    //   deadline: yup.string().required('Required'),
  });
  const onSubmitData = async (values) => {
    // // debugger;
    if (isProjectSaved) {
      setActivePage(postProjectSteps[postProjectSteps.findIndex((d) => d === 'post-price')]);
    } else if (!payload.trade) {
      setAlertCategory('Required');
    } else {
      let bodyPayload = {
        ...values,
        ...payload,
        city: filterLocation?.city?.id,
        state: filterLocation?.state?.id,
        lat: filterLocation.city?.latitude,
        long: filterLocation.city?.longitude,
        //   applicants: [],
        start_date: moment(payload.start_date).format('YYYY-MM-DD')
        //   applicants: 2
      };

      // const { applicants, ...clearPayload } = bodyPayload

      // postCreateJob(clearPayload)
      // console.log(bodyPayload, 'body__payload');

      const res = await postCreateProject(bodyPayload);
      setProjectData(res?.data);
    }
  };

  // console.log(payload.trade, 'trade');

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitData
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value: isLoading || isLoadingPaymentCharge || isLoadingAddCard || isLoadingGetAllCards
      }
    });
  }, [isLoading, isLoadingPaymentCharge, isLoadingAddCard, isLoadingGetAllCards]);

  useEffect(() => {
    if (isErrorProjectCreated) {
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });
      const message = errorValidationHandler(errorProjectCreated?.data)?.alert || [
        'Something went wrong'
      ];
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: message
        }
      });
    }
  }, [isErrorProjectCreated]);

  useEffect(() => {
    if (isSuccessPaymentCharge) {
      navigate(routerPath.projects.search);
    }
  }, [isSuccessPaymentCharge]);

  useEffect(() => {
    if (isSuccessProjectCreated) {
      setActivePage(postProjectSteps[postProjectSteps.findIndex((d) => d === 'post-price')]);
    }
  }, [isSuccessProjectCreated, postProjectSteps, setActivePage]);

  const onContinuePage = async () => {
    if (activePage === postProjectSteps[1] && !payload?.isAgreeRule) {
      setErrorPrice(['Please check that you agree on rules for job posting']);
      return false;
    }

    if (activePage === postProjectSteps[1] && !payload?.sub_id) {
      setErrorPrice(['Please select one of the packages']);
      return false;
    }
    if (payload?.charge_job === 0 || payload?.addon_id === 0) {
      const res = await updateProject({
        id: projectData?.id || projectCreationData?.id,
        data: {
          name: projectData?.name,
          description: projectData?.description,
          is_paid: true
        }
      });
      navigate(routerPath.projects.manageProject);
    } else if (activePage === postProjectSteps[1]) {
      setErrorPrice(null);
      setActivePage(postProjectSteps[3]);
    }
  };

  const onBackPage = () => {
    if (activePage === postProjectSteps[3]) {
      setActivePage(postProjectSteps[2]);
    } else if (activePage === postProjectSteps[2]) {
      setActivePage(postProjectSteps[1]);
    } else if (activePage === postProjectSteps[1]) {
      setActivePage(postProjectSteps[0]);
    }
  };

  useEffect(() => {
    if (
      isErrorProjectCreated ||
      errorPrice ||
      isErrorPaymentCharge
      //  || isErrorAddCard
    ) {
      // // debugger;
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });

      const message = errorValidationHandler(paymentChargeError?.data || errorPrice || addCardError)
        ?.alert || ['Something went wrong'];
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: message
        }
      });
    }
  }, [
    isErrorProjectCreated,
    errorPrice,
    isErrorPaymentCharge,
    addCardError
    // isErrorAddCard
  ]);

  const onSuccessCharge = (token) => {
    postAddCard({
      token: token.id
    });
  };
  useEffect(() => {
    if (isSuccessAddCard) {
      if (allCardsData?.data?.data?.length > 0) {
        // debugger;
        postPaymentCharge({
          // p_id: dataJobCreated?.id,
          sub_id: payload?.sub_id,
          // token: token?.id,
          // token: 'tok_1NdEWvLKF6Em1NNrqQeoeJe4',
          card_id: allCardsData.data.data[0].id,
          addon_id: payload?.addon_id,
          payment_type: 'project',
          project_id: projectCreationData?.id
        });
      } else {
        refetchGetAllCards();
      }
    }
  }, [isSuccessAddCard, allCardsData]);

  const MemoMap = memo(Map, [position]);

  const [
    getLocationCityAndState,
    {
      isLoading: isLoadingSuccessCityState,
      isSuccess: isSuccessCityState,
      isError: isErrorCityState,
      error: errorCityState,
      data: dataCityState
    }
  ] = useGetLocationCityAndStateMutation();

  useEffect(() => {
    if (dataCityState) {
      const { state, city } = dataCityState;
      const filteredState = stateData.find(
        (item) => item.state_name?.toLowerCase() == state?.toLowerCase()
      );
      const filteredCity = cityData[2].data.find(
        (item) => item.city_name?.toLowerCase() == city?.toLowerCase()
      );
      if (filteredState) {
        setFilterLocation({
          ...filterLocation,
          state: {
            label: filteredState.state_name,
            id: filteredState.state_id
          }
        });
        setDisableState(true);
      } else {
        setDisableState(false);
      }
      if (filteredCity) {
        setFilterLocation({
          ...filterLocation,
          city: {
            label: filteredCity.city_name,
            id: filteredCity.city_id
          }
        });
        setDisableCity(true);
      } else {
        setDisableCity(false);
      }

      setLocationInfo({ state, city });
    }
  }, [dataCityState]);

  const fetchLocationInfo = async (lat, lng) => {
    try {
      // const response = await axios.post('/api/geocode/', { lat, lng });
      const data = { lat, lng };
      await getLocationCityAndState(data);
      // const { state, city } = response.data;
      // setLocationInfo({ state, city });
    } catch (error) {
      console.error('Error fetching location info: ', error);
    }
  };

  const geocodeLatLng = async (lat, lng) => {
    const apiKey = 'AIzaSyDZxuPkQdL9XOdkZ3iRuQdEkNywiBC1Il4'; // Replace with your actual API key
    const geocodeURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    try {
      const response = await axios.get(geocodeURL);
      const results = response.data.results;

      if (results.length > 0) {
        const addressComponents = results[0].address_components;
        let state = '';
        let city = '';

        addressComponents.forEach((component) => {
          if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
          }
          if (component.types.includes('locality')) {
            city = component.long_name;
          }
        });
        setLocationInfo({ state, city });
      }
    } catch (error) {
      console.error('Error fetching geocode data: ', error);
    }
  };

  useEffect(() => {
    if (isTba) {
      formik.setFieldValue('price', '');
    }
  }, [isTba]);

  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner-wrapper">
              {activePage ===
                postProjectSteps[postProjectSteps.findIndex((d) => d === 'post-price')] && (
                <>
                  <h2 className="title-2 mb-4">Post Project Opening</h2>
                  <PricingList
                    type={POST_TYPE.PROJECT}
                    onContinue={onContinuePage}
                    onBackPage={onBackPage}
                    fieldName={'charge_job'}
                    payload={payload}
                    onUpdatePayload={(newValue) => {
                      setPayload({
                        ...payload,
                        ...newValue
                      });
                    }}
                    onVerifyPromoCode={(value) => {
                      setPayload({
                        ...payload,
                        coupon: value
                      });
                    }}
                    onAggreePostingRule={(value) => {
                      setPayload({
                        ...payload,
                        isAgreeRule: value
                      });
                    }}
                    onClickBack={() => {
                      setActivePage((prev) => prev - 1);
                    }}
                  />
                </>
              )}
              {activePage ===
                postProjectSteps[postProjectSteps.findIndex((d) => d === 'post-featured')] && (
                <>
                  <h2 className="title-2 mb-4">Feature Project Post</h2>
                  <FeaturedJobList
                    onContinue={onContinuePage}
                    onBackPage={onBackPage}
                    fieldName={'charge_job_featured'}
                    payload={payload}
                    onUpdatePayload={(newValue) => {
                      setPayload({
                        ...payload,
                        ...newValue
                      });
                    }}
                  />
                </>
              )}

              {activePage ===
                postProjectSteps[postProjectSteps.findIndex((d) => d === 'charge-project')] && (
                <ChargePaymentComponent
                  onSuccessCharge={onSuccessCharge}
                  onClickBack={() => {
                    setActivePage(postProjectSteps[postProjectSteps.length - 2]);
                  }}
                  payload={payload}
                />
              )}

              {activePage ===
                postProjectSteps[postProjectSteps.findIndex((d) => d === 'post-submit')] && (
                <>
                  <form onSubmit={formik.handleSubmit}>
                    <h2 className="title-2 mb-4">Available Project Creation</h2>
                    <h3 className="title-3">Project Name</h3>
                    <p className="text-dark mb-1">
                      Come up with a title for your assignment. The title should attract attention
                      and reflect the essence of the task.
                    </p>

                    <div className="mb-3">
                      <label className="text-dark">Project Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter name"
                        value={formik.values.name}
                        name={'name'}
                        onChange={formik.handleChange}
                        disabled={isProjectSaved}
                      />
                      {!!formik.errors.name && (
                        <div className="text-danger mt-1">{formik.errors.name}</div>
                      )}
                    </div>

                    <h3 className="title-3">Trades</h3>
                    <Grid container spacing={2} className="mb-3">
                      {!!projectCategories?.results &&
                        projectCategories?.results.map(({ name, id }, idx) => (
                          <Grid item md={4} key={idx}>
                            <FormControlLabel
                              className="text-dark"
                              control={
                                <Checkbox
                                  sx={{
                                    p: 0,
                                    pr: 1
                                  }}
                                  value={name}
                                  name={'trade'}
                                  checked={String(payload.trade) === String(name)}
                                  onChange={handleChangeCheckbox}
                                  disabled={isProjectSaved}
                                />
                              }
                              label={name}
                            />
                          </Grid>
                        ))}
                      {!payload.trade && <div className="text-danger mt-1">{alertCatgroy}</div>}
                    </Grid>

                    {/* <h3
                      className="title-3"
                      style={{
                        marginBottom: '12px'
                      }}>
                      Select exact location on the map
                    </h3> */}

                    {/* <Grid item md={3}>
                      <div
                        style={{
                          width: '50%',
                          height: '40px',
                          marginBottom: '10px',
                          borderRadius: '8px',
                          borderColor: 'rgba(0, 0, 0, 0.23)'
                        }}>
                        <GooglePlacesAutocomplete
                          apiKey={'AIzaSyDZxuPkQdL9XOdkZ3iRuQdEkNywiBC1Il4'}
                          selectProps={{
                            value,
                            onChange: setValue,
                            placeholder: 'Type to search any location'
                          }}
                        />
                      </div>

                      <APIProvider apiKey={'AIzaSyDZxuPkQdL9XOdkZ3iRuQdEkNywiBC1Il4'}>
                        <MemoMap
                          style={{ width: '50%', height: '250px' }}
                          onClick={(e) => {
                            setMarketPosition({
                              lat: e.detail.latLng.lat,
                              lng: e.detail.latLng.lng
                            });
                            fetchLocationInfo(e.detail.latLng.lat, e.detail.latLng.lng);
                          }}
                          defaultCenter={position}
                          defaultZoom={12}>
                          <Marker position={marketPosition} />
                        </MemoMap>
                      </APIProvider>
                    </Grid> */}

                    <Grid container spacing={3} style={{ marginTop: 5 }}>
                      <Grid item md={3}>
                        <label className="text-dark">State:</label>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={
                            stateData?.find((item) => item.state_id == filterLocation?.state?.id)
                              ?.state_name ?? ''
                          }
                          options={stateData
                            .filter((f) => +f.country_id == COUNTRY_ID)
                            .map((e) => {
                              return {
                                label: e.state_name,
                                id: e.state_id
                              };
                            })}
                          onChange={(e, value) => {
                            console.log({
                              value
                            });
                            setFilterLocation({
                              ...filterLocation,
                              state: value
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: false }}
                              placeholder="State"
                              variant="outlined"
                              className="form-control"
                            />
                          )}
                          disabled={disableState}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <label className="text-dark">City:</label>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={
                            cityData[2].data.find(
                              (city) => city.city_id == filterLocation?.city?.id
                            )?.city_name ?? ''
                          }
                          options={cityData[2].data
                            .filter((f) => f.state_id == filterLocation?.state?.id)
                            .map((e) => {
                              return {
                                label: e.city_name,
                                id: e.city_id,
                                latitude: e.latitude,
                                longitude: e.longitude
                              };
                            })}
                          onChange={(e, value) => {
                            console.log({
                              value
                            });
                            setFilterLocation({
                              ...filterLocation,
                              city: value
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: false }}
                              variant="outlined"
                              className="form-control"
                            />
                          )}
                          disabled={disableCity}
                        />
                      </Grid>
                    </Grid>
                    {/* {!!payload.category && (
                      <>
                        <h3 className="title-3">Sub Category</h3>
                        <Grid container spacing={2} className="mb-3">
                          {!!projectSubCategory?.results &&
                            projectSubCategory?.results
                              .filter((e) => e?.category?.id === Number(payload.category))
                              .map(({ sub_category, id }, idx) => (
                                <Grid item md={4} key={idx}>
                                  <FormControlLabel
                                    className="text-dark"
                                    control={
                                      <Checkbox
                                        sx={{
                                          p: 0,
                                          pr: 1
                                        }}
                                        value={id}
                                        name={'sub_category'}
                                        checked={Number(payload.sub_category) === Number(id)}
                                        onChange={handleChangeCheckbox}
                                        disabled={isProjectSaved}
                                      />
                                    }
                                    label={sub_category}
                                  />
                                </Grid>
                              ))}
                        </Grid>
                      </>
                    )} */}

                    <h3 className="title-3">Project Description</h3>
                    <div className="mb-3">
                      <label className="text-dark">Description</label>
                      <textarea
                        className="form-control"
                        cols="30"
                        rows="10"
                        placeholder="Enter description"
                        value={formik.values.description}
                        name={'description'}
                        onChange={formik.handleChange}
                        disabled={isProjectSaved}></textarea>
                      {!!formik.errors.description && (
                        <div className="text-danger mt-1">{formik.errors.description}</div>
                      )}
                    </div>

                    <h3 className="title-3">Files</h3>
                    <div className="mb-3 d-flex">
                      <UploadImage
                        singleUpload={false}
                        boxType={'preview'}
                        onUploadImage={handleUploadFile}
                        disabled={isProjectSaved}
                      />

                      <UploadImage
                        singleUpload={false}
                        boxType={'preview'}
                        buttonTitle={'Add Photo'}
                        onUploadImage={handleUploadPhoto}
                        disabled={isProjectSaved}
                      />
                    </div>

                    <h3 className="title-3">Budget</h3>
                    <Grid
                      container
                      spacing={4}
                      style={{
                        alignItems: 'center'
                      }}>
                      <Grid item xs={3}>
                        <label className="text-dark">Price</label>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter amount or TBA "
                            value={formik.values.price}
                            name={'price'}
                            onChange={formik.handleChange}
                            disabled={isProjectSaved}
                            disabled={isTba}
                            style={{
                              backgroundColor: isTba ? '#f5f5f5' : 'white'
                            }}
                          />
                          {/* {!!formik.errors.price && (
                            <div className="text-danger mt-1">{formik.errors.price}</div>
                          )} */}
                        </div>
                      </Grid>

                      <Grid
                        style={{
                          marginLeft: '30px'
                        }}>
                        <div className="spacing-dollar">$</div>
                      </Grid>
                      <Grid item xs={3}>
                        <label className="text-dark">Terms</label>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}>
                          <FormControl fullWidth>
                            <Select
                              value={formik.values.term}
                              onChange={formik.handleChange}
                              name={'term'}
                              sx={{
                                height: '45px',
                                borderRadius: '8px'
                              }}
                              disabled={isProjectSaved}>
                              {terms.map((e, idx) => (
                                <MenuItem key={idx} value={e.name}>
                                  {e.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {/* {!!formik.errors.term && (
                            <div className="text-danger mt-1">{formik.errors.term}</div>
                          )} */}
                        </div>
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '35%'
                      }}>
                      <Grid item xs={3}>
                        {!!formik.errors.price && (
                          <div className="text-danger mt-1">{formik.errors.price}</div>
                        )}
                      </Grid>
                      <Grid item>{''}</Grid>
                      <Grid item xs={3}>
                        {!!formik.errors.term && (
                          <div className="text-danger mt-1">{formik.errors.term}</div>
                        )}
                      </Grid>
                    </div>

                    <div
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: 'center',
                        gap: '10px'
                      }}>
                      <strong
                        style={{
                          fontSize: '16px'
                        }}>
                        TBA :
                      </strong>
                      <Checkbox
                        checked={isTba}
                        onChange={(e) => setIsTba(e.target.checked)}
                        color="success"
                        size="medium"
                      />
                    </div>

                    {/**
                            <Grid container spacing={4}>
                                
                            <Grid item xs={3}>
                                    <label className='text-dark'>Deadline</label>
                                    <input type="text" className='form-control' placeholder='Enter Deadline'
            value={formik.values.deadline}
            name={'deadline'}
            onChange={formik.handleChange}/>
            {
                !!formik.errors.deadline && <div className='text-danger mt-1'>{formik.errors.deadline}</div>
            }
                                </Grid>

                            </Grid> */}
                    <Grid
                      container
                      spacing={4}
                      style={{
                        marginTop: '2px'
                      }}>
                      <Grid item xs={3}>
                        <label className="text-dark">Estimated Start Date</label>
                        <div className="form-date">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              inputFormat="MM/dd/yyyy"
                              value={payload.start_date}
                              onChange={handleChangeDate}
                              renderInput={(params) => <TextField {...params} />}
                              disabled={isProjectSaved}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                    </Grid>

                    <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ pt: 2 }}>
                      {!isProjectSaved && (
                        <Box>
                          <button
                            disabled={isProjectSaved}
                            type="submit"
                            className="btn btn-success mr-1">
                            <img src={IconPlus} className="bottom mr-1" /> Finish
                          </button>
                        </Box>
                      )}
                      {isProjectSaved && (
                        <Box>
                          <button className="btn btn-success ml-1" onClick={onContinuePage}>
                            <img src={IconPlus} className="top mr-1" /> Continue
                          </button>
                        </Box>
                      )}
                    </Stack>
                  </form>
                </>
              )}
            </div>

            {/* <FooterDashboard /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailableProjectCreation;

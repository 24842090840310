import * as constants from './news.constant';

export const defaultState = {
  termsAndCondition: '',
  privacyPolicy: '',
  categoryItems: [],
  items: [],
  detail: null,
  purchaseItems: [],
  didMountHomepage: false,
  didMount: false,
  isPageLoading: false,
  errors: [],
  validationForm: {},
  alertSeverity: 'error',
  featuredProfiles: [],
  messages: [],
  specialists: [],
  specialistProfile: null,
  specialistFilter: {
    occupation: [],
    distance: 15,
    status: 'all'
  },
  countries: [],
  galleries: [],
  occupations: [],
  projects: [],
  projectCategory: [],
  projectSubCategory: [],
  formProjectCreation: {
    sub_category: [],
    images: null,
    name: '',
    description: '',
    price: 0,
    deadline: 0,
    is_deadline_negotiated: false,
    is_price_negotiated: false
  },
  isEditProjects: false,
  myProjects: [],
  myProjectsDetail: {
    id: '',
    sub_category: [
      {
        id: '',
        category: {
          id: '',
          name: ''
        },
        sub_category: ''
      }
    ],
    images: [
      {
        id: '',
        image: '',
        project: ''
      }
    ],
    name: '',
    description: '',
    price: '',
    deadline: '',
    is_price_negotiated: '',
    is_deadline_negotiated: '',
    is_paid: '',
    user: ''
  },
  jobs: [],
  jobsDetail: {
    id: '',
    job_category: {
      id: '',
      name: '',
      image: ''
    },
    applicants: [],
    title: '',
    city: '',
    state: '',
    description: '',
    is_archive: false
  },
  jobCategories: [],
  formJob: {
    title: '',
    job_category: '',
    city: '',
    state: '',
    description: '',
    id: null
  },
  formJobVacancie: {
    category: '',
    tags: [],
    title: '',
    description: '',
    images: [],
    files: [],
    price: '',
    deadline: '',
    is_deadline_negotiated: false,
    is_price_negotiated: false
  }
};
const ACTION_HANDLERS = {
  [constants.LOGOUT]: (state, action) => {
    return { ...state, ...defaultState };
  },
  [constants.CHANGE_ATTRIBUTE]: (state, action) => {
    const { value, key } = action.payload;

    const updateState = {
      ...state,
      [key]: value
    };

    return Object.assign({}, state, updateState);
  },
  [constants.INIT]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [constants.HYDRATE]: (state, action) => {
    return { ...state, ...action.payload.news };
  }
};

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

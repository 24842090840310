import axios from "axios";
import { setupAxios } from "utils/axiosConfig";

export const specialistApi = {
  lists: "/api/v1/specialist/",
  occupations:"/api/v1/workline/"
};

export function apiGetSpecialist(params) {
  setupAxios();
  return axios.get(specialistApi.lists, { params });
}

export function apiGetOccupation(params) {
  setupAxios()
  return axios.get(specialistApi.occupations, { params });
}

import axios from 'axios';
import { environment } from 'config/environment';
import { getCookie } from 'react-use-cookie';

export async function setupAxios(hasAuth = true) {
  await axios.interceptors.request.use(
    async (config) => {
      config.baseURL = environment.BASE_API_URL;

      if (hasAuth) {
        const authToken = getCookie(environment.ACCESS_TOKEN_COOKIE);

        if (authToken) {
          config.headers.Authorization = `Token ${authToken}`;
        }
      }

      config.crossDomain = true;
      config.credentials = 'same-origin';

      return config;
    },
    (err) => Promise.reject(err)
  );
}

export const errorValidationHandler = (error) => {
  const data = error
  let displayMessage = []
  const errorMessage = []
  const validationMessage = {}
  console.log({
    data
  })


  if (!data) {
    return {
      alert: ['Something went wrong in API, please contact api developer'],
      validation: validationMessage
    }
  }

  Object.keys(data).map((d)=> {
    let messageData = d !== "0" && data?.[d]?.[d] ? data?.[d]?.[d] : data?.[d]

    console.log({
      test: typeof messageData,
      messageData
    })
    if (typeof messageData !== 'string') {
      if (messageData?.length) {
        messageData = messageData.map(e => {
          if (typeof e !== 'string') {
            return Object.keys(e).map(f => typeof e?.[f] !== 'string' && e?.[f]?.length ? e[f].map(s=> s) : f).join(", ")
          } else {
            return e
          }
        }).join(',')
      }
      else {
        messageData = JSON.stringify(messageData)
      }
    }


    displayMessage.push(
      `${d}: ${messageData}`
    )
    validationMessage[d] = {
      error: messageData
    }
    
    if (typeof error === 'string') {
      displayMessage = ['Something went wrong!']
    }

    return {
      messageData,
      validationMessage
    }
  })

  return {
    alert: errorMessage.concat(displayMessage),
    validation: validationMessage
  }
}

import { useState, useEffect } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { connect, useDispatch, useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import FooterDashboard from 'components/FooterLink/Dashboard';
import {
  useGetUserProfileQuery,
  usePatchProfileUpdateMutation,
  usePostChangePasswordMutation
} from 'store/slices/apiSlice';
import { appState, authState, resetAuth } from 'store/slices/appSlice';
import { Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import { routerPath } from 'router/Routerlist';
import CloseIcon from 'assets/close-icon.svg';
import delAccount from 'assets/account-delete.svg';
import logoutIcon from 'assets/logout.svg';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { setCookie } from 'react-use-cookie';
import { environment } from 'config/environment';
import { useNavigate } from 'react-router-dom';
import { HandleGetProfileRequest } from 'api/callsHandler';

const label = { inputProps: { 'aria-label': 'Switch' } };

const ProfileSettings = (props) => {
  const { data, isSuccess } = useGetUserProfileQuery();

  const { authToken } = useSelector(appState);

  const { changeAuthAttribute } = props;

  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteClose = () => {
    setOpen(false);
  };
  const deleteAccount = () => {
    setOpen(!open);
  };
  const logoutClose = () => {
    setOpenLogout(false);
  };
  const logoutAccout = () => {
    setOpenLogout(!openLogout);
  };
  const confirmLogout = () => {
    // // debugger;
    setCookie(environment.ACCESS_TOKEN_COOKIE, '0');
    changeAuthAttribute({
      key: 'authenticated',
      value: null
    }).then(() => {
      // // debugger;
      window.localStorage.removeItem('persist:root');
      navigate(routerPath.homepage.root);
    });
    logoutClose();
  };
  const { authenticated } = useSelector(authState);
  const { data: profileData, mutate: mutateProfile } = HandleGetProfileRequest(
    '',
    authenticated?.id
  );

  const [
    postChangePassword,
    {
      data: dataChangePassword,
      isLoading: isLoadingPasswordChange,
      isSuccess: isSuccessPassword,
      isError,
      error
    }
  ] = usePostChangePasswordMutation();

  const [putProfileUpdate] = usePatchProfileUpdateMutation();

  const validationSchema = yup.object({
    current_password: yup.string().required('Required'),
    new_password: yup
      .string()
      .required('Required')
      .when('current_password', {
        is: (val) => !!(val && val.length > 0),
        then: yup
          .string()
          .notOneOf(
            [yup.ref('current_password')],
            'New password must be different from the current one'
          )
      }),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('new_password'), null], 'Passwords must match')
      .required('Required')
  });

  const onSubmitData = (values) => {
    const payload = {
      new_password1: values.current_password,
      new_password2: values.new_password
    };
    console.log({
      payload
    });
    postChangePassword(payload);
  };

  useEffect(() => {
    console.log({
      isSuccessPassword,
      isLoadingPasswordChange,
      isError,
      error
    });
  }, [isSuccessPassword]);

  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value: isLoadingPasswordChange
      }
    });
  }, [isLoadingPasswordChange]);

  useEffect(() => {
    if (isError) {
      const message = error?.data?.detail;
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: [message]
        }
      });
    }
  }, [isError]);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      current_password: '',
      new_password: '',
      confirm_password: ''
    },
    validationSchema: validationSchema,
    onSubmit: onSubmitData
  });

  const handleNotification = async (type, event) => {
    if (type === 'message') {
      await putProfileUpdate({
        profileId: authenticated?.id,
        messages_notification: event.target.checked
      });
    }
    if (type === 'projectApplicants') {
      await putProfileUpdate({
        profileId: authenticated?.id,
        project_applications_notification: event.target.checked
      });
    }
    if (type === 'jobApplicants') {
      await putProfileUpdate({
        profileId: authenticated?.id,
        job_applications_notification: event.target.checked
      });
    }

    mutateProfile();
  };

  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner pt-3">
              <h3 className="title-small mt-0">Your Basic Info</h3>
              <div className="box-preference">
                <div className="preference-list">
                  <div className="mui-row mui-row d-flex items-center">
                    <div className="mui-col-xs-4">Full Name</div>
                    <div className="mui-col-xs-8 text-dark">{profileData?.data?.name}</div>
                  </div>
                </div>
                <div className="preference-list">
                  <div className="mui-row mui-row d-flex items-center">
                    <div className="mui-col-xs-4">Email</div>
                    <div className="mui-col-xs-8 text-dark">{authenticated?.email}</div>
                  </div>
                </div>
                <div className="preference-list">
                  <div className="mui-row mui-row d-flex items-center">
                    <div className="mui-col-xs-4">Password</div>
                    <div className="mui-col-xs-8 text-dark">**********</div>
                  </div>
                </div>
              </div>

              <h3 className="title-small mt-4">Notifications</h3>
              <div className="box-preference">
                <div className="preference-list">
                  <div className="mui-row d-flex items-center">
                    <div className="mui-col-xs-4">Messages</div>
                    <div className="mui-col-xs-8 text-dark">
                      <Switch
                        {...label}
                        checked={profileData?.data?.messages_notification}
                        onChange={(event) => handleNotification('message', event)}
                      />
                    </div>
                  </div>
                </div>

                {profileData?.data?.user_type === 'Recruiter' ||
                profileData?.data?.user_type === 'Job Seeker' ? (
                  ''
                ) : (
                  <div className="preference-list">
                    <div className="mui-row d-flex items-center">
                      <div className="mui-col-xs-4">Available Project Applicants</div>
                      <div className="mui-col-xs-8 text-dark">
                        <Switch
                          {...label}
                          checked={profileData?.data?.project_applications_notification}
                          onChange={(event) => handleNotification('projectApplicants', event)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {(profileData?.data?.user_type === 'Recruiter' ||
                  profileData?.data?.user_type !== 'Job Seeker') && (
                  <div className="preference-list">
                    <div className="mui-row d-flex items-center">
                      <div className="mui-col-xs-4">Job Applicants</div>
                      <div className="mui-col-xs-8 text-dark">
                        <Switch
                          {...label}
                          checked={profileData?.data?.job_applications_notification}
                          onChange={(event) => handleNotification('jobApplicants', event)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <h3 className="title-small mt-4">Change Password </h3>

              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={4}>
                    <div className="mb-2">
                      <label>Current Password</label>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Password"
                        value={formik.values.current_password}
                        name={'current_password'}
                        onChange={formik.handleChange}
                      />
                      {!!formik.errors.current_password && (
                        <div className="text-danger mt-1">{formik.errors.current_password}</div>
                      )}
                    </div>
                    <div className="mb-2">
                      <label>New Password</label>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Password"
                        value={formik.values.new_password}
                        name={'new_password'}
                        onChange={formik.handleChange}
                      />
                      {!!formik.errors.new_password && (
                        <div className="text-danger mt-1">{formik.errors.new_password}</div>
                      )}
                    </div>
                    <div className="mb-2">
                      <label>Confirm New Password</label>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Password"
                        value={formik.values.confirm_password}
                        name={'confirm_password'}
                        onChange={formik.handleChange}
                      />
                      {!!formik.errors.confirm_password && (
                        <div className="text-danger mt-1">{formik.errors.confirm_password}</div>
                      )}
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {
                            console.log({ event, value, test: event.target.checked });
                            setShowPassword(value);
                          }}
                          sx={{
                            p: 0,
                            pr: 1,
                            // color: pink[800],
                            '&.Mui-checked': {
                              color: '#fff'
                            }
                          }}
                        />
                      }
                      label="Show Password"
                    />
                  </Grid>
                </Grid>
                <div className="mt-3">
                  <button className="btn btn-primary mr-2" type={'submit'}>
                    Update
                  </button>
                  <button className="btn btn-outline-secondary">Cancel</button>
                </div>
              </form>
              <div className="mt-3">
                <a className="text-danger underline pointer mr-4" onClick={deleteAccount}>
                  Delete Account
                </a>
                <a className="text-dark underline pointer" onClick={logoutAccout}>
                  Log Out
                </a>
              </div>
              <Dialog open={open} onClose={deleteClose} aria-labelledby="responsive-dialog-title">
                <div className="modal modal-sm">
                  <div className="modal-header">
                    <h3>Delete Account?</h3>
                    <div className="modal-close" onClick={deleteClose}>
                      <img src={CloseIcon} />
                    </div>
                  </div>
                  <div className="modal-content mui--text-center">
                    <img src={delAccount} className="mt-3" />
                    <p className="allert-mm mt-3 mb-3">
                      Are you sure you want to delete your account?
                    </p>
                    <div className="mui--text-center">
                      <button className="btn btn-outline-dark mr-1">Cancel</button>
                      <button className="btn btn-dark mr-1">Delete</button>
                    </div>
                  </div>
                </div>
              </Dialog>

              <Dialog
                open={openLogout}
                onClose={logoutClose}
                aria-labelledby="responsive-dialog-title">
                <div className="modal modal-sm">
                  <div className="modal-header">
                    <h3>Log Out?</h3>
                    <div className="modal-close" onClick={logoutClose}>
                      <img src={CloseIcon} />
                    </div>
                  </div>
                  <div className="modal-content mui--text-center">
                    <img src={logoutIcon} className="mt-3" />
                    <p className="allert-mm mt-3 mb-3">Are you sure you want to Log Out?</p>
                    <div className="mui--text-center">
                      <button onClick={logoutClose} className="btn btn-outline-dark mr-1">
                        Cancel
                      </button>
                      <button
                        className="btn btn-dark mr-1"
                        onClick={() => {
                          dispatch(resetAuth());
                          confirmLogout();
                        }}>
                        Sign Out
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog>
            </div>
            <FooterDashboard />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};
const mapDispatchToProps = { ...authAction };
export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);

import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { routerPath } from 'router/Routerlist';
import { useNavigate } from 'react-router-dom';

const LabelBottomNavigation = (props) => {
  const [value, setValue] = React.useState(props.active);
  const navigate = useNavigate();

  return (
    <footer>
      <div className="footer-container">
        <div className="link-footer">
          {/* <a className="cursor-pointer" onClick={()=> props.history.push(routerPath.footer.about)}>About</a> */}
          <a className="cursor-pointer" onClick={() => navigate(routerPath.footer.terms)}>
            Terms
          </a>
          <a className="cursor-pointer" onClick={() => navigate(routerPath.footer.privacy)}>
            Privacy
          </a>
        </div>
      </div>
    </footer>
  );
};
export default LabelBottomNavigation;

import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import iconVisa from 'assets/visa.svg';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const {
    changeNewsAttribute,
    onSuccessCharge,
    price,
    addCardError,
    news: { formJob }
  } = props;

  const handleSubmit = async (ev) => {
    console.log('Working');
    setLoading(true);
    if (ev) {
      ev.preventDefault();
    }

    let payload = {
      p_id: formJob.id
    };

    const { token, error } = await stripe.createToken(elements.getElement(CardNumberElement));
    if (token) {
      payload.token = token.id;
      onSuccessCharge(token, price);
    }

    if (error) {
      changeNewsAttribute({
        key: 'isPageLoading',
        value: false
      });

      changeNewsAttribute({
        key: 'errors',
        value: [error.message]
      });

      changeNewsAttribute({
        key: 'alertSeverity',
        value: 'error'
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (addCardError) {
      setLoading(false);
      handleSubmit();
    }
  }, [addCardError]);

  return (
    <div className="visa">
      <form id="payment-form" onSubmit={handleSubmit}>
        <div className="card-visa">
          <div className="text-right">
            <img src={iconVisa} alt="" />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label>Card Number</label>
              <CardNumberElement className="form-control stripe-input form-input" />
            </Grid>
            <Grid item xs={7}>
              <label>Cardholder Name</label>
              <input type="text" className="form-control text-center" />
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <label>Valid Thru</label>
                  <CardExpiryElement className="form-control stripe-input" />
                </Grid>
                <Grid item xs={6}>
                  <div className="text-right">
                    <label>CVV</label>
                  </div>
                  <CardCvcElement className="form-control stripe-input text-right" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <button className="btn btn-primary btn-payment" disabled={loading}>
          Pay by Card
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);

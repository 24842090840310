import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu'
import FooterDashboard from 'components/FooterLink/Dashboard'
import { connect } from 'react-redux'
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import JobApplicantList from 'components/Job/JobApplicantList';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const JobApplicants = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="wrapper">
                <div className="user-dashboard">
                    <SidebarMenu />
                    <div className="content-dashboard">
                        <div className="dahboard-header">
                            <h1>Job Applicants</h1>
                        </div>
                        <div className="content-inner inner-padding pt-2">
                            <div className="breadcrumb mb-4">
                                <div>Welder</div>
                                <div className='active'>/ Los Angeles, California</div>
                            </div>
                            <JobApplicantList
                                name={'Romuald Anderson'}
                                address={'Los Angeles, CA'}
                                date={'November 22, 2022'}
                                onRemove={handleClickOpen}
                                onMessage={''}
                                onView={''}
                            />
                            <JobApplicantList
                                name={'Damien Savio'}
                                address={'Los Angeles, CA'}
                                date={'November 22, 2022'}
                                onRemove={handleClickOpen}
                                onMessage={''}
                                onView={''}
                            />

                            <Dialog
                                open={open}
                                onClose={handleClose}
                                maxWidth={'xs'}
                            >
                                <DialogContent>
                                    <div className="text-center">
                                        <h4 className="subtitle">Do You Want to Delete Romuald Andersen from the list</h4>
                                        <button className='btn btn-primary mr-1 ml-1' onClick={handleClose}>Yes</button>
                                        <button className='btn btn-primary mr-1 ml-1' onClick={handleClose}>No</button>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(JobApplicants)
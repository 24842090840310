import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant';
import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_AUTH } from 'store/reducers/auth/auth.constant';
import { defaultState as defaultStateAuth } from 'store/reducers/auth/auth.reducer';
import { apiPostSignin, apiPostSignup } from 'api/login.api';
import { errorValidationHandler } from 'utils/errorHandler';
import { signInFirebase, signUpFirebase } from 'utils/firebase';

export const actionToProps = {
  actionPostSignUp: (params) => async (dispatch, getState) => {
    const {
      auth: { formSignUp }
    } = getState();

    dispatch({
      type: CHANGE_ATTRIBUTE_NEWS,
      payload: {
        key: 'isPageLoading',
        value: true
      }
    });

    try {
      let clearPayload = {};
      Object.keys(formSignUp).forEach((e) => {
        clearPayload = {
          ...clearPayload,
          [e]: formSignUp[e].value
        };
      });
      clearPayload = {
        ...clearPayload,
        user_type: params.user_type
        // name: `${clearPayload.firstname} ${clearPayload.lastname}`
      };
      console.log(clearPayload, 'clearPayload');
      const { data } = await apiPostSignup(clearPayload);
      // // debugger;
      const {
        password: { value: passwordValue },
        email: { value: emailValue }
      } = formSignUp;
      await signUpFirebase(emailValue, passwordValue);

      // dispatch({
      //   type: CHANGE_ATTRIBUTE_NEWS,
      //   payload: {
      //     key: 'isPageLoading',
      //     value: false
      //   }
      // });
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: ['User registered successfully!']
        }
      });
      // dispatch({
      //   type: CHANGE_ATTRIBUTE_AUTH,
      //   payload: {
      //     key: 'formSignUp',
      //     value: defaultStateAuth.formSignUp
      //   }
      // });
      const signInPayload = {
        email: emailValue,
        username: emailValue,
        password: passwordValue
      };
      const { data: signInResponse } = await apiPostSignin(signInPayload);
      const resp = await signInFirebase(signInPayload.email, signInPayload.password);
      dispatch({
        type: CHANGE_ATTRIBUTE_AUTH,
        payload: {
          key: 'authenticated',
          value: signInResponse.user
        }
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });
      return signInResponse;
    } catch (error) {
      // // debugger;
      const data = error?.response?.data;
      const errorHandlerData = errorValidationHandler(data || error);

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'errors',
          value: errorHandlerData.alert
        }
      });

      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });

      return false;
    } finally {
      dispatch({
        type: CHANGE_ATTRIBUTE_NEWS,
        payload: {
          key: 'isPageLoading',
          value: false
        }
      });
    }
  }
};

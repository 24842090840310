import * as React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ListPrice(props) {
  const { price, name, label, content, value, onClick } = props;
  return (
    <div className="list-price">
      <input onClick={onClick} type="radio" name={name} id={value} />
      <label htmlFor={value}>
        <div className="list-head">
          <div className="price">{price}</div>
          <div className="label-name">{label}</div>
        </div>
        {content}
      </label>
    </div>
  );
}

import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import FooterDashboard from 'components/FooterLink/Dashboard';
import { connect } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Box, Grid } from '@mui/material';
import IconPlus from 'assets/plus.svg';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import iconPicture from 'assets/image-icon.svg';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const PostJob = () => {
  const [age, setAge] = useState('');
  const [picture, setPicture] = useState(
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII='
  );
  const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeDate = (newValue) => {
    setValue(newValue);
  };

  function uploadImage(e) {
    setPicture(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner-wrapper">
              <h2 className="title-2 mb-4">Post Job Opening</h2>

              <Grid container spacing={3} className="mb-2">
                <Grid item md={3}>
                  <label className="text-dark">Job Ad</label>
                  <input type="text" className="form-control" placeholder="Enter job name" />
                </Grid>
              </Grid>

              <h4 className="title-3">Company Information</h4>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <label className="text-dark">Company name:</label>
                  <input type="text" className="form-control" placeholder="Enter company name" />
                </Grid>
                <Grid item md={3}>
                  <label className="text-dark">City:</label>
                  <input type="text" className="form-control" placeholder="Enter city" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <label className="text-dark">State:</label>
                  <FormControl fullWidth>
                    <Select
                      value={age}
                      onChange={handleChange}
                      sx={{
                        height: '45px',
                        borderRadius: '8px'
                      }}>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3}>
                  <label className="text-dark">Website:</label>
                  <input type="text" className="form-control" placeholder="Enter city" />
                </Grid>
              </Grid>
              <Grid container spacing={3} className="mb-2">
                <Grid item md={3}>
                  <label className="text-dark">ZIP Code:</label>
                  <input type="text" className="form-control" placeholder="Enter ZIP code" />
                </Grid>
              </Grid>

              <h4 className="title-3">Uploads (Optional)</h4>
              <div className="uploads-container mr-2 mb-3">
                <input type="file" onChange={uploadImage} />
                <img src={picture} alt="" className="upload-preview" />
                <div className="uploads-label">
                  <img src={iconPicture} alt="" />
                  <div>Upload Picture</div>
                </div>
              </div>

              <h4 className="title-3">About Job</h4>
              <Grid container spacing={3}>
                <Grid item md={6} className="mb-2">
                  <label>Description:</label>
                  <textarea className="form-control" rows="10" placeholder="Enter text"></textarea>
                </Grid>
              </Grid>

              <h4 className="title-3">Job Price</h4>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <label className="text-dark">Price:</label>
                  <input type="text" className="form-control" placeholder="Enter company name" />
                </Grid>
                <Grid item md={3}>
                  <label className="text-dark">Start date:</label>
                  {/* <input type="text" className="form-control" /> */}
                  <div className="form-date">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="MM/dd/yyyy"
                        value={value}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="mb-3">
                <Grid item md={3}>
                  <label className="text-dark">Terms:</label>
                  <FormControl fullWidth>
                    <Select
                      value={age}
                      onChange={handleChange}
                      sx={{
                        height: '45px',
                        borderRadius: '8px'
                      }}>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <h4 className="title-3">Trade</h4>
              <Grid container spacing={3} className="mb-3">
                <Grid item md={6}>
                  <div className="d-flex">
                    <div className="check-btn mr-1">
                      <input type="checkbox" id="check" />
                      <label htmlFor="check">Large Building Construction</label>
                    </div>
                    <div className="check-btn mr-1">
                      <input type="checkbox" id="check2" />
                      <label htmlFor="check2">HVAC</label>
                    </div>
                    <div className="check-btn mr-1">
                      <input type="checkbox" id="check3" />
                      <label htmlFor="check3">Civil Construction</label>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Box sx={{ pt: 2 }}>
                <button className="btn btn-success mr-1">
                  <img src={IconPlus} className="bottom mr-1" /> Finish
                </button>
              </Box>
            </div>
            <FooterDashboard />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(PostJob);

import { useMemo } from 'react';
import IconCalendar from 'assets/calendar.svg';
import IconLocation from 'assets/location.svg';
import IconDollar from 'assets/dollar.svg';
import IconTime from 'assets/time.svg';
import { getRelativeTime } from 'utils/date';
import IconStar from 'assets/images/star.svg';
import StarOff from 'assets/start-outline.svg';
import { Chip, CircularProgress, Stack, Typography } from '@mui/material';
import stateData from 'constant/state.json';
import cityData from 'constant/city.json';
import { useLocation } from 'react-router-dom';
import { formatDescription } from 'utils/helper';

ProjectCard.defaultProps = {
  actionTitle: 'Quick Apply',
  onActionClick: () => false,
  hasAction: true
};

export default function ProjectCard(props) {
  const {
    onClick,
    name,
    description,
    createdAt,
    address,
    tags,
    price,
    onQuickApply,
    deadline,
    hasAction,
    actionTitle,
    onActionClick,
    onAddFavourite,
    isPaid,
    isFavorite,
    cityId,
    stateId,
    location,
    hidePaidTag,
    estimated_start_date,
    trade,
    isLoading,
    disabled
  } = props;

  const windowLocation = useLocation();

  const stateName = useMemo(
    () => stateData?.find((item) => item.state_id == stateId)?.state_name ?? '',
    [stateId]
  );

  const cityName = useMemo(
    () => cityData[2].data.find((city) => city.city_id == cityId)?.city_name ?? '',
    [cityId]
  );
  const isDisplayFlags = useMemo(
    () =>
      windowLocation.pathname.includes('favorites') ||
      windowLocation.pathname.includes('search-projects')
        ? false
        : true,
    [windowLocation]
  );

  const convertDateFormat = (dateStr) => {
    // Split the original date string on the hyphen
    const [year, month, day] = dateStr.split('-');

    // Rearrange the parts to form the new date format
    return `${month}-${day}-${year}`;
  };

  return (
    <div className="card project-list">
      <div className="mui-row d-flex flex-wrap">
        <div className="mui-col-sm-9">
          <div className="project-content">
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Typography variant="h4" className="project-title pointer" onClick={onClick}>
                {name}
              </Typography>
              {isDisplayFlags && (
                <Chip
                  label={isPaid ? 'Paid' : 'Unpaid'}
                  size="small"
                  color={isPaid ? 'success' : 'error'}
                />
              )}
            </Stack>
            <p className="project-description">{formatDescription(description)}</p>

            <div className="project-meta">
              <span>
                <img src={IconCalendar} alt="icon" /> Posted {getRelativeTime(createdAt)}
              </span>
              {stateName && (
                <span>
                  <img src={IconLocation} alt="icon" /> {`${stateName}, ${cityName}`}
                </span>
              )}
            </div>

            {/* <div className="project-cat">
              {tags?.map((t, k) => {
                return (
                  <div key={k} className="project-cat-item">
                    {t}
                  </div>
                );
              })}
            </div> */}

            {trade && (
              <div className="project-cat">
                <div className="project-cat-item">
                  {/* {trade === '1'
                    ? 'Engineering'
                    : trade === '2'
                    ? 'Construction'
                    : trade === '3'
                    ? 'BILL TEST FOR CATEGORY'
                    : null} */}

                  {trade}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mui-col-md-3">
          <div className="project-price">
            {hasAction && (
              <div className="d-flex">
                <div className="pr-1">
                  <button className={`btn fav-btn`} onClick={onAddFavourite}>
                    {isFavorite ? <img src={IconStar} alt="" /> : <img src={StarOff} alt="" />}
                  </button>
                </div>
                <button
                  className="btn btn-primary w-100"
                  style={{
                    backgroundColor: disabled && '#ccc'
                  }}
                  onClick={onActionClick}
                  disabled={disabled}>
                  {isLoading ? <CircularProgress color="primary" size={16} /> : actionTitle}
                </button>
              </div>
            )}
            <div className="mt-3 text-16">
              <div className="d-flex mb-1">
                <div className="ico-pps">
                  <img src={IconDollar} />{' '}
                </div>
                <div>
                  <strong>Price: {price === null ? 'TBA' : price}</strong>
                </div>
              </div>
              <div className="d-flex">
                <div className="ico-pps">
                  <img src={IconTime} />
                </div>
                <div>
                  {/* <strong>Estimated Start Date: {deadline}</strong> */}
                  <strong>
                    Estimated Start Date:{' '}
                    {estimated_start_date ? convertDateFormat(estimated_start_date) : 'Not found'}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

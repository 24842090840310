import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { appState } from 'store/slices/appSlice';
import { routerPath } from './Routerlist';
import { CircularProgress, Stack } from '@mui/material';
import { HandleGetProfileRequest } from 'api/callsHandler';

const PublicRoutes = ({ auth }) => {
  const { authToken } = useSelector(appState);
  console.log({
    authToken
  });

  const { data: profileData } = HandleGetProfileRequest('', auth?.authenticated?.id);

  return !authToken ? (
    <Outlet />
  ) : !profileData ? (
    <Stack justifyContent={'center'} alignItems={'center'} sx={{ height: '100vh' }}>
      <CircularProgress />
    </Stack>
  ) : profileData?.data?.is_first_login ? (
    <Navigate to={routerPath.settings.editProfile} />
  ) : (
    <Navigate to={routerPath.jobs.alljob} />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};
export default connect(mapStateToProps, null)(PublicRoutes);

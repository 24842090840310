import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import ControlledImage from 'components/input/ControlledImage';
import DeleteCompanyMediaModal from 'views/Profile/DeleteCompanyMediaModal';
import CancelIcon from '@mui/icons-material/Cancel';
import MultiImageUpload from 'components/input/MultiImageUpload';

function OtherUploads({
  control,
  errors,
  profileData,
  handleTriggerFetchProfile,
  setImageArray,
  imageArray
}) {
  const [isDialogOpen, setOpenDialog] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState(-1);

  const handleCloseDialog = () => setOpenDialog(false);

  const handleResetSelectedId = () => setSelectedPhotoId(-1);

  return (
    <>
      <DeleteCompanyMediaModal
        isOpen={isDialogOpen}
        handleClose={handleCloseDialog}
        id={selectedPhotoId}
        handleResetSelectedId={handleResetSelectedId}
        handleTriggerFetchProfile={handleTriggerFetchProfile}
      />
      <Grid container mt={6} columnSpacing={2}>
        <Grid item xs={12} className="mb-2">
          <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
            Uploads
          </Typography>
        </Grid>
        <Grid item xs={5} className="mb-2">
          <Stack justifyContent={'space-between'}>
            {/* <Typography sx={{ mb: 2, width: '150px' }}>Upload Company Logo</Typography> */}

            <ControlledImage
              control={control}
              errors={errors}
              label={'Upload Company Logo'}
              param={'company_logo'}
            />
          </Stack>
        </Grid>
        <Grid item xs={5} className="mb-2">
          <Stack justifyContent={'space-between'}>
            {/* <Typography sx={{ mb: 2, width: '150px' }}>Upload Project Pictures</Typography> */}

            {/* <ControlledImage
            control={control}
            errors={errors}
            label={'Upload Project Pictures'}
            param={'image_objects'}
            multiple={true}
          /> */}
            <Stack direction={'row'} alignItems={'center'} spacing={3}>
              <Stack direction={'row'} alignItems={'center'} spacing={3}>
                {profileData?.company &&
                  profileData?.company?.images?.map((e, idx) => (
                    <div
                      className="picture-item"
                      style={{ width: '150px', height: '150px', position: 'relative' }}
                      key={idx}>
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: -20,
                          right: -20,
                          zIndex: 1
                        }}
                        onClick={() => {
                          setSelectedPhotoId(e.id);
                          setOpenDialog(true);
                        }}>
                        <CancelIcon sx={{ color: 'red' }} />
                      </IconButton>
                      <div className="images-box">
                        <img src={e.image.split('?')[0]} alt="" />
                      </div>
                    </div>
                  ))}
              </Stack>
              {(!profileData?.company?.images || profileData?.company?.images?.length < 5) && (
                <MultiImageUpload
                  control={control}
                  errors={errors}
                  label={'Upload Project Pictures'}
                  param={'image_objects'}
                  multiple={true}
                  noOfUploadedImages={profileData?.company?.images?.length ?? 0}
                  setImageArray={setImageArray}
                  imageArray={imageArray}
                />
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default OtherUploads;

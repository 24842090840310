import axios from 'axios';
import { environment } from 'config/environment';
import { getCookie } from 'react-use-cookie';

const api = axios.create({
  baseURL: `${environment.BASE_API_URL}/api/v1/`
});

const apiHost = axios.create({
  baseURL: `${environment.BASE_API_URL}/`
});

const apiNoAuth = axios.create({
  baseURL: `${environment.BASE_API_URL}/api/v1/`
});

const apiHostNoAuth = axios.create({
  baseURL: `${environment.BASE_API_URL}/`
});

// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    const authToken = getCookie(environment.ACCESS_TOKEN_COOKIE);

    if (authToken) {
      config.headers.Authorization = `Token ${authToken}`;
    }

    config.crossDomain = true;
    config.credentials = 'same-origin';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiHost.interceptors.request.use(
  async (config) => {
    const authToken = getCookie(environment.ACCESS_TOKEN_COOKIE);

    if (authToken) {
      config.headers.Authorization = `Token ${authToken}`;
    }

    config.crossDomain = true;
    config.credentials = 'same-origin';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
      error?.response?.data?.detail === 'Authentication credentials were not provided.'
    ) {
      localStorage.removeItem('persist:root');
      localStorage.removeItem('firebase:host:workium-dff5a-default-rtdb.firebaseio.com');
      window.location.reload();
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

apiHost.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
      error?.response?.data?.detail === 'Authentication credentials were not provided.'
    ) {
      localStorage.removeItem('persist:root');
      localStorage.removeItem('firebase:host:workium-dff5a-default-rtdb.firebaseio.com');
      window.location.reload();
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export { api, apiHost, apiNoAuth, apiHostNoAuth };

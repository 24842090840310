import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Drawer from '@mui/material/Drawer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeaderLogo from 'components/Logo/Homepage';
// import HeaderLogo from './../../assets/header-logo.svg';
import { ReactComponent as MenuLogo } from 'assets/menu-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/x-icon.svg';
import { routerPath } from 'router/Routerlist';
import { connect } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { environment } from 'config/environment';
import useCookie from 'react-use-cookie';
import ChangePasswordDialog from 'components/pages/signin/change-password';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const NavbarMenuTop = (props) => {
  const { page } = props;
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [userToken, setUserToken] = useCookie(environment.ACCESS_TOKEN_COOKIE, '0');
  const [isChangePasswordOpen, setIsChangePasswordOpen] = React.useState(false);
  const {
    auth: { authenticated },
    changeAuthAttribute
  } = props;

  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar className="toolbard-header-ionic">
            <HeaderLogo />
            <Box sx={{ flexGrow: 1 }} />
            <Box
              style={{
                alignItems: 'center'
              }}
              sx={{ display: { xs: 'none', md: 'flex' } }}>
              {page === 'landing' && (
                <>
                  <div className="link-top not-member cursor-pointer">Features</div>
                  <div className="link-top not-member cursor-pointer">About</div>
                  <div className="link-top not-member cursor-pointer">Contact</div>
                  <div className="link-top not-member cursor-pointer">Blog</div>
                </>
              )}

              {/* {!authenticated && (
                <a
                  className="link-top join-us cursor-pointer"
                  onClick={() =>
                    navigate(
                      page === 'login' || page === 'landing'
                        ? routerPath.auth.signup
                        : routerPath.auth.signin
                    )
                  }>
                  {page === 'login' ? `Join Us` : page === 'landing' ? `Sign Up` : `Sign In`}
                </a>
              )} */}
              {!authenticated && page !== 'landing' && (
                <Link
                  to={page === 'login' ? routerPath.auth.signup : routerPath.homepage.root}
                  className="link-top not-member"
                  style={{ color: 'white' }}>
                  {page === 'login' ? `Not a member?` : `Already a member?`}
                </Link>
              )}

              {!authenticated && (
                <Link
                  // target={'_blank'}
                  // onClick={() =>
                  //   navigate(
                  //     page === 'login' || page === 'landing'
                  //       ? routerPath.auth.signup
                  //       : routerPath.homepage.root
                  //   )
                  // }
                  to={
                    page === 'login' || page === 'landing'
                      ? routerPath.homepage.workiumHomePage
                      : routerPath.homepage.root
                  }>
                  <a href className="link-top join-us cursor-pointer">
                    {page === 'login' ? `Join Us` : page === 'landing' ? `Sign Up` : `Sign In`}
                  </a>
                </Link>
              )}

              {page === 'landing' && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={() => setDrawerOpen(!isDrawerOpen)}
                  sx={{ ...(isDrawerOpen && { display: 'none' }) }}>
                  <MenuLogo />
                </IconButton>
              )}
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton size="large" aria-label="show more" aria-haspopup="true" color="inherit">
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      {page === 'landing' && (
        <Drawer anchor={`right`} open={isDrawerOpen} onClose={() => setDrawerOpen(!isDrawerOpen)}>
          <div className="close-icon">
            <IconButton
              size="large"
              aria-label="show more"
              aria-haspopup="true"
              color="inherit"
              onClick={() => setDrawerOpen(!isDrawerOpen)}>
              <CloseIcon />
            </IconButton>
          </div>
          <ul className="drawer-menu">
            {!authenticated && <li onClick={() => navigate(routerPath.auth.signin)}>login</li>}
            {authenticated && (
              <li className="cursor-pointer" onClick={() => navigate(routerPath.settings.profile)}>
                Profile
              </li>
            )}
            <li>about</li>
            <li>contact</li>
            {authenticated && (
              <li>
                <Accordion className="menu-dropdown-12">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    Settings
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className="submenu">
                      <li
                        className="cursor-pointer"
                        onClick={() => navigate(routerPath.footer.privacy)}>
                        Privacy Policy
                      </li>
                      <li
                        className="cursor-pointer"
                        onClick={() => navigate(routerPath.footer.terms)}>
                        Terms and Conditions
                      </li>
                      <li
                        onClick={() => {
                          setIsChangePasswordOpen(true);
                        }}>
                        Change Password
                      </li>
                      <li
                        onClick={() => {
                          // // debugger;
                          setUserToken(0);
                          changeAuthAttribute({
                            key: 'authenticated',
                            value: false
                          }).then(() => {
                            // // debugger;
                            window.localStorage.removeItem('persist:root');
                            navigate(routerPath.auth.signin);
                          });
                        }}>
                        Log Out
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </li>
            )}
          </ul>
        </Drawer>
      )}

      <ChangePasswordDialog
        open={isChangePasswordOpen}
        handleClose={() => setIsChangePasswordOpen(false)}
      />
    </>
  );
};

NavbarMenuTop.defaultProps = {
  page: 'login'
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};
const mapDispatchToProps = { ...authAction };

export default connect(mapStateToProps, mapDispatchToProps)(NavbarMenuTop);

import React from 'react';
import SidebarMenu from 'components/SidebarMenu'
import FooterDashboard from 'components/FooterLink/Dashboard'
import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconStar from 'assets/star-off.svg';
import ProjectCard from 'components/ProjectCard';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const JobSeekerFavorite = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <div className="wrapper">
                <div className="user-dashboard">
                    <SidebarMenu />
                    <div className="content-dashboard">
                        <div className="content-inner pt-2">
                            <h1 className="title-2 mt-0 mb-3">Favorites</h1>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                className="nav-tab"
                                sx={{
                                    color: '#9AB650',
                                }}
                            >
                                <Tab label="Job Openings" {...a11yProps(0)} />
                                <Tab label="Available Projects" {...a11yProps(1)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <div className="text-center mt-5">
                                    <img src={IconStar} alt="" />
                                    <p className='text-dark mt-2'>No favorites added yet</p>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ProjectCard
                                    name="HVAC contractor for Atrium Business Center"
                                    description="Material, human and time resources, with various technical and organizational difficulties, are used to carry out design and construction and installation work (CMP). At the same time, situations often arise when a contractor for a project may act in bad faith, take another more profitable order and not start work on a previously concluded contract"
                                    tags={['Large Building Construction', 'HVAC']}
                                    address="10005, New York, NY"
                                    price="Negotiated"
                                    deadline="2 weeks"
                                />
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(JobSeekerFavorite)
import React from 'react';
import { connect } from 'react-redux'
import {
  actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import SignUpPage from 'components/pages/signup';
import FooterLink from 'components/FooterLink';
import NavbarMenuTop from 'components/NavbarMenuTop'

const SignIn = (props) => {
  return (
    <>

    <NavbarMenuTop page={`signup`}/>
    <div className="main-content">
      <SignUpPage/>
    </div>
    <FooterLink/>
    </>
  );
}
const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      news: state.news,
    }
}
const mapDispatchToProps = {...authAction}
export default connect(mapStateToProps, mapDispatchToProps)(SignIn)

import React, { useState } from 'react';
import { Box, CircularProgress, FormControl, FormHelperText, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import iconUpload from 'assets/upload.svg';
import { DEFAULT_IMAGE } from 'constant';
import { string } from 'yup';

function ControlledImage({
  control,
  errors,
  label,
  param,
  marginTop = 0,
  multiple = false,
  noOfUploadedImages = 0,
  max = 5
}) {
  const [loading, setLoading] = useState(true);
  const [localErrors, setLocalErrors] = useState();

  return (
    <Controller
      name={param}
      control={control}
      rules={{ required: 'Picture is required' }}
      render={({ field: { value, onChange, ...field } }) => {
        return (
          <FormControl
            error={errors?.[param]}
            variant="standard"
            sx={{ mt: marginTop, width: '100%' }}>
            <Box className="uploads-container mr-2" sx={{ position: 'relative' }}>
              <input
                type="file"
                multiple={multiple}
                {...field}
                // value={value}
                onChange={(event) => {
                  if (
                    !multiple ||
                    (multiple && event.target.files.length + noOfUploadedImages <= max)
                  ) {
                    onChange(multiple ? event.target.files : event.target.files[0]);
                    setLocalErrors();
                  } else {
                    setLocalErrors('Max number of images: 5');
                  }
                }}
              />

              <img
                src={
                  typeof value === 'string'
                    ? value
                    : value?.length > 1
                    ? DEFAULT_IMAGE
                    : multiple
                    ? typeof value[0] === 'string' || (value.length === 1 && !value[0]?.image)
                      ? URL.createObjectURL(value[0])
                      : value[0]?.image
                    : URL.createObjectURL(value)
                }
                alt=""
                className="upload-preview"
                onLoad={() => setLoading(false)}
                // onLoadedData={() => setLoading(false)}
              />

              {multiple && typeof value !== 'string' && value.length > 1 && (
                <Box sx={{ position: 'absolute', top: '40%', left: '43%', zIndex: 1 }}>
                  <Typography fontWeight={'700'}>{value.length}</Typography>
                </Box>
              )}
              {loading && (
                <Box sx={{ position: 'absolute', top: '40%', left: '43%', zIndex: 1 }}>
                  <CircularProgress size={18} />
                </Box>
              )}
              <div className="uploads-label">
                <img src={iconUpload} alt="" />
                <div>{label}</div>
              </div>
            </Box>
            <FormHelperText id="component-error-text" sx={{ color: 'red' }}>
              {errors?.[param]?.message || localErrors}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

export default ControlledImage;

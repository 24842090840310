import { Box, MenuItem, Select, Typography } from '@mui/material';

function CustomSelect({
  label,
  recordValue,
  onChange,
  arrayRecrods,
  marginTop = 3,
  disabled = false,
  styleText = {}
}) {
  return (
    <Box sx={{ width: '100%', marginTop: marginTop }}>
      <Typography sx={{ color: 'text.primary', marginBottom: 1 }}>{label}</Typography>
      <Select
        variant="outlined"
        value={recordValue ?? null}
        onChange={(e) => {
          onChange(e);
        }}
        sx={[{ width: '100%', height: '44px' }, { ...styleText }]}
        disabled={disabled}>
        {arrayRecrods.map((record) => (
          <MenuItem key={record.value} value={record.value}>
            {record.value === 0 ? <em>{record.label}</em> : record.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default CustomSelect;

import { Grid, Typography } from '@mui/material';
import InputField from 'components/input/InputField';
import React from 'react';

function RecruiterCompanyContactInformation({ control, errors, loading }) {
  return (
    <Grid container mt={6} columnSpacing={2}>
      <Grid item xs={12} className="mb-2">
        <Typography fontWeight={'500'} fontSize={'18px'} variant="h4">
          Company Contact Information
        </Typography>
      </Grid>

      <Grid item xs={6} className="">
        <InputField
          control={control}
          errors={errors}
          label={'Name:'}
          param={'name'}
          type={'text'}
          marginTop={1}
          placeholder={'Enter name'}
          disabled={loading}
        />
      </Grid>
      <Grid item md={6} className="mb-2">
        <InputField
          control={control}
          errors={errors}
          label={'Email address:'}
          param={'company_email'}
          type={'text'}
          marginTop={1}
          disabled={loading}
        />
      </Grid>
    </Grid>
  );
}

export default RecruiterCompanyContactInformation;

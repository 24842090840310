import React from 'react';

import { connect } from 'react-redux'
import {
    actionToProps as authAction
} from 'store/reducers/auth/auth.action'
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { Container } from '@mui/material';
import IconArrow from "assets/arrow-left-outline.svg"

const JobPostingRule = () => {
    return (
        <>
            <Container>
                <div className="content-inner-wrapper policy-page">
                    <h3 className="single-title d-flex items-center mb-5">
                        <a href="#">
                            <img src={IconArrow} className="sub mr-1" alt="" />
                        </a>
                        Job and Project Posting Rules
                    </h3>
                    <p>
                        Workium reserves the right in any representation of employment and or any construction project opportunities. If the Job and Project posting rules are not followed, we reserve the right to remove or suspend or terminate the job poster’s account without a refund of any fees paid
                    </p>

                    <h3 className="title-3">Rules</h3>
                    <ul className='dashed'>
                        <li>
                            There should be no job requirements or criteria that discriminate against people based on race, gender, genital information, national origin, citizenship, or military service
                        </li>
                        <li>
                            Ensure compliance with local, state, federal and international laws, including those related to antidiscrimination, labor and employment, equal employment opportunity and employment eligibility
                        </li>
                        <li>
                            Do not disqualify applicants who have criminal convictions, criminal records, and/or felonies, unless is a legally permissible and legitimate requirement of the job, or a government regulation.

                        </li>
                        <li>
                            Not contain any screening criteria or requirements that aren’t, legitimately, and legally necessary for the employment.

                        </li>
                        <li>
                            Each job listing reflects a genuine, active position.

                        </li>
                        <li>
                            Franchise and business opportunities should not be posted.

                        </li>
                        <li>
                            Never should a job ad request that a candidate provide a photo or video along with their application.

                        </li>
                        <li>
                            Don't post - Focus groups, opinion participants, survey participants, etc.

                        </li>
                        <li>
                            Contains or links to content that exploits an individual sexually, violently, or otherwise, or that solicits personal information from anyone under the age of 16

                        </li>
                        <li>
                            Job advertisers should not require job applicants to pay employers directly

                        </li>
                        <li>
                            Don't post - multi-level marketing opportunity

                        </li>
                        <li>
                            Job advertisers should not include non-job-related keywords in job titles or job descriptions

                        </li>
                        <li>
                            Job posters should never include content, or links to content, that is illegal, offensive, obscene, threatening, exploitative, violent, or inappropriate for any other reason

                        </li>
                        <li>
                            Do not post - Any arrangement that does not represent an employment opportunity

                        </li>
                        <li>
                            Job posters should never include any personal information in the job description or as part of the instructions for submitting the application

                        </li>
                        <li>
                            Do not post - Survey collections, opinion participants, focus groups, etc.

                        </li>
                    </ul>

                </div>

            </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        news: state.news,
    }
}
const mapDispatchToProps = { ...authAction, ...newsAction }
export default connect(mapStateToProps, mapDispatchToProps)(JobPostingRule)
import React, { useState } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { connect } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import Checkbox from '@mui/material/Checkbox';
import { Grid } from '@mui/material';
import CardPrice from 'components/CardPrice';
import IconBuilding from 'assets/hotel.svg';
import IconRocket from 'assets/rocket.svg';
import IconPlus from 'assets/plus.svg';
import { Link } from 'react-router-dom';

const AvailableProject = () => {
  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="content-inner-wrapper">
              <h2 className="title-2 mb-4">Available Project Creation</h2>
              <Grid container spacing={3} justifyContent="center">
                <Grid item md={8}>
                  <div className="text-dark mb-1">
                    <Checkbox
                      sx={{
                        pl: '0'
                      }}
                    />
                    I have read and agree with the{' '}
                    <a href="#">rules of posting Available Projects</a>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={3} justifyContent="center">
                <Grid item md={4}>
                  <CardPrice
                    title="STARTER"
                    icon={IconBuilding}
                    price="FREE"
                    dataId="price2"
                    radioName="price">
                    <ul>
                      <li>Post Available Project For 30 Days</li>
                      <li>Re-Subcontract Your Work and Grow Your Business and Profits</li>
                      <li>Grow Your Trade Partner Network</li>
                      <li>Have Your Ad Seen On Other Contractors Home Pages</li>
                    </ul>
                  </CardPrice>
                </Grid>
                <Grid item md={4}>
                  <CardPrice
                    title="PRO"
                    icon={IconRocket}
                    price="$7.95"
                    dataId="price3"
                    radioName="price">
                    <ul>
                      <li>Post Available Project For 30 Days</li>
                      <li>Re-Subcontract Your Work and Grow Your Business and Profits</li>
                      <li>Grow Your Trade Partner Network</li>
                      <li>Have Your Ad Seen On Other Contractors Home Pages</li>
                      <li>
                        Get your ad distributed on multiple construction social media platforms
                      </li>
                    </ul>
                  </CardPrice>
                </Grid>
              </Grid>

              <div className="text-center mt-4">
                <button className="btn btn-primary mr-1">Back</button>
                <Link to={'/contractor/created-project'}>
                  <button className="btn btn-success ml-1">
                    <img src={IconPlus} className="top mr-1" /> Continue
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(AvailableProject);
